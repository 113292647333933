import { setLocale } from 'yup'

export default setLocale({
  mixed: {
    required: 'VALIDATION:REQUIRED_FIELD',
  },
  string: {
    required: 'VALIDATION:REQUIRED_FIELD',
    length: ({ length }) => ({
      key: 'VALIDATION:STRING_LENGTH',
      params: { length },
    }),
    email: 'VALIDATION:INVALID_EMAIL',
    cpf: 'VALIDATION:INVALID_CPF',
    cnpj: 'VALIDATION:INVALID_CNPJ',
    max: ({ max }) => ({
      key: 'VALIDATION:MAX_LENGTH',
      params: { max },
    }),
  },
  date: {
    required: 'VALIDATION:REQUIRED_FIELD',
    type: 'VALIDATION:INVALID_DATE',
  },
  object: {
    required: 'VALIDATION:REQUIRED_FIELD',
  },
  array: {
    required: 'VALIDATION:REQUIRED_FIELD',
  },
  boolean: {
    required: 'VALIDATION:REQUIRED_FIELD',
  },
})
