/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import wrapper from 'components/Wrapper'
import { COMPROVANTE_ROUTES } from './constants/routes'
import { useReactToPrint } from 'react-to-print'
import { LoadingFixedScreen } from 'modules/InitialPage/styles'
import axios from 'axios'
import { awsTokenManager } from 'config/auth'
import { Wrapper, PrintButtonContainer } from './styles'
import { Container } from 'react-bootstrap'
import { SisuComprovante } from 'components/SisuComprovante'

const Comprovante = () => {
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const [cpf, setCpf] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [apiData1, setApiData1] = useState([])
  const [apiData2, setApiData2] = useState([])
  const [corseType, setCorseType] = useState('')
  const [hash, setHash] = useState('')
  const [createdAt, setCreatedAt] = useState('')

  useEffect(() => {
    const getUserMe = async () => {
      try {
        const token = await awsTokenManager.getToken()
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        }
        let data = await axios
          .get(`${process.env.REACT_APP_API_URL}/users/me`, config)
          .then(({ data }) => data)

        setCpf(data.cpf)

        try {
          const config = {
            headers: { Authorization: `${token}` },
          }

          let data = await axios
            .get(
              `${process.env.REACT_APP_API_URL}/user-registration/${cpf}`,
              config
            )
            .then(({ data }) => data)

          setHash(data[0].hash)
          setCreatedAt(data[0]?.createdDate)
          console.log(data)
          if (data.length !== 0) {
            data.map(item => {
              MapProgram(item)
            })
            async function MapProgram(item) {
              if (item.program === 'prouni') {
                setCorseType('PROUNI')
                item.courses.map(item => {
                  MapCoursesOptions(item)
                })
                async function MapCoursesOptions(item) {
                  const courseMap = item.option
                  const courseCode = item.courseName
                  if (courseMap === 'option3') {
                    try {
                      const config = {
                        headers: { Authorization: `${token}` },
                      }

                      let data = await axios
                        .get(`${process.env.REACT_APP_API_URL}/course`, config)
                        .then(({ data }) => data)

                      async function mapCourse1(item) {
                        const courseMap = item.courseTimeCode
                        if (courseMap === courseCode) {
                          setApiData1(item)
                        }
                      }
                      data.map(item => {
                        mapCourse1(item)
                      })
                    } catch (err) {
                      console.log(err)
                    }
                  } else if (courseMap === 'option4') {
                    try {
                      const config = {
                        headers: { Authorization: `${token}` },
                      }

                      let data = await axios
                        .get(`${process.env.REACT_APP_API_URL}/course`, config)
                        .then(({ data }) => data)

                      async function mapCourse1(item) {
                        const courseMap = item.courseTimeCode
                        if (courseMap === courseCode) {
                          setApiData2(item)
                        }
                      }
                      data.map(item => {
                        mapCourse1(item)
                      })
                      setTimeout(function () {
                        // setLoaded(true);
                      }, 1000)
                    } catch (err) {
                      console.log(err)
                    }
                  }
                }
              } else if (item.program === 'sisu') {
                setCorseType('SISU')
                item.courses.map(item => {
                  MapCoursesOptions(item)
                })
                async function MapCoursesOptions(item) {
                  const courseMap = item.option
                  const courseCode = item.courseName
                  if (courseMap === 'option1') {
                    try {
                      const config = {
                        headers: { Authorization: `${token}` },
                      }

                      let data = await axios
                        .get(`${process.env.REACT_APP_API_URL}/course`, config)
                        .then(({ data }) => data)

                      async function mapCourse1(item) {
                        const courseMap = item.courseTimeCode
                        if (courseMap === courseCode) {
                          setApiData1(item)
                        }
                      }
                      data.map(item => {
                        mapCourse1(item)
                      })
                    } catch (err) {
                      console.log(err)
                    }
                  } else if (courseMap === 'option2') {
                    try {
                      const config = {
                        headers: { Authorization: `${token}` },
                      }

                      let data = await axios
                        .get(`${process.env.REACT_APP_API_URL}/course`, config)
                        .then(({ data }) => data)

                      async function mapCourse1(item) {
                        const courseMap = item.courseTimeCode
                        if (courseMap === courseCode) {
                          setApiData2(item)
                        }
                      }
                      data.map(item => {
                        mapCourse1(item)
                      })
                      setTimeout(function () {
                        // setLoaded(true);
                      }, 1000)
                    } catch (err) {
                      console.log(err)
                    }
                  }
                }
              }
            }
          } else {
            // setLoaded(true);
          }
        } catch (err) {
          console.log(err)
        }
      } catch (err) {
        console.log(err)
      }
    }

    getUserMe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf])

  // useEffect(() => {
  //   if (loaded) {
  //     handlePrint();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loaded]);

  // console.log(apiData1);
  return (
    <>
      {loaded ? null : (
        <>
          <LoadingFixedScreen data-test="LoadingFixedScreen">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </LoadingFixedScreen>
        </>
      )}
      <Wrapper>
        <Container>
          <PrintButtonContainer>
            <a className="printButton" onClick={handlePrint}>
              Imprimir comprovante
            </a>
          </PrintButtonContainer>

          <SisuComprovante
            title={corseType}
            ref={componentRef}
            setLoaded={setLoaded}
            createdAt={createdAt}
            firstCourseOption={apiData1}
            secondCourseOption={apiData2}
            hash={hash}
          />
        </Container>
      </Wrapper>
    </>
  )
}

export default wrapper(Comprovante, {
  route: { path: COMPROVANTE_ROUTES.COMPROVANTE },
})
