import React, { useContext } from 'react'
import { Button, Container, DivImg, DivText } from './styles'

import avatarIconImg from './img/avatar_icon.png'
import Context from './Context'

export function DataConfirm(props) {
  const { setClick } = useContext(Context)
  return (
    <Container data-test="confirmation-data-Container">
      <div className="row" data-test="confirmation-data-row">
        <div className="col-12" data-test="confirmation-data-div">
          <h1 data-test="confirmation-data-h1">{props.main_title}</h1>
        </div>
        <DivImg
          className="col-sm-2"
          data-test="confirmation-data-avatar-DivImg"
        >
          <img
            data-test="confirmation-data-avatar"
            src={avatarIconImg}
            alt=""
          />
          <h3 data-test="confirmation-data-avatar-name"> </h3>
        </DivImg>
        <DivText className="col-sm-10" data-test="confirmation-data-DivText">
          <div>
            <svg
              data-test="confirmation-data-seta"
              width="35"
              height="36"
              viewBox="0 0 35 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.82842 20.5416C0.266326 18.9795 0.26633 16.4469 1.82843 14.8848L16.7132 -1.43458e-05L34.4264 17.7132L16.7132 35.4264L1.82842 20.5416Z"
                fill="#E2EAF5"
              />
            </svg>

            <h2 data-test="confirmation-data-hello-name">{props.hello_name}</h2>
            <p data-test="confirmation-data-description">{props.description}</p>
            <ul data-test="confirmation-data-list">{props.description_list}</ul>
          </div>

          <Button
            data-test="confirmation-data-Button"
            onClick={function (event) {
              setClick(true)
            }}
          >
            {props.button_text}
          </Button>
        </DivText>
      </div>
    </Container>
  )
}
