import React from 'react'

import PropTypes from 'prop-types'

import { Typography, useTheme } from '@material-ui/core'

import { LogoRNP } from 'components/Image'

import {
  Container,
  InfoContainerBG,
  InfoContainer,
  InfoContent,
  Title,
  LogoContainer,
  Description,
  PageContainer,
} from './style'

const PublicContainer = ({ title, description, children }) => {
  const theme = useTheme()

  return (
    <Container>
      <InfoContainerBG>
        <InfoContainer maxWidth="sm">
          <InfoContent>
            {title ? (
              <Title>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: 700,
                    color: theme.palette.primary.contrastText,
                  }}
                >
                  {title}
                </Typography>
              </Title>
            ) : null}
            {description ? (
              <Description>
                <Typography
                  variant="body1"
                  style={{ color: theme.palette.primary.contrastText }}
                >
                  {description}
                </Typography>
              </Description>
            ) : null}
          </InfoContent>
          <LogoContainer>
            <LogoRNP />
          </LogoContainer>
        </InfoContainer>
      </InfoContainerBG>
      <PageContainer maxWidth="sm">{children}</PageContainer>
    </Container>
  )
}

PublicContainer.defaultProps = {
  title: null,
  description: null,
  children: null,
}

PublicContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default PublicContainer
