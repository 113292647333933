import styled from 'styled-components'

import { FONT_FAMILY } from 'constants/fonts'

export const Wrapper = styled.div`
  padding: 0;
  margin: 0;
  margin-top: 28px;

  position: relative;

  font-family: ${FONT_FAMILY};

  p {
    margin: 0 0 4px 0;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: var(--colorBlack);

    span {
      position: initial;
      font-size: 30px;
      margin-bottom: -5px;
      margin-left: 2px;
      color: #000000;
    }
  }

  span {
    position: absolute;
    bottom: -75px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: red;
  }
`
