import styled, { css } from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 30px;
`

export const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
`

export const LinksContainer = styled.div`
  display: flex;
  margin: 20px;
`

export const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 160px;
  text-decoration: none;
  cursor: pointer;
  margin: 10px;

  ${props => css`
    color: ${props.theme.palette.grey[200]};
    background-color: ${props.theme.palette.grey[700]};
    border-radius: 5px;
    margin: ${props.theme.margin};

    & svg {
      margin: 5px;
    }
  `}
`

export const ClickableSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;

  ${props => css`
    color: ${props.theme.palette.primary.main};
  `}
`

export const CopyContainer = styled.span`
  margin-bottom: 20px;
  ${props => css`
    margin: ${props.theme.margin};
  `}
`
