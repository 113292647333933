import { FaAngleDown, FaAngleUp } from 'react-icons/fa'

import styled, { css } from 'styled-components'

const hidden = css`
  visibility: hidden;
`

const icon = css`
  color: ${props => props.theme.palette.primary.main};
  cursor: pointer;
`

export const UpArrow = styled(FaAngleUp)`
  ${icon};
  ${props => props.$hidden && hidden}
`

export const DownArrow = styled(FaAngleDown)`
  ${icon};
  ${props => props.$hidden && hidden}
`

export const RowPosition = styled.div``

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
