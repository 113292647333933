/* eslint-disable react/prop-types */
import React from 'react'

import { useLangContext } from 'components/Globalization'
import wrapper from 'components/Wrapper'

import { Container } from './style'

const CopiedPopup = ({ show }) => {
  const translate = useLangContext()

  if (!show) return null

  return <Container>{translate('COPIED')}</Container>
}

export default wrapper(CopiedPopup, {
  namespace: 'COPIED_POPUP',
})
