import { MessageBox } from '../../MessageBox'
import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import InputRange from 'react-input-range'

import 'react-input-range/lib/css/index.css'

import {
  InputGroupDiv,
  DivSpace,
  SearchButton,
  TitleSearch,
  Check,
  ContainerFilterBy,
  TitleFilter,
  SubTitle,
  ContainerCheck,
  ClearFilter,
  ResultsBox,
  TitleCourse,
  NotesContainer,
} from './style'
import { Divider } from '@material-ui/core'
import { CardCourse } from '../CardCourse'
// import { Pagination } from 'components/SisuSubscribeForm/Pagination';
import { awsTokenManager } from 'config/auth'
import axios from 'axios'
import { LoadingFixedScreen } from '../../style'
import { CourseDetail } from '../CourseDetail'

export function FilterCourse(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
    setCourseDetail(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [value, setValue] = useState({
    min: 0,
    max: 1000,
  })

  const [courseDetail, setCourseDetail] = useState(false)
  const [search, setSearch] = useState(false)
  const [showFilter, setShowFilter] = useState(false)

  function handleSearchCourses() {
    setLoaded(false)
    setSearch(false)
    getUserMe()
    setShowFilter(true)
  }

  const [courses, setCourses] = useState([])
  const [course, setCourse] = useState('')
  const [loaded, setLoaded] = useState(true)
  const [local, setLocal] = useState('')
  const [instituition, setInstituition] = useState('')
  const [totalSearch, setTotalSearch] = useState(0)

  const getUserMe = async () => {
    try {
      const token = await awsTokenManager.getToken()
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      }

      let data = await axios
        .get(
          `${process.env.REACT_APP_API_URL}/course/search?gradeMinimum=${value.min}&gradeMaximum=${value.max}`,
          config
        )
        .then(({ data }) => data)

      setCourses(data)
      setSearch(true)
      setLoaded(true)
      const totalSearch =
        document.getElementsByClassName('cardCourseShow').length
      setTotalSearch(totalSearch)
    } catch (err) {
      console.log(err)
    }
  }

  const [filter1, setFilter1] = useState(true)
  const [filter2, setFilter2] = useState(true)
  const [filter3, setFilter3] = useState(true)
  const [filter4, setFilter4] = useState(true)

  const [conditions1, setConditions1] = useState([])

  useEffect(() => {
    const conditions1 = [
      `${filter1 && 'Bacharelado'}`,
      `${filter2 && 'Licenciatura'}`,
      `${filter3 && 'ABI'}`,
      `${filter4 && 'Tecnólogo'}`,
    ]
    setConditions1(conditions1)
  }, [filter1, filter2, filter3, filter4])

  const [filter5, setFilter5] = useState(true)
  const [filter6, setFilter6] = useState(true)
  const [filter7, setFilter7] = useState(true)
  const [filter8, setFilter8] = useState(true)
  const [filter9, setFilter9] = useState(true)

  // eslint-disable-next-line no-unused-vars
  const [conditions2, setConditions2] = useState([])

  useEffect(() => {
    const conditions2 = [
      `${filter5 && 'Matutino'}`,
      `${filter6 && 'Vespertino'}`,
      `${filter7 && 'Noturno'}`,
      `${filter8 && 'Integral'}`,
      `${filter9 && 'EAD'}`,
    ]
    setConditions2(conditions2)
  }, [filter5, filter6, filter7, filter8, filter9])

  function mouseUp() {
    handleSearchCourses()
  }

  function handleClearFilter() {
    setCourse('')
    setLocal('')
    setInstituition('')
    setTotalSearch('')
  }

  const [notaCorte, setNotaCorte] = useState('')
  const [courseContext, setCourseContext] = useState('')
  const [modality, setModality] = useState('')
  const [courseTime, setCourseTime] = useState('')
  const [university, setUniversity] = useState('')
  const [city, setCity] = useState('')
  const [uf, setUf] = useState('')
  const [timeCode, setTimeCode] = useState('')

  return (
    <>
      {courseDetail ? (
        <CourseDetail
          timeCode={timeCode}
          option={props.option}
          notaCorte={notaCorte}
          course={courseContext}
          modality={modality}
          courseTime={courseTime}
          university={university}
          city={city}
          uf={uf}
        />
      ) : (
        <>
          {loaded ? null : (
            <>
              <LoadingFixedScreen data-test="LoadingFixedScreen">
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </LoadingFixedScreen>
            </>
          )}
          <DivSpace>
            <Container className="py-5">
              <MessageBox
                main_title="Faça a busca do seu curso de preferência"
                description="Utilize o filtro abaixo para encontrar a oferta de vaga perfeita para você dentro do PROUNI. "
              />
            </Container>
            <Container className="my-1">
              <Row>
                <TitleSearch>Buscar opção de curso</TitleSearch>
                <Divider />
              </Row>

              <Row className="mt-5">
                <Col sm={12} md={4}>
                  <InputGroupDiv>
                    <label htmlFor="">Curso</label>
                    <input
                      value={course}
                      onChange={e => {
                        setCourse(e.target.value)
                      }}
                    />
                  </InputGroupDiv>
                </Col>

                <Col sm={12} md={4}>
                  <InputGroupDiv>
                    <label htmlFor="">Local</label>
                    <input
                      value={local}
                      onChange={e => {
                        setLocal(e.target.value)
                      }}
                    />
                  </InputGroupDiv>
                </Col>

                <Col sm={12} md={4}>
                  <InputGroupDiv>
                    <label htmlFor="">Instituição</label>
                    <input
                      value={instituition}
                      onChange={e => {
                        setInstituition(e.target.value)
                      }}
                    />
                  </InputGroupDiv>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-end">
                  <SearchButton>
                    <button
                      onClick={() => {
                        handleSearchCourses()
                      }}
                    >
                      Buscar
                    </button>
                  </SearchButton>
                </Col>
              </Row>
              <Divider className="mt-5" />
            </Container>

            {showFilter && (
              <>
                <Container>
                  <ContainerFilterBy>
                    <TitleFilter>Filtrar por:</TitleFilter>

                    <ContainerCheck>
                      <Row>
                        <Col md={12}>
                          <SubTitle>Grau</SubTitle>
                          <Row>
                            <Col sm={12} md={3}>
                              <Check>
                                <input
                                  type="checkbox"
                                  name="a"
                                  value="1"
                                  defaultChecked={true}
                                  onChange={() => {
                                    setFilter1(!filter1)
                                    handleSearchCourses()
                                  }}
                                />
                                <label htmlFor="">Bacharelado</label>
                              </Check>
                            </Col>

                            <Col sm={12} md={3}>
                              <Check>
                                <input
                                  type="checkbox"
                                  name="a"
                                  value="1"
                                  defaultChecked={true}
                                  onChange={() => {
                                    setFilter2(!filter2)
                                    handleSearchCourses()
                                  }}
                                />
                                <label htmlFor="">Licenciatura</label>
                              </Check>
                            </Col>

                            <Col sm={12} md={3}>
                              <Check>
                                <input
                                  type="checkbox"
                                  name="a"
                                  value="1"
                                  defaultChecked={true}
                                  onChange={() => {
                                    setFilter3(!filter3)
                                    handleSearchCourses()
                                  }}
                                />
                                <label htmlFor="">
                                  ABI (Área Básica de Ingresso)
                                </label>
                              </Check>
                            </Col>

                            <Col sm={12} md={3}>
                              <Check>
                                <input
                                  type="checkbox"
                                  name="a"
                                  value="1"
                                  defaultChecked={true}
                                  onChange={() => {
                                    setFilter4(!filter4)
                                    handleSearchCourses()
                                  }}
                                />
                                <label htmlFor="">Tecnólogo</label>
                              </Check>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4}>
                          <p id="myP">Faixa da Nota de Corte</p>
                          <div onMouseUp={mouseUp}>
                            <NotesContainer>
                              <span>{value.min}</span>
                              <span>{value.max}</span>
                            </NotesContainer>
                            <InputRange
                              maxValue={1000}
                              minValue={0}
                              value={value}
                              onChange={value => {
                                setValue(value)
                                console.log(value)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          <p id="myP">Faixa de valor da mensalidade (R$)</p>
                          <div onMouseUp={mouseUp}>
                            <NotesContainer>
                              <span>{value.min}</span>
                              <span>{value.max}</span>
                            </NotesContainer>
                            <InputRange
                              maxValue={1000}
                              minValue={0}
                              value={value}
                              onChange={value => {
                                setValue(value)
                                console.log(value)
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </ContainerCheck>

                    <ContainerCheck>
                      <Row>
                        <Col>
                          <SubTitle>Turno</SubTitle>
                          <Row>
                            <Col md={2}>
                              <Check>
                                <input
                                  type="checkbox"
                                  name="a"
                                  value="1"
                                  defaultChecked={true}
                                  onChange={() => {
                                    setFilter5(!filter5)
                                    handleSearchCourses()
                                  }}
                                />
                                <label htmlFor="">Matutino</label>
                              </Check>
                            </Col>

                            <Col md={2}>
                              <Check>
                                <input
                                  type="checkbox"
                                  name="a"
                                  value="1"
                                  defaultChecked={true}
                                  onChange={() => {
                                    setFilter6(!filter6)
                                    handleSearchCourses()
                                  }}
                                />
                                <label htmlFor="">Vespertino</label>
                              </Check>
                            </Col>

                            <Col md={2}>
                              <Check>
                                <input
                                  type="checkbox"
                                  name="a"
                                  value="1"
                                  defaultChecked={true}
                                  onChange={() => {
                                    setFilter7(!filter7)
                                    handleSearchCourses()
                                  }}
                                />
                                <label htmlFor="">Noturno</label>
                              </Check>
                            </Col>

                            <Col md={2}>
                              <Check>
                                <input
                                  type="checkbox"
                                  name="a"
                                  value="1"
                                  defaultChecked={true}
                                  onChange={() => {
                                    setFilter8(!filter8)
                                    handleSearchCourses()
                                  }}
                                />
                                <label htmlFor="">Integral</label>
                              </Check>
                            </Col>

                            <Col md={2}>
                              <Check>
                                <input
                                  type="checkbox"
                                  name="a"
                                  value="1"
                                  defaultChecked={true}
                                  onChange={() => {
                                    setFilter9(!filter9)
                                    handleSearchCourses()
                                  }}
                                />
                                <label htmlFor="">EAD</label>
                              </Check>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </ContainerCheck>

                    <ClearFilter>
                      <Divider />
                      <p
                        onClick={() => {
                          handleClearFilter()
                        }}
                      >
                        Limpar Filtro
                      </p>
                    </ClearFilter>
                  </ContainerFilterBy>
                </Container>
              </>
            )}
            {search && (
              <>
                {totalSearch === 0 && (
                  <>
                    <Container className="my-1">
                      <MessageBox
                        main_title="A partir dos filtros escolhidos não encontramos cursos disponíveis"
                        description="Altere os filtros ou refaça a busca para encontrar cursos de sua preferência."
                      />
                    </Container>
                    <ResultsBox>
                      <p>Nenhum curso encontrado</p>
                    </ResultsBox>
                  </>
                )}

                <Container>
                  {totalSearch !== 0 && (
                    <TitleCourse>
                      <h1>{totalSearch} CURSOS ENCONTRADOS</h1>
                    </TitleCourse>
                  )}
                  <Row>
                    {courses.map(item => (
                      <>
                        {item.courseName
                          .toLowerCase()
                          .includes(course.toLowerCase()) && (
                          <>
                            {item.collegeName
                              .toLowerCase()
                              .includes(instituition.toLowerCase()) && (
                              <>
                                {item.campusCity
                                  .toLowerCase()
                                  .includes(local.toLowerCase()) && (
                                  <>
                                    {conditions1.some(el =>
                                      item.courseDegree.includes(el)
                                    ) && (
                                      <>
                                        <Col
                                          className="cardCourseShow"
                                          sm={12}
                                          md={6}
                                          lg={4}
                                          onClick={() => {
                                            setCourseDetail(true)
                                            setNotaCorte(
                                              item.courseTimeMinimumGrade
                                            )
                                            setTimeCode(item.courseTimeCode)
                                            setCourseContext(item.courseName)
                                            setModality(item.courseDegree)
                                            setCourseTime(item.courseTimeType)
                                            setUniversity(item.collegeName)
                                            setCity(item.campusCity)
                                            setUf(item.campusState)
                                          }}
                                          key={item.campusId}
                                        >
                                          <CardCourse
                                            title={item.courseName}
                                            subTitle={item.courseDegree}
                                            college={item.collegeName}
                                            location={item.campusCity}
                                            state={
                                              (item.campusCity,
                                              item.campusState)
                                            }
                                            timeType={item.courseTimeType}
                                            note={item.courseTimeMinimumGrade}
                                            vacancies={
                                              item.courseTimeIesVacancies
                                            }
                                            affirmativeAction="Sim"
                                          />
                                        </Col>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    ))}
                  </Row>
                </Container>
                <Container>
                  {/* <Pagination totalSearch={totalSearch} /> */}
                </Container>
              </>
            )}
          </DivSpace>
        </>
      )}
    </>
  )
}
