export const copyToClipboard = (value, copyTimeoutHandler = () => {}) => {
  const textField = document.createElement('textarea')

  textField.innerText = value

  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()

  setTimeout(() => copyTimeoutHandler(), 2000)
}
