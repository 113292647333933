import React, { useEffect, useState } from 'react'

import {
  Container,
  DivImg,
  ImgContainer,
  LoadingItem,
  TextDiv,
  Wrapper,
} from './styles'

import girlImg from './img/girl.svg'

export function LoadingScreen(props) {
  const [itemStatus1, setItemStatus1] = useState(false)
  const [itemStatus2, setItemStatus2] = useState(false)
  const [itemStatus3, setItemStatus3] = useState(false)
  const [itemStatus4, setItemStatus4] = useState(false)

  useEffect(() => {
    setTimeout(() => setItemStatus1(true), 1000)
    setTimeout(() => setItemStatus2(true), 1500)
    setTimeout(() => setItemStatus3(true), 2000)
    setTimeout(() => setItemStatus4(true), 2500)
  }, [])

  return (
    <Wrapper>
      <Container data-test="loading-data-Container">
        <div className="row" data-test="loading-data-row">
          <DivImg className="col-sm-6" data-test="loading-data-divImg">
            <ImgContainer src={girlImg} data-test="loading-data-ImgContainer" />
          </DivImg>

          <TextDiv className="col-sm-6" data-test="loading-data-TextDiv">
            <h2 data-test="loading-data-title">{props.title}</h2>
            <p data-test="loading-data-description">{props.description}</p>

            <LoadingItem
              className={itemStatus1 ? 'done' : 'processing'}
              data-test="loading-data-LoadingItem"
            />
            <LoadingItem
              className={itemStatus2 ? 'done' : 'processing'}
              data-test="loading-data-LoadingItem"
            />
            <LoadingItem
              className={itemStatus3 ? 'done' : 'processing'}
              data-test="loading-data-LoadingItem"
            />
            <LoadingItem
              className={itemStatus4 ? 'done' : 'processing'}
              data-test="loading-data-LoadingItem"
            />
          </TextDiv>
        </div>
      </Container>
    </Wrapper>
  )
}
