import React, { useCallback } from 'react'

import { Button } from '@material-ui/core'

import { useLangContext } from 'components/Globalization'
import wrapper from 'components/Wrapper'

import { authService, authUseCases } from 'services/providers/auth'

import { Container } from './style'

const Logout = props => {
  const translate = useLangContext()

  const onClickHandler = useCallback(async () => {
    authService.unsetMeFailedFlag()
    await authUseCases.signOut(true)
  }, [])

  return (
    <Container>
      <Button onClick={onClickHandler}>
        {props.label || translate('LOGOUT')}
      </Button>
    </Container>
  )
}

export default wrapper(Logout, {
  namespace: 'LOGIN',
})
