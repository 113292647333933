import Image from '../Image'
import src from 'assets/img/logo-rnp.png'
import { Box } from '@material-ui/core'

const LogoRNP = () => (
  <Box display="block" bgcolor="white" p={2} maxWidth={150}>
    <Image src={src} alt="Logotipo" />
  </Box>
)

export default LogoRNP
