import React from 'react'
import { globalization } from 'components/Globalization'
import { Container } from './style'
import { Footer } from 'components/Footer'

const PrivateFooter = () => {
  // const translate = useLangContext();

  return (
    <Container>
      {/* <Content> */}
      {/* <CustomLink to="#">{translate('PRIVACITY_POLICY')}</CustomLink> */}
      {/* </Content> */}
      <Footer />
    </Container>
  )
}

const globalized = globalization(PrivateFooter, 'PRIVATE_FOOTER')

export default globalized
