export const adressToApi = user => {
  return {
    zipCode: user.zipCode,
    streetAddressType: user.streetAddressType,
    address: user.address,
    number: user.number,
    complement: user.complement,
    neighborhood: user.neighborhood,
    municipality: user.municipality,
    uf: user.uf,
    email: user.email,
    cellPhone: user.cellPhone,
    phoneNumber: user.phoneNumber,
  }
}
