/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react'
// import React, { useCallback, useContext, useEffect } from 'react';

// import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper'

import { AUTH_ROUTES } from 'modules/Auth/constants/routes'
import { authService, authUseCases } from 'services/providers/auth'

// import { TokenLoaderContainer, TokenWrapperContainer } from './style';
// import { Container } from '../../../App/submodules/NoAccess/style';
// import { PulseLoader } from 'react-spinners';
// import { ThemeContext } from 'styled-components';
// import { LogoContainer } from '../../../../components/PublicContainer/style';
// import { LogoRNP } from '../../../../components/Image';
import { LoadingScreen } from 'components/LoadingScreen'

const Logout = () => {
  // const theme = useContext(ThemeContext);
  // const translate = useLangContext();

  const handleLogout = async () => {
    authUseCases
      .forceLogout()
      .then(() => {
        authService.unsetIsWaitingGovBrAuthLogout()
        window.location.href = `${process.env.REACT_APP_GOV_BR_URI}/logout?post_logout_redirect_uri=${process.env.REACT_APP_FRONTEND_URL}/`
      })
      .catch(err => {
        console.error(err)
      })
  }

  const autoLogout = useCallback(() => {
    authUseCases.signOut(false).then(() => {
      handleLogout()
    })
  }, [])

  useEffect(() => {
    setTimeout(() => autoLogout(), 2000)
  }, [autoLogout])

  return (
    <>
      <LoadingScreen
        title="Aguarde por favor"
        description="Aguarde enquanto encerramos a sua sessão..."
      />
    </>
    // <Container>
    //   <TokenWrapperContainer>
    //     <TokenLoaderContainer>
    //       <PulseLoader
    //         size="10px"
    //         margin="2px"
    //         color={theme.textColors.primaryLighten01}
    //       />
    //       <span>{translate('WAIT')}</span>
    //     </TokenLoaderContainer>

    //     <LogoContainer>
    //       <LogoRNP />
    //     </LogoContainer>
    //   </TokenWrapperContainer>
    // </Container>
  )
}

export default wrapper(Logout, {
  namespace: 'LOGOUT',
  route: {
    path: AUTH_ROUTES.LOGOUT,
  },
  mapState: state => ({
    isAuthenticated: state.auth.isAuthenticated,
  }),
})
