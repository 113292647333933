import styled from 'styled-components'

import { Container as MuiContainer } from '@material-ui/core'

export const Container = styled.div`
  display: flex;
  flex: 1;
`

export const InfoContainerBG = styled.div`
  padding: 24px;
  background: ${props => props.theme.palette.primary.main};
  background: linear-gradient(
    45deg,
    ${props => props.theme.palette.primary.dark} 0%,
    ${props => props.theme.palette.primary.light} 100%
  );
`
export const InfoContainer = styled(MuiContainer)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const Info = styled.div``
export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`
export const Title = styled.div``

export const LogoContainer = styled.div`
  text-align: -webkit-center;
  padding-bottom: 32px;
  img {
    max-width: 100px;
  }
`

export const Description = styled.div``
export const PageContainer = styled(MuiContainer)`
  min-width: 350px;
  display: flex;
  flex-direction: column;

  justify-content: center;
  flex: 1;
`
