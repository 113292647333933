import styled from 'styled-components'
export const Wrapper = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 2.5625rem;
`
export const HeaderSubscribe = styled.div`
  display: flex;
  border-radius: 4px;

  align-items: center;
  height: 6.25rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 11rem;
  }
`

export const ClockTime = styled.div`
  border-radius: 4px 0px 0px 4px;

  display: flex;
  gap: 1.8rem;
  align-items: center;
  background: ${props => props.theme.bgColors.wireSisuColor01};

  height: 100%;
  width: 100%;
  padding: 1.8rem 1.5rem;
  flex: 1.5;

  img {
    width: 3rem;
  }

  div {
    h2 {
      font-size: 1.2rem;
      margin: 0;
      padding: 0;
      /* color: ${props => props.theme.bgColors.colorSecondary11}; */
      font-weight: 600;
    }
    p {
      font-size: 1rem;
      margin: 0;
      padding: 0;
      font-weight: 600;
      /* color: ${props => props.theme.bgColors.colorSecondary11}; */

      strong {
        font-weight: 600;
      }
    }

    @media screen and (max-width: 992px) {
      h2 {
        font-size: 1rem;
      }

      p {
        font-size: 0.875rem;
      }
    }
  }
`

export const AverageGrade = styled.div`
  border-radius: 0px 4px 4px 0px;

  display: flex;
  gap: 1.8rem;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  flex: 1;
  padding: 1.8rem 1.5rem;

  background: ${props => props.theme.bgColors.wireSisuColor02};
  /* color: ${props => props.theme.bgColors.colorSecondary01}; */
  div {
    h2 {
      font-size: 1.2rem;
      padding: 0;
      margin: 0;
      /* color: ${props => props.theme.bgColors.colorSecondary01}; */
    }
    p {
      font-size: 1rem;
      /* color: ${props => props.theme.bgColors.colorSecondary01}; */
    }

    @media screen and (max-width: 992px) {
      h2 {
        font-size: 1rem;
      }

      p {
        font-size: 0.875rem;
      }
    }
  }

  span {
    font-size: 2.5rem;
  }
`

export const Title = styled.h1`
  font-size: 2.3rem;
  margin-top: 1rem;
  margin-bottom: 1.25rem;
  font-weight: 400;
  color: ${props => props.theme.bgColors.colorSecondary08};
`
