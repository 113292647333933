/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import * as Yup from 'yup'

import { Box, Button, Grid, Typography } from '@material-ui/core'

import { emailOrDocumentValidation } from 'helpers/emailOrDocumentValidation'

import Form from 'components/Form'
import { globalization, useLangContext } from 'components/Globalization'
import PublicContainer from 'components/PublicContainer'
import { router } from 'components/Router'
import TextField from 'components/TextField'

import { APP_ROUTES } from 'modules/App/constants/route'
import { USER_ROUTES } from 'modules/User/constants/routes'
import { userUseCases } from 'services/providers/user'

import RequestConfirmedPopUp from './fragments/RequestConfirmedPopUp'

const RequestPasswordReset = ({ isAuthenticated }) => {
  const translate = useLangContext()
  const [showRequestConfirmed, setShowRequestConfirmed] = useState(false)

  const RequestPasswordResetSchema = Yup.object().shape({
    username: Yup.string()
      .test(
        'validateUsername',
        translate('EMAIL_OR_DOCUMENT_REQUIRED'),
        emailOrDocumentValidation
      )
      .required(translate('USER_REQUIRED')),
  })

  const onSubmitHandler = async data => {
    const { username } = data

    await userUseCases.resetPassword(username)

    setShowRequestConfirmed(true)
  }

  if (isAuthenticated) {
    return <Redirect to={APP_ROUTES.BASE_ROUTE} />
  }

  return (
    <>
      <RequestConfirmedPopUp show={showRequestConfirmed} />

      <PublicContainer
        title={translate('WELCOME_TITLE')}
        description={translate('WELCOME_MESSAGE')}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" color="primary">
              {translate('TITLE')}
            </Typography>
            <Box mt={2} />
          </Grid>
        </Grid>
        <Form onSubmit={onSubmitHandler} schema={RequestPasswordResetSchema}>
          {formMethods => (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  name="username"
                  label={translate('USER')}
                  placeholder={translate('USER_PLACEHOLDER')}
                  formMethods={formMethods}
                />
              </Grid>

              <Grid item xs={12}>
                <Box mt={2} />
                <Button type="submit" fullWidth size="large">
                  {translate('CONFIRM').toUpperCase()}
                </Button>
              </Grid>
            </Grid>
          )}
        </Form>
      </PublicContainer>
    </>
  )
}

const mapState = state => ({
  isAuthenticated: state.auth.isAuthenticated,
})

const connected = connect(mapState)(RequestPasswordReset)
const globalized = globalization(connected, 'REQUEST_PASSWORD_RESET')

export default router(globalized, {
  path: [USER_ROUTES.REQUEST_PASSWORD_RESET],
})
