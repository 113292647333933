import styled from 'styled-components'

import { FONT_FAMILY } from 'constants/fonts'

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const Thead = styled.thead`
  background-color: ${props => props.theme.bgColors.colorSecondary03};
  text-align: left;
  color: ${props => props.theme.bgColors.primaryDefault};

  tr {
    height: 56px;

    th {
      padding-left: 12px;
      font-size: 14px;
    }
  }
`

export const TableBody = styled.tbody`
  tr {
    font-size: 14px;
    height: 56px;
    border-bottom: 1px solid ${props => props.theme.bgColors.colorSecondary04};

    td {
      padding-left: 12px;
      word-break: break-word;
    }
  }
`

export const Pagination = styled.div`
  font-family: ${FONT_FAMILY};
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  height: 56px;
  align-items: center;
`

export const BlockLeftPagination = styled.div`
  font-size: 14px;

  select {
    border: none;
    font-family: ${FONT_FAMILY};
    color: ${props => props.theme.bgColors.primaryDefault};
    font-weight: 600;
  }
`

export const BlockRightPagination = styled.div`
  font-family: ${FONT_FAMILY};

  span {
    margin-right: 15px;
  }

  button {
    font-family: ${FONT_FAMILY};
    background-color: transparent;
    border: none;
    color: ${props => props.theme.bgColors.primaryDefault};
    font-weight: 600;
    cursor: pointer;
    font-size: 16px;
  }
`
