import React from 'react'
import { useController } from 'react-hook-form'

import PropTypes from 'prop-types'

import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  Switch as MuiSwitch,
  Typography,
} from '@material-ui/core'

const compoenentType = {
  checkbox: MuiCheckbox,
  switch: MuiSwitch,
}
function Checkbox({ type, label, formMethods, name, defaultValue, disabled }) {
  const { control } = formMethods

  const {
    field: { ref, value, onChange, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
  })

  const Component = compoenentType[type] || MuiCheckbox

  return (
    <FormControlLabel
      {...inputProps}
      name={name}
      label={<Typography variant="body2">{label}</Typography>}
      value={value}
      defaultValue={defaultValue}
      onChange={e => onChange(e.target.checked)}
      control={<Component checked={value} inputRef={ref} />}
      disabled={disabled}
    />
  )
}

Checkbox.defaultProps = {
  label: '',
  type: 'checkbox',
  defaultValue: false,
  disabled: false,
}

Checkbox.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  formMethods: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Checkbox
