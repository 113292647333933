import styled from 'styled-components'
import { Button, Grow } from '@material-ui/core'

export const Container = styled.div`
  display: flex;
`

export const ProfileToggler = styled(Button)`
  padding: 8px 16px;
  margin: 0px 10px;
  border-radius: 25px;

  span {
    padding: 0px 5px;
  }
`

export const ProfileOptionList = styled(Grow)`
  transform-origin: right bottom;
`

export const ProfileDiv = styled.div`
  /* margin-left: 20px; */

  img {
    height: 40px;
    width: 40px;

    border-radius: 50%;
  }

  svg {
    margin-left: 8px;
  }
`
