import React from 'react'
import InputMask from 'react-input-mask'

import PropTypes from 'prop-types'

import TextField from 'components/TextField'

const MaskedTextField = ({ mask, ...rest }) => (
  <TextField
    InputProps={{
      inputComponent: InputMask,
      inputProps: { mask, maskChar: null },
    }}
    {...rest}
  />
)

MaskedTextField.defaultProps = {
  defaultValue: '',
  label: '',
}

MaskedTextField.propTypes = {
  formMethods: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  mask: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
}

export default MaskedTextField
