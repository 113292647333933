import React, { useEffect, useState } from 'react'
import { useContentRenderExtraTags } from '../../hooks/use-content-render-extra-tags'
import { Redirect } from 'react-router-dom'

import wrapper from 'components/Wrapper'

import { INITIAL_PAGE_ROUTES } from './constants/routes'
import { LoadingDataResults } from 'components/LoadingDataResults'
import { Display, LoadingFixedScreen } from './styles'

import axios from 'axios'
import { awsTokenManager } from 'config/auth'

const content = {
  headerjs:
    '<script src="https://dsgov.estaleiro.serpro.gov.br/assets/design-system/dist/dsgov-init.js"></script>',
}

const InitialPage = () => {
  useContentRenderExtraTags(content)

  const [timer, setTimer] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [erro_1, setErro_1] = useState('')
  const [erro_2, setErro_2] = useState('')
  const [erro_3, setErro_3] = useState('')
  const [erro_4, setErro_4] = useState('')
  const [sucess, setSucess] = useState(false)
  const [click] = useState(true)
  const [redirect, setRedirect] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [showResults, setshowResults] = useState(false)

  useEffect(() => {
    if (click !== '') {
      setTimer(true)
      if (sucess === true) {
        setRedirect(true)
      }
      setshowResults(true)
    }
  }, [click, sucess])

  const bodyContent = ''

  useEffect(() => {
    const getUser = async () => {
      try {
        const token = await awsTokenManager.getToken()
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        }

        await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/validate`,
          bodyContent,
          config
        )

        setLoaded(true)
        setshowResults(true)
        setSucess(true)
      } catch (err) {
        if (err.response.data.message === 'user.trainee') {
          setErro_1('show_error')
        }
        if (err.response.data.message === 'user.not.enem') {
          setErro_2('show_error')
        }
        if (err.response.data.message === 'user.zero.redaction') {
          setErro_3('show_error')
        }
        if (err.response.data.message === 'user.cpf.error') {
          setErro_4('show_error')
        }
        setLoaded(true)
        setshowResults(true)
      }
    }
    getUser()
  }, [])

  return (
    <>
      {redirect ? <Redirect push to="/confirmation" /> : null}
      {loaded ? null : (
        <>
          <LoadingFixedScreen data-test="LoadingFixedScreen">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </LoadingFixedScreen>
        </>
      )}
      <Display className={!timer && 'loading_done'}>
        <div className={`load_screen ${erro_1}`}>
          <LoadingDataResults
            title="Sentimos muito, mas você não possui acesso ao sistema"
            description="• Identificamos que sua"
            bold=" participação no ENEM foi como TREINEIRO"
          />
        </div>
        <div className={`load_screen ${erro_2}`}>
          <LoadingDataResults
            title="Sentimos muito, mas você não possui acesso ao sistema"
            description={
              <>
                Identificamos que você <span>não fez o ENEM</span> e este é um
                critério para participar dos programas.
              </>
            }
          />
        </div>
        <div className={`load_screen ${erro_3}`}>
          <LoadingDataResults
            title="Sentimos muito, mas você não possui acesso ao sistema"
            description="• Identificamos que você obteve"
            bold=" nota ZERO na redação do ENEM."
          />
        </div>
        <div className={`load_screen ${erro_4}`}>
          <LoadingDataResults
            title="Sentimos muito, mas você não possui acesso ao sistema"
            description="• Identificamos que o seu"
            bold=" CPF não é válido!"
          />
        </div>
      </Display>
    </>
  )
}

export default wrapper(InitialPage, {
  route: { path: INITIAL_PAGE_ROUTES.INITIAL_PAGE },
})
