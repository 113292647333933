import React, { useContext, useEffect, useState } from 'react'
import { MessageBox } from '../MessageBox'
import {
  Button,
  DivError,
  DivErrorMessage,
  DivSection,
  SelectInputGroup,
  Wrapper,
} from './styles'
import InputMask from 'react-input-mask'

import { Dropdown, DropdownButton } from 'react-bootstrap'
import { TextInputRequired } from '../TextInputRequired'
import axios from 'axios'
import { TextInputOptional } from '../TextInputOptional'
import { adressToApi } from '../adapters/adressToApi'
// eslint-disable-next-line no-unused-vars
import { awsTokenManager } from 'config/auth'
import FormContext2 from '../formContext2'
import { LoadingFixedScreen } from '../styles'
import { apiWithToken } from 'services/apiWithToken'
import { clearNonNumericCharacters } from 'utils/clearNonNumericCharacters'

export function StepTwo() {
  useEffect(() => {
    window.scrollTo(0, 0)
    setFormContent2(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [cpf, setCpf] = useState('')
  const [firstPost, setFirstPost] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [isInvalidCep, setIsInvalidCep] = useState(false)
  const [editField, setEditField] = useState(false)

  const [streetAddressType, setStreetAddressType] = useState('R.')
  const [zipCode, setZipCode] = useState('')
  const [cepStatus, setCepStatus] = useState('')
  const [address, setAddress] = useState('')
  const [neighborhood, setNeighborhood] = useState('')
  const [uf, setUf] = useState('')
  const [municipality, setMunicipality] = useState('')
  const [number, setNumber] = useState('')
  const [complement, setComplement] = useState('')
  const [email, setEmail] = useState('')
  const [cellPhone, setCellPhone] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const [number_Error_Flag, set_Number_Error_Flag] = useState(false)
  const [adress_Error_Flag, set_Adress_Error_Flag] = useState(false)
  const [email_Error_Flag, set_Email_Error_Flag] = useState(false)
  const [tel1_Error_Flag, setTel1_Error_Flag] = useState(false)
  const [CEP_Error_Flag, setCEP_Error_Flag] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [lograState, setLograState] = useState([
    'AL.',
    'AE.',
    'AV.',
    'BR.',
    'BC.',
    'CS.',
    'CH.',
    'CJ.',
    'ESTR.',
    'JD.',
    'LG.',
    'LOT.',
    'PQ.',
    'PAT.',
    'PC.',
    'QD.',
    'R.',
    'ST.',
    'TRANSV.',
    'TV.',
    'VL.',
    'Z.',
  ])

  function handleSetLograStatus(lograStatusName) {
    setStreetAddressType(lograStatusName)
  }

  const onInputChange = async event => {
    const inputName = event.target.name
    const inputValue = event.target.value

    if (inputName === 'endereco') {
      set_Adress_Error_Flag(false)
      setAddress(inputValue)
    }
    if (inputName === 'number') {
      set_Number_Error_Flag(false)
      setNumber(inputValue)
    }
    if (inputName === 'complemento') {
      setComplement(inputValue)
    }
    if (inputName === 'bairro') {
      setNeighborhood(inputValue)
    }
    if (inputName === 'uf') {
      setUf(inputValue)
    }
    if (inputName === 'email') {
      set_Email_Error_Flag(false)
      setEmail(inputValue)
    }
    if (inputName === 'contactNumber1') {
      setTel1_Error_Flag(false)
      const cellPhoneInputNumber = inputValue.replace(/[^0-9]+/g, '')
      setCellPhone(cellPhoneInputNumber)
    }
    if (inputName === 'contactNumber2') {
      const cellPhoneInputNumber = inputValue.replace(/[^0-9]+/g, '')
      setPhoneNumber(cellPhoneInputNumber)
    }
  }

  // console.log(zipCode);
  const lograValidation = e => {
    if (!e) return

    const splitedData = e.split(' ')
    let logradouro = 'R.'
    if (splitedData) {
      logradouro = splitedData[0]
    }

    const validation_Alameda =
      logradouro.toUpperCase() === 'ALAMEDA' ||
      logradouro.toUpperCase() === 'AL.' ||
      logradouro.toUpperCase() === 'AL'

    const validation_AreaEspecial =
      logradouro.toUpperCase() === 'AREA' ||
      logradouro.toUpperCase() === 'ÁREA' ||
      logradouro.toUpperCase() === 'AE.' ||
      logradouro.toUpperCase() === 'AE'

    const validation_Avenida =
      logradouro.toUpperCase() === 'AVENIDA' ||
      logradouro.toUpperCase() === 'AV.' ||
      logradouro.toUpperCase() === 'AV'

    const validation_Bairro =
      logradouro.toUpperCase() === 'BAIRRO' ||
      logradouro.toUpperCase() === 'BR.' ||
      logradouro.toUpperCase() === 'BR'

    const validation_Beco =
      logradouro.toUpperCase() === 'BECO' ||
      logradouro.toUpperCase() === 'BC.' ||
      logradouro.toUpperCase() === 'BC'

    const validation_Casa =
      logradouro.toUpperCase() === 'CASA' ||
      logradouro.toUpperCase() === 'CS.' ||
      logradouro.toUpperCase() === 'CS'

    const validation_Chacara =
      logradouro.toUpperCase() === 'CHÁCARA' ||
      logradouro.toUpperCase() === 'CHACARA' ||
      logradouro.toUpperCase() === 'CH.' ||
      logradouro.toUpperCase() === 'CH'

    const validation_Conjunto =
      logradouro.toUpperCase() === 'CONJUNTO' ||
      logradouro.toUpperCase() === 'CJ.' ||
      logradouro.toUpperCase() === 'CJ'

    const validation_Estrada =
      logradouro.toUpperCase() === 'ESTRADA' ||
      logradouro.toUpperCase() === 'ESTR.' ||
      logradouro.toUpperCase() === 'ESTR'

    const validation_Jardim =
      logradouro.toUpperCase() === 'JARDIM' ||
      logradouro.toUpperCase() === 'JD.' ||
      logradouro.toUpperCase() === 'JD'

    const validation_Largo =
      logradouro.toUpperCase() === 'LARGO' ||
      logradouro.toUpperCase() === 'LG.' ||
      logradouro.toUpperCase() === 'LG'

    const validation_Loteamento =
      logradouro.toUpperCase() === 'LOTEAMENTO' ||
      logradouro.toUpperCase() === 'LOT.' ||
      logradouro.toUpperCase() === 'LOT'

    const validation_Parque =
      logradouro.toUpperCase() === 'PARQUE' ||
      logradouro.toUpperCase() === 'PQ.' ||
      logradouro.toUpperCase() === 'PQ'

    const validation_Patio =
      logradouro.toUpperCase() === 'PÁTIO' ||
      logradouro.toUpperCase() === 'PATIO' ||
      logradouro.toUpperCase() === 'PAT.' ||
      logradouro.toUpperCase() === 'PAT'

    const validation_Praca =
      logradouro.toUpperCase() === 'PRAÇA' ||
      logradouro.toUpperCase() === 'PRACA' ||
      logradouro.toUpperCase() === 'PC.' ||
      logradouro.toUpperCase() === 'PC'

    const validation_Quadra =
      logradouro.toUpperCase() === 'QUADRA' ||
      logradouro.toUpperCase() === 'QD.' ||
      logradouro.toUpperCase() === 'QD'

    const validation_Rua =
      logradouro.toUpperCase() === 'RUA' ||
      logradouro.toUpperCase() === 'R.' ||
      logradouro.toUpperCase() === 'R'

    const validation_Setor =
      logradouro.toUpperCase() === 'SETOR' ||
      logradouro.toUpperCase() === 'ST.' ||
      logradouro.toUpperCase() === 'ST'

    const validation_Transversal =
      logradouro.toUpperCase() === 'TRANSVERSAL' ||
      logradouro.toUpperCase() === 'TRANSV.' ||
      logradouro.toUpperCase() === 'TRANSV'

    const validation_Travessa =
      logradouro.toUpperCase() === 'TRAVESSA' ||
      logradouro.toUpperCase() === 'TV.' ||
      logradouro.toUpperCase() === 'TV'

    const validation_Vila =
      logradouro.toUpperCase() === 'VILA' ||
      logradouro.toUpperCase() === 'VL.' ||
      logradouro.toUpperCase() === 'VL'

    const validation_Zona =
      logradouro.toUpperCase() === 'ZONA' ||
      logradouro.toUpperCase() === 'Z.' ||
      logradouro.toUpperCase() === 'Z'

    if (validation_Alameda) {
      return handleSetLograStatus('AL.')
    } else if (validation_AreaEspecial) {
      return handleSetLograStatus('AE.')
    } else if (validation_Avenida) {
      return handleSetLograStatus('AV.')
    } else if (validation_Bairro) {
      return handleSetLograStatus('BR.')
    } else if (validation_Beco) {
      return handleSetLograStatus('BC.')
    } else if (validation_Casa) {
      return handleSetLograStatus('CS.')
    } else if (validation_Chacara) {
      return handleSetLograStatus('CH.')
    } else if (validation_Conjunto) {
      return handleSetLograStatus('CJ.')
    } else if (validation_Estrada) {
      return handleSetLograStatus('ESTR.')
    } else if (validation_Jardim) {
      return handleSetLograStatus('JD.')
    } else if (validation_Largo) {
      return handleSetLograStatus('LG.')
    } else if (validation_Loteamento) {
      return handleSetLograStatus('LOT.')
    } else if (validation_Parque) {
      return handleSetLograStatus('PQ.')
    } else if (validation_Patio) {
      return handleSetLograStatus('PAT.')
    } else if (validation_Praca) {
      return handleSetLograStatus('PC.')
    } else if (validation_Quadra) {
      return handleSetLograStatus('QD.')
    } else if (validation_Rua) {
      return handleSetLograStatus('R.')
    } else if (validation_Setor) {
      return handleSetLograStatus('ST.')
    } else if (validation_Transversal) {
      return handleSetLograStatus('TRANSV.')
    } else if (validation_Travessa) {
      return handleSetLograStatus('TV.')
    } else if (validation_Vila) {
      return handleSetLograStatus('VL.')
    } else if (validation_Zona) {
      return handleSetLograStatus('Z.')
    } else {
      return handleSetLograStatus('R.')
    }
  }

  const onCepNumberChange = async event => {
    setCEP_Error_Flag(false)
    setIsInvalidCep(true)
    setCepStatus('')
    const cepValue = event.target.value
    const cepCleared = cepValue.replace(/\D/g, '')

    if (!cepValue.includes('_')) {
      try {
        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cepCleared}/json`
        )
        // console.log(data);

        if (data.erro) {
          setCepStatus('CEP Não Encontrado')
          setEditField(true)
          setIsInvalidCep(false)
        }

        if (!data.erro) {
          setEditField(false)
          setIsInvalidCep(false)
          setCepStatus('')
          lograValidation(data?.logradouro)

          setAddress(data.logradouro || '')
          setNeighborhood(data.bairro)
          setUf(data.uf)
          setMunicipality(data.localidade)
        }
      } catch (error) {
        console.error(error)
      }
    }

    setZipCode(clearNonNumericCharacters(cepValue))
  }

  useEffect(() => {
    const getUserMe = async () => {
      try {
        let data = await apiWithToken
          .get(`${process.env.REACT_APP_API_URL}/users/me`)
          .then(({ data }) => data)

        setCpf(data.cpf)
        setEmail(data.email)

        try {
          let data = await apiWithToken
            .get(`${process.env.REACT_APP_API_URL}/users-address?name=${cpf}`)
            .then(({ data }) => data)

          if (data.length !== 0) {
            setStreetAddressType(data.streetAddressType)
            setZipCode(data.zipCode)
            setAddress(data.address)
            setNeighborhood(data.neighborhood)
            setUf(data.uf)
            setMunicipality(data.municipality)
            setNumber(data.number)
            setComplement(data.complement)
            setEmail(data.email)
            setCellPhone(data.cellPhone)
            setPhoneNumber(data.phoneNumber)
          } else {
            setFirstPost(true)
          }

          setLoaded(true)
        } catch (err) {
          console.log(err)
        }
      } catch (err) {
        console.log(err)
      }
    }

    getUserMe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf])
  // const [showButton, setShowButton] = useState(false)
  useEffect(() => {
    if (
      zipCode.trim().length !== 0 &&
      zipCode.trim().length === 8 &&
      address.trim().length !== 0 &&
      number.trim().length !== 0 &&
      email.trim().length !== 0 &&
      !(cellPhone.length > 0 && cellPhone.length < 11) &&
      !(phoneNumber.length > 0 && phoneNumber.length < 10) &&
      ((cellPhone.length && !phoneNumber.length) ||
        (!cellPhone.length && phoneNumber.length) ||
        (cellPhone.length && phoneNumber.length))
    ) {
      // setShowButton(true)
    } else {
      // setShowButton(false)
    }
  }, [address, cellPhone, complement, email, number, zipCode, phoneNumber])

  const handleForm2 = async data => {
    data.preventDefault()
    setLoaded(false)
    if (firstPost === true) {
      try {
        const userMapped = adressToApi({
          zipCode,
          streetAddressType,
          address,
          number,
          complement,
          neighborhood,
          municipality,
          uf,
          email,
          cellPhone,
          phoneNumber,
        })

        await apiWithToken.post(
          `${process.env.REACT_APP_API_URL}/users-address?name=${cpf}`,
          userMapped
        )
        setFormContent2(true)
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        const userMapped = adressToApi({
          zipCode,
          streetAddressType,
          address,
          number,
          complement,
          neighborhood,
          municipality,
          uf,
          email,
          cellPhone,
          phoneNumber,
        })

        await apiWithToken.put(
          `${process.env.REACT_APP_API_URL}/users-address?name=${cpf}`,
          userMapped
        )
        setFormContent2(true)
      } catch (err) {
        console.log(err)
      }
    }
  }
  console.log(address)

  function handleValidationFocus(e) {
    document.getElementById('formtoscroll').scrollIntoView()
  }

  // eslint-disable-next-line no-unused-vars
  const { formContent2, setFormContent2 } = useContext(FormContext2)
  return (
    <>
      {loaded ? null : (
        <>
          <LoadingFixedScreen data-test="LoadingFixedScreen">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </LoadingFixedScreen>{' '}
        </>
      )}
      <Wrapper data-test="confirmation-data-steps-Wrapper">
        <MessageBox
          style={{ width: '103%' }}
          main_title="Agora confirme os seus dados de contato"
          description={
            <>
              Certo, agora vamos ver os seus dados de contato, para que possamos
              falar com você caso seja necessário.
            </>
          }
        />
        <form id="form2" onSubmit={handleForm2}>
          <DivSection
            className="row"
            data-test="confirmation-data-steps-DivSection"
            id="formtoscroll"
          >
            <div className="col-md-6 noPadding">
              <div className="row">
                <div className="col-md-6" style={{ position: 'relative' }}>
                  <TextInputRequired
                    title="CEP"
                    errorMessage="Campo obrigatório"
                    showErrorMessage={CEP_Error_Flag ? true : false}
                  />
                  {loaded ? (
                    <>
                      <InputMask
                        data-test="confirmation-data-steps-input"
                        type="text"
                        placeholder="00000-000"
                        name="cep"
                        defaultValue={zipCode}
                        mask="99999-999"
                        required
                        onChange={onCepNumberChange}
                        id="idGoesHere23"
                        onInvalid={() => {
                          handleValidationFocus('idGoesHere23')
                          setCEP_Error_Flag(true)
                          setIsInvalidCep(false)
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <InputMask
                        data-test="confirmation-data-steps-input"
                        type="text"
                        placeholder="00000-000"
                        name="cep"
                        defaultValue={zipCode}
                        required
                        onChange={onCepNumberChange}
                      />
                    </>
                  )}
                  <DivErrorMessage>
                    {cepStatus}
                    {isInvalidCep && 'CEP inválido'}
                  </DivErrorMessage>
                </div>
                <div className="col-md-6">
                  <p className="selectText">Tipo de Logradouro</p>
                  <SelectInputGroup data-test="confirmation-data-steps-SelectInputGroup">
                    <DropdownButton
                      data-test="confirmation-data-steps-DropdownButton"
                      className="dropPersonalized"
                      title={streetAddressType}
                    >
                      {lograState.map(e => (
                        <Dropdown.Item
                          key={e}
                          href="#"
                          onClick={() => {
                            handleSetLograStatus(e)
                          }}
                        >
                          {e}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </SelectInputGroup>
                </div>
                <div className="col-md-6">
                  <TextInputRequired
                    title="Número"
                    errorMessage="Campo obrigatório"
                    showErrorMessage={number_Error_Flag ? true : false}
                  />
                  <InputMask
                    id="idGoesHere1"
                    onInvalid={() => {
                      handleValidationFocus('idGoesHere1')
                      set_Number_Error_Flag(true)
                    }}
                    data-test="confirmation-data-steps-input"
                    type="text"
                    placeholder="00"
                    name="number"
                    defaultValue={number}
                    required
                    onChange={onInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <TextInputOptional
                    title="Complemento (opcional)"
                    errorMessage="Campo obrigatório"
                    showErrorMessage={false}
                  />
                  <InputMask
                    data-test="confirmation-data-steps-input"
                    type="text"
                    placeholder="Insira o complemento..."
                    name="complemento"
                    defaultValue={complement}
                    onChange={onInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <TextInputRequired
                title="Endereço"
                errorMessage="Campo obrigatório"
                showErrorMessage={adress_Error_Flag ? true : false}
              />
              <InputMask
                id="idGoesHere2"
                onInvalid={() => {
                  handleValidationFocus('idGoesHere2')
                  set_Adress_Error_Flag(true)
                }}
                data-test="confirmation-data-steps-input"
                type="text"
                placeholder="Endereço..."
                name="endereco"
                defaultValue={address}
                required
                onChange={onInputChange}
              />

              <TextInputRequired
                title="Bairro"
                errorMessage="Campo obrigatório"
                showErrorMessage={false}
              />
              <InputMask
                className={!editField ? 'disabledBefore' : ''}
                disabled={!editField}
                data-test="confirmation-data-steps-input"
                type="text"
                placeholder="Vila da Serra"
                name="bairro"
                defaultValue={neighborhood}
                required
                onChange={onInputChange}
              />
            </div>
            <div className="col-md-6">
              <TextInputRequired
                title="Município"
                errorMessage="Campo obrigatório"
                showErrorMessage={false}
              />
              <InputMask
                className={!editField ? 'disabledBefore' : ''}
                disabled={!editField}
                data-test="confirmation-data-steps-input"
                type="text"
                placeholder="Porto Alegre"
                name="municipio"
                defaultValue={municipality}
                required
                onChange={onInputChange}
              />
            </div>
            <div className="col-md-6">
              <TextInputRequired
                title="UF"
                errorMessage="Campo obrigatório"
                showErrorMessage={false}
              />
              <InputMask
                className={!editField ? 'disabledBefore' : ''}
                disabled={!editField}
                data-test="confirmation-data-steps-input"
                type="text"
                placeholder="AA"
                name="UF"
                defaultValue={uf}
                required
                onChange={onInputChange}
              />
            </div>
          </DivSection>
          <DivSection
            className="row"
            data-test="confirmation-data-steps-DivSection"
          >
            <div className="col-md-6">
              <TextInputRequired
                title="E-mail"
                errorMessage="Campo obrigatório"
                showErrorMessage={email_Error_Flag ? true : false}
              />
              <InputMask
                id="idGoesHere3"
                onInvalid={() => {
                  handleValidationFocus('idGoesHere3')
                  set_Email_Error_Flag(true)
                }}
                data-test="confirmation-data-steps-input"
                type="email"
                placeholder="email@candidato.com.br"
                name="email"
                defaultValue={email}
                required
                onChange={onInputChange}
              />
            </div>
            <div className="col-md-3">
              <TextInputRequired title="Telefone para contato 1" />
              {loaded ? (
                <>
                  <InputMask
                    data-test="confirmation-data-steps-input"
                    type="text"
                    placeholder="99 99999-9999"
                    name="contactNumber1"
                    defaultValue={cellPhone}
                    mask="(99) 99999-9999"
                    required={!phoneNumber}
                    onChange={onInputChange}
                    id="idGoesHere22"
                    onInvalid={() => {
                      handleValidationFocus('idGoesHere22')
                      setTel1_Error_Flag(true)
                    }}
                  />
                </>
              ) : (
                <>
                  <InputMask
                    data-test="confirmation-data-steps-input"
                    type="text"
                    placeholder="99 99999-9999"
                    name="contactNumber1"
                    defaultValue={cellPhone}
                    required
                    onChange={onInputChange}
                  />
                </>
              )}

              <DivError>
                {(cellPhone.replace(/[^0-9]+/g, '').length < 10 &&
                  cellPhone.replace(/[^0-9]+/g, '').length > 0) ||
                tel1_Error_Flag
                  ? 'Não foi informado nenhum número para contato telefônico, é obrigatório a inclusão de pelo menos um número de contato'
                  : null}
              </DivError>
            </div>
            <div className="col-md-3">
              <TextInputRequired
                title="Telefone para contato 2 (opcional)"
                errorMessage="Dado inválido"
                showErrorMessage={
                  phoneNumber.replace(/[^0-9]+/g, '').length < 10 &&
                  phoneNumber.replace(/[^0-9]+/g, '').length > 0
                    ? 'Dado inválido'
                    : null
                }
              />
              {loaded ? (
                <>
                  <InputMask
                    data-test="confirmation-data-steps-input"
                    type="text"
                    placeholder="99 99999-9999"
                    name="contactNumber2"
                    defaultValue={phoneNumber}
                    mask="(99) 99999-9999"
                    onChange={onInputChange}
                  />
                </>
              ) : (
                <>
                  <InputMask
                    data-test="confirmation-data-steps-input"
                    type="text"
                    placeholder="99 99999-9999"
                    name="contactNumber2"
                    defaultValue={phoneNumber}
                    onChange={onInputChange}
                  />
                </>
              )}
            </div>
          </DivSection>

          <Button
            data-test="confirmation-data-steps-ButtonNext"
            className="next"
            type="submit"
            form="form2"
            defaultValue="Submit"
          >
            Confirmar
          </Button>
        </form>
      </Wrapper>
    </>
  )
}
