import { Container } from 'react-bootstrap'
import styled from 'styled-components'

export const TitlePage = styled.h1`
  font-size: 1.5rem;
  margin: 4.3rem 0;
  padding: 0;
`


export const Modalities = styled(Container)`
  > h1 {
    margin: 0;
    padding: 0;
    margin-top: 2.8rem;
    font-size: 1.25rem;
    font-weight: 500;
  }

  > h2 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 500;
  }

  > h3 {
    margin: 0;

    background-color: ${props => props.theme.bgColors.primaryDefault};
    padding: 0.625rem;
    margin-top: 1.75rem;
    font-size: 1rem;
    color: ${props => props.theme.bgColors.colorSecondary01};
    font-weight: bold;
  }

  > h4 {
    margin-top: 1rem;
    padding: 0;
    font-size: 1.25rem;
  }
`
