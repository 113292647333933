/* eslint-disable array-callback-return */
import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button, ButtonSetData } from './styles'

export function ConfirmPopup({ title, children, confirmHandler, cancelHandler }) {

  return (<Modal show={true} onHide={cancelHandler} centered style={{ boxShadow: '0px 9px 6px rgba(51, 51, 51, 0.2);'}}>
    
    {title && (
      <Modal.Title style={{ fontSize: '1.25rem', fontWeight: 'bold', padding: '1rem', background: '#E2EAF5', color: '#0C326F'}}>
        {title}
      </Modal.Title>
    )}

    {children && (
    <Modal.Body style={{ fontStyle: 'italic', color: '#000000' }}>
      <div className="modalBody" style={{ color: '#000000', fontWeight: 600, fontSize: '0.8rem'}}>
        {children}
      </div>
    </Modal.Body>
    )}
    
    <Modal.Footer style={{ borderBottom: '0', borderTop: '0' }}>
      <ButtonSetData>
        <Button className="buttonStyleGOV modalBProps cancel" onClick={cancelHandler}>
          Cancelar
        </Button>
        <Button className="buttonStyleGOV primary modalBProps " onClick={confirmHandler}>
          Confirmar
        </Button>
      </ButtonSetData>
    </Modal.Footer>
  </Modal>)
}
