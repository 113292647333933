/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'

import wrapper from 'components/Wrapper'

import { FIES_FOLLOW_REGISTRATION_ROUTES } from './constants/routes'
import BreadcrumbWrapper from 'components/Breadcrumb'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import homeSvg from './img/homeIcon.svg'

import {
  ContainerStep,
  SubscribeInformation,
  ButtonsStepContainer,
  ConfirmedCourse,
  HashContainer,
  LeftButtons,
} from './style'
import { Container, Row, Col } from 'react-bootstrap'
import { CardConfirmCourse } from './CardConfirmCourse'
import { HeaderSisu } from './HeaderSisu'
import { LoadingFixedScreen } from 'modules/InitialPage/styles'
import { awsTokenManager } from 'config/auth'
import axios from 'axios'

const FiesFollowRegistration = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [cpf, setCpf] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [apiData1, setApiData1] = useState([])
  const [apiData2, setApiData2] = useState([])
  const [apiData3, setApiData3] = useState([])

  const [courseCode1, setCourseCode1] = useState('')
  const [courseCode2, setCourseCode2] = useState('')
  const [courseCode3, setCourseCode3] = useState('')
  const [hash, setHash] = useState('')

  useEffect(() => {
    const getUserMe = async () => {
      try {
        const token = await awsTokenManager.getToken()
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        }
        let data = await axios
          .get(`${process.env.REACT_APP_API_URL}/users/me`, config)
          .then(({ data }) => data)

        setCpf(data.cpf)

        try {
          const config = {
            headers: { Authorization: `${token}` },
          }

          let data = await axios
            .get(
              `${process.env.REACT_APP_API_URL}/user-registration/${cpf}`,
              config
            )
            .then(({ data }) => data)
          setHash(data[0].hash)
          if (data.length !== 0) {
            // eslint-disable-next-line array-callback-return
            data.map(item => {
              MapProgram(item)
            })
            async function MapProgram(item) {
              if (item.program === 'fies') {
                item.courses.map(item => {
                  MapCoursesOptions(item)
                })
                async function MapCoursesOptions(item) {
                  const courseMap = item.option
                  const courseCode = item.courseName
                  if (courseMap === 'option5') {
                    try {
                      const config = {
                        headers: { Authorization: `${token}` },
                      }

                      let data = await axios
                        .get(`${process.env.REACT_APP_API_URL}/course`, config)
                        .then(({ data }) => data)

                      async function mapCourse1(item) {
                        const courseMap = item.courseTimeCode
                        if (courseMap === courseCode) {
                          setApiData1(item)
                          setCourseCode1(item.courseTimeCode)
                        }
                      }
                      data.map(item => {
                        mapCourse1(item)
                      })
                      setLoaded(true)
                    } catch (err) {
                      console.log(err)
                    }
                  } else if (courseMap === 'option6') {
                    try {
                      const config = {
                        headers: { Authorization: `${token}` },
                      }

                      let data = await axios
                        .get(`${process.env.REACT_APP_API_URL}/course`, config)
                        .then(({ data }) => data)

                      async function mapCourse1(item) {
                        const courseMap = item.courseTimeCode
                        if (courseMap === courseCode) {
                          setApiData2(item)
                          setCourseCode2(item.courseTimeCode)
                        }
                      }
                      data.map(item => {
                        mapCourse1(item)
                      })
                      setLoaded(true)
                    } catch (err) {
                      console.log(err)
                    }
                  } else {
                    try {
                      const config = {
                        headers: { Authorization: `${token}` },
                      }

                      let data = await axios
                        .get(`${process.env.REACT_APP_API_URL}/course`, config)
                        .then(({ data }) => data)

                      async function mapCourse1(item) {
                        const courseMap = item.courseTimeCode
                        if (courseMap === courseCode) {
                          setApiData3(item)
                          setCourseCode3(item.courseTimeCode)
                        }
                      }
                      data.map(item => {
                        mapCourse1(item)
                      })
                      setLoaded(true)
                    } catch (err) {
                      console.log(err)
                    }
                  }
                }
              }
            }
          } else {
            setLoaded(true)
          }
        } catch (err) {
          console.log(err)
        }
      } catch (err) {
        console.log(err)
      }
    }

    getUserMe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf])

  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    if (redirect) {
      window.location.href = '/candidate-page'
    }
  }, [redirect])

  const handleCancelSubscription = async () => {
    setLoaded(false)
    try {
      const token = await awsTokenManager.getToken()
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      }
      let data = await axios
        .get(`${process.env.REACT_APP_API_URL}/users/me`, config)
        .then(({ data }) => data)

      const newCpf = data.cpf

      try {
        const config = {
          headers: { Authorization: `${token}` },
        }

        await axios.delete(
          `${process.env.REACT_APP_API_URL}/user-registration/${newCpf}?courseCode=${courseCode1}&program=fies`,
          config
        )
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/user-registration/${newCpf}?courseCode=${courseCode2}&program=fies`,
          config
        )
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/user-registration/${newCpf}?courseCode=${courseCode3}&program=fies`,
          config
        )
        setLoaded(true)
        setRedirect(true)
      } catch (err) {
        console.log(err)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const [userAverage, setUserAverage] = useState({})

  useEffect(() => {
    async function LoadUserAverage() {
      const token = await awsTokenManager.getToken()
      const config = {
        headers: { Authorization: `${token}` },
      }
      try {
        let data = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/average`,
          config
        )

        setUserAverage(data.data)
      } catch (err) {
        console.log(err)
      }
    }

    LoadUserAverage()
  }, [])

  return (
    <>
      {loaded ? null : (
        <>
          <LoadingFixedScreen data-test="LoadingFixedScreen">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </LoadingFixedScreen>
        </>
      )}
      <ContainerStep>
        <BreadcrumbWrapper>
          <Link to="/initial-page">
            <img src={homeSvg} alt="" />
          </Link>
          <Typography>Acompanhar inscrição no FIES</Typography>
        </BreadcrumbWrapper>

        <Container>
          <HeaderSisu
            subType="Acompanhar inscrição no FIES"
            daysLeft="1 Dia e 23 horas e 12 minuto"
            note={userAverage.averageScore || ''}
            year={userAverage.year || ''}
          />
        </Container>

        <Container>
          <SubscribeInformation>
            <h1>Inscrição Recebida</h1>
            <p>
              Fique atento aos nosso comunicados para saber o resultado do
              processo de inscrição.
            </p>

            <p className="rankingText">
              O ranking de classificação parcial será publicado dia 23/07/2022
              às 23:59:00
            </p>

            <ButtonsStepContainer>
              <LeftButtons>
                <button onClick={handleCancelSubscription}>
                  Cancelar Inscrição
                </button>
                <Link to="/" className="editSub">
                  Alterar Inscrição
                </Link>
              </LeftButtons>

              {/* <Link
                to={`/comprovante/${hash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Imprimir comprovante
              </Link> */}
            </ButtonsStepContainer>
          </SubscribeInformation>

          <ConfirmedCourse>
            <h2>Cursos Inscritos</h2>

            <Row>
              <Col md={4}>
                <CardConfirmCourse
                  courseData={true}
                  proUni={true}
                  courseOption="1"
                  courseName={apiData1.courseName}
                  courseType={apiData1.courseDegree}
                  courseTime={apiData1.courseTimeType}
                  // courseTimeComplement="VESP/NOT"
                  courseLocation={apiData1.campusNeighborhood}
                  courseState={apiData1.campusState}
                  collegeName={apiData1.campusName}
                  modalityType="Renda familiar bruta per capita igual ou inferior a 1,5 salário mínimo"
                  // subscribeStatus="done"
                />
              </Col>
              <Col md={4}>
                <CardConfirmCourse
                  courseData={true}
                  proUni={true}
                  courseOption="2"
                  courseName={apiData2.courseName}
                  courseType={apiData2.courseDegree}
                  courseTime={apiData2.courseTimeType}
                  // courseTimeComplement="VESP/NOT"
                  courseLocation={apiData2.campusNeighborhood}
                  courseState={apiData2.campusState}
                  collegeName={apiData2.campusName}
                  modalityType="Renda familiar bruta per capita igual ou inferior a 1,5 salário mínimo"
                  // subscribeStatus="done"
                />
              </Col>
              <Col md={4}>
                <CardConfirmCourse
                  courseData={true}
                  proUni={true}
                  courseOption="3"
                  courseName={apiData3.courseName}
                  courseType={apiData3.courseDegree}
                  courseTime={apiData3.courseTimeType}
                  // courseTimeComplement="VESP/NOT"
                  courseLocation={apiData3.campusNeighborhood}
                  courseState={apiData3.campusState}
                  collegeName={apiData3.campusName}
                  modalityType="Renda familiar bruta per capita igual ou inferior a 1,5 salário mínimo"
                  // subscribeStatus="done"
                />
              </Col>
            </Row>
          </ConfirmedCourse>
        </Container>

        <Container>
          <HashContainer>
            <h3>Próxima atualização do ranking : 23/05/22 às 23h59</h3>
            <Link to="candidate-page">Ir para o painel do candidato</Link>
            <p>{hash || ''}</p>
            <p>Última atualização: 24/05/22 às 18h20</p>
          </HashContainer>
        </Container>
      </ContainerStep>
    </>
  )
}

export default wrapper(FiesFollowRegistration, {
  route: { path: FIES_FOLLOW_REGISTRATION_ROUTES.FIES_FOLLOW_REGISTRATION },
})
