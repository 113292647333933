import {
  CardConfirmCourseContainer,
  CardConfirmHeader,
  CardCourseName,
  CardCourseLocation,
  CardCourseModality,
  CardConfirmFooter,
  CourseTime,
  CourseLocation,
  CardWrapper,
  Classification,
  ProUniHeader,
} from './style'
import clockImage from './img/clockImage.svg'
import locationImage from './img/locationCourse.svg'
import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'

export function CardConfirmCourse({
  courseData,
  editCourseData,
  proUni = false,
  courseOption,
  courseName,
  courseType,
  courseTime,
  courseTimeComplement,
  collegeName,
  courseLocation,
  courseState,
  modalityType,
  subscribeStatus,
}) {
  return (
    <CardConfirmCourseContainer>
      <ProUniHeader>
        <h1>{courseOption}ª OPÇÃO DE CURSO</h1>
      </ProUniHeader>

      <CardWrapper>
        <CardConfirmHeader>
          {editCourseData ? (
            <OverlayTrigger
              trigger="click"
              key="bottom"
              placement="bottom"
              overlay={
                <Popover id={`popover-positioned-bottom`}>
                  <Popover.Body>
                    <p>Trocar para 2ª opção</p>
                    <p>Cancelar Curso</p>
                    <p>Alterar Curso</p>
                  </Popover.Body>
                </Popover>
              }
            >
              <span>
                <svg
                  width="6"
                  height="16"
                  viewBox="0 0 6 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 5.75C2.375 5.75 1.84375 5.96875 1.40625 6.40625C0.96875 6.84375 0.75 7.375 0.75 8C0.75 8.625 0.96875 9.15625 1.40625 9.59375C1.84375 10.0312 2.375 10.25 3 10.25C3.625 10.25 4.15625 10.0312 4.59375 9.59375C5.03125 9.15625 5.25 8.625 5.25 8C5.25 7.375 5.03125 6.84375 4.59375 6.40625C4.15625 5.96875 3.625 5.75 3 5.75ZM0.75 2.5C0.75 1.875 0.96875 1.34375 1.40625 0.90625C1.84375 0.46875 2.375 0.25 3 0.25C3.625 0.25 4.15625 0.46875 4.59375 0.90625C5.03125 1.34375 5.25 1.875 5.25 2.5C5.25 3.125 5.03125 3.65625 4.59375 4.09375C4.15625 4.53125 3.625 4.75 3 4.75C2.375 4.75 1.84375 4.53125 1.40625 4.09375C0.96875 3.65625 0.75 3.125 0.75 2.5ZM0.75 13.5C0.75 12.875 0.96875 12.3438 1.40625 11.9062C1.84375 11.4688 2.375 11.25 3 11.25C3.625 11.25 4.15625 11.4688 4.59375 11.9062C5.03125 12.3438 5.25 12.875 5.25 13.5C5.25 14.125 5.03125 14.6562 4.59375 15.0938C4.15625 15.5312 3.625 15.75 3 15.75C2.375 15.75 1.84375 15.5312 1.40625 15.0938C0.96875 14.6562 0.75 14.125 0.75 13.5Z"
                    fill="#1351B4"
                  />
                </svg>
              </span>
            </OverlayTrigger>
          ) : null}
        </CardConfirmHeader>

        {subscribeStatus && (
          <Classification>
            <h1>Classificação parcial</h1>
            <h2>
              Você está ocupando a <span>6º</span> de 20 vagas
            </h2>

            <button>
              Nota de Corte: <span>641,5</span>
            </button>

            <p>235 candidatos inscritos</p>
          </Classification>
        )}

        <CardCourseName>
          <h2>{courseName}</h2>
          <p>{courseType}</p>
          <CourseTime>
            <img src={clockImage} alt="Imagem de relógio" />
            <p>
              {courseTime} ({courseTimeComplement})
            </p>
          </CourseTime>
        </CardCourseName>

        <CardCourseLocation>
          <h2>{collegeName}</h2>
          <CourseLocation>
            <img src={locationImage} alt="Imagem de Localização" />
            <p>
              {courseLocation}
              <br />({courseLocation}, {courseState})
            </p>
          </CourseLocation>
        </CardCourseLocation>

        <CardCourseModality>
          <h2>Modalidade de concorrência</h2>
          <h3>{modalityType}</h3>
        </CardCourseModality>

        {courseData && (
          <CardConfirmFooter>
            <button>Ver documentação Necessária</button>
          </CardConfirmFooter>
        )}
      </CardWrapper>
    </CardConfirmCourseContainer>
  )
}
