import React, { createContext, useContext, useState } from 'react'

const LoadingContext = createContext({
  isLoading: false,
  loading: {
    turnOff: () => {},
    turnOn: () => {},
  },
})

const LoadingProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)

  const turnOff = () => {
    setIsLoading(false)
  }

  const turnOn = () => {
    setIsLoading(true)
  }

  const loading = {
    turnOff,
    turnOn,
  }

  return (
    <LoadingContext.Provider value={{ isLoading, loading }}>
      {children}
    </LoadingContext.Provider>
  )
}

const useLoading = () => {
  const loadingContext = useContext(LoadingContext)

  return loadingContext
}

export { LoadingProvider, useLoading }
