/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import wrapper from 'components/Wrapper'

import { CHANGE_SISU_ROUTES } from './constants/routes'

import { Wrapper, TextDiv, ButtonsDiv } from './styles'
import { Col, Container, Row } from 'react-bootstrap'
import { HeaderSisu } from 'components/SisuSubscribeForm/HeaderSisu'
import BreadcrumbWrapper from 'components/Breadcrumb'
import { Link } from 'react-router-dom'

import homeSvg from '../../assets/img/breadcrumb/homeIcon.svg'
import { Typography } from '@material-ui/core'
import { MessageBox } from 'components/DataConfirmSteps/MessageBox'
import { CardConfirmCourse } from 'components/SisuSubscribeForm/CardConfirmCourse'
import { apiWithToken } from '../../services/apiWithToken'
import { useUser } from '../../hooks/useUser'
import { useLoading } from '../../hooks/useLoading'

const ChangeSisu = () => {
  const { cpf } = useUser()
  const { loading } = useLoading()

  const [userAverage, setUserAverage] = useState({})

  const [courseOne, setCourseOne] = useState({})
  const [courseTwo, setCourseTwo] = useState({})

  console.log({ courseOne })
  console.log({ courseTwo })

  useEffect(() => {
    const getUserMe = async () => {
      loading.turnOn()

      try {
        const { data } = await apiWithToken.get(`/user-registration/${cpf}`)

        const subscribeInSisu = data.filter(
          subscribe => subscribe.program.toLowerCase() === 'sisu'
        )[0]

        const firstOption = subscribeInSisu.courses.filter(
          course => course.option === 'option1'
        )

        if (!!firstOption.length) {
          setCourseOne(firstOption[0])
        }

        const secondOption = subscribeInSisu.courses.filter(
          course => course.option === 'option2'
        )

        if (!!secondOption.length) {
          setCourseTwo(secondOption[0])
        }
      } catch (err) {
        console.log(err)
      } finally {
        loading.turnOff()
      }
    }

    if (!!cpf) {
      getUserMe()
    }
  }, [cpf])

  useEffect(() => {
    async function loadUserAverage() {
      try {
        const { data } = await apiWithToken.get(`/users/average`)

        setUserAverage(data)
      } catch (err) {
        console.log(err)
      }
    }

    loadUserAverage()
  }, [])

  return (
    <>
      <BreadcrumbWrapper>
        <Link to="/initial-page">
          <img src={homeSvg} alt="Home" />
        </Link>
        <Typography>SISU</Typography>
        <Typography>Alterar inscrição do SISU</Typography>
      </BreadcrumbWrapper>
      <HeaderSisu
        subType="SISU"
        daysLeft="1 dia e 23 horas"
        note={userAverage.averageScore || ''}
        year={userAverage.year || ''}
      />
      <Wrapper>
        <Container>
          <MessageBox
            data-test="confirmation-data-steps-MessageBox"
            main_title="Faça a alteração na opção de curso que deseja alterar"
            description="Você pode cancelar ou alterar o curso selecionado em cada opção
            de curso."
          />
        </Container>
        <Container>
          <TextDiv>
            <h1>Cursos selecionados</h1>
          </TextDiv>
          <Row>
            {!!courseOne?.option && (
              <Col md={6}>
                <CardConfirmCourse
                  program="sisu"
                  editPage
                  isEdit
                  hideDocument
                  option={1}
                  courseCode={courseOne.courseTimeCode}
                  courseCodeChange={courseTwo.courseTimeCode}
                  courseName={courseOne.courseName}
                  modality={courseOne.courseDegree}
                  courseTime={courseOne.courseTimeType}
                  university={courseOne.campusName}
                  courseType={courseOne.courseDegree}
                  courseLocation={courseOne.campusName}
                  courseState={courseOne.campusState}
                  campusNeighborhood={courseOne.campusNeighborhood}
                  city={courseOne.campusNeighborhood}
                  uf={courseOne.campusState}
                  modalityType={courseOne.modality}
                  modalityTypeChange={courseTwo.modality}
                />
              </Col>
            )}
            {!!courseTwo?.option && (
              <Col md={6}>
                <CardConfirmCourse
                  program="sisu"
                  editPage
                  isEdit
                  hideDocument
                  option={2}
                  courseCode={courseTwo.courseTimeCode}
                  courseCodeChange={courseOne.courseTimeCode}
                  courseName={courseTwo.courseName}
                  modality={courseTwo.courseDegree}
                  courseTime={courseTwo.courseTimeType}
                  university={courseTwo.campusName}
                  city={courseTwo.campusNeighborhood}
                  uf={courseTwo.campusState}
                  courseType={courseTwo.courseDegree}
                  courseLocation={courseTwo.campusName}
                  courseState={courseTwo.campusState}
                  campusNeighborhood={courseTwo.campusNeighborhood}
                  modalityType={courseTwo.modality}
                  modalityTypeChange={courseOne.modality}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col md={12}>
              <ButtonsDiv>
                <Link to="/candidate-page" className="prev">
                  Voltar
                </Link>
                {/* <Link to="/confirmation" className="middle">
                  Alterar meus Dados
                </Link> */}
                <Link to="/candidate-page" className="next">
                  Concluir
                </Link>
              </ButtonsDiv>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </>
  )
}

export default wrapper(ChangeSisu, {
  route: { path: CHANGE_SISU_ROUTES.CHANGE_SISU },
})
