import React, { createContext, useContext, useEffect, useState } from 'react'

import { awsTokenManager } from 'config/auth'
import { useLoading } from './useLoading'
import { useSelector } from 'react-redux'
import { apiWithToken } from 'services/apiWithToken'

const UserContext = createContext({
  cpf: '',
  name: '',
  email: '',
  securityQuestionsAnswered: false,
  role: '',
})

const UserProvider: React.FC = ({ children }) => {
  const { loading } = useLoading()
  const { isAuthenticated } = useSelector(state => state.auth)

  const [user, setUser] = useState({})

  useEffect(() => {
    const loadUser = async () => {
      if (!isAuthenticated) {
        return
      }

      try {
        loading.turnOn()

        const token = await awsTokenManager.getToken()

        apiWithToken.defaults.headers.Authorization = token

        const { data } = await apiWithToken.get('/users/me')

        setUser(data)
      } catch (error) {
        console.log('Error no Catch no /users/me em UserProvider')
      } finally {
        loading.turnOff()
      }
    }

    loadUser()
  }, [isAuthenticated])

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

const useUser = () => {
  const userContext = useContext(UserContext)

  return userContext
}

export { UserProvider, useUser }
