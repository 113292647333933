import React, { useEffect, useState } from 'react'
import {
  TitlePage,
  AboutCollege,
  LocationDiv,
  ClockDiv,
  CourseDiv,
  CourseChoosedContainer,
  VacanciesContainer,
  TitleVacancies,
  SubTitleVacancies,
  LawContainer,
  Income,
  VacanciesChooseContainer,
  CardContainer,
  CardHeader,
  CardInformation,
} from './styles'
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import clockImage from '.././img/clockImage.svg'
import locationImage from '.././img/locationCourse.svg'
import { ContainerStepButton } from '../style'

import infoImage from './img/info.svg'
import { LoadingFixedScreen } from 'modules/InitialPage/styles'
import { awsTokenManager } from 'config/auth'
import axios from 'axios'

export function CourseDetail(props) {
  useEffect(() => {
    setRedirect(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [vacancie1, setVacancie1] = useState(true)
  const [vacancie2, setVacancie2] = useState(false)
  const [vacancie3, setVacancie3] = useState(false)

  const renderTooltip1 = props => (
    <Tooltip
      id="tooltip1"
      data-test="confirmation-data-steps-Tooltip"
      {...props}
    >
      Caso identifique que algum dos dados acima está incorreto, clique no botão
      Alterar dados na Receita Federal, para solicitar a atualização do mesmo,
      se já o fez na Receita Federal. Se não realizou ainda a alteração acesse o
      site da Receita Federal e faça a atualização.
    </Tooltip>
  )

  const [loaded, setLoaded] = useState(true)
  const [redirect, setRedirect] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [cpf, setCpf] = useState('')

  function handleNewOption(option) {
    if (option === 'option1') {
      return 'option5'
    } else if (option === 'option2') {
      return 'option6'
    } else {
      return 'option7'
    }
  }

  useEffect(() => {
    if (redirect) {
      window.location.reload(true)
    }
  }, [redirect])

  async function handlePostCourse() {
    setLoaded(false)

    const token = await awsTokenManager.getToken()
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }
    let data = await axios
      .get(`${process.env.REACT_APP_API_URL}/users/me`, config)
      .then(({ data }) => data)

    setCpf(data.cpf)

    const newOption = handleNewOption(props.option)

    try {
      const courseMapped = {
        courseCode: props.timeCode,
        option: newOption,
        program: 'fies',
        modality: 'Ampla Concorrencia',
      }
      const token = await awsTokenManager.getToken()
      const config = {
        headers: { Authorization: `${token}` },
      }
      let data = await axios
        .get(`${process.env.REACT_APP_API_URL}/users/me`, config)
        .then(({ data }) => data)

      const newCpf = data.cpf
      console.log(newCpf)
      await axios.post(
        `${process.env.REACT_APP_API_URL}/user-registration/${newCpf}`,
        courseMapped,
        config
      )
      setLoaded(false)
      setRedirect(true)
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <>
      {loaded ? null : (
        <>
          <LoadingFixedScreen data-test="LoadingFixedScreen">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </LoadingFixedScreen>
        </>
      )}
      <Container>
        <TitlePage>1ª opção de curso - Detalhe</TitlePage>

        <CourseChoosedContainer>
          <Row>
            <Col md={6}>
              <CourseDiv>
                <h1>{props.course}</h1>
                <p>{props.modality}</p>

                <ClockDiv>
                  <img src={clockImage} alt="" />
                  <p>{props.courseTime}</p>
                </ClockDiv>
                <button>Ver documentação Necessária</button>
              </CourseDiv>
            </Col>
            <Col md={6}>
              <AboutCollege>
                <h1>{props.university}</h1>
                <LocationDiv>
                  <img src={locationImage} alt="" />
                  <p>
                    {props.city} - {props.uf}
                  </p>
                </LocationDiv>
              </AboutCollege>
            </Col>
          </Row>
        </CourseChoosedContainer>

        <VacanciesContainer>
          <Container>
            <TitleVacancies>VAGAS</TitleVacancies>
            <SubTitleVacancies>Selecione o Tipo de Vaga</SubTitleVacancies>
          </Container>

          <Container>
            <LawContainer>
              <h2>Vagas reservadas - Lei nº 12.711/2012</h2>
            </LawContainer>

            <Income>
              Renda familiar bruta per capita igual ou inferior a 1,5 salário
              mínimo
            </Income>
          </Container>

          <Container>
            <VacanciesChooseContainer>
              <Row>
                <Col md={6}>
                  <CardContainer
                    onClick={() => {
                      setVacancie1(true)
                      setVacancie2(false)
                      setVacancie3(false)
                    }}
                  >
                    <CardHeader className={vacancie1 ? 'free' : ''}>
                      {vacancie1 ? (
                        <input type="radio" />
                      ) : (
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip1}
                        >
                          <img src={infoImage} alt="" />
                        </OverlayTrigger>
                      )}
                      {vacancie1 ? (
                        <p>DEMAIS VAGAS DE LEI</p>
                      ) : (
                        'Pretos, pardos e indígenas'
                      )}
                    </CardHeader>

                    <CardInformation className={vacancie1 ? 'free' : ''}>
                      <h1>7 Vagas</h1>
                      <h2>
                        Para candidatos autodeclarados pretos, pardos ou
                        indígenas, com renda familiar bruta per capita igual ou
                        inferior a 1,5 salário mínimo e que tenham cursado
                        integralmente o ensino médio em escolas públicas (Lei nº
                        12.711/2012).
                      </h2>
                      <h3>Nota de corte: {props.notaCorte}</h3>
                      <h4>Melhor nota válida do ENEM: {props.notaCorte}</h4>
                      <h5>200 candidatos inscritos</h5>
                      <h6>Última atualização: 22/11/22 às 18h20</h6>
                    </CardInformation>
                  </CardContainer>
                </Col>
                <Col md={6}>
                  <CardContainer
                    onClick={() => {
                      setVacancie1(false)
                      setVacancie2(true)
                      setVacancie3(false)
                    }}
                  >
                    <CardHeader className={vacancie2 ? 'free' : ''}>
                      {vacancie2 ? (
                        <input type="radio" />
                      ) : (
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip1}
                        >
                          <img src={infoImage} alt="" />
                        </OverlayTrigger>
                      )}
                      {vacancie2 ? (
                        <p>DEMAIS VAGAS DE LEI</p>
                      ) : (
                        'Pretos, pardos e indígenas'
                      )}
                    </CardHeader>

                    <CardInformation className={vacancie2 ? 'free' : ''}>
                      <h1>7 Vagas</h1>
                      <h2>
                        Para candidatos autodeclarados pretos, pardos ou
                        indígenas, com renda familiar bruta per capita igual ou
                        inferior a 1,5 salário mínimo e que tenham cursado
                        integralmente o ensino médio em escolas públicas (Lei nº
                        12.711/2012).
                      </h2>
                      <h3>Nota de corte: {props.notaCorte}</h3>
                      <h4>Melhor nota válida do ENEM: 674,2</h4>
                      <h5>200 candidatos inscritos</h5>
                      <h6>Última atualização: 22/11/22 às 18h20</h6>
                    </CardInformation>
                  </CardContainer>
                </Col>
              </Row>
            </VacanciesChooseContainer>

            <LawContainer>
              <h2>Demais vagas</h2>
            </LawContainer>

            <Row>
              <Col md={6}>
                <CardContainer
                  onClick={() => {
                    setVacancie1(false)
                    setVacancie2(false)
                    setVacancie3(true)
                  }}
                >
                  <CardHeader className={vacancie3 ? 'free' : ''}>
                    {vacancie3 ? (
                      <input type="radio" />
                    ) : (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip1}
                      >
                        <img src={infoImage} alt="" />
                      </OverlayTrigger>
                    )}
                    {vacancie3 ? (
                      <p>DEMAIS VAGAS DE LEI</p>
                    ) : (
                      'Pretos, pardos e indígenas'
                    )}
                  </CardHeader>

                  <CardInformation className={vacancie3 ? 'free' : ''}>
                    <h1>7 Vagas</h1>
                    <h2>
                      Para candidatos autodeclarados pretos, pardos ou
                      indígenas, com renda familiar bruta per capita igual ou
                      inferior a 1,5 salário mínimo e que tenham cursado
                      integralmente o ensino médio em escolas públicas (Lei nº
                      12.711/2012).
                    </h2>
                    <h3>Nota de corte: {props.notaCorte}</h3>
                    <h4>Melhor nota válida do ENEM: 674,2</h4>
                    <h5>200 candidatos inscritos</h5>
                    <h6>Última atualização: 22/11/22 às 18h20</h6>
                  </CardInformation>
                </CardContainer>
              </Col>
            </Row>
          </Container>
        </VacanciesContainer>

        <Container>
          <ContainerStepButton>
            .
            <button
              className="nextButton"
              onClick={() => {
                handlePostCourse()
              }}
            >
              Selecionar Modalidade
            </button>
          </ContainerStepButton>
        </Container>
      </Container>
    </>
  )
}
