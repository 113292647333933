import FormContext2 from 'components/DataConfirmSteps/formContext2'
import { useLoading } from 'hooks/useLoading'
import React, { useContext, useEffect, useState } from 'react'
import { Table, Container } from 'react-bootstrap'
import { apiWithToken } from 'services/apiWithToken'
import { formatCurrency } from 'utils/formatCurrency'
import { relativesFromApi } from './adapters/fromApi'

import { FamilyGroup, DivSection, Wrapper, Button, MainText } from './style'

export function StepTwoSubscribeForm() {
  const { loading } = useLoading()

  useEffect(() => {
    window.scrollTo(0, 0)
    setFormContent2(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { setFormContent2 } = useContext(FormContext2)

  const [relatives, setRelatives] = useState([])

  const [perCapita, setPerCapita] = useState(0)
  const [totalIncome, setTotalIncome] = useState(0)

  useEffect(() => {
    const getUserMe = async () => {
      loading.turnOn()

      try {
        const {
          data: { cpf },
        } = await apiWithToken.get('/users/me')

        const { data } = await apiWithToken.get(
          `/user-relative/relatives/${cpf}`
        )

        const relativesMapped = relativesFromApi(data)

        setRelatives(relativesMapped)

        const totalIncome = relativesMapped.reduce((acc, transaction) => {
          acc += parseFloat(transaction.individualIncome)

          return acc
        }, 0)

        setTotalIncome(totalIncome)

        const incomePerCapita = (totalIncome / data.length).toFixed(2)

        setPerCapita(incomePerCapita)
      } catch (err) {
        console.log(err)
      }

      loading.turnOff()
    }

    getUserMe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper>
      <DivSection>
        <Container>
          <MainText>Renda mensal familiar</MainText>
        </Container>
      </DivSection>
      <DivSection>
        <FamilyGroup>
          <Container>
            <h2>Membros do Grupo Familiar</h2>

            <Table responsive="md" className="familyTable">
              <thead>
                <tr>
                  <th className="alignLeft">CPF</th>
                  <th className="alignLeft">Nome Completo</th>
                  <th>Data de Nascimento</th>
                  <th>Grau de Parentesco</th>
                  <th>Telefone</th>
                  <th>Renda individual (R$)</th>
                </tr>
              </thead>
              <tbody>
                {relatives.map(item => (
                  <tr key={item.id}>
                    <td className="alignLeft">{item.cpf}</td>
                    <td className="alignLeft">{item.name}</td>
                    <td>{item.birthdate}</td>
                    <td>{item.degreeKinship}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{formatCurrency(item.individualIncome)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
          <Container>
            <p>
              Renda familiar mensal bruta (R$){' '}
              <strong>
                {formatCurrency(totalIncome, { notation: false })}
              </strong>
            </p>
            <p>
              Renda mensal bruta per capita (R$){' '}
              <strong>{formatCurrency(perCapita, { notation: false })}</strong>
            </p>
          </Container>
        </FamilyGroup>
      </DivSection>
      <Button
        className="next"
        onClick={() => {
          setFormContent2(true)
        }}
      >
        Confirmar
      </Button>
    </Wrapper>
  )
}
