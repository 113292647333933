import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #071d41;
  width: 100%;
  height: 100%;
`

export const IconsWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 350px) {
    flex-direction: column;
    margin: 0 auto;
    gap: 1rem;
  }
`

export const WrapperLinks = styled.div`
  padding: 2rem 2.5rem;
`

export const Links = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  @media screen and (max-width: 1200px) {
    gap: 1rem;
  }
  h2 {
    font-weight: 700;
    font-size: 0.8rem;
    color: ${props => props.theme.bgColors.colorSecondary01};

    @media screen and (max-width: 992px) {
      margin-top: 1rem;
      margin-bottom: 0;
      font-size: 1.2rem;
      gap: 1rem;
    }
  }

  li {
    font-weight: 700;
    a {
      color: ${props => props.theme.bgColors.colorSecondary01};
    }
  }
`

export const ImagesContainer = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  padding: 2rem 2.5rem;
`

export const IconContainer = styled.div`
  display: flex;
  gap: 1.2rem;

  div {
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease 0.2s;
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
    }
  }

  svg {
    font-size: 2rem;
    color: #071d41;
  }
`

export const ImageMinisteryContainer = styled.div`
  align-self: flex-end;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 992px) {
    justify-content: initial;
    margin: 2rem 0;
  }
`

export const EndFooter = styled.div`
  text-align: center;
  margin-top: 2rem;
  width: 100%;
  border-top: 1px solid white;

  p {
    color: white;
    margin-top: 1rem;
    width: 100%;
  }
`
