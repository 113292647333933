import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
`

export const ResultBox = styled.div`
  border: 1px solid black;
  padding: 1.5rem;
  width: 100%;
`

export const TextWithline = styled.div`
  h3 {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    color: black;
  }
`
