import React from 'react'

import PropTypes from 'prop-types'

import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'

import { useLangContext } from 'components/Globalization'
import { ROLES } from 'modules/User/constants/roles'

function SearchRadios(props) {
  const { handleRadioChange, radioValue } = props
  const translate = useLangContext()

  return (
    <FormControl component="fieldset">
      <RadioGroup
        row
        aria-label="search"
        name="search"
        onChange={handleRadioChange}
      >
        <FormControlLabel
          value=""
          control={<Radio checked={radioValue === ''} color="primary" />}
          label="Todos"
        />
        <FormControlLabel
          value={ROLES.ROLE_ADMIN}
          control={<Radio color="primary" />}
          label={translate('ROLES:ROLE_ADMIN')}
        />
      </RadioGroup>
    </FormControl>
  )
}

SearchRadios.propTypes = {
  handleRadioChange: PropTypes.func.isRequired,
  radioValue: PropTypes.string.isRequired,
}

export default SearchRadios
