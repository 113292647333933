export const relativeToApi = user => {
  const newCurrency = user.individualIncome.split('.').join('')
  const newCurrencyFinal = newCurrency.split(',').join('.')

  return [
    {
      birthdate: user.birthdate,
      cpf: user.cpf,
      degreeKinship: user.degreeKinship,
      individualIncome: newCurrencyFinal,
      name: user.name,
      phoneNumber: user.phoneNumber,
    },
  ]
}
