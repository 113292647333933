import * as Yup from 'yup'

Yup.addMethod(
  Yup.string,
  'cnpj',
  function cnpjValidation(errorMessage = 'VALIDATION:INVALID_CNPJ') {
    return this.test('cnpj', errorMessage, function testCNPJ(cnpj) {
      const { path, createError } = this
      cnpj = cnpj.replace(/[^\d]+/g, '')

      if (cnpj === '') {
        return createError({ path, message: errorMessage })
      }

      if (cnpj.length !== 14) {
        return createError({ path, message: errorMessage })
      }

      const characters = new Set(Array.from(cnpj))
      if (characters.size === 1) {
        return createError({ path, message: errorMessage })
      }

      let tamanho = cnpj.length - 2
      let numeros = cnpj.substring(0, tamanho)
      const digitos = cnpj.substring(tamanho)
      let soma = 0
      let pos = tamanho - 7
      for (let i = tamanho; i >= 1; i -= 1) {
        soma += numeros.charAt(tamanho - i) * pos
        pos -= 1
        if (pos < 2) pos = 9
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
      if (String(resultado) !== digitos.charAt(0)) {
        return createError({ path, message: errorMessage })
      }
      tamanho += 1
      numeros = cnpj.substring(0, tamanho)
      soma = 0
      pos = tamanho - 7
      for (let i = tamanho; i >= 1; i -= 1) {
        soma += numeros.charAt(tamanho - i) * pos
        pos -= 1
        if (pos < 2) pos = 9
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
      if (String(resultado) !== digitos.charAt(1)) {
        return createError({ path, message: errorMessage })
      }
      return true
    })
  }
)
