import styled from 'styled-components'

export const Container = styled.div`
  padding: 0 40px;
  justify-content: center;
  display: flex;
`

export const ContentContainer = styled.div`
  padding: 0 32px;
  flex: auto;
  max-width: 1280px;
`

export const TitlePage = styled.h1`
  font-size: 41px;
  color: ${props => props.theme.bgColors.colorSecondary08};
  margin-top: 32px;
`

export const SeachAndRegisterEdital = styled.div`
  margin-top: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TableContainer = styled.div`
  margin-top: 48px;
`

export const TitleListEdital = styled.h2`
  color: ${props => props.theme.bgColors.colorSecondary08};
  font-size: 17px;
  margin-bottom: 16px;
`
