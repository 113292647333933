import styled from 'styled-components'

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
`
export const Items = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin-top: 2rem;
`
export const Pages = styled.div``
export const ItemsNumber = styled.div`
  position: relative;
  &:before {
    content: '';
    border-left: 1px solid #cccccc;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: -16px;
  }
`

export const Show = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  select {
    width: 4.25rem;
    height: 2rem;
    text-align: center;
    border: 0;
  }
`

export const Arrows = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;

  position: relative;
  &:before {
    content: '';
    border-left: 1px solid #cccccc;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: -16px;
  }
  svg {
    color: ${props => props.theme.bgColors.primaryDefault};
    width: 20px;
    height: 18px;
    cursor: pointer;
  }
`
