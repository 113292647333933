import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0;
  margin: 0;

  .containerWrapper {
    margin-top: 2rem;
    max-width: 1232px;

    a {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #1351b4;
    }

    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #333333;
    }
  }
`
