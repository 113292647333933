import styled from 'styled-components'

export const Display = styled.div`
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
  width: 1140px;
  max-width: 100%;
  padding: 0;
  padding-left: 15px;

  @media (max-width: 575px) {
    padding: 15px;
  }

  h1 {
    margin-top: 1.25rem;
    margin-bottom: 3rem;
    font-size: 2.5rem;
    font-weight: 400;

    @media screen and (max-width: 992px) {
      font-size: 1.5rem;
    }
  }

  .userInformation {
    background-color: ${props => props.theme.bgColors.primaryDarken03};
    border-radius: 4px;
  }
  .titleSectionCards {
    font-size: 1.5rem;
    margin-top: 2.8rem;
    margin-bottom: 1.5rem;
    font-weight: 400;
    padding: 0;

    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }
`

export const UserInformations = styled.div`
  width: 100%;
  height: 9rem;
  border-radius: 4px;
  padding: 1.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  transition: filter ease 0.2s;

  .buttonDiv {
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  button {
    width: 12rem;
    height: 2rem;
    background: ${props => props.theme.bgColors.primaryDefault};
    padding: 0.5rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.bgColors.colorSecondary01};
    border-radius: 20px;
    border: 0;
    font-weight: 600;
    transition: all ease 0.2s;
    font-size: 1rem;

    &:hover {
      filter: brightness(0.8);
    }

    @media (max-width: 767px) {
      margin-top: 1rem;
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    height: auto;
    font-size: 0.8rem;
    padding: 1rem 0;
  }
`

export const UserProfile = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  img {
    height: 80px;
    width: 80px;

    border-radius: 50%;
  }

  @media (max-width: 575px) {
    gap: 1rem;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    padding: 0;

    @media (max-width: 575px) {
      font-size: 1.1rem;
    }
  }
`

export const Card = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  padding-bottom: 2rem;
  min-height: 37rem; 

  @media screen and (max-width: 992px) {
    margin-top: 2rem;
    min-height: 0;
  }

  &.disabled {
    opacity: 0.3;
  }
`
export const HeaderCard = styled.header`
  border-radius: 4px 4px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.SISU {
    background: linear-gradient(
      180deg,
      #dde4fc 0%,
      rgba(221, 228, 252, 0) 100%
    );
  }

  &.FIES {
    background: linear-gradient(
      180deg,
      #ddfce9 0%,
      rgba(221, 252, 233, 0) 100%
    );
  }

  &.PROUNI {
    background: linear-gradient(
      180deg,
      #f8ddfc 0%,
      rgba(248, 221, 252, 0) 100%
    );
  }

  img {
    margin: 2.5rem 0;
  }
`

export const CardContent = styled.div`
  padding: 1rem 2.5rem;

  @media (max-width: 1199px) {
    padding: 1rem;
  }

  strong {
    font-size: 1.25rem;
    font-weight: 700;
  }
  p {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    font-weight: 400;
    max-height: 3rem;
    margin-bottom: 2rem;

    @media (max-width: 991px) {
      max-height: initial;
    }
  }
`

export const SubscribeArea = styled.div`
  div svg {
    color: ${props => props.theme.textColors.primaryPastel03};
    font-size: 2.5rem;
  }
  .active svg {
    color: ${props => props.theme.bgColors.primaryDefault} !important;
  }
`

export const DateCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0;

  h3 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: bold;
    color: ${props => props.theme.textColors.colorTextGrey03};

    &.subscribeActive {
      color: ${props => props.theme.bgColors.primaryDefault};
    }
  }

  p {
    margin: 0.3rem 0;
    padding: 0;
    color: ${props => props.theme.textColors.colorTextGrey03};
    font-weight: 600;
    &.active {
      color: ${props => props.theme.bgColors.colorSecondary08};
    }
  }
`

export const FooterCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .linkItem {
    text-decoration: underline;
    font-weight: 500;
  }

  a {
    text-decoration: underline;
    color: ${props => props.theme.bgColors.primaryDefault};

    :hover {
      background: transparent;
    }
  }
  button,
  a {
    &[disabled] {
      opacity: 1 !important;
    }
    margin: 0.5rem 0;

    transition: all ease 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
    &.readMore {
      width: 100%;
      padding: 0.4rem 1.5rem;
      border: 1px solid ${props => props.theme.bgColors.primaryDefault};
      background: ${props => props.theme.bgColors.colorSecondary01};
      border-radius: 200px;
      color: ${props => props.theme.bgColors.primaryDefault};
      font-weight: 600;
    }

    &.isSubscribed {
      width: 100%;
      padding: 0.4rem 1.5rem;
      border: 1px solid ${props => props.theme.bgColors.primaryDefault};
      background: none;
      border-radius: 200px;
      color: #1351b4;
      font-weight: 600;
      text-align: center;
    }

    &.subscribeButton {
      background: ${props => props.theme.bgColors.primaryDefault};
      border-radius: 20px;
      color: ${props => props.theme.bgColors.colorSecondary01};
      padding: 0.5rem 1.5rem;
      border: 0;
      width: 100%;

      font-weight: 600;
    }

    &.cantSubscribe {
      background: ${props => props.theme.textColors.primaryPastel03};
    }

    &.whyCantSubscribe {
      background: #ffda00;
      border-radius: 4px;
      width: 100%;
      margin-top: -0.2rem;
      border: 0;
      text-decoration: underline;
      font-weight: 700;
      color: ${props => props.theme.bgColors.primaryDefault};
    }
  }
`

export const ContactMec = styled.section`
  width: 100%;
  background: #f6f6f6;
  height: 13rem;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 7rem 0 3.5rem 0;
  flex-direction: column;

  @media (max-width: 767px) {
    margin: 2rem 0;
  }
`

export const ContactMecContent = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  button {
    background: ${props => props.theme.bgColors.colorSecondary10};
    color: ${props => props.theme.bgColors.colorSecondary01};
    margin: 1rem 0;
    height: 2.4rem;
    font-weight: bold;
    cursor: pointer;
    border-radius: 20px;
    padding: 0.5rem 1.5rem;
    border: 0;
    transition: all ease 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
  p {
    text-align: center;

    span {
      color: #737373;
      font-weight: bold;
    }
  }
`

export const CloseButtonModal = styled.button`
  background: ${props => props.theme.bgColors.primaryDefault};
  border-radius: 4px;
  width: 6rem;
  height: 2rem;
  padding: 0.5rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: 0;
  font-weight: 600;
  transition: all ease 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`

export const Wrapper = styled.section`
  padding: 0;
  margin: 0;

  .textDiv {
    padding: 0;
  }

  .flexMinisterios {
    display: flex;
    @media (max-width: 991px) {
      flex-direction: column;
    }
  }
`
