import styled from 'styled-components'

import { APP_MEASURES } from 'modules/App/constants/measures'

export const ContentWrapper = styled.div`
  min-height: ${`calc(100vh - ${
    APP_MEASURES.HEADER_HEIGHT + APP_MEASURES.FOOTER_HEIGHT
  }px)`};
  margin-bottom: 50px;
`
