import { formatString } from 'helpers/formatString'

import { USER_API } from './api'

export default class UserService {
  /**
   * @param {object} props
   * @param {import('config/http/axios').Axios} props.http
   */
  constructor({ http }) {
    this.http = http
  }

  /**
   * @returns {Promise<UserDto>}
   */
  async me() {
    return this.http.get(USER_API.ME)
  }

  /**
   * @param {object} props
   * @param {object} props.active
   * @param {object} props.role
   * @param {object} props.search
   * @param {PaginatedDto} paginated
   */
  async users({ active, role, search }, { size, page, direction, sort }) {
    const users = await this.http.get(USER_API.USERS, {
      params: {
        size,
        page,
        active,
        role,
        search,
        direction,
        sort,
      },
    })

    users.content = users.content.map(({ name, ...user }) => ({
      userName: name,
      ...user,
    }))

    return users
  }

  async usersSearch(term, role, page, size, sort, direction) {
    try {
      const users = await this.http.get(USER_API.USERS_SEARCH, {
        params: {
          term,
          role,
          page,
          size,
          sort,
          direction,
        },
      })
      return users
    } catch (e) {
      return e
    }
  }

  async createAccount({ active, cpf, name, email, id, phoneNumber, role }) {
    const response = await this.http.post(USER_API.USERS, {
      active,
      cpf,
      email,
      id,
      name,
      phoneNumber,
      role,
    })
    return response
  }

  async editUser({ active, cpf, name, email, id, phoneNumber, role }) {
    const response = await this.http.put(USER_API.USERS, {
      active,
      cpf,
      email,
      id,
      name,
      phoneNumber,
      role,
    })
    return response
  }

  /**
   * @param {object} props
   * @param {string} props.userId
   */
  async resetCredentials(userId) {
    const url = formatString(USER_API.RESET_CREDENTIALS, { userId })
    const response = await this.http.post(url, {
      note: 'Reset by admin',
      resetType: 'ALL',
    })
    return response
  }

  /**
   * @param {object} props
   * @param {string} props.cpfOrEmail
   */
  async resetPassword(cpfOrEmail) {
    const response = await this.http.post(USER_API.RESET_PASSWORD, {
      cpfOrEmail,
    })
    return response
  }

  /**
   * @param {object} props
   * @param {string} props.cpf
   */
  async firstAccess(cpf) {
    const url = formatString(USER_API.FIRST_ACCESS, { id: cpf })
    const response = await this.http.post(url)
    return response
  }

  /**
   * @param {object} props
   * @param {string} props.cpf
   */
  async setFirstAccess() {
    const response = await this.http.post(USER_API.SET_FIRST_ACCESS)
    return response
  }

  /**
   * @param {object} props
   * @param {string} props.cpf
   */
  async deactivateUser(cpf) {
    const url = formatString(USER_API.DEACTIVATE_USER, { id: cpf })

    const response = await this.http.put(url)
    return response
  }

  /**
   * @param {object} props
   * @param {string} props.cpf
   */
  async restoreUser(cpf) {
    const url = formatString(USER_API.RESTORE_USER, { id: cpf })

    const response = await this.http.put(url)
    return response
  }

  /**
   * @param {object} props
   * @param {string} props.cpf
   */
  async deleteUser(cpf) {
    const url = formatString(USER_API.DELETE_USER, { id: cpf })
    const response = await this.http.delete(url)
    return response
  }

  /**
   * @property {object} props
   * @property {string} props.token
   * @property {string} props.cpf
   * @property {string} props.recaptcha
   * @property {string} props.password
   * @property {string} props.confirmPassword
   * @property {AnswerDto} props.securityAnswer
   */
  async changePassword({
    token,
    cpf,
    password,
    recaptcha,
    confirmPassword,
    securityAnswer,
  }) {
    const response = await this.http.post(USER_API.CHANGE_PASSWORD, {
      cpf,
      token,
      password,
      confirmPassword,
      securityAnswer,
      gRecaptchaResponse: recaptcha,
      gRecaptchaVersion: 'v2',
    })
    return response
  }

  /**
   * @property {object} props
   * @property {string} props.token
   * @property {string} props.cpf
   * @property {string} props.recaptcha
   * @property {string} props.password
   * @property {string} props.confirmPassword
   */
  async createPassword({ token, cpf, password, recaptcha, confirmPassword }) {
    const response = await this.http.post(USER_API.CREATE_PASSWORD, {
      cpf,
      token,
      password,
      confirmPassword,
      gRecaptchaResponse: recaptcha,
      gRecaptchaVersion: 'v2',
    })
    return response
  }

  /**
   * @property {AnswerDto[]} payload
   */
  async securityQuestionsAnswers(payload) {
    const response = await this.http.post(
      USER_API.SECURITY_QUESTIONS_ANSWER,
      payload
    )
    return response
  }

  /**
   *
   */
  async dataProcessing() {
    const dataProcessing = await this.http.get(USER_API.DATA_PROCESSING, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })

    return dataProcessing
  }

  /**
   *
   */
  async dataProcessingAgreement() {
    const response = await this.http.post(USER_API.DATA_PROCESSING_AGREEMENT)
    return response
  }

  /**
   * @param {string} token
   * @returns {Promise<UserDto>}
   */
  async findUserByToken(token) {
    const url = formatString(USER_API.FIND_USER_BY_TOKEN, { token })
    const response = await this.http.get(url)
    return response
  }

  /**
   * @returns {Promise<SecurityQuestionDto>}
   */
  async securityQuestions() {
    const questions = await this.http.get(USER_API.SECURITY_QUESTIONS)
    return questions
  }
}
