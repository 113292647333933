import { PaginationContainer, Items, ItemsNumber, Show, Arrows } from './style'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
export function Pagination({
  totalSearch,
  totalElementsPaginated,
  setPageSize,
  pageSize,
  setPageNumber,
  pageNumber,
}) {
  // function handlePreviewPage() {
  //   console.log('handlePreviewPage');
  // }

  // function handleNextPage() {
  //   // setCurrentPage(page => page + 1);
  // }
  const totalPagesNumbers = Math.ceil(totalElementsPaginated / pageSize)
  // console.log(totalPagesNumbers);
  return (
    <PaginationContainer>
      <Items>
        <Show>
          <span>Exibir</span>

          <select
            disabled={totalElementsPaginated <= 12}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            <option value="12">12</option>
            <option value="24">24</option>
            <option value="48">48</option>
            <option value="96">96</option>
          </select>
        </Show>

        <ItemsNumber>
          {totalSearch} de {totalElementsPaginated} itens
        </ItemsNumber>
      </Items>
      <Items>
        <Show>
          <span>Página</span>
          <select
            value={pageNumber}
            onChange={event => {
              setPageNumber(Number(event.target.value))
            }}
          >
            {Array.from(Array(totalPagesNumbers), (item, index) => {
              return (
                <option key={index} value={index}>
                  {index + 1}
                </option>
              )
            })}
          </select>
        </Show>

        <Arrows>
          <FaChevronLeft
            onClick={() => {
              setPageNumber(pageNumber - 1)
            }}
            className={pageNumber > 0 ? 'd-flex' : 'd-none'}
          />
          <FaChevronRight
            onClick={() => {
              setPageNumber(pageNumber + 1)
            }}
            className={
              pageNumber + 1 >= totalPagesNumbers ? 'd-none' : 'd-flex'
            }
          />
        </Arrows>
      </Items>
    </PaginationContainer>
  )
}
