import {
  CourseContainer,
  CourseHeader,
  CourseBody,
  CourseFooter,
  CourseLocation,
  CourseTime,
  CourseLastInformation,
  AboutCourse,
  TextsHeader,
} from './style'

import heartImage from './img/heartImage.svg'
import heartFill from './img/heartFill.svg'
import locationImage from './img/locationCourse.svg'
import clockImage from './img/clockImage.svg'
import { useState } from 'react'
export function CardCourse({
  title,
  subTitle,
  college,
  campusName,
  state,
  timeType,
  time,
  vacancies,
  affirmativeAction,
  note,
  locationCity,
  props,
}) {
  const [fav, setFav] = useState(false)

  return (
    <CourseContainer>
      <CourseHeader>
        <TextsHeader>
          <h1>{title}</h1>
          <p>{subTitle}</p>
        </TextsHeader>
        <img
          src={fav ? heartFill : heartImage}
          alt="Fav"
          onClick={() => {
            setFav(!fav)
          }}
        />
      </CourseHeader>
      <CourseBody>
        <h2>{college}</h2>
        <CourseLocation>
          <img src={locationImage} alt="" />
          <div>
            <p>{campusName}</p>
            <p>
              ({locationCity}, {state})
            </p>
          </div>
        </CourseLocation>
        <CourseTime>
          <img src={clockImage} alt="" />
          <p>{timeType}</p>
        </CourseTime>

        <CourseLastInformation>
          <AboutCourse>
            <h3>Total de Vagas</h3>
            <span>{vacancies}</span>
          </AboutCourse>
          <AboutCourse>
            <h3>Acões Afirmativas?</h3>
            <span>{affirmativeAction}</span>
          </AboutCourse>
        </CourseLastInformation>
      </CourseBody>
      <CourseFooter>
        <h1>Nota de Corte</h1>
        <span>{note}</span>
      </CourseFooter>
    </CourseContainer>
  )
}
