/* eslint-disable react/prop-types */
import React, { useCallback } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'

import history from 'config/history/history'

import { useLangContext } from 'components/Globalization'

import { AUTH_ROUTES } from 'modules/Auth/constants/routes'

const RequestConfirmedPopUp = ({ show }) => {
  const translate = useLangContext()

  const onClickHandler = useCallback(() => {
    history.push(AUTH_ROUTES.LOGIN)
  }, [])

  return (
    <Dialog open={show}>
      <DialogTitle>{translate('EMAIL_SENT')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate('EMAIL_SENT_CONFIRMATION')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickHandler}>{translate('GOT_IT')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default RequestConfirmedPopUp
