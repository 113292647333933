import styled from 'styled-components'

import { FONT_FAMILY } from 'constants/fonts'

export const Container = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  margin-left: 15px;

  @media (max-width: 576px) {
    margin-top: 15px;
  }

  .row {
    width: 1140px;
    max-width: 100%;
    background-color: var(--loading-page);
    border-radius: 8px;
    padding: 34px 60px 0 60px;

    @media (max-width: 900px) {
      padding: 15px 30px 15px 30px;
    }
  }
`

export const ImgContainer = styled.img`
  width: 100%;
  @media (max-width: 576px) {
    max-width: 70%;
  }
`

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  h2 {
    font-family: ${FONT_FAMILY};
    color: var(--loading-page-text);
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    padding: 0;

    @media (max-width: 1100px) {
      margin: 10px 0;
    }
  }

  p {
    font-family: ${FONT_FAMILY};
    color: var(--loading-page-text);
    font-style: normal;
    margin-bottom: 40px;
    span {
      font-weight: bold;
    }

    @media (max-width: 1100px) {
      margin-bottom: 20px;
    }
    @media (max-width: 800px) {
      font-size: 16px;
    }
  }
`

export const LoadingItem = styled.img`
  width: 281px;
  max-width: 100%;
  margin-bottom: 5px;
  transition: all 3s ease;

  @media (max-width: 800px) and (min-width: 576px) {
    max-width: 80%;
  }
`

export const Button = styled.a`
  font-family: 'Rawline';
  font-style: normal;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Pure/.pure */
  color: #ffffff;
  position: static;
  width: 219px;
  max-width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;

  left: 0px;
  top: 162px;

  background: #1351b4;
  border-radius: 20px;
  border: none;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`

export const DivImg = styled.div`
  padding-right: 60px;

  @media (max-width: 800px) {
    padding-right: 0;
  }
`
