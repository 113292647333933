import * as Yup from 'yup'

Yup.addMethod(Yup.number, 'allowEmptyString', function allowEmptyString() {
  return this.transform((value, originalValue) => {
    if (typeof originalValue === 'string' && originalValue === '') {
      return null
    }
    return value
  }).nullable()
})
