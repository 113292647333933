import {
  ContainerLastCourseCard,
  TypeCourseContainer,
  CourseTimeDiv,
  NameCollegeAndInformationsDiv,
  LocationCollegeDiv,
} from './style'
import locationImage from './img/locationCourse.svg'
import courseTimeImage from './img/clockImage.svg'
export function LastCoursesCard({ lastCoursesInformations }) {
  console.log(lastCoursesInformations)
  return (
    <ContainerLastCourseCard>
      <h1>{lastCoursesInformations.course}</h1>
      <TypeCourseContainer>
        <h2>{lastCoursesInformations.degree}</h2>
        <CourseTimeDiv>
          <img src={courseTimeImage} alt="Imagem de Relógio" />
          <p>{lastCoursesInformations.time}</p>
        </CourseTimeDiv>
      </TypeCourseContainer>
      <NameCollegeAndInformationsDiv>
        <h3>{lastCoursesInformations.collegeName}</h3>
        <LocationCollegeDiv>
          <img src={locationImage} alt="" />
          <p>
            {lastCoursesInformations.campusCity} <br />(
            {lastCoursesInformations.campusCity}/
            {lastCoursesInformations.campusState})
          </p>
        </LocationCollegeDiv>

        <h4>
          Classificação parcial :{' '}
          <strong>{lastCoursesInformations.rank}º</strong> de{' '}
          {lastCoursesInformations.totalVacancies} vagas
        </h4>

        <p>
          Atualização em:{' '}
          {`${new Date(lastCoursesInformations.lastUpdate).toLocaleDateString(
            'pt-br'
          )}  às  ${new Date(
            lastCoursesInformations.lastUpdate
          ).toLocaleTimeString('pt-br')}`}
        </p>
      </NameCollegeAndInformationsDiv>
    </ContainerLastCourseCard>
  )
}
