import { FONT_FAMILY } from 'constants/fonts'
import styled from 'styled-components'
import arrowIMG from './img/arrowDropBox.svg'

export const Wrapper = styled.section`
  padding: 5px;
  margin: 10px 10px 100px 30px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  .row {
    width: 100%;
  }

  .ufInput {
    text-transform: uppercase;
  }

  [disabled] {
    opacity: 1;
  }

  input {
    height: 51px;
    width: 100%;

    padding-left: 12px;

    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: var(--colorBlack);

    &.disabledBefore {
      background: #f3f3f3;
      border: 1px solid #cccccc;
      border-radius: 4px;
    }

    &::placeholder {
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: var(--colorBlack);

      opacity: 0.5;
    }

    &:not(:placeholder-shown):disabled {
      background: #f3f3f3;
      border: 1px solid #cccccc;
      border-radius: 4px;
    }
  }

  input[type='checkbox'] {
    margin-left: 4px;
    width: auto;
    height: 16px;
    margin-top: 15px;
    transform: scale(1.5);
  }
  label {
    font-family: ${FONT_FAMILY};
    padding-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;

    font-feature-settings: 'pnum' on, 'lnum' on;
    color: var(--colorBlack);
  }

  .noPadding {
    padding: 0 !important;
    .row {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
`

export const SelectInputGroup = styled.div`
  padding: 0;
  margin: 0;
  position: relative;

  .hiddenInput {
    opacity: 0;
    position: absolute;
    bottom: 0;
  }

  button {
    padding: 12.5px 14px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${props => props.theme.bgColors.colorSecondary04};
    background: ${props => props.theme.bgColors.colorSecondary01};
    font-style: italic;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    position: relative;
    /* background: transparent url(${props =>
      arrowIMG}) no-repeat 95% center; */
    background-size: 1rem;
    color: black;
    text-align: left;

    &::after {
      right: 2rem;
      bottom: 50%;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      font-size: 1.5rem;
      color: ${props => props.theme.bgColors.primaryDefault};
    }

    &:hover {
      background: #ffffff;
      color: ${props => props.theme.bgColors.primaryDefault};
    }

    &:focus {
      background: #ffffff;
      color: black;
    }

    @media screen and (max-width: 1112px) {
      max-width: 100%;
      width: 100%;
    }
  }

  .dropPersonalized {
    .dropdown-menu {
      padding: 0;
      max-height: 200px;
      overflow-y: scroll;
    }

    div {
      width: 35rem;

      @media screen and (max-width: 992px) {
        max-width: 100%;
        width: 100%;
      }

      a {
        border-bottom: 1px solid
          ${props => props.theme.bgColors.colorSecondary04};
        padding: 12.5px 14px;
      }
    }
  }
`

export const DivReceita = styled.div`
  display: flex;
  align-items: center;
  flex-direction: center;

  margin-top: 24px;

  .secondaryButton {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--colorBlueConfirmation);

    transition: all ease 0.2s;

    display: block;
    text-align: center;

    max-width: 310px;

    padding: 8px 24px;

    background: #ffffff;
    border: 1px solid #1351b4;
    border-radius: 20px;
  }

  img {
    margin-left: 10px;
  }
`

export const DivSection = styled.div`
  padding-bottom: 28px;
  border-bottom: 1px solid #c8c7c7;

  .infoText {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    display: flex;
    align-items: center;

    color: #000000;

    margin-top: 32px;

    img {
      margin-left: 10px;
    }

    @media (max-width: 575px) {
      margin-left: 0;
      padding: 0 10px;
    }

    span {
      position: initial;
      font-size: 30px;
      margin-bottom: -5px;
      margin-left: 2px;
      color: #000000;
    }
  }

  .selectText {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    display: flex;
    align-items: center;
    margin: 0 0 4px 0;
    margin-top: 22px;

    position: relative;

    color: #000000;

    img {
      margin-left: 10px;
    }

    span {
      position: initial;
      font-size: 30px;
      margin-bottom: -5px;
      margin-left: 2px;
      color: #000000;
    }
  }
`

export const RadioButtonContainer = styled.div`
  display: flex;
  gap: 4rem;
`
export const RadioButton = styled.div`
  display: flex;
  align-items: center;
  input {
    width: 1.2rem;
    height: 1.2rem;
  }
  label {
    margin-left: 8px;
    padding: 0;
  }
`

export const Button = styled.button`
  z-index: 99;
  outline: none;
  border: none;

  &.next {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 600;
    font-size: 16.8px;
    line-height: 23px;
    color: #ffffff;

    padding: 8px 24px;

    background: #1351b4;
    border-radius: 20px;

    max-width: 100%;
    width: 127px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all ease 0.2s;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    position: absolute;
    bottom: -60px;
    right: 15px;
  }

  &.prev {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 600;
    font-size: 16.8px;
    line-height: 23px;

    color: #1351b4;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all ease 0.2s;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    position: absolute;
    bottom: 30px;
    left: 15px;
  }

  &:disabled {
    &:hover {
      opacity: 0.5 !important;
    }
    opacity: 0.5;
    cursor: not-allowed !important;
  }
`

export const ErrorMessageText = styled.p`
  span {
    color: #da0000;
  }
`

export const ErrorSpan = styled.span`
  margin-top: 2px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: red;
`
