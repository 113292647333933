import styled from 'styled-components'
export const CourseContainer = styled.div`
  margin-top: 2rem;
  cursor: pointer;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`
export const CourseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background: #5c5c5c;
  border-radius: 4px 4px 0px 0px;
  padding: 1rem;
`
export const TextsHeader = styled.div`
  h1 {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-weight: 600;
  }

  p {
    margin: 0;
    padding: 0;
    font-style: italic;
    color: #f3f1f1;
  }
`
export const CourseBody = styled.div`
  padding: 1rem;
  background: #f3f1f1;
  h2 {
    font-size: 1.5rem;
    font-weight: 600;
  }
`
export const CourseLocation = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  p {
    margin: 0;
    padding: 0;
    color: black;
  }
`
export const CourseTime = styled.div`
  margin: 2rem 0;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  p {
    margin: 0;
    padding: 0;
    color: black;
    font-style: italic;
    font-size: 0.75rem;
  }
`
export const CourseFooter = styled.div`
  display: flex;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #c4c4c4;
  border-radius: 0px 0px 4px 4px;

  h1 {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    font-weight: bold;
  }

  span {
    font-size: 1.5rem;
    font-weight: bold;
  }
`

export const CourseLastInformation = styled.div`
  display: flex;
  gap: 6.25rem;
`
export const AboutCourse = styled.div`
  h3 {
    margin: 0;
    padding: 0;
    font-size: 0.75rem;
  }
  span {
    font-weight: 700;
    font-size: 1.5rem;
  }
`
