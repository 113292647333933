/* eslint-disable class-methods-use-this */
import { Auth } from 'aws-amplify'

export class AwsTokenManager {
  /**
   * Create a token manger
   *
   * @param {object} controllerProps
   */
  constructor({ storage, storageKey }) {
    this.storageKey = storageKey
    this.storage = storage
  }

  /**
   */
  async getToken() {
    try {
      const currentSession = await Auth.currentSession()

      if (!currentSession.isValid()) return null

      return `Bearer ${currentSession.getIdToken().getJwtToken()}`
    } catch (error) {
      return null
    }
  }

  /**
   */
  clearToken() {
    Auth.signOut()
  }
}
