import React from 'react'
import { Row, Col } from 'react-bootstrap'

import clockImage from '../../../../assets/img/clockImage.svg'
import locationImage from '../../../../assets/img/locationCourse.svg'

import {
  Container,
  CourseDiv,
  ClockDiv,
  AboutCollege,
  LocationDiv,
} from './styles'

export const SelectedCourse = ({
  name,
  degree,
  shift,
  university,
  city,
  uf,
}) => {
  return (
    <Container>
      <Row className="g-3">
        <Col md={6}>
          <CourseDiv>
            <h1>{name}</h1>
            <p>{degree}</p>

            <ClockDiv>
              <img src={clockImage} alt="" />
              <p>{shift}</p>
            </ClockDiv>
            <button>Ver documentação Necessária</button>
          </CourseDiv>
        </Col>
        <Col md={6}>
          <AboutCollege>
            <h1>{university}</h1>
            <LocationDiv>
              <img src={locationImage} alt="" />
              <p>
                {city} - {uf}
              </p>
            </LocationDiv>
          </AboutCollege>
        </Col>
      </Row>
    </Container>
  )
}
