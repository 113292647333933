import styled from 'styled-components'

import { FONT_FAMILY } from 'constants/fonts'

export const Container = styled.div`
  margin-top: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 575px) {
    margin-top: 15px;
  }

  .row {
    width: 1140px;
    max-width: 100%;
    padding: 0;
    margin-right: 15px;
    margin-left: 15px;

    @media (max-width: 900px) {
      padding: 15px;
    }
    @media (max-width: 575px) {
      padding: 0;
    }

    h1 {
      font-family: ${FONT_FAMILY};

      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 44px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: var(--loading-page-text);

      @media (max-width: 575px) {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }
`

export const DivImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 575px) {
    align-items: center;
    flex-direction: row;
    margin-bottom: 30px;
    padding: 0;
  }
  img {
    width: 124px;
    max-width: 100%;

    @media (max-width: 575px) {
      margin-right: 15px;
    }
  }
  h3 {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 29px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: var(--loading-page-text);
    margin: 8px 0 0 0;
    padding: 0;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
`

export const DivText = styled.div`
  padding-left: 30px;
  @media (max-width: 575px) {
    padding: 0;
  }
  div {
    background: var(--data-confirm-bg);
    border-radius: 16px;
    width: 100%;

    padding: 24px 33px;
    position: relative;

    @media (max-width: 575px) {
      padding: 15px;
    }

    svg {
      position: absolute;
      top: 24px;
      left: -15px;
      @media (max-width: 575px) {
        top: -15px;
        left: 45px;
        transform-origin: center;
        transform: rotate(90deg);
      }
    }

    h2 {
      font-family: ${FONT_FAMILY};
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: var(--loading-page-text);
      margin: 0 0 16px 0;
      padding: 0;
    }

    p {
      font-family: ${FONT_FAMILY};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      font-feature-settings: 'pnum' on, 'lnum' on;

      color: var(--loading-page-text);

      span {
        font-weight: bold;
      }
    }
    ul {
      list-style-type: initial;
      font-family: ${FONT_FAMILY};
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      font-feature-settings: 'pnum' on, 'lnum' on;

      color: var(--loading-page-text);
    }
  }
`

export const Button = styled.a`
  font-family: ${FONT_FAMILY};
  font-weight: 700;
  font-size: 20px;
  font-style: normal;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;

  color: var(--white);

  max-width: 100%;
  width: 179px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 42px;
  margin-top: 40px;

  @media (max-width: 575px) {
    margin-top: 15px;
    padding: 15px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
  }

  background: #1351b4;
  border-radius: 50px;
  border: none;

  transition: all ease 0.2s;

  &:hover {
    color: var(--white);
    opacity: 0.7;
    cursor: pointer;
  }
`
