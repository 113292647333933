/* eslint-disable react/prop-types */
import React, { useCallback, useState, createContext, useContext } from 'react'

import { copyToClipboard } from 'helpers/copyToClipboard'

import CopiedPopup from 'components/CopyText/fragments/CopiedPopup'

import { Container } from './style'

const CopyContext = createContext(false)

const CopyText = ({ children }) => {
  const copied = useContext(CopyContext)

  return (
    <Container>
      <CopiedPopup show={copied} />

      {children}
    </Container>
  )
}

const useCopy = () => {
  const [copied, setCopied] = useState(false)
  const [closeTimeout, setCloseTimeout] = useState()

  const onCopyHandler = useCallback(
    text => () => {
      setCopied(true)
      copyToClipboard(text)

      if (closeTimeout) {
        clearTimeout(closeTimeout)
        setCloseTimeout()
      }

      const timeout = setTimeout(() => setCopied(false), 2000)
      setCloseTimeout(timeout)
    },
    [closeTimeout, setCloseTimeout]
  )

  const CopyTextProvider = ({ children }) => (
    <CopyContext.Provider value={copied}>
      <CopyContext.Consumer>
        {() => <CopyText>{children}</CopyText>}
      </CopyContext.Consumer>
    </CopyContext.Provider>
  )

  return {
    onCopyHandler,
    CopyText: CopyTextProvider,
  }
}

export { useCopy }

export default CopyText
