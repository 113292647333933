import { useEffect } from 'react'

const createScript = el => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.defer = true
  if (el.src) {
    script.src = el.src
  } else {
    script.innerHTML = el.innerHTML
  }

  return script
}

export const useContentRenderExtraTags = content => {
  useEffect(() => {
    const contentJSElementAux = document.createElement('div')

    const tagList = []

    if (document && content.headerjs) {
      contentJSElementAux.innerHTML = content.headerjs
      const elements = contentJSElementAux.querySelectorAll('script')

      const loadSyncScripts = interator => {
        const el = interator.next().value
        if (el) {
          const tagScript = createScript(el)
          if (tagScript.src) {
            tagScript.onload = () => {
              loadSyncScripts(interator)
            }
          } else {
            loadSyncScripts(interator)
          }
          tagList.push(tagScript)
          document.body.appendChild(tagScript)
        } else {
          window.dispatchEvent(new Event('load'))
        }
      }

      loadSyncScripts(elements.values())
    }

    return () => {
      contentJSElementAux.remove()
      tagList.forEach(script => script.remove())
    }
  }, [content])
}
