/* eslint-disable react/prop-types */
import React, { useContext, useCallback, useState } from 'react'
import { FaEllipsisV } from 'react-icons/fa'

import { ThemeContext } from 'styled-components'

// import { useLangContext } from 'components/Globalization';
import SeparatorVertical from 'components/SeparatorVertical'
import wrapper from 'components/Wrapper'

// import logoRnp from 'assets/img/logo-rnp.png';
import logoGov from 'assets/img/govbr.png'
import contrastSvg from 'assets/img/contrast.svg'
import barsSvg from 'assets/img/bars.svg'
import searchSvg from 'assets/img/search.svg'

import ModuleLink from './fragments/ModuleLink'
import PrivateMenu from './fragments/PrivateMenu'
import ProfileHeaderMenu from './fragments/ProfileHeaderMenu'
import {
  Container,
  HeaderBrand,
  HeaderContent,
  MenuOptions,
  MenuButtonContainer,
  NavBrand,
  HideMobile,
} from './style'
import { Box } from '@material-ui/core'
import { Link } from 'react-router-dom'

const PrivateHeader = ({ role, menus, links, location }) => {
  // STATE
  const [show, setShow] = useState(false)
  const menuLenght = menus.length

  // HOOKS E CONTEXTS
  // const translate = useLangContext();
  const theme = useContext(ThemeContext)

  // // OTHER FUNCTIONS
  // const isActiveLocation = path => {
  //   if (location) {
  //     return location.pathname.startsWith(path) ? 'true' : undefined;
  //   }
  //   return false;
  // };

  const onShowMenuClickHandler = useCallback(() => {
    if (!menuLenght) return
    setShow(true)
  }, [menuLenght])

  const onCloseMenuClickHandler = useCallback(() => {
    setShow(false)
  }, [])

  return (
    <>
      <PrivateMenu show={show} menus={menus} close={onCloseMenuClickHandler} />

      <Container style={{ overflowX: 'hidden' }}>
        <HeaderContent>
          <HeaderBrand onClick={onShowMenuClickHandler}>
            {menus.length > 0 && (
              <MenuButtonContainer>
                <FaEllipsisV size="20px" color={theme.palette.grey[400]} />
              </MenuButtonContainer>
            )}
            <Box m={1.5}>
              <Link to="/" className="logoBtn">
                <NavBrand src={logoGov} width={111} />
              </Link>
            </Box>
          </HeaderBrand>
          <SeparatorVertical />
          <p className="govFed">Governo Federal</p>

          <MenuOptions>
            {/* {links.map(link => (
              <ModuleLink
                key={link.to}
                to={link.to}
                active={isActiveLocation(link.to)}
                label={translate(link.label)}
              />
            ))} */}
            <HideMobile>
              <ModuleLink
                key="Legislação"
                to="/"
                active="true"
                label="Legislação"
              />
              <ModuleLink
                key="Acesso à Informação"
                to="/"
                active="true"
                label="Acesso à Informação"
              />
              <div style={{ marginRight: '15px' }}>
                <ModuleLink
                  key="Acessibilidade"
                  to="/"
                  active="true"
                  label="Acessibilidade"
                />
              </div>
            </HideMobile>

            <Link to="/">
              <img src={contrastSvg} alt="Contraste" />
            </Link>
          </MenuOptions>

          {/* <SeparatorVertical /> */}

          <ProfileHeaderMenu />
        </HeaderContent>
        <HeaderContent>
          <div className="barsImg">
            <img src={barsSvg} alt="Bars" />
          </div>
          <div className="TextDiv">
            <h3>Acesso Único ao Ensino Superior</h3>
            <p>Ministério da Educação</p>
          </div>
          <MenuOptions>
            <input
              type="text"
              name="search"
              id="search"
              placeholder="O que você procura?"
            />
            <img src={searchSvg} alt="Search" className="searchImg" />
          </MenuOptions>
        </HeaderContent>
      </Container>

      {/* <Container style={{ overflowX: 'hidden' }}>
        <HeaderContent>
          <HeaderBrand onClick={onShowMenuClickHandler}>
            {menus.length > 0 && (
              <MenuButtonContainer>
                <FaEllipsisV size="20px" color={theme.palette.grey[400]} />
              </MenuButtonContainer>
            )}
            <Box m={1.5}>
              <Link to="/" className="logoBtn">
                <NavBrand src={logoRnp} width={145} />
              </Link>
            </Box>
          </HeaderBrand>

          <MenuOptions>
            {links.map(link => (
              <ModuleLink
                key={link.to}
                to={link.to}
                active={isActiveLocation(link.to)}
                label={translate(link.label)}
              />
            ))}
          </MenuOptions>

          <SeparatorVertical />

          <ProfileHeaderMenu />
        </HeaderContent>
      </Container> */}
    </>
  )
}

export default wrapper(PrivateHeader, {
  namespace: 'PRIVATE_HEADER',
  mapState: state => ({
    role: state.auth.role,
  }),
})
