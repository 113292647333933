import InitialPage from 'modules/InitialPage'
import RegisterUsers from 'modules/User/submodules/RegisterUsers'
import NoAccess from './submodules/NoAccess'
import { REGISTER_USERS_ROUTES } from 'modules/User/submodules/RegisterUsers/constants/routes'
import Confirmation from 'modules/Confirmation'
import Candidate from '../Candidate'
import Sisu from 'modules/Sisu'
import SisuFollowRegistration from 'modules/SisuFollowRegistration'
import Comprovante from 'modules/Comprovante'
import Fies from 'modules/Fies'
import FiesFollowRegistration from 'modules/FiesFollowRegistration'
import Prouni from 'modules/Prouni'
import ProuniFollow from 'modules/ProuniFollow'
import ChangeSisu from 'modules/ChangeSisu'
import ChangeProuni from 'modules/ChangeProuni'
import ComprovanteComHash from 'modules/ComprovanteComHash'
import ResetMyAccount from 'modules/ResetMyAccount'

export const adminRouteProvider = {
  routes: [
    InitialPage,
    RegisterUsers,
    Confirmation,
    Candidate,
    Comprovante,
    Sisu,
    SisuFollowRegistration,
    Fies,
    FiesFollowRegistration,
    Prouni,
    ProuniFollow,
    ChangeSisu,
    ChangeProuni,
    ComprovanteComHash,
    ResetMyAccount,
  ],
  links: [{ to: REGISTER_USERS_ROUTES.LIST_USERS, label: 'USERS:TITLE' }],
  menus: [],
}

export const defaultRouteProvider = {
  routes: [NoAccess],
  links: [],
  menus: [],
}
