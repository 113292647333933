import { createGlobalStyle } from 'styled-components'

import { FONT_FAMILY } from 'constants/fonts'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    font-family: ${FONT_FAMILY};
    font-feature-settings: 'lnum';
    -webkit-font-smoothing: antialiased;
  }

  a {
    text-decoration: none;
  }
  
  ul {
    list-style: none;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }


.react-datepicker {
  font-family: ${FONT_FAMILY};
}

.react-datepicker__header {
  background-color: #f0f0f0;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__current-month {
  text-transform: capitalize;
  color: ${props => props.theme.palette.primary.main};
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
}

.react-datepicker__navigation {
  color: ${props => props.theme.palette.primary.main};
}

.react-datepicker__day-names {
  text-transform: capitalize;
}

.react-datepicker__day--selected {
  border-radius: 50%;
  background-color: ${props => props.theme.palette.primary.main};
  color: #fff !important;
}

.react-datepicker__day + :hover {
  border-radius: 50%;
}

.react-datepicker__day {
  color: ${props => props.theme.palette.primary.main};
}

.popover-body p:hover {
  cursor: pointer;
  opacity: 0.8;    
}


`
