import {
  CardConfirmCourseContainer,
  CardConfirmHeader,
  CardCourseName,
  CardCourseLocation,
  CardCourseModality,
  CardConfirmFooter,
  CourseTime,
  CourseLocation,
  CardWrapper,
  Classification,
  ProUniHeader,
  WaitingList,
} from './style'
import { ConfirmPopup } from '../../../components/Popup/ConfirmPopup/index.jsx'
import clockImage from './img/clockImage.svg'
import locationImage from './img/locationCourse.svg'
import React, { useState } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import checkImage from './img/check.svg'
import medalImage from './img/medal.svg'
import { format } from 'date-fns'
import { apiWithToken } from 'services/apiWithToken'
import ptBR from 'date-fns/locale/pt-BR'
import { useLoading } from '../../../hooks/useLoading'
export function CardConfirmCourse({
  courseOptionId,
  courseData,
  position,
  totalVacancies,
  totalSubscribers,
  minimumGrade,
  editCourseData,
  courseOption,
  courseName,
  courseType,
  courseTime,
  collegeName,
  courseLocation,
  courseState,
  campusNeighborhood,
  modalityType,
  isClassified,
  isInWaitingList,
  waitingListEnterDate,
  waitingListPosition,
  waitingListTotalCandidates,
  rankType,
  optionActived,
  callToReload,
  stage

}) {
  const [showConfirmSubWaitList, setShowConfirmSubWaitList] = useState(false)

  let isParcial = rankType === 'parcial'
  let isRegular = rankType === 'regular'
  let isTheActiveOption = optionActived.number === courseOption || optionActived.number === null
  let isWaitListAnnounced = stage === 'Divulgar Classificação na Lista de Espera' && isInWaitingList

  // isInWaitingList === null significa que usuário não está participando de nenhuma lista de espera.
  // isInWaitingList === false significa que usuário não está participando da lista de espera da opção corrente

  let canSubscribeWaitingList = isRegular && !optionActived.isClassified && optionActived.isInWaitingList === null
  isClassified = isClassified && optionActived.number === courseOption // o usuário só é classifica em apena uma opção

  let formattedDateTime = theDate => {
    return format(new Date(theDate), "dd/MM/yyyy 'às' HH:mm", {
      locale: ptBR,
    })
  }


  const { loading } = useLoading()

  const subcribeWaitList = async () => {
    try {
      await apiWithToken.post(`/wait-list/${courseOptionId}`)
      callToReload()
      setShowConfirmSubWaitList(false)

    } catch (err) {
      console.error(err)
    } finally {
      loading.turnOff()
    }
  }

  return (
    <CardConfirmCourseContainer>
      <ProUniHeader
        className={isClassified ? 'classifiedBlue' : null}
      >
        <h1>{courseOption}ª OPÇÃO DE CURSO</h1>
      </ProUniHeader>

      <CardWrapper>
        <CardConfirmHeader>
          {editCourseData ? (
            <OverlayTrigger
              trigger="click"
              key="bottom"
              placement="bottom"
              overlay={
                <Popover id={`popover-positioned-bottom`}>
                  <Popover.Body>
                    <p>Trocar para 2ª opção</p>
                    <p>Cancelar Curso</p>
                    <p>Alterar Curso</p>
                  </Popover.Body>
                </Popover>
              }
            >
              <span>
                <svg
                  width="6"
                  height="16"
                  viewBox="0 0 6 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 5.75C2.375 5.75 1.84375 5.96875 1.40625 6.40625C0.96875 6.84375 0.75 7.375 0.75 8C0.75 8.625 0.96875 9.15625 1.40625 9.59375C1.84375 10.0312 2.375 10.25 3 10.25C3.625 10.25 4.15625 10.0312 4.59375 9.59375C5.03125 9.15625 5.25 8.625 5.25 8C5.25 7.375 5.03125 6.84375 4.59375 6.40625C4.15625 5.96875 3.625 5.75 3 5.75ZM0.75 2.5C0.75 1.875 0.96875 1.34375 1.40625 0.90625C1.84375 0.46875 2.375 0.25 3 0.25C3.625 0.25 4.15625 0.46875 4.59375 0.90625C5.03125 1.34375 5.25 1.875 5.25 2.5C5.25 3.125 5.03125 3.65625 4.59375 4.09375C4.15625 4.53125 3.625 4.75 3 4.75C2.375 4.75 1.84375 4.53125 1.40625 4.09375C0.96875 3.65625 0.75 3.125 0.75 2.5ZM0.75 13.5C0.75 12.875 0.96875 12.3438 1.40625 11.9062C1.84375 11.4688 2.375 11.25 3 11.25C3.625 11.25 4.15625 11.4688 4.59375 11.9062C5.03125 12.3438 5.25 12.875 5.25 13.5C5.25 14.125 5.03125 14.6562 4.59375 15.0938C4.15625 15.5312 3.625 15.75 3 15.75C2.375 15.75 1.84375 15.5312 1.40625 15.0938C0.96875 14.6562 0.75 14.125 0.75 13.5Z"
                    fill="#1351B4"
                  />
                </svg>
              </span>
            </OverlayTrigger>
          ) : null}
        </CardConfirmHeader>

        {isTheActiveOption && rankType && (
          <Classification className={(isParcial ? 'parcial' : 'regular') + ' ' + (isClassified ? 'classified' : 'unclassified')}>

            {isInWaitingList && isRegular && (
              <WaitingList>
                <img src={checkImage} alt="" />
                <div>
                  <h3> Você esta participando da lista de espera</h3>
                  <h4>Data de manifestação de participação: {formattedDateTime(waitingListEnterDate)}</h4>
                </div>
              </WaitingList>
            )}


            {isRegular && isClassified && (<img src={medalImage} alt="" />)}


            {!isWaitListAnnounced &&
              (<h1>{isParcial ? 'Classificação parcial' : 'Classificação final da chamada regular'}</h1>)
            }
            {isWaitListAnnounced &&
              (<h1>Classificação da lista de espera</h1>)
            }


            {(isParcial || !isClassified) && !isWaitListAnnounced && (
              <h2>Você está ocupando a <span>{position}º</span> de {totalVacancies} vagas</h2>
            )}
            {isRegular && isClassified && !isWaitListAnnounced && (
              <h2>Parabéns! Você está classificado em <span>{position}º</span> de {totalVacancies} vagas</h2>
            )}
            {isWaitListAnnounced && (
              <h2>Você ficou em <span>{waitingListPosition}º</span> de {waitingListTotalCandidates} candidatos</h2>
            )}

            {!isWaitListAnnounced && (
              <button class="note">
                Nota de Corte: <span>{totalSubscribers >= totalVacancies && minimumGrade ? minimumGrade.toString().replace('.', ',') : 'Indisponível'}</span>
              </button>
            )}

            {!isWaitListAnnounced && (
              <p>{totalSubscribers} candidatos inscritos</p>
            )}

            {isRegular && isClassified && (
              <button className="seeResult">Ver classificados</button>
            )}

            {canSubscribeWaitingList && isInWaitingList === null && (
              <button className="waitList" onClick={() => setShowConfirmSubWaitList(true)}>Participar da lista de espera</button>
            )}

            {isWaitListAnnounced && (
              <button className="seeWaitList">Ver lista de espera</button>
            )}
          </Classification>
        )}

        {!isTheActiveOption && rankType && (
          <Classification className={(isParcial ? 'parcial' : 'regular') + ' ' + (isClassified ? 'classified' : 'unclassified')}>
            <h1>{isParcial ? 'Classificação parcial' : 'Classificação final da chamada regular'}</h1>

            {isRegular && optionActived.isClassified &&
              <h2>Você foi selecionado na {optionActived.number}ª opção de curso</h2>
            }
            {isParcial && optionActived.isClassified &&
              <h2>Você está classificado parcialmente na {optionActived.number}ª opção de curso</h2>
            }
            {optionActived.isInWaitingList &&
              <h2>Você está na lista de espera da {optionActived.number}ª opção de curso</h2>
            }
          </Classification>
        )}

        <CardCourseName>
          <h2>{courseName}</h2>
          <p>{courseType}</p>
          <CourseTime>
            <img src={clockImage} alt="Imagem de relógio" />
            <p>{courseTime}</p>
          </CourseTime>
        </CardCourseName>

        <CardCourseLocation>
          <h2>{collegeName}</h2>
          <CourseLocation>
            <img src={locationImage} alt="Imagem de Localização" />
            <p>
              {courseLocation}
              <br />({campusNeighborhood}, {courseState})
            </p>
          </CourseLocation>
        </CardCourseLocation>

        <CardCourseModality>
          <h2>Modalidade de concorrência</h2>
          <h3>{modalityType}</h3>
        </CardCourseModality>

        {courseData && (
          <CardConfirmFooter>
            <button>Ver documentação Necessária</button>
          </CardConfirmFooter>
        )}
      </CardWrapper>

      {showConfirmSubWaitList && (
        <ConfirmPopup
          title={courseName + ' / ' + collegeName}
          confirmHandler={subcribeWaitList}
          cancelHandler={() => setShowConfirmSubWaitList(false)}>
          <p>Tem certeza que deseja se inscrever na fila de espera da opção {courseOption}?</p>
          <p>Após se inscrever, não será possível trocar de curso!</p>
        </ConfirmPopup>
      )}
    </CardConfirmCourseContainer>
  )
}
