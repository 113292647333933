import React, { useEffect, useState } from 'react'

import wrapper from 'components/Wrapper'

import { SISU_ROUTES } from './constants/routes'

import { Link, useHistory, useLocation } from 'react-router-dom'
import BreadcrumbWrapper from 'components/Breadcrumb'

import homeSvg from '../../assets/img/breadcrumb/homeIcon.svg'
import { Typography } from '@material-ui/core'
import { StepSubsCriberProvider } from 'components/SisuSubscribeForm/Context'
import { Button, Container, DivRow, WrapperBackground } from './styles'
import { HeaderSisu } from 'components/SisuSubscribeForm/HeaderSisu'
import { StepsDiv } from './styles'
import { StepsBox } from './styles'
import { StepItem } from './styles'
import { StepOneSubscribeForm } from 'components/SisuSubscribeForm/StepOne'
import { StepTwoSubscribeForm } from 'components/SisuSubscribeForm/StepTwo'
import { StepThreeSubscribeForm } from 'components/SisuSubscribeForm/StepThree'
import { StepFourSubscribeForm } from 'components/SisuSubscribeForm/StepFour'
import tagOkSvg from './img/tagOk.svg'
import FormContext1 from 'components/DataConfirmSteps/formContext1'
import FormContext2 from 'components/DataConfirmSteps/formContext2'
import FormContext3 from 'components/DataConfirmSteps/formContext3'
import { apiWithToken } from 'services/apiWithToken'

const SISU = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const location = useLocation()
  const history = useHistory()

  const [step1, setStep1] = useState(!!location.state?.cameFromSubscribeButton)
  const [step2, setStep2] = useState(false)
  const [step3, setStep3] = useState(!location.state?.cameFromSubscribeButton)
  const [step4, setStep4] = useState(false)

  const [formContent1, setFormContent1] = useState(false)
  const [formContent2, setFormContent2] = useState(false)
  const [formContent3, setFormContent3] = useState(false)
  const value1 = { formContent1, setFormContent1 }
  const value2 = { formContent2, setFormContent2 }
  const value3 = { formContent3, setFormContent3 }

  useEffect(() => {
    if (formContent1) {
      setStep1(false)
      setStep2(true)
      setStep3(false)
      setStep4(false)
    }
    if (formContent2) {
      setStep1(false)
      setStep2(false)
      setStep3(true)
      setStep4(false)
    }
    if (formContent3) {
      setStep1(false)
      setStep2(false)
      setStep3(false)
      setStep4(true)
    }

    history.replace('/sisu', {})
  }, [formContent1, formContent2, formContent3])

  const [userAverage, setUserAverage] = useState({})

  useEffect(() => {
    async function loadUserAverage() {
      try {
        const { data } = await apiWithToken.get('/users/average')

        setUserAverage(data)
      } catch (err) {
        console.log(err)
      }
    }

    loadUserAverage()
  }, [])

  return (
    <>
      <BreadcrumbWrapper>
        <Link to="/initial-page">
          <img src={homeSvg} alt="Home" />
        </Link>
        <Link to="/candidate-page">Painel do Candidato</Link>
        <Typography>SISU</Typography>
        <Typography>Inscrição do SISU</Typography>
      </BreadcrumbWrapper>
      <StepSubsCriberProvider>
        <Container>
          <DivRow>
            <HeaderSisu
              subType="SISU"
              daysLeft="1 dia e 23 horas"
              note={userAverage.averageScore || ''}
              year={userAverage.year || ''}
            />
            <div className="col-12">
              <StepsDiv>
                <StepsBox>
                  <StepItem
                    stepNumber="1"
                    className={` ${step1 ? 'active' : null}`}
                  >
                    {step2 || step3 || step4 ? (
                      <img src={tagOkSvg} alt="Concluído" />
                    ) : null}

                    <span>
                      Confirmação dos
                      <br />
                      seus dados
                    </span>
                  </StepItem>
                  <StepItem
                    stepNumber="2"
                    className={`${step2 ? 'active' : null}`}
                  >
                    {step3 || step4 ? (
                      <img src={tagOkSvg} alt="Concluídoooo" />
                    ) : null}
                    <span>
                      Confirmação renda <br /> mensal familiar
                    </span>
                  </StepItem>
                  <StepItem
                    stepNumber="3"
                    className={` ${step3 ? 'active' : null}`}
                  >
                    {step4 ? <img src={tagOkSvg} alt="Concluído" /> : null}
                    <span>
                      Seleção dos <br />
                      cursos
                    </span>
                  </StepItem>
                  <StepItem
                    stepNumber="4"
                    className={` ${step4 ? 'active' : null}`}
                  >
                    <span>Finalizar</span>
                  </StepItem>
                </StepsBox>
              </StepsDiv>
            </div>
          </DivRow>
        </Container>
        <DivRow>
          <FormContext1.Provider value={value1}>
            {step1 && <StepOneSubscribeForm />}
          </FormContext1.Provider>
          <FormContext2.Provider value={value2}>
            {step2 && (
              <WrapperBackground>
                <StepTwoSubscribeForm />
                <Button
                  className="prev"
                  onClick={event => {
                    setStep1(true)
                    setStep2(false)
                    setStep3(false)
                    setStep4(false)
                  }}
                >
                  Voltar
                </Button>
              </WrapperBackground>
            )}
          </FormContext2.Provider>
        </DivRow>
        <FormContext3.Provider value={value3}>
          {step3 && (
            <>
              <StepThreeSubscribeForm
                comeBackStepTwo={() => {
                  setStep1(false)
                  setStep2(true)
                  setStep3(false)
                  setStep4(false)
                }}
              />
            </>
          )}
        </FormContext3.Provider>
        <DivRow>{step4 && <StepFourSubscribeForm />}</DivRow>
      </StepSubsCriberProvider>
    </>
  )
}

export default wrapper(SISU, {
  route: { path: SISU_ROUTES.SISU },
})
