import * as Yup from 'yup'

Yup.addMethod(
  Yup.string,
  'digitsOnly',
  function digitsOnly(message = 'Must cotain only digits') {
    return this.transform(val =>
      val != null ? val.replace(/\D/gm, '') : val
    ).test({
      message,
      name: 'onlyDigits',
      test: value => value == null || !value.match(/\D/gm)?.length,
    })
  }
)
