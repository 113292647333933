import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0;
  margin: 0;
  margin-top: 3.125rem;

  a.printButton {
    &:hover {
      filter: brightness(0.8);
      cursor: pointer;
    }
    margin-bottom: 50px;
    color: white;
    transition: all ease 0.2s;
    padding: 8px 24px;
    border-radius: 20px;
    border: 1px solid ${props => props.theme.bgColors.primaryDefault};
    background: ${props => props.theme.bgColors.primaryDefault};
    font-weight: bold;

    @media screen and (max-width: 992px) {
      margin-top: 1.5rem;
      width: 100%;
      text-align: center;
    }
  }
`

export const PrintButtonContainer = styled.div`
  margin: 2rem 0;
`
