/**
 * @enum {string}
 */
export const ROLES = {
  ROLE_ADMIN: 'ROLE_ADMIN',
}

/**
 * @enum {string}
 */
export const HUMANIZED_ROLES = {
  ROLE_ADMIN: 'Gestor',
  ROLE_CONSULTANT: 'Consultor',
  ROLE_PUBLISHER: 'Editora',
  ROLE_IPT: 'IPT',
}
