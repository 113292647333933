import styled from 'styled-components'

export const CardConfirmCourseContainer = styled.div`
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 992px) {
    margin-top: 1rem;
  }
`

export const CardWrapper = styled.div`
  .boxTooltip {
    background: white !important;
  }
  padding: 24px;
  background: #ffffff;
  border-radius: 4px;
  @media screen and (max-width: 992px) {
    margin-top: 1.5rem;
  }
`

export const ProUniHeader = styled.div`
  background-color: #e2eaf5;
  height: 100%;
  padding: 1rem 1.5rem;

  text-align: center;
  h1 {
    margin: 0;
    padding: 0;
    font-weight: 700;
    font-size: 1.25rem;
    color: #0c326f;
  }
`
export const CardConfirmHeader = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }
  span {
    background: #f8f8f8;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
  }
`

export const CardCourseName = styled.div`
  h2 {
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0;
    padding: 0;
  }
`

export const ProUniInformations = styled.div`
  margin-top: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
  }

  h2 {
    margin: 0;
    padding: 0;
    font-size: 12px;
  }

  span {
    font-weight: bold;
    font-size: 1.5rem;
  }

  p {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
`

export const CardCourseLocation = styled.div`
  margin-top: 2rem;
  h2 {
    margin: 0;
    font-size: 1.25rem;
    color: #555555;
    font-weight: 600;
    margin: 0;
  }
  p {
    font-size: 1rem;
    margin: 0;
    padding: 0;
  }
`

export const CardCourseModality = styled.div`
  margin-top: 2rem;
  h2 {
    margin: 0;
    padding: 0;
    font-size: 1rem !important;
    color: #585858;
    font-style: italic;
  }

  h3 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 600;
  }
`

export const CardConfirmFooter = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  button {
    background: transparent;
    border: 0;
    padding: 6px 16px 7px;
    color: ${props => props.theme.bgColors.primaryDefault};
    font-weight: bold;
    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const CourseTime = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  p {
    margin: 0;
    padding: 0;
    color: black;
    font-style: italic;
    font-size: 0.75rem;
  }
`

export const CourseLocation = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  img {
    align-self: flex-start;
  }

  p {
    margin: 0;
    padding: 0;
    color: black;
  }
`

export const CourseOptions = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`

export const Classification = styled.div`
  background: #5992ed;
  text-align: center;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2.5rem;
  border-radius: 4px;

  button {
    max-width: content;
    padding: 4px 16px 4px 16px;
    border: 0;
    border-radius: 32px;
    color: #1351b4;

    span {
      font-weight: 700;
    }

    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
  }

  h1 {
    padding: 0;
    margin: 0;
    font-weight: 600;

    font-size: 1.25rem;
    color: #071d41;
  }

  h2 {
    margin: 0;
    padding: 0;
    font-size: 1.25rem;
    font-weight: 400;
    margin: 1rem 0;

    color: ${props => props.theme.bgColors.colorSecondary01};
    span {
      font-weight: 700;
    }

    p {
      padding: 0;
      margin: 0;
      color: black !important;
    }
  }
`
