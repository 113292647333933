import React from 'react'
import { Container } from 'react-bootstrap'
import {
  ClockTime,
  HeaderSubscribe,
  AverageGrade,
  Title,
  Wrapper,
} from './style'
import alarmClock from './img/alarmClock.svg'
export function HeaderSisu({ note, daysLeft, subType, year }) {
  return (
    <Wrapper>
      <Container>
        <Title>Inscrição no {subType}</Title>
        <HeaderSubscribe>
          <ClockTime>
            <img src={alarmClock} alt="ícone do Relógio" />
            <div>
              <h2>Fique ligado!</h2>

              <p>
                Faltam <strong>{daysLeft}</strong> para encerrarem as inscrições
              </p>
            </div>
          </ClockTime>
          <AverageGrade>
            <div>
              <h2>Sua melhor média no ENEM</h2>
              <p>Edição utilizada: {year}</p>
            </div>
            <span>{note}</span>
          </AverageGrade>
        </HeaderSubscribe>
      </Container>
    </Wrapper>
  )
}
