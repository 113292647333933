import styled from 'styled-components'
import { FONT_FAMILY } from 'constants/fonts'

export const ButtonSetData = styled.div`
  display: flex;
  gap: 1rem;

  button {
    border-radius: 20px;
    padding: 0.5rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;
    width: 146px;
    height: 39px;

    color: ${props => props.theme.bgColors.primaryDefault};
    border: 1px solid ${props => props.theme.bgColors.primaryDefault};
    background: #ffffff;
    transition: all ease 0.2s;

    &:focus {
      background: ${props => props.theme.bgColors.primaryDefault};
    }
    &.primary {
      background: ${props => props.theme.bgColors.primaryDefault};
      color: #ffffff;
      width: 102px;
      height: 32px;
    }

    &.cancel {
      color: ${props => props.theme.bgColors.primaryDefault};
      width: 120px;
      height: 32px;

      :hover {
        color: #ffffff;
      }
    }
    :hover {
      background: ${props => props.theme.bgColors.primaryDefault};
    }
  }
`

export const Button = styled.button`
  z-index: 99;
  outline: none;
  border: none;
  &.next {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 600;
    font-size: 16.8px;
    line-height: 23px;
    color: #ffffff;

    padding: 8px 24px;

    background: #1351b4;
    border-radius: 20px;

    max-width: 100%;
    width: 127px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all ease 0.2s;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    position: absolute;
    bottom: -60px;
    right: 15px;
  }

  &.prev {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 600;
    font-size: 16.8px;
    line-height: 23px;

    color: #1351b4;
    background: none;

    border: 1px solid #1351b4;
    border-radius: 20px;
    padding: 8px 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all ease 0.2s;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    position: absolute;
    bottom: -60px;
    left: 15px;
  }

  &:disabled {
    &:hover {
      opacity: 0.5 !important;
    }
    opacity: 0.5;
    cursor: not-allowed !important;
  }
`
