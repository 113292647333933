/* eslint-disable react/prop-types */
import React from 'react'
// import React, { useState, useRef, useEffect } from 'react';
// import { useCookies } from 'react-cookie';
import { Redirect } from 'react-router-dom'

// import { Box, Button, Grid, Typography } from '@material-ui/core';

// import history from 'config/history/history';
// import { recaptchaEnabled } from 'helpers/environment';

// import Form from 'components/Form';
// import { useLangContext } from 'components/Globalization';
// import PublicContainer from 'components/PublicContainer';
// import ReCAPTCHAInput from 'components/ReCAPTCHAInput';
// import TextField from 'components/TextField';
import wrapper from 'components/Wrapper'

// import { APP_ROUTES } from 'modules/App/constants/route';
// import { AUTH } from 'modules/Auth/constants/auth';
import { AUTH_ROUTES } from 'modules/Auth/constants/routes'
import { authService } from 'services/providers/auth'
// import { authService, authUseCases } from 'services/providers/auth';
// import { notificationUseCases } from 'modules/Notification/providers';
// import { USER_ROUTES } from 'modules/User/constants/routes';

// import formSchema from './formSchema';
// import PrivacyPolicy from './fragments/PrivacyPolicy';
// import { CustomLink } from './style';
import { authActions } from '../../../../config/store/slices/auth.slice'
import store from '../../../../config/store/store'
import { INITIAL_PAGE_ROUTES } from '../../../InitialPage/constants/routes'
import { LoadingFixedScreen } from './style'

const Login = ({ isAuthenticated }) => {
  // const translate = useLangContext();
  // const recaptchaRef = useRef();

  // const [cookies, setCookies] = useCookies(['privacyPolicy']);
  // const [hidePrivacyPolicy, setHidePrivacyPolicy] = useState(
  //   !cookies.privacyPolicy
  // );

  const govBrAuthURL = `https://${process.env.REACT_APP_AUTH_URL}/oauth2/authorize?identity_provider=Gov.BR&redirect_uri=${process.env.REACT_APP_FRONTEND_URL}/token&response_type=CODE&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID_NO_SECRET}&scope=openid+profile+aws.cognito.signin.user.admin`

  const govBrLogin = () => {
    authService.setIsWaitingGovBrAuthLogout()
    store.dispatch(authActions.isWaitingGovBrLogout(true))
    window.location.href = govBrAuthURL
  }

  govBrLogin()

  // const onSubmitHandler = async data => {
  //   const { username, password, recaptcha: token } = data;

  //   try {
  //     const user = await authUseCases.login({ username, password, token });
  //     switch (user.challengeName) {
  //       case AUTH.MFA_SETUP:
  //         return history.push(AUTH_ROUTES.MFA_SETUP);
  //       case AUTH.SOFTWARE_TOKEN_MFA:
  //         return history.push(AUTH_ROUTES.MFA_TOKEN);
  //       case AUTH.NEW_PASSWORD_REQUIRED:
  //         return history.push(USER_ROUTES.CHANGE_PASSWORD);
  //       default:
  //         return history.push(APP_ROUTES.BASE_ROUTE);
  //     }
  //   } catch (error) {
  //     notificationUseCases.error(error, { namespace: 'ERROR' });
  //     return recaptchaRef.current?.reset();
  //   }
  // };

  if (isAuthenticated) {
    return <Redirect to={INITIAL_PAGE_ROUTES.INITIAL_PAGE} />
  }

  // const onCloseConfirmPrivacyPolicy = () => {
  //   setCookies('privacyPolicy', true, { path: '/' });
  //   setHidePrivacyPolicy(false);
  // };

  return (
    <LoadingFixedScreen data-test="LoadingFixedScreen">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoadingFixedScreen>
    // <PublicContainer
    //   title={translate('WELCOME_TITLE')}
    //   description={translate('WELCOME_MESSAGE')}
    // >
    //   <Grid container spacing={3}>
    //     <Grid item>
    //       <Typography variant="h4" color="primary">
    //         {translate('LOGIN_TITLE')}
    //       </Typography>
    //       <Box mt={2} />
    //     </Grid>
    //   </Grid>

    //   <Form onSubmit={onSubmitHandler} schema={formSchema}>
    //     {formMethods => (
    //       <Grid container spacing={3}>
    //         <Grid item xs={12}>
    //           <TextField
    //             name="username"
    //             label={translate('USER')}
    //             placeholder={translate('USER_PLACEHOLDER')}
    //             formMethods={formMethods}
    //           />
    //         </Grid>

    //         <Grid item xs={12}>
    //           <TextField
    //             name="password"
    //             type="password"
    //             label={translate('PASSWORD')}
    //             placeholder={translate('PASSWORD_PLACEHOLDER')}
    //             formMethods={formMethods}
    //           />
    //         </Grid>

    //         <Grid item xs={12}>
    //           <Box display="flex" mt={-2} mb={2} justifyContent="flex-end">
    //             <CustomLink to={USER_ROUTES.REQUEST_PASSWORD_RESET}>
    //               {translate('FORGET_PASSWORD')}
    //             </CustomLink>
    //           </Box>
    //         </Grid>

    //         {recaptchaEnabled ? (
    //           <Grid item xs={12}>
    //             <ReCAPTCHAInput formMethods={formMethods} ref={recaptchaRef} />
    //           </Grid>
    //         ) : null}

    //         <Grid item xs={12}>
    //           <Button type="submit" fullWidth size="large">
    //             {translate('SIGNIN').toUpperCase()}
    //           </Button>
    //         </Grid>
    //         <Grid item xs={12}>
    //           <Button fullWidth onClick={govBrLogin}>
    //             Entrar com gov.br
    //           </Button>
    //         </Grid>
    //       </Grid>
    //     )}
    //   </Form>
    //   <PrivacyPolicy
    //     hide={hidePrivacyPolicy}
    //     close={onCloseConfirmPrivacyPolicy}
    //   />
    // </PublicContainer>
  )
}

export default wrapper(Login, {
  namespace: 'LOGIN',
  mapState: state => ({
    isAuthenticated: state.auth.isAuthenticated,
  }),
  route: {
    path: AUTH_ROUTES.LOGIN,
  },
})
