import {
  CourseContainer,
  CourseHeader,
  CourseBody,
  CourseFooter,
  CourseLocation,
  CourseTime,
  CourseLastInformation,
  AboutCourse,
  TextsHeader,
} from './style'

import heartImage from './img/heartImage.svg'
import locationImage from './img/locationCourse.svg'
import clockImage from './img/clockImage.svg'
export function CardCourse({
  title,
  subTitle,
  college,
  location,
  state,
  timeType,
  time,
  vacancies,
  affirmativeAction,
  note,
}) {
  return (
    <CourseContainer>
      <CourseHeader>
        <TextsHeader>
          <h1>{title}</h1>
          <p>{subTitle}</p>
        </TextsHeader>
        <img src={heartImage} alt="" />
      </CourseHeader>
      <CourseBody>
        <h2>{college}</h2>
        <CourseLocation>
          <img src={locationImage} alt="" />
          <p>
            {location}
            <br />({state})
          </p>
        </CourseLocation>
        <CourseTime>
          <img src={clockImage} alt="" />
          <p>{timeType}</p>
        </CourseTime>

        <CourseLastInformation>
          <AboutCourse>
            <h3>Total de Vagas</h3>
            <span>{vacancies}</span>
          </AboutCourse>
          <AboutCourse>
            <h3>Acões Afirmativas?</h3>
            <span>{affirmativeAction}</span>
          </AboutCourse>
        </CourseLastInformation>
      </CourseBody>
      <CourseFooter>
        <h1>Nota de Corte</h1>
        <span>{note}</span>
      </CourseFooter>
    </CourseContainer>
  )
}
