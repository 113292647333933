/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import {
  ContainerStep,
  SubscribeInformation,
  ButtonsStepContainer,
  ConfirmedCourse,
  HashContainer,
} from './style'
import { Link, useHistory } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { CardConfirmCourse } from '../CardConfirmCourse'

import { awsTokenManager } from 'config/auth'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { LoadingFixedScreen } from '../style'

export function StepFourSubscribeForm() {
  const [cpf, setCpf] = useState('')
  const [option1, setOption1] = useState('')
  const [option2, setOption2] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [apiData1, setApiData1] = useState([])
  const [apiData2, setApiData2] = useState([])
  const [hash, setHash] = useState('')

  useEffect(() => {
    const getUserMe = async () => {
      try {
        const token = await awsTokenManager.getToken()
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        }
        let data = await axios
          .get(`${process.env.REACT_APP_API_URL}/users/me`, config)
          .then(({ data }) => data)

        setCpf(data.cpf)

        try {
          const config = {
            headers: { Authorization: `${token}` },
          }

          let data = await axios
            .get(
              `${process.env.REACT_APP_API_URL}/user-registration/${cpf}`,
              config
            )
            .then(({ data }) => data)
          setHash(data[0].hash)

          if (data.length !== 0) {
            data.map(item => {
              MapProgram(item)
            })
            async function MapProgram(item) {
              if (item.program === 'prouni') {
                item.courses.map(item => {
                  MapCoursesOptions(item)
                })
                async function MapCoursesOptions(item) {
                  const courseMap = item.option
                  const courseCode = item.courseName
                  if (courseMap === 'option3') {
                    setOption1('1')
                    try {
                      const config = {
                        headers: { Authorization: `${token}` },
                      }

                      let data = await axios
                        .get(`${process.env.REACT_APP_API_URL}/course`, config)
                        .then(({ data }) => data)

                      async function mapCourse1(item) {
                        const courseMap = item.courseTimeCode
                        if (courseMap === courseCode) {
                          setApiData1(item)
                        }
                      }
                      data.map(item => {
                        mapCourse1(item)
                      })
                      setLoaded(true)
                    } catch (err) {
                      console.log(err)
                    }
                  } else {
                    setOption2('2')
                    try {
                      const config = {
                        headers: { Authorization: `${token}` },
                      }

                      let data = await axios
                        .get(`${process.env.REACT_APP_API_URL}/course`, config)
                        .then(({ data }) => data)

                      async function mapCourse1(item) {
                        const courseMap = item.courseTimeCode
                        if (courseMap === courseCode) {
                          setApiData2(item)
                        }
                      }
                      data.map(item => {
                        mapCourse1(item)
                      })
                      setLoaded(true)
                    } catch (err) {
                      console.log(err)
                    }
                  }
                }
              }
            }
          }
        } catch (err) {
          console.log(err)
        }
      } catch (err) {
        console.log(err)
      }
    }

    getUserMe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf])

  const history = useHistory()
  return (
    <>
      {loaded ? null : (
        <>
          <LoadingFixedScreen data-test="LoadingFixedScreen">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </LoadingFixedScreen>
        </>
      )}
      <ContainerStep>
        <Container>
          <h1>Processo Concluído</h1>
        </Container>

        <Container>
          <SubscribeInformation>
            <p>
              Sua inscrição foi recebida, fique atento aos nosso comunicados
              para saber o resultado
              <br /> do processo.
            </p>

            <ButtonsStepContainer>
              <Link
                to={`/comprovante/${hash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Imprimir comprovante
              </Link>
            </ButtonsStepContainer>
          </SubscribeInformation>

          <ConfirmedCourse>
            <h2>Cursos Confirmados</h2>

            <Row>
              <Col md={6}>
                <CardConfirmCourse
                  isEdit={false}
                  option={option1}
                  courseName={apiData1.courseName}
                  modality={apiData1.courseDegree}
                  courseTime={apiData1.courseTimeType}
                  university={apiData1.campusName}
                  city={apiData1.campusNeighborhood}
                  uf={apiData1.campusState}
                />
              </Col>
              <Col md={6}>
                <CardConfirmCourse
                  isEdit={false}
                  option={option2}
                  courseName={apiData2.courseName}
                  modality={apiData2.courseDegree}
                  courseTime={apiData2.courseTimeType}
                  university={apiData2.campusName}
                  city={apiData2.campusNeighborhood}
                  uf={apiData2.campusState}
                />
              </Col>
            </Row>
          </ConfirmedCourse>
        </Container>

        <Container>
          <HashContainer>
            <h3>{hash || ''}</h3>
            <p>Inscrição realizada em: 22/11/22 às 18h20</p>
            <button
              onClick={() => {
                history.push('/candidate-page')
              }}
            >
              Ir para o painel do estudante
            </button>
          </HashContainer>
        </Container>
      </ContainerStep>
    </>
  )
}
