import { MessageBox } from '../../../MessageBox'
import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import InputRange from 'react-input-range'

import 'react-input-range/lib/css/index.css'

import {
  DivSpace,
  InputGroupDiv,
  SearchButton,
  TitleSearch,
  Check,
  ContainerFilterBy,
  TitleFilter,
  SubTitle,
  ContainerCheck,
  ClearFilter,
  ResultsBox,
  TitleCourse,
  NotesContainer,
  DivRow,
} from './style'
import { Divider } from '@material-ui/core'
import { CardCourse } from './components/CardCourse'
import { Pagination } from 'components/SisuSubscribeForm/Pagination'

import { CourseDetail } from '../CourseDetail'
import { apiWithToken } from 'services/apiWithToken'
import { useLoading } from 'hooks/useLoading'
import { Button } from '../../styles'

export function FilterCourse({ option, comeBackCourseSelection }) {
  const { loading } = useLoading()

  useEffect(() => {
    window.scrollTo(0, 0)
    setCourseDetail(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [value, setValue] = useState({
    min: 0,
    max: 1000,
  })

  const [courseDetail, setCourseDetail] = useState(false)
  const [search, setSearch] = useState(false)
  const [showFilter, setShowFilter] = useState(false)

  function handleSearchCourses() {
    loading.turnOn()
    setSearch(false)
    handleCallCourses()
    setShowFilter(true)
  }

  const [courses, setCourses] = useState([])
  const [local, setLocal] = useState('')
  const [instituition, setInstituition] = useState('')
  const [totalSearch, setTotalSearch] = useState(0)

  // function getCourseToAdd(event) {}
  const [filter1, setFilter1] = useState(true)
  const [filter2, setFilter2] = useState(true)
  const [filter3, setFilter3] = useState(true)
  const [filter4, setFilter4] = useState(true)

  const [conditions1, setConditions1] = useState([
    'Bacharelado',
    'Licenciatura',
    'ABI',
    'Tecnólogo',
  ])

  // const [lenght1, setLenght1] = useState(0);
  // const [lenght2, setLenght2] = useState(0);

  useEffect(() => {
    const conditions1 = [
      filter1 && 'Bacharelado',
      filter2 && 'Licenciatura',
      filter3 && 'ABI',
      filter4 && 'Tecnólogo',
    ]
    setConditions1(conditions1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter1, filter2, filter3, filter4])

  const [filter5, setFilter5] = useState(true)
  const [filter6, setFilter6] = useState(true)
  const [filter7, setFilter7] = useState(true)
  const [filter8, setFilter8] = useState(true)
  const [filter9, setFilter9] = useState(true)

  const [conditions2, setConditions2] = useState([
    'Matutino',
    'Vespertino',
    'Noturno',
    'Integral',
    'EAD',
  ])

  useEffect(() => {
    const conditions2 = [
      filter5 && 'Matutino',
      filter6 && 'Vespertino',
      filter7 && 'Noturno',
      filter8 && 'Integral',
      filter9 && 'EAD',
    ]
    setConditions2(conditions2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter5, filter6, filter7, filter8, filter9])

  const [updates, setUpdates] = useState(false)

  useEffect(() => {
    updates && handleSearchCourses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions1, conditions2])

  function mouseUp() {
    handleSearchCourses()
  }

  async function handleClearFilter() {
    setValue({
      min: 0,
      max: 1000,
    })

    setCourse('')
    setLocal('')
    setInstituition('')
    setTotalSearch('')

    setFilter1(true)
    setFilter2(true)
    setFilter3(true)
    setFilter4(true)
    setFilter5(true)
    setFilter6(true)
    setFilter7(true)
    setFilter8(true)
    setFilter9(true)

    loading.turnOn()

    const totalSearch = document.getElementsByClassName('cardCourseShow').length
    setTotalSearch(totalSearch)

    const { data } = await apiWithToken.get('/course/search/pageable', {
      params: {
        pageSize,
        pageNumber,
        programType: 'sisu',
        course: course,
        gradeMinimum: 0,
        gradeMaximum: 1000,
        courseTime: `${conditions2.map(item => item)}`,
        degree: `${conditions1.map(item => item)}`,
      },
    })

    const { content, totalElements, totalPages } = data

    setCourses(content)
    setTotalPages(totalPages)
    setTotalSearch(content.length)
    setTotalElementsPaginated(totalElements)
    setSearch(true)

    setShowFilter(true)

    loading.turnOff()
    setUpdates(true)
  }

  const [notaCorte, setNotaCorte] = useState('')
  const [courseContext, setCourseContext] = useState('')
  const [modality, setModality] = useState('')
  const [courseTime, setCourseTime] = useState('')
  const [university, setUniversity] = useState('')
  const [city, setCity] = useState('')
  const [uf, setUf] = useState('')
  const [timeCode, setTimeCode] = useState('')

  const [pageSize, setPageSize] = useState(12)
  const [pageNumber, setPageNumber] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [totalPages, setTotalPages] = useState(0)
  const [totalElementsPaginated, setTotalElementsPaginated] = useState(0)
  // console.log(totalPages);

  async function handleCallCourses() {
    loading.turnOn()

    const totalSearch = document.getElementsByClassName('cardCourseShow').length
    setTotalSearch(totalSearch)

    const { data } = await apiWithToken.get('/course/search/pageable', {
      params: {
        pageSize,
        pageNumber,
        programType: 'sisu',
        course: course,
        gradeMinimum: value.min,
        gradeMaximum: value.max,
        courseTime: `${conditions2.map(item => item)}`,
        degree: `${conditions1.map(item => item)}`,
      },
    })

    const { content, totalElements, totalPages } = data

    setCourses(content)
    setTotalPages(totalPages)
    setTotalSearch(content.length)
    setTotalElementsPaginated(totalElements)
    setSearch(true)

    setShowFilter(true)

    loading.turnOff()
    setUpdates(true)
  }

  useEffect(() => {
    if (courses.length > 0) {
      handleCallCourses()
    }
  }, [pageSize, pageNumber])

  useEffect(() => {
    setPageNumber(0)
  }, [pageSize])

  const [course, setCourse] = useState('')

  const [courseTimeId, setCourseTimeId] = useState(0);
  

  return (
    <>
      {courseDetail ? (
        <CourseDetail
          timeCode={timeCode}
          option={option}
          notaCorte={notaCorte}
          course={courseContext}
          modality={modality}
          courseTime={courseTime}
          courseTimeId={courseTimeId}
          university={university}
          city={city}
          uf={uf}
          comeBackFilterCourse={() => setCourseDetail(false)}
        />
      ) : (
        <>
          <DivSpace>
            {courses.length === 0 && (
              <Container className="py-5">
                <MessageBox
                  main_title="Realize uma pesquisa  para encontrar a vaga perfeita"
                  description="Utilize o filtro abaixo para encontrar a oferta de vaga perfeita para você dentro do SISU. "
                />
              </Container>
            )}
            <Container className="my-1">
              <Row>
                <TitleSearch>Buscar opção de curso</TitleSearch>
                <Divider />
              </Row>

              <Row className="mt-5 g-3">
                <Col sm={12} md={4}>
                  <InputGroupDiv>
                    <label htmlFor="">Curso</label>
                    <input
                      value={course}
                      onChange={e => {
                        setCourse(e.target.value)
                      }}
                    />
                    {/* {course && (
                      <Options>
                        <ul>
                          <li>Curso 1</li>
                          <li>Curso 2</li>
                          <li>Curso 3</li>
                          <li>Curso 4</li>
                        </ul>
                      </Options>
                    )} */}
                  </InputGroupDiv>
                </Col>

                <Col sm={12} md={4}>
                  <InputGroupDiv>
                    <label htmlFor="">Local</label>
                    <input
                      value={local}
                      onChange={e => {
                        setLocal(e.target.value)
                      }}
                    />
                  </InputGroupDiv>
                </Col>

                <Col sm={12} md={4}>
                  <InputGroupDiv>
                    <label htmlFor="">Instituição</label>
                    <input
                      value={instituition}
                      onChange={e => {
                        setInstituition(e.target.value)
                      }}
                    />
                  </InputGroupDiv>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-end">
                  <SearchButton>
                    <button
                      onClick={() => {
                        handleCallCourses()
                      }}
                    >
                      Buscar
                    </button>
                  </SearchButton>
                </Col>
              </Row>
              <Divider className="mt-5" />
            </Container>

            {showFilter && (
              <>
                <Container>
                  <ContainerFilterBy>
                    <TitleFilter>Filtrar por:</TitleFilter>

                    <ContainerCheck>
                      <Row>
                        <Col md={8}>
                          <SubTitle>Grau</SubTitle>
                          <Row>
                            <Col sm={12} md={3}>
                              <Check>
                                <input
                                  type="checkbox"
                                  name="a"
                                  value="1"
                                  checked={filter1}
                                  defaultChecked={filter1}
                                  onChange={() => {
                                    setFilter1(!filter1)
                                  }}
                                />
                                <label htmlFor="">Bacharelado</label>
                              </Check>
                            </Col>

                            <Col sm={12} md={3}>
                              <Check>
                                <input
                                  type="checkbox"
                                  name="a"
                                  value="1"
                                  checked={filter2}
                                  defaultChecked={filter2}
                                  onChange={() => {
                                    setFilter2(!filter2)
                                  }}
                                />
                                <label htmlFor="">Licenciatura</label>
                              </Check>
                            </Col>

                            <Col sm={12} md={3}>
                              <Check>
                                <input
                                  type="checkbox"
                                  name="a"
                                  value="1"
                                  checked={filter3}
                                  defaultChecked={filter3}
                                  onChange={() => {
                                    setFilter3(!filter3)
                                  }}
                                />
                                <label htmlFor="">
                                  ABI (Área Básica de Ingresso)
                                </label>
                              </Check>
                            </Col>

                            <Col sm={12} md={3}>
                              <Check>
                                <input
                                  type="checkbox"
                                  name="a"
                                  value="1"
                                  checked={filter4}
                                  defaultChecked={filter4}
                                  onChange={() => {
                                    setFilter4(!filter4)
                                  }}
                                />
                                <label htmlFor="">Tecnólogo</label>
                              </Check>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4}>
                          <p id="myP">Faixa da Nota de Corte</p>
                          <div onMouseUp={mouseUp}>
                            <NotesContainer>
                              <span>{value.min}</span>
                              <span>{value.max}</span>
                            </NotesContainer>

                            <InputRange
                              maxValue={1000}
                              minValue={0}
                              value={value}
                              onChange={valueEvent => {
                                let min = 0
                                let max = 1000

                                const processedValues = { ...valueEvent }

                                if (processedValues.min < min) {
                                  processedValues.min = min
                                }

                                if (processedValues.max > max) {
                                  processedValues.max = max
                                }
                                setValue(processedValues)
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </ContainerCheck>

                    <ContainerCheck>
                      <Row>
                        <Col>
                          <SubTitle>Turno</SubTitle>
                          <Row>
                            <Col md={2}>
                              <Check>
                                <input
                                  type="checkbox"
                                  name="a"
                                  value="1"
                                  checked={filter5}
                                  defaultChecked={filter5}
                                  onChange={() => {
                                    setFilter5(!filter5)
                                  }}
                                />
                                <label htmlFor="">Matutino</label>
                              </Check>
                            </Col>

                            <Col md={2}>
                              <Check>
                                <input
                                  type="checkbox"
                                  name="a"
                                  value="1"
                                  checked={filter6}
                                  defaultChecked={filter6}
                                  onChange={() => {
                                    setFilter6(!filter6)
                                  }}
                                />
                                <label htmlFor="">Vespertino</label>
                              </Check>
                            </Col>

                            <Col md={2}>
                              <Check>
                                <input
                                  type="checkbox"
                                  name="a"
                                  value="1"
                                  checked={filter7}
                                  defaultChecked={filter7}
                                  onChange={() => {
                                    setFilter7(!filter7)
                                  }}
                                />
                                <label htmlFor="">Noturno</label>
                              </Check>
                            </Col>

                            <Col md={2}>
                              <Check>
                                <input
                                  type="checkbox"
                                  name="a"
                                  value="1"
                                  checked={filter8}
                                  defaultChecked={filter8}
                                  onChange={() => {
                                    setFilter8(!filter8)
                                  }}
                                />
                                <label htmlFor="">Integral</label>
                              </Check>
                            </Col>

                            <Col md={2}>
                              <Check>
                                <input
                                  type="checkbox"
                                  name="a"
                                  value="1"
                                  checked={filter9}
                                  defaultChecked={filter9}
                                  onChange={() => {
                                    setFilter9(!filter9)
                                  }}
                                />
                                <label htmlFor="">EAD</label>
                              </Check>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </ContainerCheck>

                    <ClearFilter>
                      <Divider />
                      <p
                        onClick={() => {
                          handleClearFilter()
                        }}
                      >
                        Limpar Filtro
                      </p>
                    </ClearFilter>
                  </ContainerFilterBy>
                </Container>
              </>
            )}
            {search && (
              <>
                {totalSearch === 0 && (
                  <>
                    <Container className="my-1">
                      <MessageBox
                        main_title="A partir dos filtros escolhidos não encontramos cursos disponíveis"
                        description="Altere os filtros ou refaça a busca para encontrar cursos de sua preferência."
                      />
                    </Container>
                    <ResultsBox>
                      <p>Nenhum curso encontrado</p>
                    </ResultsBox>
                  </>
                )}

                <Container>
                  {totalElementsPaginated !== 0 && (
                    <TitleCourse>
                      <h1>{totalElementsPaginated} CURSOS ENCONTRADOS</h1>
                    </TitleCourse>
                  )}
                  <Row>
                    {courses.map(item => (
                      <>
                        {item.courseName
                          .toLowerCase()
                          .includes(course.toLowerCase()) && (
                          <>
                            {item.collegeName
                              .toLowerCase()
                              .includes(instituition.toLowerCase()) && (
                              <>
                                {item.campusCity
                                  .toLowerCase()
                                  .includes(local.toLowerCase()) && (
                                  <>
                                    {/* {conditions1.some(el =>
                                      item.courseDegree.includes(el)
                                    ) && (
                                      <>
                                        {conditions2.some(el1 =>
                                          item.courseTimeType.includes(el1)
                                        ) && (
                                          <> */}
                                    <Col
                                      className="cardCourseShow"
                                      sm={12}
                                      md={6}
                                      lg={4}
                                      key={item.campusId}
                                      onClick={() => {
                                        setCourseDetail(true)
                                        setNotaCorte(
                                          item.courseTimeMinimumGrade
                                        )
                                        setTimeCode(item.courseTimeCode)
                                        setCourseContext(item.courseName)
                                        setModality(item.courseDegree)
                                        setCourseTime(item.courseTimeType)
                                        setUniversity(item.collegeName)
                                        setCity(item.campusCity)
                                        setUf(item.campusState)
                                        setCourseTimeId(item.courseTimeId)
                                      }}
                                      title={item.courseName}
                                      subTitle={item.courseDegree}
                                      college={item.collegeName}
                                      location={item.campusCity}
                                      state={
                                        (item.campusCity, item.campusState)
                                      }
                                      timeType={item.courseTimeType}
                                      note={item.courseTimeMinimumGrade}
                                      vacancies={item.courseTimeIesVacancies}
                                    >
                                      <CardCourse
                                        title={item.courseName}
                                        subTitle={item.courseDegree}
                                        college={item.collegeName}
                                        campusName={item.campusName}
                                        state={item.campusState}
                                        locationCity={item.campusCity}
                                        timeType={item.courseTimeType}
                                        note={item.courseTimeMinimumGrade}
                                        vacancies={item.courseTimeIesVacancies}
                                        affirmativeAction="Sim"
                                      />
                                    </Col>
                                  </>
                                  //       )}
                                  //     </>
                                  //   )}
                                  // </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    ))}
                  </Row>
                </Container>
                <Container>
                  <Pagination
                    setPageSize={setPageSize}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                    totalSearch={totalSearch}
                    totalElementsPaginated={totalElementsPaginated}
                    pageSize={pageSize}
                  />
                </Container>
              </>
            )}
          </DivSpace>
          <Container>
            <DivRow style={{margin:0}}>
              <Button className="prev" onClick={comeBackCourseSelection}>
                Voltar
              </Button>
            </DivRow>
            <p>&nbsp;</p>
          </Container>
          
        </>
      )}
    </>
  )
}
