import styled from 'styled-components'

export const ContainerLastCourseCard = styled.div`
  background: #dfdfdf;
  padding: 1rem;
  border-radius: 4px;

  h1 {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 992px) {
    margin-top: 1rem;
  }
`

export const TypeCourseContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;
  h2 {
    font-style: italic;
    color: #585858;
    font-weight: 400;
    font-size: 1rem;
    margin: 0;
    padding: 0;
  }
`

export const CourseTimeDiv = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }
  img {
    width: 15px;
    height: 15px;
  }
`

export const NameCollegeAndInformationsDiv = styled.div`
  margin-top: 1rem;

  h3 {
    font-size: 1.25rem;
    color: #585858;
    font-weight: 600;
  }

  h4 {
    margin-top: 1rem;
    font-size: 1.25rem;
    color: black;
  }

  p {
    font-size: 0.75rem;
    font-weight: 500;
  }
`

export const LocationCollegeDiv = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 5px;

  img {
    align-self: flex-start;
  }

  p {
    margin: 0;
    padding: 0;
  }
`

export const StatusVacancies = styled.h3`
  font-size: 1rem;
  color: #585858;
`
