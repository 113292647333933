import { Row, Col, Container } from 'react-bootstrap'
import { Data, Wrapper, ContainerBgColor } from './style'

import { UserData } from '../UserData'
import { Button } from './style'
import { useContext, useEffect, useState } from 'react'
import FormContext1 from 'components/DataConfirmSteps/formContext1'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { awsTokenManager } from 'config/auth'
import { LoadingFixedScreen } from '../style'

export function StepOneSubscribeForm() {
  useEffect(() => {
    window.scrollTo(0, 0)
    setFormContent1(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { setFormContent1 } = useContext(FormContext1)

  const [loaded, setLoaded] = useState(false)

  // API Data
  const [fullName, setFullName] = useState('')
  const [cpf, setCpf] = useState('')
  const [socialName, setSocialName] = useState('')
  const [gender, setGender] = useState('')
  const [birthdate, setBirthdate] = useState('')
  // const [newBirthdate, setNewBirthdate] = useState('');
  const [uf, setUf] = useState('')
  const [birthPlace, setBirthPlace] = useState('')
  const [motherName, setMotherName] = useState('')
  const [responsibleCpf, setResponsibleCpf] = useState('')
  const [responsibleName, setResponsibleName] = useState('')
  const [responsibleContact, setResponsibleContact] = useState('')
  const [brazilian, setBrazilian] = useState(true)
  const [identificationDocument, setIdentificationDocument] = useState('')
  const [issuingState, setIssuingState] = useState('')
  const [maritalStatus, setMaritalStatus] = useState('')
  const [ethnicity, setEthnicity] = useState('')
  const [disabilityFlag, setDisabilityFlag] = useState(false)
  const [disabilityType, setDisabilityType] = useState('')
  const [issuingAgency, setIssuingAgency] = useState('')
  const [documentNumber, setDocumentNumber] = useState('')
  const [serie, setSerie] = useState('')

  const [streetAddressType, setStreetAddressType] = useState('AI')
  const [zipCode, setZipCode] = useState('')
  const [address, setAddress] = useState('')
  const [neighborhood, setNeighborhood] = useState('')
  const [municipality, setMunicipality] = useState('')
  const [number, setNumber] = useState('')
  const [complement, setComplement] = useState('')
  const [email, setEmail] = useState('')
  const [cellPhone, setCellPhone] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const [conclusionYear, setConclusionYear] = useState('')
  const [brazilianSchoolFlag, setBrazilianSchoolFlag] = useState(true)
  const [professorFlag, setProfessorFlag] = useState(false)
  const [academicFlag, setAcademicFlag] = useState(false)
  // const [superiorFlag, setSuperiorFlag] = useState(false);
  const [publicFlag, setPublicFlag] = useState(false)
  const [schoolCountry, setSchoolCountry] = useState('')
  const [schoolType, setSchoolType] = useState('')
  const [courseType, setCourseType] = useState('')
  const [schoolGrade, setSchoolGrade] = useState('')
  const [schoolName, setSchoolName] = useState('')

  useEffect(() => {
    const getUserMe = async () => {
      try {
        const token = await awsTokenManager.getToken()
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        }
        let data = await axios
          .get(`${process.env.REACT_APP_API_URL}/users/me`, config)
          .then(({ data }) => data)

        setFullName(data.name)
        setCpf(data.cpf)

        try {
          const config = {
            headers: { Authorization: `${token}` },
          }

          let data = await axios
            .get(
              `${process.env.REACT_APP_API_URL}/user-information/${cpf}`,
              config
            )
            .then(({ data }) => data)

          if (data.length !== 0) {
            setSocialName(data.socialName)
            setGender(data.gender)
            setBirthdate(data.birthdate)
            setUf(data.uf)
            setBirthPlace(data.birthPlace)
            setMotherName(data.motherName)
            setResponsibleCpf(data.responsibleCpf)
            setResponsibleName(data.responsibleName)
            setResponsibleContact(data.responsibleContact)
            setBrazilian(data.brazilian)
            setIdentificationDocument(data.identificationDocument)
            setMaritalStatus(data.maritalStatus)
            setEthnicity(data.ethnicity)
            setDisabilityFlag(data.disabilityFlag)
            setDisabilityType(data.disabilityType)
            setDocumentNumber(data.documentNumber)
            setIssuingAgency(data.issuingAgency)
            setIssuingState(data.issuingState)
            setSerie(data.serie)
          }

          try {
            const config = {
              headers: { Authorization: `${token}` },
            }

            let data = await axios
              .get(
                `${process.env.REACT_APP_API_URL}/users-address?name=${cpf}`,
                config
              )
              .then(({ data }) => data)

            if (data.length !== 0) {
              setStreetAddressType(data.streetAddressType)
              setZipCode(data.zipCode)
              setAddress(data.address)
              setNeighborhood(data.neighborhood)
              setUf(data.uf)
              setMunicipality(data.municipality)
              setNumber(data.number)
              setComplement(data.complement)
              setEmail(data.email)
              setCellPhone(data.cellPhone)
              setPhoneNumber(data.phoneNumber)
            }
            try {
              const config = {
                headers: { Authorization: `${token}` },
              }

              let data = await axios
                .get(
                  `${process.env.REACT_APP_API_URL}/users-education?name=${cpf}`,
                  config
                )
                .then(({ data }) => data)

              if (data.length !== 0) {
                setConclusionYear(data.conclusionYear)
                setBrazilianSchoolFlag(data.brazilianSchoolFlag)
                setSchoolCountry(data.schoolCountry)
                setSchoolType(data.schoolType)
                setProfessorFlag(data.professorFlag)
                setAcademicFlag(data.academicFlag)
                // setSuperiorFlag(data.superiorFlag);
                setPublicFlag(data.publicFlag)
                setCourseType(data.courseType)
                setSchoolName(data.schoolName)
                setSchoolGrade(data.schoolGrade)
              }

              setLoaded(true)
            } catch (err) {
              console.log(err)
            }
          } catch (err) {
            console.log(err)
          }
        } catch (err) {
          console.log(err)
        }
      } catch (err) {
        console.log(err)
      }
    }

    getUserMe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf])

  return (
    <>
      {loaded ? null : (
        <>
          <LoadingFixedScreen data-test="LoadingFixedScreen">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </LoadingFixedScreen>
        </>
      )}
      <Wrapper>
        <Container>
          <Data>
            <h3>Dados do Estudante</h3>
          </Data>
        </Container>

        <Container>
          <Row>
            <Col sm={12} md={6}>
              <UserData label="Nome de Registro" dataLabel={fullName} />
            </Col>
            <Col sm={12} md={6}>
              <UserData label="CPF" dataLabel={cpf} />
            </Col>
          </Row>
        </Container>
        <ContainerBgColor>
          <Container>
            <Row>
              <Col sm={12} md={6}>
                <UserData label="Nome Social" dataLabel={socialName} />
              </Col>
              <Col sm={12} md={3}>
                <UserData label="Data de Nascimento" dataLabel={birthdate} />
              </Col>

              <Col sm={12} md={3}>
                <UserData label="Gênero" dataLabel={gender} />
              </Col>
            </Row>
          </Container>
        </ContainerBgColor>
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <UserData
                label="É brasileiro nato ou naturalizado?"
                dataLabel={brazilian ? 'Sim' : 'Não'}
              />
            </Col>
            <Col sm={12} md={3}>
              <UserData label="Natural do UF" dataLabel={uf} />
            </Col>

            <Col sm={12} md={3}>
              <UserData label="Natural do Município" dataLabel={birthPlace} />
            </Col>
          </Row>
        </Container>
        <ContainerBgColor>
          <Container>
            <Row>
              <Col sm={12} md={3}>
                <UserData
                  label="Documento de Identificação"
                  dataLabel={identificationDocument}
                />
              </Col>
              <Col sm={12} md={3}>
                <UserData
                  label="Número do documento"
                  dataLabel={documentNumber}
                />
              </Col>

              <Col sm={12} md={2}>
                <UserData label="Série" dataLabel={serie} />
              </Col>
              <Col sm={12} md={2}>
                <UserData label="Orgão Emissor" dataLabel={issuingAgency} />
              </Col>

              <Col sm={12} md={2}>
                <UserData label="UF de Emissão" dataLabel={issuingState} />
              </Col>
            </Row>
          </Container>
        </ContainerBgColor>
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <UserData label="Estado Civil" dataLabel={maritalStatus} />
            </Col>
            <Col sm={12} md={6}>
              <UserData label="Etnia/Cor" dataLabel={ethnicity} />
            </Col>
          </Row>
        </Container>

        <ContainerBgColor>
          <Container>
            <Row>
              <Col sm={12} md={6}>
                <UserData label="Nome da Mãe" dataLabel={motherName} />
              </Col>
              <Col sm={12} md={6}>
                <UserData
                  label="O cadidato é PCD (Pessoa com deficiência)?"
                  dataLabel={
                    disabilityFlag ? <>Sim, {disabilityType}</> : 'Não'
                  }
                />
              </Col>
            </Row>
          </Container>
        </ContainerBgColor>
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <UserData
                label="Nome do Responsável Legal"
                dataLabel={responsibleName}
              />
            </Col>
            <Col sm={12} md={3}>
              <UserData
                label="CPF do Responsável Legal"
                dataLabel={responsibleCpf}
              />
            </Col>

            <Col sm={12} md={3}>
              <UserData
                label="Contato do Responsável Legal"
                dataLabel={responsibleContact}
              />
            </Col>
          </Row>
        </Container>

        {/* DADOS DE CONTATO */}
        <Container>
          <Data>
            <h3>Dados De Contato</h3>
          </Data>
        </Container>

        <Container>
          <Row>
            <Col sm={12} md={3}>
              <UserData label="CEP" dataLabel={zipCode} />
            </Col>
            <Col sm={12} md={3}>
              <UserData
                label="Tipo de Logradouro"
                dataLabel={streetAddressType}
              />
            </Col>

            <Col sm={12} md={6}>
              <UserData label="Endereço" dataLabel={address} />
            </Col>
          </Row>
        </Container>
        <ContainerBgColor>
          <Container>
            <Row>
              <Col sm={12} md={3}>
                <UserData label="Número" dataLabel={number} />
              </Col>
              <Col sm={12} md={3}>
                <UserData label="Complemento" dataLabel={complement} />
              </Col>

              <Col sm={12} md={6}>
                <UserData label="Bairro" dataLabel={neighborhood} />
              </Col>
            </Row>
          </Container>
        </ContainerBgColor>

        <Container>
          <Row>
            <Col sm={12} md={6}>
              <UserData label="Município" dataLabel={municipality} />
            </Col>

            <Col sm={12} md={6}>
              <UserData label="UF" dataLabel={uf} />
            </Col>
          </Row>
        </Container>
        <ContainerBgColor>
          <Container>
            <Row>
              <Col sm={12} md={6}>
                <UserData label="E-mail*" dataLabel={email} />
              </Col>

              <Col sm={12} md={3}>
                <UserData
                  label="Telefone para contato 1"
                  dataLabel={cellPhone}
                />
              </Col>

              <Col sm={12} md={3}>
                <UserData label="Telefone contato 2" dataLabel={phoneNumber} />
              </Col>
            </Row>
          </Container>
        </ContainerBgColor>

        <Container>
          <Data>
            <h3>Dados Escolares</h3>
          </Data>
        </Container>
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <UserData
                label="Ano de conclusão do ensino médio"
                dataLabel={conclusionYear}
              />
            </Col>
            <Col sm={12} md={6}>
              <UserData
                label="Concluiu o ensino médio no Brasil?"
                dataLabel={brazilianSchoolFlag ? 'Sim' : 'Não'}
              />
            </Col>
          </Row>
        </Container>
        <ContainerBgColor>
          <Container>
            <Row>
              {brazilianSchoolFlag ? null : (
                <>
                  <Col sm={12} md={6}>
                    <UserData
                      label="Em que país você concluiu o ensino médio?"
                      dataLabel={schoolCountry}
                    />
                  </Col>
                </>
              )}
              <Col sm={12} md={6}>
                <UserData
                  label="Qual tipo de escola o candidato cursou o ensino médio?"
                  dataLabel={schoolType}
                />
              </Col>
            </Row>
          </Container>
        </ContainerBgColor>

        <Container>
          <Row>
            <Col sm={12} md={6}>
              <UserData
                label="O candidato é professor da rede pública de ensino, no efetivo exercício do magistério da educação básica, integrando o quadro de pessoal permanente?"
                dataLabel={professorFlag ? 'Sim' : 'Não'}
              />
            </Col>
            <Col sm={12} md={6}>
              <UserData
                label="O candidato possui vínculo acadêmico (matrícula ativa ou trancada) com instituição de ensino superior pública e gratuita?"
                dataLabel={academicFlag ? 'Sim' : 'Não'}
              />
            </Col>
          </Row>
        </Container>
        <ContainerBgColor>
          <Container>
            <Row>
              <Col sm={12} md={3}>
                <UserData label="Concluiu o ensino superior?" dataLabel="Sim" />
              </Col>
              <Col sm={12} md={3}>
                <UserData
                  label="Cursou em instituição pública?"
                  dataLabel={publicFlag ? 'Sim' : 'Não'}
                />
              </Col>

              <Col sm={12} md={3}>
                <UserData label="Qual o curso?" dataLabel={courseType} />
              </Col>

              <Col sm={12} md={3}>
                <UserData label="Qual grau?" dataLabel={schoolGrade} />
              </Col>
            </Row>
          </Container>
        </ContainerBgColor>

        <Container>
          <Row>
            <Col>
              <UserData label="Nome da Instituição" dataLabel={schoolName} />
            </Col>
          </Row>
        </Container>

        <Button
          data-test="confirmation-data-steps-ButtonNext"
          className="next"
          onClick={() => {
            setFormContent1(true)
          }}
        >
          Confirmar
        </Button>
        <Link to="/candidate-page">
          <Button
            data-test="confirmation-data-steps-ButtonPrev"
            className="prev"
          >
            Cancelar
          </Button>
        </Link>
      </Wrapper>
    </>
  )
}
