/* eslint-disable react/prop-types */
import React, { useCallback } from 'react'
import { Redirect } from 'react-router-dom'

import { Auth } from 'aws-amplify'
// import { ContentContainer } from 'ui/styles';

import { Box, Button, Grid, Typography } from '@material-ui/core'

import Form from 'components/Form'
import { useLangContext } from 'components/Globalization'
import MaskedTextField from 'components/MaskedTextField'
import wrapper from 'components/Wrapper'

import { APP_ROUTES } from 'modules/App/constants/route'
import { AUTH } from 'modules/Auth/constants/auth'
import { AUTH_ROUTES } from 'modules/Auth/constants/routes'
import { notificationUseCases } from 'modules/Notification/providers'

import { Container } from './style'
import PublicContainer from 'components/PublicContainer'

const MFAToken = ({ challenge, isAuthenticated }) => {
  const translate = useLangContext()

  const onConfirmHandler = useCallback(
    async ({ token }) => {
      try {
        await Auth.confirmSignIn(
          challenge,
          token.split(' ').join(''),
          AUTH.SOFTWARE_TOKEN_MFA
        )
      } catch (error) {
        switch (error.code) {
          case 'CodeMismatchException':
            // eslint-disable-next-line no-console
            console.log(translate('TOKEN_EXCEPTION'))
            notificationUseCases.error(`${translate('TOKEN_EXCEPTION')}`)
            break
          case 'InternalErrorException':
            // eslint-disable-next-line no-console
            console.log(translate('INTERNAL_ERROR_EXCEPTION'))
            notificationUseCases.error(
              `${translate('INTERNAL_ERROR_EXCEPTION')}`
            )
            break
          default:
            // eslint-disable-next-line no-console
            console.log(translate('DEFAULT_ERROR'))
            notificationUseCases.error(`${translate('DEFAULT_ERROR')}`)
        }
      }
    },
    [challenge, translate]
  )

  if (!challenge || isAuthenticated) {
    return <Redirect to={APP_ROUTES.BASE_ROUTE} />
  }

  return (
    <PublicContainer
      title={translate('LOGIN:WELCOME_TITLE')}
      description={translate('LOGIN:WELCOME_MESSAGE')}
    >
      <Container>
        <Box pb={4}>
          <Typography variant="h4" color="primary" align="center">
            {translate('MFA')}
          </Typography>
        </Box>

        <Box pb={4}>
          <Typography variant="body1">{translate('DESCRIPTION')}</Typography>
        </Box>

        <Form
          onSubmit={onConfirmHandler}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {formMethods => (
            <>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item style={{ width: '100px' }}>
                  <MaskedTextField
                    formMethods={formMethods}
                    name="token"
                    mask="999 999"
                  />
                </Grid>
              </Grid>

              <Box pt={4}>
                <Button size="large" type="submit">
                  {translate('CONFIRM_CODE')}
                </Button>
              </Box>
            </>
          )}
        </Form>
      </Container>
    </PublicContainer>
  )
}

export default wrapper(MFAToken, {
  namespace: 'MFA_TOKEN',
  mapState: state => ({
    challenge: state.auth.challenge,
    isAuthenticated: state.auth.isAuthenticated,
  }),
  route: {
    path: AUTH_ROUTES.MFA_TOKEN,
  },
})
