/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { Wrapper, ChooseCourse, Button } from './style'
import { FaPlus } from 'react-icons/fa'
import { MessageBox } from '../MessageBox'
import { FilterCourse } from './FilterCourse'
import { CardConfirmCourse } from '../CardConfirmCourse'
import FormContext3 from 'components/DataConfirmSteps/formContext3'
import { awsTokenManager } from 'config/auth'
import axios from 'axios'
import { LoadingFixedScreen } from '../style'

export function StepThreeSubscribeForm() {
  useEffect(() => {
    window.scrollTo(0, 0)
    setFormContent3(false)
    setShowFilter(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { setFormContent3 } = useContext(FormContext3)

  const [selectCourse1, setSelectCourse1] = useState(false)
  const [selectCourse2, setSelectCourse2] = useState(false)

  const [optionFilter, setOptionFilter] = useState('')
  const [showFilter, setShowFilter] = useState(false)

  function handleSetCourse(option) {
    setOptionFilter(option)
    setShowFilter(true)
  }

  const [cpf, setCpf] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [apiData1, setApiData1] = useState([])
  const [apiData2, setApiData2] = useState([])
  const [messageToAvatar, setMessageToAvatar] = useState({
    title: '',
    description: '',
  })

  useEffect(() => {
    const getUserMe = async () => {
      try {
        const token = await awsTokenManager.getToken()
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        }
        let data = await axios
          .get(`${process.env.REACT_APP_API_URL}/users/me`, config)
          .then(({ data }) => data)

        setCpf(data.cpf)

        try {
          const config = {
            headers: { Authorization: `${token}` },
          }

          let data = await axios
            .get(
              `${process.env.REACT_APP_API_URL}/user-registration/${cpf}`,
              config
            )
            .then(({ data }) => data)

          if (data.length !== 0) {
            // eslint-disable-next-line array-callback-return
            data.map(item => {
              MapProgram(item)
            })
            async function MapProgram(item) {
              if (item.program === 'prouni') {
                item.courses.map(item => {
                  MapCoursesOptions(item)
                })
                async function MapCoursesOptions(item) {
                  const courseMap = item.option
                  console.log(courseMap)
                  const courseCode = item.courseName
                  if (courseMap === 'option3') {
                    setSelectCourse1(true)

                    try {
                      const config = {
                        headers: { Authorization: `${token}` },
                      }

                      let data = await axios
                        .get(`${process.env.REACT_APP_API_URL}/course`, config)
                        .then(({ data }) => data)

                      async function mapCourse1(item) {
                        const courseMap = item.courseTimeCode
                        if (courseMap === courseCode) {
                          setApiData1(item)
                        }
                      }
                      data.map(item => {
                        mapCourse1(item)
                      })
                      setLoaded(true)
                    } catch (err) {
                      console.log(err)
                    }
                  } else {
                    setSelectCourse2(true)

                    try {
                      const config = {
                        headers: { Authorization: `${token}` },
                      }

                      let data = await axios
                        .get(`${process.env.REACT_APP_API_URL}/course`, config)
                        .then(({ data }) => data)

                      async function mapCourse1(item) {
                        const courseMap = item.courseTimeCode
                        if (courseMap === courseCode) {
                          setApiData2(item)
                        }
                      }
                      data.map(item => {
                        mapCourse1(item)
                      })
                      setLoaded(true)
                    } catch (err) {
                      console.log(err)
                    }
                  }
                }
              } else {
                setSelectCourse1(false)
                setSelectCourse2(false)
                setLoaded(true)
              }
            }
          } else {
            setSelectCourse1(false)
            setSelectCourse2(false)
            setLoaded(true)
          }
        } catch (err) {
          console.log(err)
        }
      } catch (err) {
        console.log(err)
      }
    }

    getUserMe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf])

  useEffect(() => {
    if (selectCourse1 && selectCourse2) {
      setMessageToAvatar({
        title: 'Agora revise e confirme a seleção dos cursos',
        description:
          'Certo, agora você tem que revisar os cursos selecionados e continuar para enviar os dados complementares',
      })
    }

    if (selectCourse1 && !selectCourse2) {
      setMessageToAvatar({
        title: 'Agora faça a seleção do curso de 2ª opção',
        description:
          'Certo, agora você tem que selecionar o curso de 2ª opção de sua preferência para concorrer às bolsas. Clique na caixa de seleção da sua 2ª opção de curso e realize a busca e seleção',
      })
    }

    if (!selectCourse1 && !selectCourse2) {
      setMessageToAvatar({
        title: 'Agora faça a seleção dos cursos',
        description:
          'Certo, agora você tem que selecionar 2 cursos de sua preferência para concorrer às vagas. Clique na caixa de seleção da sua 1ª opção de curso e realize a busca e seleção.”',
      })
    }
  }, [selectCourse1, selectCourse2])

  return (
    <>
      {showFilter ? (
        <>
          <FilterCourse option={optionFilter} />
        </>
      ) : (
        <>
          {loaded ? null : (
            <>
              <LoadingFixedScreen data-test="LoadingFixedScreen">
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </LoadingFixedScreen>
            </>
          )}
          <Wrapper>
            <Container>
              <MessageBox
                className="my-5"
                main_title={messageToAvatar.title}
                description={messageToAvatar.description}
              />
              <Row>
                {!selectCourse1 ? (
                  <Col sm={12} md={6}>
                    <ChooseCourse
                      onClick={() => {
                        handleSetCourse('option1')
                      }}
                    >
                      <FaPlus />
                      <h1>Selecionar minha 1ª opção de curso</h1>
                    </ChooseCourse>
                  </Col>
                ) : (
                  <>
                    <Col md={6}>
                      <CardConfirmCourse
                        program="prouni"
                        isEdit
                        option={1}
                        courseCode={apiData1.courseTimeCode}
                        courseCodeChange={apiData2.courseTimeCode}
                        courseName={apiData1.courseName}
                        modality={apiData1.courseDegree}
                        courseTime={apiData1.courseTimeType}
                        university={apiData1.campusName}
                        city={apiData1.campusNeighborhood}
                        uf={apiData1.campusState}
                      />
                    </Col>
                  </>
                )}

                {!selectCourse2 ? (
                  <Col
                    sm={12}
                    md={6}

                    // disabled={!selectCourse1}
                  >
                    <ChooseCourse
                      onClick={() => {
                        handleSetCourse('option2')
                      }}
                    >
                      <FaPlus />
                      <h1>Selecionar minha 2ª opção de curso</h1>
                    </ChooseCourse>
                  </Col>
                ) : (
                  <>
                    <Col md={6}>
                      <CardConfirmCourse
                        program="prouni"
                        isEdit
                        option={2}
                        courseCode={apiData2.courseTimeCode}
                        courseCodeChange={apiData1.courseTimeCode}
                        courseName={apiData2.courseName}
                        modality={apiData2.courseDegree}
                        courseTime={apiData2.courseTimeType}
                        university={apiData2.campusName}
                        city={apiData2.campusNeighborhood}
                        uf={apiData2.campusState}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Container>

            {selectCourse1 & selectCourse2 ? (
              <Container>
                <Button
                  data-test="confirmation-data-steps-ButtonNext"
                  className="next"
                  onClick={() => {
                    setFormContent3(true)
                  }}
                >
                  Confirmar
                </Button>
              </Container>
            ) : null}
          </Wrapper>
        </>
      )}
    </>
  )
}
