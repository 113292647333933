import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3;
`

export const LoadingPopup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100px;

  ${props => css`
    color: ${props.theme.palette.grey[700]};
    background-color: ${props.theme.palette.common.white};
    border-radius: ${props.theme.borderRadius};
    box-shadow: ${props.theme.boxShadow};

    transition: background-color ${props.theme.animationDuration} ease-in-out;
  `}
`

export const LoadingText = styled.div`
  padding: ${props => `${props.theme.spacing(2)}px`};
`
