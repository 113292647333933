/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import wrapper from 'components/Wrapper'
import { RESETMYACCOUNT_ROUTES } from './constants/routes'

import { Wrapper } from './styles'
import { Container } from 'react-bootstrap'
import { apiWithToken } from 'services/apiWithToken'
import { useUser } from '../../hooks/useUser'

const ResetMyAccount = () => {
  // const [cpf, setCpf] = useState('');
  const [message, setMessage] = useState('')
  const [usersRelative, setUserRelative] = useState('')
  const { cpf } = useUser()
  useEffect(() => {
    const getUserRelative = async () => {
      try {
        const data = await apiWithToken.get(`/user-relative/relatives/${cpf}`)
        if (data.data.length > 0) {
          const mappedRelatives = data.data.map(relative => relative)
          setUserRelative(mappedRelatives)
        }
      } catch (err) {
        console.error(err)
      }
    }

    getUserRelative()
  }, [cpf])
  async function deleteMyAccount() {
    setMessage('Carregando....')
    try {
      await apiWithToken.delete(`/users-address/${cpf}`)
      setMessage('Deletando Endereços...')
    } catch (err) {
      console.error(err)
    }

    try {
      await apiWithToken.delete(`/users-education/${cpf}`)

      setMessage('Deletando dados escolares...')
    } catch (err) {
      console.error(err)
    }

    try {
      await apiWithToken.delete(`/user-information/${cpf}`)

      setMessage('Deletando informações de usuário')
      setMessage('Finalizado')
    } catch (err) {
      console.error(err)
    }

    try {
      const relatived = usersRelative.map(relative => relative.id)

      let responseItem
      for (let relativeitem of relatived) {
        responseItem = await deleteAllRelativeUsers(relativeitem)
      }

      if (responseItem) {
        console.log(responseItem)
      }
    } catch (err) {
      console.error(err)
    }
  }

  async function deleteAllRelativeUsers(idNumber) {
    let data = await apiWithToken.delete(`/user-relative/${idNumber}`)

    setMessage('Deletando informações de usuário')
    setMessage('Finalizado')
    return data
  }

  return (
    <>
      <Wrapper>
        <Container>
          <h1>Resetar conta</h1>

          <button onClick={deleteMyAccount}>Resetar minha conta</button>
          <h3>{message && message}</h3>
        </Container>
      </Wrapper>
    </>
  )
}

export default wrapper(ResetMyAccount, {
  route: { path: RESETMYACCOUNT_ROUTES.RESETMYACCOUNT },
})
