import React from 'react'
import { FaTimes } from 'react-icons/fa'

import styled from 'styled-components'

import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'

export const Dialog = styled(MuiDialog)``
export const DialogActions = styled(MuiDialogActions)``

export const DialogContent = styled(MuiDialogContent)`
  padding-bottom: 16px;
`

export const DialogTitle = styled(({ children, onClose, ...props }) => (
  <MuiDialogTitle disableTypography {...props}>
    <Typography variant="h6">{children}</Typography>
    {onClose ? (
      <div>
        <IconButton
          size="small"
          color="primary"
          aria-label="close"
          onClick={onClose}
        >
          <FaTimes />
        </IconButton>
      </div>
    ) : null}
  </MuiDialogTitle>
))`
  display: flex;
  h6 {
    flex: 1;
  }
`
