import styled from 'styled-components'

export const ContainerComprovante = styled.div`
  /* max-width: 700px; */

  @media print {
    .col-md-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-md-1 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-md-2 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-md-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-md-5 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-md-6 {
      flex: 0 0 auto !important;
      width: 50% !important;
    }

    .col-md-7 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-md-8 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-md-9 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-md-10 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-md-11 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-md-12 {
      flex: 0 0 auto;
      width: 25%;
    }

    .col-sm-12 {
      flex: 0 0 auto;
      width: 25%;
    }
  }
  .row {
    /* display: grid; */

    /* @media screen and (max-width: 768px) {
      flex-direction: column !important;
    } */
  }
  margin: 0 auto;

  a {
    color: black;
    text-decoration: underline;
  }

  @media print {
    margin: 0 2rem;
  }
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 992px) {
    align-items: end;
    flex-direction: column;
  }
`
export const DivImage = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  @media screen and (max-width: 992px) {
    align-self: flex-start;
  }

  img {
    max-width: 100%;
    height: 100%;
  }
`

export const TextsGov = styled.div`
  h1,
  h2,
  h3 {
    margin: 0;
    padding: 0;
  }

  h1 {
    font-weight: 600;
    font-size: 16px;
    color: black;
  }

  h2 {
    font-weight: 500;
    font-size: 14px;
    color: black;
  }

  h3 {
    font-weight: 400;
    font-size: 12px;
  }
`

export const QRCODEDIV = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;

  div {
    display: flex;
  }

  img {
    max-width: 100%;
    height: 100%;
    align-self: flex-end;
    justify-self: flex-end;
  }
  span {
    font-weight: 600;
    font-size: 10px;
  }

  p {
    font-weight: 600;
    font-size: 10px;
    margin: 0;
    padding: 0;
  }
`

export const LineText = styled.div`
  background: #f3f3f3;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  p {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    color: black;
  }
`

export const Data = styled.div`
  border-bottom: 1px solid #cccccc;
  margin-top: 1.4rem;
  h3 {
    font-size: 1rem;
    color: #000000;
    font-weight: 600;
  }
  display: flex;
  align-items: center;

  margin-bottom: 8px;
`

export const FamilyGroup = styled.section`
  margin-bottom: 1.8rem;
  margin-top: 2rem;

  th {
    background: #f2f2f2;
    border: 1px solid #b4b4b4;
    margin: auto;
    font-weight: 700;
    font-size: 14px;
    height: 53px;
    text-align: center;
  }

  td {
    /* text-align: center; */
    border: 1px solid #b4b4b4;
  }
`

export const OptionCourseText = styled.h5`
  margin: 1rem 0;
  font-weight: 600;
  color: #585858;
  font-size: 20px;
`

export const CardOptionCourse = styled.div`
  background-color: #f2f2f2;
  padding: 1rem;

  display: flex;
  flex-direction: column;

  gap: 16px;
`

export const OptionDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
`
export const DataOption = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 600;
    color: #585858;
  }
  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }

  span {
    font-size: 11px;
  }
`

export const TextWithline = styled.div`
  h3 {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    color: black;
  }
`

export const ResumeOptionCourse = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`

export const ResultBox = styled.div`
  border: 1px solid black;
  padding: 1.5rem;
  width: 100%;
`

export const ContainerDeclarations = styled.div``

export const PrintText = styled.div`
  text-align: center;
`

export const DivSpaceContainer = styled.div`
  margin-top: 1.5rem;

  .schoolDiv {
    margin-top: 0;
  }
`

export const PageStyle = styled.div`
  @media print {
    size: A4;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;

    /* .schoolDiv {
      margin-top: 600px;
    } */
    .pagebreak {
      page-break-before: always;
    }
  }
`
