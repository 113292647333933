import thunk from 'redux-thunk'

import { configureStore } from '@reduxjs/toolkit'

import { isDevelopment } from 'helpers/environment'

import reducer from './root.reducer'

const store = configureStore({
  reducer,
  middleware: [thunk],
})

if (isDevelopment() && module.hot) {
  module.hot.accept('config/store/root.reducer', () => {
    const newRootReducer = require('config/store/root.reducer').default
    store.replaceReducer(newRootReducer)
  })
}

export default store
