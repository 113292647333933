/* eslint-disable react/prop-types */
import React from 'react'
import { Drawer, List, ListItem } from '@material-ui/core'

import { useLangContext } from 'components/Globalization'
import wrapper from 'components/Wrapper'

import logoRnp from 'assets/img/logo-rnp.png'

import { MenuContainer, MenuItem, MenuLink, NavBrand } from './style'

const PrivateMenu = ({ show, menus, close }) => {
  const translate = useLangContext()

  if (!show) return null
  if (!menus || menus.length === 0) return null

  return (
    <Drawer
      open={show}
      anchor="left"
      onClose={close}
      transitionDuration={{ appear: 300, enter: 300, exit: 450 }}
    >
      <NavBrand src={logoRnp} width={145} />
      <MenuContainer onClick={close} onKeyDown={close}>
        <List>
          {menus.map((menu, idx) => {
            const key = `menu-item-${idx}`
            return (
              <ListItem button key={key}>
                <MenuLink to={menu.to}>
                  <MenuItem primary={translate(menu.label)} />
                </MenuLink>
              </ListItem>
            )
          })}
        </List>
      </MenuContainer>
    </Drawer>
  )
}

export default wrapper(PrivateMenu, {
  namespace: 'PRIVATE_MENU',
})
