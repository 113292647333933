import { FONT_FAMILY } from 'constants/fonts'
import styled from 'styled-components'

export const Display = styled.div`
  .textInput {
    padding: 0.5rem 0.8rem;
    border-radius: 4px;
    border: 1px solid ${props => props.theme.bgColors.colorSecondary04};

    &:not(:placeholder-shown):disabled {
      background: #f3f3f3;

      border: 1px solid #cccccc;

      border-radius: 4px;
    }
  }

  .addNew {
    margin: 15px 0 -30px 0;
    padding: 0;
    width: 100%;
    text-align: center;
  }

  .dropDiv {
    button {
      padding: 0.5rem 0.8rem;

      width: 100%;
      border-radius: 4px;
      border: 1px solid ${props => props.theme.bgColors.colorSecondary04};
      background: ${props => props.theme.bgColors.colorSecondary01};
      font-style: italic;
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
      position: relative;

      background-size: 1rem;
      color: black;
      text-align: left;

      &::after {
        right: 2rem;
        bottom: 50%;
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
        font-size: 1.5rem;
        color: ${props => props.theme.bgColors.primaryDefault};
      }

      &:hover {
        background: #ffffff;
        color: ${props => props.theme.bgColors.primaryDefault};
      }

      &:focus {
        background: #ffffff;
        color: black;
      }

      @media screen and (max-width: 1112px) {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .dropPersonalized {
    div {
      width: 100%;

      @media screen and (max-width: 992px) {
        max-width: 100%;
        width: 100%;
      }

      a {
        border-bottom: 1px solid
          ${props => props.theme.bgColors.colorSecondary04};
        padding: 1rem 0.8rem;
      }
    }
  }

  .familyTable {
    thead tr th {
      font-size: 0.8rem;
      font-weight: 600;
      /* text-align: left; */
      /* &.alignLeft {
        text-align: left;
      } */
    }
    tbody tr td {
      font-size: 0.8rem;
      padding: 1rem 0;
      padding-left: 0.5rem;
      /* text-align: center;
      padding: 1rem; */
      /* &.alignLeft {
        text-align: left;
      } */
    }
  }

  .modalBody {
    color: black;
  }
`

export const TitleArea = styled.div`
  h2 {
    margin-top: 3rem;
    font-size: 1.2rem;
    padding: 0;
    font-weight: 500;
  }

  p {
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 19px;
    color: ${props => props.theme.bgColors.colorSecondary07};
    margin-top: 0.5rem;
    margin-bottom: 1.3rem;
  }
`

export const Input = styled.input`
  max-width: 17rem;
  width: 100%;
  padding: 0.5rem 0.8rem;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.bgColors.colorSecondary04};

  &:not(:placeholder-shown):disabled {
    background: #f3f3f3;
    border: 1px solid #cccccc;
    border-radius: 4px;
  }
`

export const FamilyGroup = styled.section`
  margin-bottom: 1.8rem;
  h2 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 1.3rem;
  }

  h3 {
    font-size: 1rem;
    font-weight: 500;
    margin: 2rem 0;
    color: ${props => props.theme.textColors.primaryPastel02};
  }
`

export const ButtonSetData = styled.div`
  display: flex;
  gap: 1rem;

  button {
    border-radius: 20px;
    padding: 0.5rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;

    color: ${props => props.theme.bgColors.primaryDefault};
    border: 1px solid ${props => props.theme.bgColors.primaryDefault};
    background: #ffffff;
    transition: all ease 0.2s;

    &:focus {
      background: ${props => props.theme.bgColors.primaryDefault};
    }
    &.primary {
      background: ${props => props.theme.bgColors.primaryDefault};
      color: #ffffff;
      width: 102px;
      height: 32px;
    }

    &.cancel {
      color: ${props => props.theme.bgColors.primaryDefault};
      width: 120px;
      height: 32px;

      :hover {
        color: #ffffff;
      }
    }
    :hover {
      background: ${props => props.theme.bgColors.primaryDefault};
    }
  }
`

export const DivSection = styled.div`
  border-bottom: 1px solid #c8c7c7;

  .infoText {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    display: flex;
    align-items: center;

    color: #000000;

    margin-top: 32px;

    img {
      margin-left: 10px;
    }

    @media (max-width: 575px) {
      margin-left: 0;
      padding: 0 10px;
    }
  }

  .selectText {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    display: flex;
    align-items: center;
    margin: 0 0 4px 0;
    margin-top: 22px;

    color: #000000;

    img {
      margin-left: 10px;
    }
  }
`

export const Wrapper = styled.section`
  margin-bottom: 100px;
  .invalidItem {
    color: #da0000;
  }

  position: relative;

  strong {
    margin-left: 5px;
  }

  .noPadding {
    padding: 0 !important;
    .row {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  input {
    height: 51px;
    width: 100%;

    padding-left: 12px;

    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: var(--colorBlack);

    &::placeholder {
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: var(--colorBlack);

      opacity: 0.5;
    }

    &:not(:placeholder-shown):disabled {
      background: #f3f3f3;
      border: 1px solid #cccccc;
      border-radius: 4px;
    }
  }

  .hoverTrash {
    transition: all ease 0.2s;

    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
  }
`

export const SelectInputGroup = styled.div`
  padding: 0;
  margin: 0;

  &.paddingBottom {
    padding-bottom: 28px;
  }

  button {
    padding: 12.5px 14px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${props => props.theme.bgColors.colorSecondary04};
    background: ${props => props.theme.bgColors.colorSecondary01};
    font-style: italic;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    position: relative;

    background-size: 1rem;
    color: black;
    text-align: left;

    &::after {
      right: 2rem;
      bottom: 50%;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      font-size: 1.5rem;
      color: ${props => props.theme.bgColors.primaryDefault};
    }

    &:hover {
      background: #ffffff;
      color: ${props => props.theme.bgColors.primaryDefault};
    }

    &:focus {
      background: #ffffff;
      color: black;
    }

    @media screen and (max-width: 1112px) {
      max-width: 100%;
      width: 100%;
    }
  }

  .dropPersonalized {
    .dropdown-menu {
      padding: 0;
      max-height: 200px;
      overflow-y: scroll;
    }

    div {
      width: 35rem;

      @media screen and (max-width: 992px) {
        max-width: 100%;
        width: 100%;
      }

      a {
        border-bottom: 1px solid
          ${props => props.theme.bgColors.colorSecondary04};
        padding: 12.5px 14px;
      }
    }
  }
`

export const ButtonNext = styled.button`
  z-index: 99;
  outline: none;
  border: none;
  &.next {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 600;
    font-size: 16.8px;
    line-height: 23px;
    color: #ffffff;

    padding: 8px 24px;

    background: #1351b4;
    border-radius: 20px;

    max-width: 100%;
    width: 127px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all ease 0.2s;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    position: absolute;
    bottom: -60px;
    right: 15px;
  }

  &.prev {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 600;
    font-size: 16.8px;
    line-height: 23px;

    color: #1351b4;
    background: none;

    border: 1px solid #1351b4;
    border-radius: 20px;
    padding: 8px 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all ease 0.2s;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    position: absolute;
    bottom: -60px;
    left: 15px;
  }
`
