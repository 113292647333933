import styled from 'styled-components'

export const CardConfirmCourseContainer = styled.div`
  .boxTooltip {
    background: white !important;
  }
  padding: 24px;
  background: #f1f1f1;

  @media screen and (max-width: 992px) {
    margin-top: 1.5rem;
  }
`

export const CardConfirmHeader = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }
  span {
    background: #f8f8f8;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
  }
`

export const CardCourseName = styled.div`
  margin-top: 3.5rem;
  h2 {
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0;
    padding: 0;
  }
`

export const CardCourseLocation = styled.div`
  margin-top: 2rem;
  h2 {
    font-size: 1.25rem;
    color: #555555;
    font-weight: 600;
  }
  p {
    font-size: 1rem;
  }
`

export const CardCourseModality = styled.div`
  margin-top: 2rem;
  h2 {
    margin: 0;
    padding: 0;
    font-size: 1rem !important;
    color: #585858;
    font-style: italic;
  }

  h3 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 600;
  }
`

export const CardConfirmFooter = styled.div`
  margin-top: 1.5rem;
  button {
    background: #fcfcfc;
    border: 1px solid #1351b4;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 42px;
    padding: 6px 16px 7px;
    color: ${props => props.theme.bgColors.primaryDefault};
    font-weight: bold;
    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const CourseTime = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  p {
    margin: 0;
    padding: 0;
    color: black;
    font-style: italic;
    font-size: 0.75rem;
  }
`

export const CourseLocation = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  img {
    align-self: flex-start;
  }

  p {
    margin: 0;
    padding: 0;
    color: black;
  }
`

export const CourseOptions = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`
