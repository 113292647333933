import { Container, DivImg, ImgContainer, TextDiv, Button } from './styles'

import backgroundImg from './img/ilustra_02.svg'

export function LoadingDataResults(props) {
  return (
    <Container>
      <div className="row" data-test="loading-data-results-row">
        <DivImg className="col-sm-6" data-test="loading-data-results-divImg">
          <ImgContainer src={backgroundImg} />
        </DivImg>

        <TextDiv className="col-sm-6" data-test="loading-data-results-TextDiv">
          <h2 data-test="loading-data-results-title">{props.title}</h2>
          <p data-test="loading-data-results-description">
            {props.description}
            <span>{props.bold}</span>
          </p>

          <Button href="https://acessounico.mec.gov.br/formas-de-acesso">
            Ver mais informações
          </Button>
        </TextDiv>
      </div>
    </Container>
  )
}
