import styled from 'styled-components'
export const InformationContent = styled.div`
  margin-top: 2rem;
  h3 {
    font-size: 0.875rem;
    font-weight: 600;
    color: ${props => props.theme.bgColors.colorSecondary09};
  }

  p {
    margin-top: 0.5rem;
    color: ${props => props.theme.bgColors.colorSecondary09};
    font-size: 1rem;
  }
`
