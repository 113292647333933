/* eslint-disable no-debugger */
/* eslint-disable react/prop-types */
import React, { useCallback, useMemo, useState } from 'react'
import { FaPlus } from 'react-icons/fa'

// eslint-disable-next-line no-unused-vars
import { Box, Button, Grid } from '@material-ui/core'

import { ConfirmDialog } from 'components/Dialog'
import { useLangContext } from 'components/Globalization'
import InputSearch from 'components/InputSearch'
import Table from 'components/Table'
import CellActions from 'components/Table/CellActions'
import wrapper from 'components/Wrapper'

import { notificationUseCases } from 'modules/Notification/providers'
import { userService } from 'services/providers/user'

import { REGISTER_USERS_ROUTES } from './constants/routes'
import ModalUser from './fragments/ModalUser'
import SearchRadios from './fragments/SearchRadios'
import {
  Container,
  TitlePage,
  TableContainer,
  ContentContainer,
} from './styles'

const PRESENTATION_TYPE = {
  ALERT: 0,
  MODAL: 1,
}

function RegisterUsers() {
  const translate = useLangContext()

  const [searchValue, setSearchValue] = useState('')
  const [radioValue, setRadioValue] = useState('')

  const [data, setData] = useState({})
  const [currentAction, setCurrentAction] = useState({})

  const handleRadioChange = e => {
    setRadioValue(e.target.value)
  }

  const fetchData = useCallback(
    async ({ pageSize = 10, pageIndex = 0 }) => {
      const res = await userService.usersSearch(
        searchValue,
        radioValue || '',
        pageIndex,
        pageSize
      )
      setData(res)
    },
    [radioValue, searchValue]
  )

  const editUserHandler = useCallback(({ row }) => {
    const action = {
      selectedRow: row,
      presentation: PRESENTATION_TYPE.MODAL,
    }

    setCurrentAction(action)
  }, [])

  const createUserHandler = useCallback(() => {
    const action = {
      presentation: PRESENTATION_TYPE.MODAL,
    }

    setCurrentAction(action)
  }, [])

  const actionResetPasswordHandler = useCallback(({ row }) => {
    const confirmAction = async () => {
      try {
        await userService.resetCredentials(row.id)
        notificationUseCases.success('UPDATED_SUCCESS')
      } catch (e) {
        console.log(e)
      }
      closeActionPresentationHandler()
    }

    const action = {
      selectedRow: row,
      presentation: PRESENTATION_TYPE.ALERT,
      confirmActionHandler: confirmAction,
    }

    setCurrentAction(action)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeActionPresentationHandler = () => {
    setCurrentAction({})
    fetchData({})
  }

  const cpfFormatterHandler = ({ value }) =>
    value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')

  const roleFormatterHandler = ({ value }) => translate(`ROLES:${value}`)

  const statusFormatterHandler = ({ value }) =>
    value ? translate('STATUS:ACTIVE') : translate('STATUS:INACTIVE')

  const columns = useMemo(
    () => [
      {
        Header: translate('TABLE_HEADER_NAME'),
        accessor: 'name',
      },
      {
        Header: translate('TABLE_HEADER_CPF'),
        accessor: 'cpf',
        Cell: cpfFormatterHandler,
      },
      {
        Header: translate('TABLE_HEADER_ROLE'),
        accessor: 'role',
        Cell: roleFormatterHandler,
      },
      {
        Header: translate('TABLE_HEADER_STATUS'),
        accessor: 'active',
        Cell: statusFormatterHandler,
      },
      {
        Header: translate('TABLE_HEADER_ACTIONS'),
        style: { width: 100, textAlign: 'center' },
        Cell: params => {
          const { row } = params
          const actions = {
            onEdit: editUserHandler,
            onResetPassword: row.original.active
              ? actionResetPasswordHandler
              : undefined,
          }

          return <CellActions row={row.original} {...actions} />
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actionResetPasswordHandler, editUserHandler]
  )

  return (
    <>
      <ModalUser
        open={currentAction.presentation === PRESENTATION_TYPE.MODAL}
        handleModal={closeActionPresentationHandler}
        userData={currentAction.selectedRow}
      />

      <ConfirmDialog
        open={currentAction.presentation === PRESENTATION_TYPE.ALERT}
        onConfirm={currentAction.confirmActionHandler}
        onCancel={closeActionPresentationHandler}
        title={translate('DIALOG_PASSWORD_TITLE')}
      >
        {translate('DIALOG_PASSWORD_MESSAGE')}
      </ConfirmDialog>

      <Container>
        <ContentContainer>
          <TitlePage>{translate('TITLE')}</TitlePage>
          <Grid container spacing={3} style={{ marginTop: '16px' }}>
            <Grid item xs>
              <InputSearch
                name="term"
                placeholder={translate('SEARCH_PLACEHOLDER')}
                onSearch={setSearchValue}
              />
            </Grid>

            <Grid
              item
              container
              xs
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Button startIcon={<FaPlus />} onClick={createUserHandler}>
                  {translate('CREATE_USER')}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <SearchRadios
            radioValue={radioValue}
            handleRadioChange={handleRadioChange}
          />

          <TableContainer>
            <Table
              columns={columns}
              data={data?.content || []}
              pageCount={data?.totalPages || 0}
              fetchData={fetchData}
            />
          </TableContainer>
        </ContentContainer>
      </Container>
    </>
  )
}

export default wrapper(RegisterUsers, {
  namespace: 'USERS',
  route: {
    path: REGISTER_USERS_ROUTES.LIST_USERS,
  },
})
