/* eslint-disable react/prop-types */
/* eslint-disable no-debugger */
import React, { useCallback } from 'react'

import { merge } from 'lodash'
import PropTypes from 'prop-types'

import { Checkbox, ListItemText, MenuItem } from '@material-ui/core'

import TextField, { UncontrolledTextField } from 'components/TextField'

const ITEM_HEIGHT = 54
const ITEM_PADDING_TOP = 0
const MenuProps = {
  variant: 'menu',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
  MenuListProps: { disablePadding: true },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      padding: 0,
    },
  },
}

const createOptionRenderer = labelKey => option => option[labelKey]
const groupOptions = (options, multiple) => {
  if (multiple) {
    const [first, ...rest] = options
    return `${first}${rest.length ? ` + (${rest.length})` : ''}`
  }
  return options[0]
}

const SelectField = ({
  options,
  optionRenderer: optionRendererProp,
  placeholder,
  idKey,
  labelKey,
  multiple,
  defaultValue,
  formMethods,
  menuProps,
  displayEmpty,
  ...rest
}) => {
  const optionRenderer = optionRendererProp || createOptionRenderer(labelKey)
  const commonProps = {
    placeholder,
    multiple,
    defaultValue: defaultValue || (multiple ? [] : ''),
  }

  const selectProps = {
    multiple,
    displayEmpty,
    MenuProps: merge(MenuProps, menuProps),
    renderValue: selected => {
      if (selected === '' || selected) {
        const selectedArray = [].concat(selected)
        const selectedOptions = options.filter(
          option => selectedArray.indexOf(option[idKey]) >= 0
        )
        return !selectedOptions.length
          ? placeholder
          : groupOptions(
              selectedOptions.map(option => optionRenderer(option)),
              multiple
            )
      }
      return placeholder
    },
  }

  const isChecked = useCallback(
    ({ value, option }) => value.includes(option[idKey]),
    [idKey]
  )

  const Component = formMethods ? TextField : UncontrolledTextField

  return (
    <Component
      select
      {...rest}
      {...commonProps}
      {...(formMethods ? { formMethods } : null)}
      SelectProps={selectProps}
    >
      {value =>
        options.map(option => (
          <MenuItem key={option[idKey]} value={option[idKey]} dense>
            {multiple ? (
              <Checkbox checked={isChecked({ value, option })} />
            ) : null}
            <ListItemText
              primary={optionRenderer(option)}
              primaryTypographyProps={{
                noWrap: false,
                style: { whiteSpace: 'normal' },
              }}
            />
          </MenuItem>
        ))
      }
    </Component>
  )
}

SelectField.defaultProps = {
  options: [],
  optionRenderer: null,
  label: '',
  placeholder: '',
  multiple: false,
  idKey: 'id',
  labelKey: 'name',
  defaultValue: undefined,
  formMethods: undefined,
  menuProps: {},
}

SelectField.propTypes = {
  menuProps: PropTypes.objectOf(PropTypes.any),
  formMethods: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.arrayOf(PropTypes.object),
  optionRenderer: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  idKey: PropTypes.string,
  labelKey: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

export default SelectField
