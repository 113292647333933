/* eslint-disable react/prop-types */
import React, { useCallback, useState, useEffect } from 'react'
import { FaAndroid, FaApple } from 'react-icons/fa'
import { Redirect } from 'react-router-dom'

import { Auth } from 'aws-amplify'
import QRCode from 'qrcode.react'

import { Button, Typography, Grid, Box } from '@material-ui/core'

import { useCopy } from 'components/CopyText'
import Form from 'components/Form'
import { useLangContext } from 'components/Globalization'
import MaskedTextField from 'components/MaskedTextField'
import wrapper from 'components/Wrapper'

import { APP_ROUTES } from 'modules/App/constants/route'
import { MFA } from 'modules/Auth/constants/mfa'
import { AUTH_ROUTES } from 'modules/Auth/constants/routes'
import { notificationUseCases } from 'modules/Notification/providers'

import {
  Container,
  QRCodeContainer,
  LinksContainer,
  ClickableSpan,
  Link,
  CopyContainer,
} from './style'
import PublicContainer from 'components/PublicContainer'
import history from 'config/history/history'

const MFASetup = ({ challenge, isAuthenticated }) => {
  const translate = useLangContext()

  const { CopyText, onCopyHandler } = useCopy()

  const [qrcode, setQRCode] = useState()
  const [secret, setSecret] = useState()

  const getQRCode = useCallback(async () => {
    if (!challenge) return

    const totpSecret = await Auth.setupTOTP(challenge)

    const url = `otpauth://totp/AWSCognito:${challenge.username}?secret=${totpSecret}&issuer=fies`

    setQRCode(url)
    setSecret(totpSecret)
  }, [challenge])

  const onConfirmHandler = async ({ token }) => {
    try {
      await Auth.verifyTotpToken(challenge, token.split(' ').join(''))
      await Auth.setPreferredMFA(challenge, MFA.TOTP)

      await Auth.signOut()
      history.push(APP_ROUTES.BASE_ROUTE)
    } catch (error) {
      switch (error.code) {
        case 'EnableSoftwareTokenMFAException':
          // eslint-disable-next-line no-console
          console.log(translate('TOKEN_EXCEPTION'))
          notificationUseCases.error(`${translate('TOKEN_EXCEPTION')}`)
          break
        case 'InternalErrorException':
          // eslint-disable-next-line no-console
          console.log(translate('INTERNAL_ERROR_EXCEPTION'))
          notificationUseCases.error(`${translate('INTERNAL_ERROR_EXCEPTION')}`)
          break
        default:
          // eslint-disable-next-line no-console
          console.log(translate('DEFAULT_ERROR'))
          notificationUseCases.error(`${translate('DEFAULT_ERROR')}`)
      }
    }
  }

  useEffect(() => {
    getQRCode()
  }, [getQRCode])

  if (!challenge || isAuthenticated) {
    return <Redirect to={APP_ROUTES.BASE_ROUTE} />
  }

  const formStyle = { alignItems: 'center' }
  const gridWidthStyle = { width: '100px' }

  return (
    <PublicContainer
      title={translate('LOGIN:WELCOME_TITLE')}
      description={translate('LOGIN:WELCOME_MESSAGE')}
    >
      <Container>
        <Typography variant="h4" color="primary">
          {translate('CONFIGURE_MFA')}
        </Typography>

        <Form onSubmit={onConfirmHandler} style={formStyle}>
          {formMethods => (
            <>
              <QRCodeContainer>
                {qrcode ? (
                  <QRCode
                    value={qrcode}
                    size={256}
                    bgColor="#ffffff"
                    fgColor="#000000"
                    level="L"
                    includeMargin
                    renderAs="svg"
                  />
                ) : null}
              </QRCodeContainer>

              <span>{translate('DOWNLOAD_AUTHENTICATOR')}</span>

              <LinksContainer>
                <Link
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=pt_BR"
                  target="_blank"
                  rel="noreferrer"
                  title={translate('DOWNLOAD_ON_GOOGLE_PLAY')}
                >
                  <FaAndroid size="30px" />
                  <span>{translate('GOOGLE_PLAY')}</span>
                </Link>
                <Link
                  href="https://apps.apple.com/br/app/google-authenticator/id388497605"
                  target="_blank"
                  rel="noreferrer"
                  title={translate('DOWNLOAD_ON_APP_STORE')}
                >
                  <FaApple size="25px" />
                  <span>{translate('APP_STORE')}</span>
                </Link>
              </LinksContainer>

              <CopyContainer>
                <CopyText>
                  {translate('COPY_SECRET_1')}&nbsp;
                  <ClickableSpan onClick={onCopyHandler(secret)}>
                    {translate('COPY_SECRET_2')}
                  </ClickableSpan>
                  &nbsp;
                  {translate('COPY_SECRET_3')}
                </CopyText>
              </CopyContainer>

              <Grid container justifyContent="center">
                <Grid item style={gridWidthStyle}>
                  <MaskedTextField
                    formMethods={formMethods}
                    mask="999 999"
                    name="token"
                  />
                </Grid>
              </Grid>

              <Box pt={2}>
                <Button size="large" type="submit">
                  {translate('CONFIRM_CODE')}
                </Button>
              </Box>
            </>
          )}
        </Form>
      </Container>
    </PublicContainer>
  )
}

export default wrapper(MFASetup, {
  namespace: 'MFA_SETUP',
  mapState: state => ({
    challenge: state.auth.challenge,
    isAuthenticated: state.auth.isAuthenticated,
  }),
  route: {
    path: AUTH_ROUTES.MFA_SETUP,
  },
})
