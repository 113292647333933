import { authActions } from 'config/store/slices/auth.slice'
import jwt_decode from 'jwt-decode'
import history from 'config/history/history'
import { USER_ROUTES } from '../../../modules/User/constants/routes'
import { authService } from './index'
import store from '../../../config/store/store'

export default class AuthUseCases {
  GOV_BR_PROVIDER_ID = 'Gov.BR'

  /**
   * @param {object} props
   * @param {import('redux-starter-kit').Store} props.store
   * @param {import('services/providers/user/service').default} props.userService
   * @param {import('services/providers/auth/service').default} props.authService
   */
  constructor({ store, authService, userService }) {
    this.store = store
    this.authService = authService
    this.userService = userService
  }

  async login({ username, password, token }) {
    // await this.authService.validateRecaptcha(token)

    const user = await this.authService.signIn(username, password)

    if (user.challengeName) {
      await this.store.dispatch(authActions.challengeUser(user))
    } else {
      await this.signIn(user.getSignInUserSession())
    }

    return user
  }

  async accessTokenLogin(jwt, token, username) {
    const { user, session } = await this.authService.accessTokenLogin(
      jwt,
      token,
      username
    )
    await this.signIn(session)
    return { user, session }
  }

  async signIn(session) {
    if (!session) return
    try {
      authService.unsetMeFailedFlag()
      store.dispatch(authActions.meFailed(false))
      const user = await this.userService.me()
      this.store.dispatch(authActions.signIn({ user, session }))
    } catch (error) {
      console.log('SignIn Failed!', error)
      authService.setMeFailedFlag()
      store.dispatch(authActions.meFailed(true))
      await this.checkGovBrSession()
    }
  }

  async getMe() {
    const { isAuthenticated } = this.store.getState().auth

    if (isAuthenticated) {
      try {
        authService.unsetMeFailedFlag()
        store.dispatch(authActions.meFailed(false))
        return await this.userService.me()
      } catch (error) {
        console.log('[getMe-ERROR]', error)
        authService.setMeFailedFlag()
        store.dispatch(authActions.meFailed(true))
        await this.checkGovBrSession()
        return null
      }
    }

    return null
  }

  async updateCurrentUserInformation() {
    const user = await this.getMe()
    if (user) this.store.dispatch(authActions.me(user))
  }

  async signOut(redirect = false) {
    const session = await this.authService.restoreUserSession()

    if (session) {
      if (this.getAuthProvider(session) === this.GOV_BR_PROVIDER_ID) {
        await this.authService.govBrSignOut()
      } else {
        authService.unsetIsWaitingGovBrAuthLogout()
        store.dispatch(authActions.isWaitingGovBrLogout(false))
      }

      await this.authService.awsLogout()
    }

    authService.clearAuthStorage()
    // localStorage.clear();

    this.store.dispatch(authActions.signOut())

    if (redirect) {
      history.push(USER_ROUTES)
    }
  }

  async restoreCurrentSession() {
    const session = await this.authService.restoreUserSession()
    if (session) await this.signIn(session)
    else this.signOut()
  }

  async forceLogout() {
    this.store.dispatch(authActions.signOut())
  }

  async checkGovBrSession() {
    const session = await this.authService.restoreUserSession()

    if (session) {
      if (this.getAuthProvider(session) === this.GOV_BR_PROVIDER_ID) {
        authService.setIsWaitingGovBrAuthLogout()
        store.dispatch(authActions.isWaitingGovBrLogout(true))
      } else {
        authService.unsetIsWaitingGovBrAuthLogout()
        store.dispatch(authActions.isWaitingGovBrLogout(false))
      }
    }
  }

  getAuthProvider(session) {
    const jwtDecoded = jwt_decode(session.idToken.jwtToken)
    const { identities } = jwtDecoded
    return identities && identities[0] && identities[0].providerName
  }

  async getFullToken(code) {
    await this.authService.getFullToken(code)
  }

  isAuthenticated() {
    return authService.isAuthenticated()
  }
}
