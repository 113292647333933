/* eslint-disable react/prop-types */
import React from 'react'
import {
  FaEye,
  FaEdit,
  FaPlus,
  FaTrashAlt,
  FaCopy,
  FaCheck,
  FaKey,
  FaToggleOn,
  FaToggleOff,
} from 'react-icons/fa'

import PropTypes from 'prop-types'

import { IconButton, Tooltip } from '@material-ui/core'

import { useLangContext } from 'components/Globalization'
import wrapper from 'components/Wrapper'

import { Container } from './styles'

const ActionButton = ({ title, onClick, icon }) => (
  <Tooltip title={title} arrow>
    <IconButton size="small" color="primary" onClick={onClick}>
      {icon}
    </IconButton>
  </Tooltip>
)

const CellActions = ({
  row,
  onDuplicate,
  onView,
  onEdit,
  onAdd,
  onDelete,
  onToggle,
  isToggleActivated,
  onResetPassword,
  onConfirm,
  getRowId,
  // eslint-disable-next-line react/prop-types
  className,
}) => {
  const translate = useLangContext()

  const actions = {
    onDuplicate,
    onView,
    onEdit,
    onAdd,
    onDelete,
    onToggle,
    onResetPassword,
  }

  const actionList = {
    onDuplicate: { icon: <FaCopy />, title: 'DUPLICATE' },
    onView: { icon: <FaEye />, title: 'VIEW' },
    onEdit: { icon: <FaEdit />, title: 'EDIT' },
    onAdd: { icon: <FaPlus />, title: 'ADD' },
    onDelete: { icon: <FaTrashAlt />, title: 'DELETE' },
    onToggle: {
      icon: isToggleActivated ? <FaToggleOn /> : <FaToggleOff />,
      title: isToggleActivated ? 'DEACTIVATE' : 'ACTIVATE',
    },
    onResetPassword: { icon: <FaKey />, title: 'RESET_PASSWORD' },
  }

  return (
    <Container className={className}>
      {Object.keys(actions).map(action =>
        typeof actions[action] === 'function' ? (
          <ActionButton
            key={action}
            title={translate(actionList[action].title)}
            onClick={() => actions[action]({ row, id: getRowId(row) })}
            icon={actionList[action].icon}
          />
        ) : null
      )}
      {onConfirm && (
        <ActionButton
          title={translate('CONFIRM')}
          onClick={() => onConfirm({ row, id: getRowId(row) })}
          icon={<FaCheck />}
        />
      )}
    </Container>
  )
}

CellActions.defaultProps = {
  row: undefined,
  onDuplicate: undefined,
  onView: undefined,
  onEdit: undefined,
  onAdd: undefined,
  onDelete: undefined,
  onToggle: undefined,
  onResetPassword: undefined,
  onConfirm: undefined,
  getRowId: row => row?.id,
}

CellActions.propTypes = {
  row: PropTypes.objectOf(PropTypes.any),
  onDuplicate: PropTypes.func,
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onToggle: PropTypes.func,
  onResetPassword: PropTypes.func,
  onConfirm: PropTypes.func,
  getRowId: PropTypes.func,
}

export default wrapper(CellActions, { namespace: 'CELL_ACTIONS' })
