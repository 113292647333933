import styled from 'styled-components'

export const TitlePage = styled.h1`
  font-size: 1.5rem;
  margin: 4.3rem 0;
  padding: 0;
`

export const CourseDiv = styled.div`
  h1 {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
  }

  p {
    font-style: italic;
    margin: 0.5rem 0;
  }

  button {
    border: 1px solid #1351b4;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 42px;
    padding: 0.375rem 1rem 0.4375rem;
    color: ${props => props.theme.bgColors.primaryDefault};
    font-weight: bold;
    background: #fcfcfc;
    margin-top: 1.1875rem;
    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
    min-width: 19rem;
  }
`
export const ClockDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  p {
    font-size: 0.75rem;
  }
`
export const CourseChoosedContainer = styled.div`
  background: #f3f1f1;
  padding: 1.5rem;
`
export const AboutCollege = styled.div`
  h1 {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
  }
`
export const LocationDiv = styled.div`
  display: flex;
  margin-top: 1rem;
  gap: 0.5rem;
  img {
    align-self: flex-start;
  }

  p {
    color: #000000;
  }
`

export const VacanciesContainer = styled.div``

export const TitleVacancies = styled.div`
  margin: 0;
  padding: 0;
  margin-top: 2.8rem;
  font-size: 1.25rem;
  font-weight: 500;
`
export const SubTitleVacancies = styled.div`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 500;
`

export const LawContainer = styled.div`
  background-color: ${props => props.theme.bgColors.primaryDefault};
  padding: 0.625rem;
  margin-top: 1.75rem;
  h2 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    color: ${props => props.theme.bgColors.colorSecondary01};
    font-weight: bold;
  }
`

export const Income = styled.h3`
  margin-top: 1rem;
  padding: 0;
  font-size: 1.25rem;
`
export const VacanciesChooseContainer = styled.div``

export const CardContainer = styled.div`
  margin-top: 1.25rem;
  border-radius: 0px 0px 4px 4px;
  cursor: pointer;
`

export const CardHeader = styled.div`
  border-radius: 4px;
  &.free {
    background: #1351b4;
    border-bottom: 0;
    p {
      color: ${props => props.theme.bgColors.colorSecondary01};
    }
  }
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #e3e3e3;

  background: #e3e3e3;
  display: flex;
  gap: 0.8rem;
  padding: 0.5625rem 1.5625rem;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }
`

export const CardInformation = styled.div`
  background: #f2f2f2;
  padding: 2.5rem;
  &.free {
    background: #0071bc;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${props => props.theme.bgColors.colorSecondary01};
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  h1 {
    font-weight: 500;
    font-size: 1.5rem;
    color: #8a8a8a;
  }

  h2 {
    font-weight: 500;
    font-size: 0.875rem;
    color: #a5a5a5;
  }

  h3 {
    font-weight: 500;
    font-size: 1.25rem;
    color: #8a8a8a;
    margin-top: 1.5rem;
  }

  h4 {
    font-weight: 400;
    font-size: 1rem;
    color: #8a8a8a;
  }

  h5 {
    font-weight: 300;
    font-size: 0.875rem;
    margin-top: 1rem;
    color: #8a8a8a;
  }

  h6 {
    font-weight: 400;

    margin-top: 0.5rem;
    color: #8a8a8a;
    font-size: 0.625rem;
  }
`
