import {
  ContainerLastCourseCard,
  TypeCourseContainer,
  CourseTimeDiv,
  NameCollegeAndInformationsDiv,
  LocationCollegeDiv,
} from './style'
import locationImage from './img/locationCourse.svg'
import courseTimeImage from './img/clockImage.svg'
export function LastCoursesCard() {
  return (
    <ContainerLastCourseCard>
      <h1>ARTES</h1>
      <TypeCourseContainer>
        <h2>Bacharelado</h2>
        <CourseTimeDiv>
          <img src={courseTimeImage} alt="Imagem de Relógio" />
          <p>Integral (VESP/NOT)</p>
        </CourseTimeDiv>
      </TypeCourseContainer>
      <NameCollegeAndInformationsDiv>
        <h3>UFC - UNIVERSIDADE FEDERAL DO CEARÁ</h3>
        <LocationCollegeDiv>
          <img src={locationImage} alt="" />
          <p>
            Integral <br />
            (VESP/NOT)
          </p>
        </LocationCollegeDiv>

        <h4>
          Classificação parcial : <strong>17º</strong> de 20 vagas
        </h4>

        <p>Atualização em: 23/11/22 às 18h20</p>
      </NameCollegeAndInformationsDiv>
    </ContainerLastCourseCard>
  )
}
