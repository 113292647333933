/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, Container, Modal } from 'react-bootstrap'
import { Wrapper, ChooseCourse, Button, SaveTimeDiv } from './styles'
import { FaPlus } from 'react-icons/fa'
import { GuidanceInCourseSelection } from './components/GuidanceInCourseSelection'
import { FilterCourse } from './containers/FilterCourse'
import { CardConfirmCourse } from '../CardConfirmCourse'
import FormContext3 from 'components/DataConfirmSteps/formContext3'

import tagSvg from './assets/img/tag.svg'
import { ButtonSetData } from '../StepTwo/style'
import { apiWithToken } from 'services/apiWithToken'
import { useLoading } from 'hooks/useLoading'
import { useUser } from 'hooks/useUser'

export function StepThreeSubscribeForm({ comeBackStepTwo }) {
  const { loading } = useLoading()
  const { cpf } = useUser()

  useEffect(() => {
    window.scrollTo(0, 0)
    setFormContent3(false)
    setShowFilter(false)
  }, [])

  const { setFormContent3 } = useContext(FormContext3)

  const [isFirstCourseSelected, setIsFirstCourseSelected] = useState(false)
  const [isSecondCourseSelected, setIsSecondCourseSelected] = useState(false)

  const [optionFilter, setOptionFilter] = useState('')
  const [showFilter, setShowFilter] = useState(false)
  const [declarations, setDeclarations] = useState([
    {
      title: '',
      isChecked: false,
      id: 0,
    },
  ])

  function handleSetCourse(option) {
    setOptionFilter(option)
    setShowFilter(true)
  }

  const [apiData1, setApiData1] = useState({})
  const [apiData2, setApiData2] = useState({})

  const [show, setShow] = useState(false)

  const toogleModal = () => setShow(!show)

  useEffect(() => {
    const getUserMe = async () => {
      loading.turnOn()

      try {
        const { data } = await apiWithToken.get(`/user-registration/${cpf}`)

        const subscribeInSisu = data.filter(
          subscribe => subscribe.program.toLowerCase() === 'sisu'
        )[0]

        if (!subscribeInSisu) {
          return
        }

        const firstOption = subscribeInSisu.courses.filter(
          course => course.option === 'option1'
        )

        if (!!firstOption.length) {
          setApiData1(firstOption[0])
          setIsFirstCourseSelected(true)
        }

        const secondOption = subscribeInSisu.courses.filter(
          course => course.option === 'option2'
        )

        if (!!secondOption.length) {
          setApiData2(secondOption[0])
          setIsSecondCourseSelected(true)
        }

        const declarationsMapped = data[0].courses.map((item, index) => {
          return {
            id: index,
            title: item.modalityDeclaration,
            isChecked: false,
          }
        })
        setDeclarations(declarationsMapped)
      } catch (err) {
        console.log(err)
      } finally {
        loading.turnOff()
      }
    }

    if (!!cpf) {
      getUserMe()
    }
  }, [cpf])
  console.log(declarations)

  function toggleDeclarationChecked(id, event) {
    const declarationsTemporary = declarations

    declarationsTemporary[id] = {
      ...declarationsTemporary[id],
      isChecked: event.target.checked,
    }
    setDeclarations([...declarationsTemporary])
  }

  return (
    <>
      {showFilter ? (
        <FilterCourse
          option={optionFilter}
          comeBackCourseSelection={() => setShowFilter(false)}
        />
      ) : (
        <Wrapper>
          <Modal
            show={show}
            onHide={toogleModal}
            centered
            style={{
              boxShadow: '0px 9px 6px rgba(51, 51, 51, 0.2);',
            }}
          >
            <Modal.Title
              style={{
                fontSize: '1.25rem',
                fontWeight: 'bold',
                padding: '1rem',
                background: '#E2EAF5',
                color: '#0C326F',
              }}
            >
              Apenas 1 opção de curso
            </Modal.Title>
            <Modal.Body style={{ fontStyle: 'italic', color: '#000000' }}>
              <p
                className="modalBody"
                style={{
                  color: '#000000',
                  fontWeight: 600,
                  fontSize: '0.8rem',
                }}
              >
                Você selecionou apenas uma opção de curso para o SISU, Tem
                certeza que deseja prosseguir?
              </p>
            </Modal.Body>
            <Modal.Footer style={{ borderBottom: '0', borderTop: '0' }}>
              <ButtonSetData>
                <Button
                  className="buttonStyleGOV modalBProps cancel"
                  onClick={toogleModal}
                >
                  Cancelar
                </Button>
                <Button
                  className="buttonStyleGOV primary modalBProps "
                  onClick={() => {
                    setFormContent3(true)
                  }}
                >
                  Concluir
                </Button>
              </ButtonSetData>
            </Modal.Footer>
          </Modal>
          <Container>
            <GuidanceInCourseSelection
              isFirstOptionSelected={isFirstCourseSelected}
              isSecondOptionSelected={isSecondCourseSelected}
            />
            <Row className="g-3">
              {!isFirstCourseSelected ? (
                <Col sm={12} md={6}>
                  <ChooseCourse
                    onClick={() => {
                      handleSetCourse('option1')
                    }}
                  >
                    <FaPlus />
                    <h1>Selecionar minha 1ª opção de curso</h1>
                  </ChooseCourse>
                </Col>
              ) : (
                <Col md={6}>
                  <CardConfirmCourse
                    program="sisu"
                    isEdit
                    option={1}
                    changeCourse={() => handleSetCourse('option1')}
                    selectCourse2={isSecondCourseSelected}
                    courseCode={apiData1.courseTimeCode}
                    courseCodeChange={apiData2.courseTimeCode}
                    courseName={apiData1.courseName}
                    modality={apiData1.courseDegree}
                    courseType={apiData1.courseDegree}
                    courseTime={apiData1.courseTimeType}
                    courseLocation={apiData1.campusName}
                    courseState={apiData1.campusState}
                    university={apiData1.collegeName}
                    campusNeighborhood={apiData1.campusNeighborhood}
                    modalityType={apiData1.modality}
                    modalityTypeChange={apiData2.modality}
                    subscribeStatus="parcial"
                    hideDocument
                  />
                </Col>
              )}

              {!isSecondCourseSelected ? (
                <Col
                  sm={12}
                  md={6}
                  disabled={!isFirstCourseSelected ? true : false}
                >
                  <ChooseCourse
                    onClick={() => {
                      handleSetCourse('option2')
                    }}
                  >
                    <FaPlus />
                    <h1>Selecionar minha 2ª opção de curso</h1>
                  </ChooseCourse>
                </Col>
              ) : (
                <Col md={6}>
                  <CardConfirmCourse
                    program="sisu"
                    isEdit
                    option={2}
                    changeCourse={() => handleSetCourse('option2')}
                    courseCode={apiData2.courseTimeCode}
                    courseCodeChange={apiData1.courseTimeCode}
                    courseName={apiData2.courseName}
                    modality={apiData2.courseDegree}
                    courseType={apiData2.courseDegree}
                    courseTime={apiData2.courseTimeType}
                    courseLocation={apiData2.campusName}
                    courseState={apiData2.campusState}
                    university={apiData2.collegeName}
                    campusNeighborhood={apiData2.campusNeighborhood}
                    modalityType={apiData2.modality}
                    modalityTypeChange={apiData1.modality}
                    subscribeStatus="parcial"
                    hideDocument
                  />
                </Col>
              )}
            </Row>
          </Container>

          {isFirstCourseSelected || isSecondCourseSelected ? (
            <>
              {declarations.map((item, index) => (
                <Container className="declaro">
                  <input
                    type="checkbox"
                    name={index}
                    id={index}
                    onChange={event => {
                      toggleDeclarationChecked(item.id, event)
                    }}
                  />
                  <label htmlFor={index}>{item.title}</label>
                </Container>
              ))}
              {/* <Container className="declaro">
                <input
                  type="checkbox"
                  name="checkDeclair"
                  id="checkDeclair"
                  onChange={event => setIsMarkedStatement(event.target.checked)}
                />
                <label htmlFor="checkDeclair">
                  Declaro que ....xxxx.... candidatos com deficiência
                  autodeclarados pretos, pardos ou indígenas que,
                  independentemente da renda (art. 14, II, Portaria Normativa nº
                  18/2012), tenham cursado integralmente o ensino médio em
                  escolas públicas (Lei nº 12.711/2012).
                </label>
              </Container> */}
              <SaveTimeDiv>
                <p>
                  <img src={tagSvg} alt="Check" />
                  Dados salvos às{' '}
                  {`${new Date().toLocaleDateString(
                    'pt-br'
                  )} - ${new Date().toLocaleTimeString('pt-br')}`}
                </p>
              </SaveTimeDiv>
              <Container>
                <Button
                  className="next"
                  disabled={
                    !declarations.every(declaration => declaration.isChecked)
                  }
                  onClick={() => {
                    setFormContent3(true)
                  }}
                >
                  Confirmar
                </Button>
              </Container>
            </>
          ) : null}

          {/* {isFirstCourseSelected & !isSecondCourseSelected ? (
            <>
              <Container className="declaro">
                <input
                  type="checkbox"
                  name="checkDeclair"
                  id="checkDeclair"
                  onChange={event => setIsMarkedStatement(event.target.checked)}
                />
                <label htmlFor="checkDeclair">
                  Declaro que ....xxxx.... candidatos com deficiência
                  autodeclarados pretos, pardos ou indígenas que,
                  independentemente da renda (art. 14, II, Portaria Normativa nº
                  18/2012), tenham cursado integralmente o ensino médio em
                  escolas públicas (Lei nº 12.711/2012).
                </label>
              </Container>
              <SaveTimeDiv>
                <p>
                  <img src={tagSvg} alt="Check" />
                  Dados salvos às{' '}
                  {`${new Date().toLocaleDateString(
                    'pt-br'
                  )} - ${new Date().toLocaleTimeString('pt-br')}`}
                </p>
              </SaveTimeDiv>
              <Container>
                <Button
                  className="next"
                  disabled={!isMarkedStatement}
                  onClick={toogleModal}
                >
                  Confirmar
                </Button>
              </Container>
            </>
          ) : null} */}
          <Container>
              <Button className="prev" onClick={comeBackStepTwo}>
                Voltar
              </Button>
              <p>&nbsp;</p>
          </Container>
        </Wrapper>
      )}
    </>
  )
}
