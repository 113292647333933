/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'

import {
  DivImage,
  TextsGov,
  QRCODEDIV,
  ContainerComprovante,
  Header,
  Data,
  LineText,
  FamilyGroup,
  OptionCourseText,
  CardOptionCourse,
  DataOption,
  OptionDataContainer,
  TextWithline,
  ContainerDeclarations,
  PrintText,
  DivSpaceContainer,
  PageStyle,
} from './styles'
import governoimg from './assets/img/governoimg.png'
// import qrcode from './img/qrcode.svg';

import { Col, Row } from 'react-bootstrap'
import { UserData } from './components/UserData'
import { Table } from 'react-bootstrap'
import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import QRCode from 'react-qr-code'
import { useParams, useHistory } from 'react-router-dom'
import { CandidateRanking } from './components/CandidateRanking'
import { apiWithToken } from 'services/apiWithToken'
import { cpfFormatted } from 'utils/cpfFormatted'
import { phoneFormatted } from 'utils/phoneFormatted'

export const ComprovanteComHashComponent = React.forwardRef((props, ref) => {
  const { hash } = useParams()
  const history = useHistory()

  const [userInformation, setUserInformation] = useState([])
  const [userEducation, setUserEducation] = useState({})
  const [userRelative, setUserRelative] = useState([])
  const [userAverage, setUserAverage] = useState({})
  const [userContact, setUserContact] = useState({})
  const [perCapita, setPerCapita] = useState(0)
  const [coursesSelected, setCoursesSelected] = useState([])
  const [programName, setProgramName] = useState('')
  const [createdAt, setCreatedAt] = useState('')
  const [validHash, setValidHash] = useState(true)

  const summary = userRelative.reduce(
    (acc, transaction) => {
      acc.total += parseFloat(transaction.individualIncome)
      return acc
    },
    {
      total: 0,
    }
  )

  useEffect(() => {
    if (!hash) {
      history.push('/candidate-page')
    }

    const getUserByHash = async () => {
      try {
        const { data } = await apiWithToken.get(
          `/user-registration/verify/${hash}`
        )

        setValidHash(true)
        setUserInformation(data.educationInfoDTO)
        setUserEducation(data.collegeInfoDTO)
        setUserRelative(data.familyInfoDTO)
        setUserContact(data.contactInfoDTO)
        setCoursesSelected(
          data.registrationInfoDTO.sort((a, b) =>
            a.option > b.option ? 1 : -1
          )
        )
        setUserAverage(data.averageDTO)
        setProgramName(data.program.toUpperCase())

        setCreatedAt(data.createdDate)
      } catch (err) {
        console.error(err)
      } finally {
        props.setLoaded(true)
      }
    }

    getUserByHash()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash])

  useEffect(() => {
    if (userRelative.length) {
      const perCapitaa = summary.total / userRelative.length

      setPerCapita(perCapitaa)
    }
  }, [userRelative, summary])

  let formattedDate = theDate => {
    return format(new Date(theDate), 'dd/MM/yyyy', {
      locale: ptBR,
    })
  }

  function formatToBRL(number) {
    return new Intl.NumberFormat('pt-br', {
      currency: 'BRL',
      style: 'currency',
    }).format(number)
  }

  function formatNote(note, numberDecimals = 1) {
    return (
      new Intl.NumberFormat('pt-br', {
        minimumFractionDigits: numberDecimals,
      }).format(note) || ''
    )
  }

  return (
    <>
      {validHash ? (
        <PageStyle ref={ref}>
          <ContainerComprovante>
            <Header>
              <DivImage>
                <img src={governoimg} alt="Imagem do Governo" />
                <TextsGov>
                  <h1>MINISTÉRIO DA EDUCAÇÃO - MEC</h1>
                  <h2>Secretaria de Educação Superior - {programName}</h2>
                  <h3>{programName} - Sistema de seleção unificada </h3>
                </TextsGov>
              </DivImage>

              <QRCODEDIV>
                <div>
                  <QRCode
                    value={`${process.env.REACT_APP_FRONTEND_URL}/comprovante/${hash}`}
                    size={80}
                  />
                </div>
                <span>{hash}</span>
                <p>
                  Inscrição efetuada em:
                  {`${new Date(createdAt).toLocaleDateString(
                    'pt-br'
                  )} - ${new Date(createdAt).toLocaleTimeString('pt-br')}`}
                </p>
              </QRCODEDIV>
            </Header>

            <LineText>
              <p>Comprovante de Inscrição {programName} 1º/2022</p>
            </LineText>
            <DivSpaceContainer>
              <Data>
                <h3>Dados do Estudante</h3>
              </Data>

              <Row>
                {userInformation.socialName && (
                  <Col sm={3} md={3} lg={3} xl={3} xxl={3}>
                    <UserData
                      label="Nome Social"
                      dataLabel={userInformation.socialName}
                    />
                  </Col>
                )}

                <Col sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <UserData
                    label="Nome de Registro"
                    dataLabel={userInformation.registerName}
                  />
                </Col>

                <Col sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <UserData
                    label="CPF"
                    dataLabel={cpfFormatted(userInformation.cpf)}
                  />
                </Col>

                <Col sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <UserData label="Gênero" dataLabel={userInformation.gender} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <UserData
                    label="Data de Nascimento"
                    dataLabel={
                      userInformation.birthDate
                        ? formattedDate(userInformation.birthDate)
                        : null
                    }
                  />
                </Col>

                <Col sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <UserData
                    label="Etnia/Cor"
                    dataLabel={userInformation.ethnicity}
                  />
                </Col>
                <Col sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <UserData
                    label="Estado Civil"
                    dataLabel={userInformation.maritalStatus}
                  />
                </Col>

                {userInformation.disabilityType !==
                  'Selecione a deficiência' && (
                  <Col sm={3} md={3} lg={3} xl={3} xxl={3}>
                    <UserData
                      label="Candidato PCD"
                      dataLabel={
                        userInformation.disabilityType &&
                        userInformation.disabilityType !==
                          'Selecione a deficiência'
                          ? `Deficiência ${userInformation.disabilityType}`
                          : ''
                      }
                    />
                  </Col>
                )}
              </Row>

              <Row>
                <Col sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <UserData
                    label="Brasileiro nato ou naturalizado?"
                    dataLabel={userInformation.brazilian ? 'Sim' : 'Não'}
                  />
                </Col>

                <Col sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <UserData
                    label="Natural do Munícípio"
                    dataLabel={userInformation.municipality}
                  />
                </Col>
                <Col sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <UserData
                    label="Natural do UF"
                    dataLabel={userInformation.uf}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <UserData
                    label="Documento de Identificação"
                    dataLabel={userInformation.identificationDocument}
                  />
                </Col>
                <Col sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <UserData
                    label="Número do documento"
                    dataLabel={userInformation.documentNumber}
                  />
                </Col>

                {userInformation.identificationDocument !== 'CTPS' &&
                userInformation.uf !== 'Selecione seu UF' ? (
                  <Col sm={3} md={3} lg={3} xl={3} xxl={3}>
                    <UserData
                      label="Orgão Emissor"
                      dataLabel={userInformation.issuingAgency}
                    />
                  </Col>
                ) : null}

                {userInformation.uf !== 'Selecione seu UF' &&
                userInformation.identificationDocument !== 'CTPS' ? (
                  <Col sm={3} md={3} lg={3} xl={3} xxl={3}>
                    <UserData
                      label="UF de Emissão"
                      dataLabel={userInformation.issuingState}
                    />
                  </Col>
                ) : null}

                {userInformation.serie &&
                userInformation.identificationDocument === 'CTPS' ? (
                  <Col sm={3} md={3} lg={3} xl={3} xxl={3}>
                    <UserData
                      label="Série do documento"
                      dataLabel={userInformation.serie}
                    />
                  </Col>
                ) : null}
              </Row>

              {console.log(userInformation.identificationDocument)}

              <Row>
                <Col sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <UserData
                    label="Número do ENEM"
                    dataLabel={userAverage.registrationNumber}
                  />
                </Col>
                <Col sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <UserData
                    label="Edição do ENEM"
                    dataLabel={userAverage && userAverage.year}
                  />
                </Col>
              </Row>
            </DivSpaceContainer>

            <DivSpaceContainer>
              <Data>
                <h3>Dados de Contato</h3>
              </Data>

              <Row>
                <Col sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <UserData label="E-mail" dataLabel={userContact.email} />
                </Col>
                {userContact.contactNumbers?.map(
                  (contactNumber, index) =>
                    !!contactNumber && (
                      <Col sm={4} md={4} lg={4} xl={4} xxl={4}>
                        <UserData
                          label={`Telefone para contato ${index + 1}`}
                          dataLabel={phoneFormatted(contactNumber)}
                        />
                      </Col>
                    )
                )}
              </Row>
            </DivSpaceContainer>

            <DivSpaceContainer className="schoolDiv pagebreak">
              <Data>
                <h3>Dados Escolares</h3>
              </Data>

              <Row>
                {userEducation.schoolCountry !== 'Selecione o país' && (
                  <Col md={6} lg={6} xl={6} xxl={6}>
                    <UserData
                      label="País de conclusão do ensino médio"
                      dataLabel={
                        userEducation.schoolCountry === 'Selecione o país'
                          ? ''
                          : userEducation.schoolCountry
                      }
                    />
                  </Col>
                )}

                <Col md={6} lg={6} xl={6} xxl={6}>
                  <UserData
                    label="Ano de conlusão do ensino médio"
                    dataLabel={userEducation.conclusionYear}
                  />
                </Col>
              </Row>
              {/* <Row>
            <Col>
              <UserData
                label="UF de conclusão do ensino médio"
                dataLabel="Rio grande do norte"
              />
            </Col>
            <Col>
              <UserData
                label="Município de conclusão do ensino médio"
                dataLabel="Natal"
              />
            </Col>
          </Row> */}

              <Row>
                <Col md={6} lg={6} xl={6} xxl={6}>
                  <UserData
                    label="Tipo de escola o candidato cursou o ensino médio"
                    dataLabel={userEducation.schoolType}
                  />
                </Col>
                {/* <Col>
              <UserData
                label="Cursou o ensino médio integralmente na UF informada?"
                dataLabel="Integralmente na UF informada"
              />
            </Col> */}
              </Row>

              <Row>
                {userEducation.courseType !== 'Selecione o curso' && (
                  <Col md={6} lg={6} xl={6} xxl={6}>
                    <UserData
                      label="Concluí o ensino superior no curso"
                      dataLabel={userEducation.courseType}
                    />
                  </Col>
                )}

                <Col md={6} lg={6} xl={6} xxl={6}>
                  <UserData
                    label="Cursou o ensino superior em instituição pública?"
                    dataLabel={userEducation.publicFlag ? 'Sim' : 'Não'}
                  />
                </Col>
              </Row>

              <Row>
                {userEducation.schoolGrade !== 'Selecione o grau' && (
                  <Col md={6} lg={6} xl={6} xxl={6}>
                    <UserData
                      label="Qual o grau?"
                      dataLabel={userEducation.schoolGrade}
                    />
                  </Col>
                )}

                {userEducation.schoolName !== 'Selecione a instituição' && (
                  <Col md={6} lg={6} xl={6} xxl={6}>
                    <UserData
                      label="Nome da Instituição"
                      dataLabel={userEducation.schoolName}
                    />
                  </Col>
                )}
              </Row>

              <Row>
                <Col md={6} lg={6} xl={6} xxl={6}>
                  <UserData
                    label="O candidato é professor da rede pública de ensino, no efetivo exercício do magistério da educação básica, 
              integrando o quadro de pessoal permanente?"
                    dataLabel={userEducation.professorFlag ? 'Sim' : 'Não'}
                  />
                </Col>
                <Col md={6} lg={6} xl={6} xxl={6}>
                  <UserData
                    label="O candidato possui vínculo acadêmico (matrícula ativa ou trancada) com instituição de ensino superior pública e gratuita?"
                    dataLabel={userEducation.academicFlag ? 'Sim' : 'Não'}
                  />
                </Col>
              </Row>

              {userInformation.brazilian && (
                <p className="text-bold">
                  O candidato declarou ser brasileiro(a) nato(a) ou
                  naturalizado(a).
                </p>
              )}
            </DivSpaceContainer>

            <DivSpaceContainer>
              <Data>
                <h3>Grupo Familiar</h3>
              </Data>

              <FamilyGroup>
                <Table responsive="md" className="familyTable">
                  <thead>
                    <tr>
                      <th>Nome Completo</th>
                      <th>CPF</th>
                      <th>Grau de Parentesco</th>
                      <th>Data de Nascimento</th>
                      <th>Renda Mensal (R$)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr> */}
                    {userRelative &&
                      userRelative.map(
                        ({
                          birthDate,
                          cpf,
                          degreeKinship,
                          individualIncome,
                          name,
                        }) => (
                          <tr>
                            <td>{name}</td>
                            <td>{cpfFormatted(cpf)}</td>
                            <td>{degreeKinship}</td>
                            <td>
                              {birthDate ? formattedDate(birthDate) : null}
                            </td>
                            <td>{formatToBRL(individualIncome)}</td>
                          </tr>
                        )
                      )}
                  </tbody>
                </Table>

                <p>
                  Renda familiar mensal bruta
                  <strong> {formatToBRL(summary.total.toFixed(2))}</strong>
                </p>
                <p>
                  Renda mensal bruta per capita
                  <strong> {formatToBRL(perCapita.toFixed(2))}</strong>
                </p>
              </FamilyGroup>
            </DivSpaceContainer>

            <LineText>
              <p>Comprovante de Inscrição {programName} 1º/2022</p>
            </LineText>

            <Data>
              <h2>Cursos Selecionados</h2>
            </Data>

            <DivSpaceContainer>
              {coursesSelected?.map((courseSelected, index) => (
                <>
                  <DivSpaceContainer className="pagebreak">
                    <OptionCourseText>{index + 1}ª Opção</OptionCourseText>

                    <CardOptionCourse>
                      <Row>
                        <Col>
                          <DataOption>
                            <h2>Curso selecionado:</h2>
                            <p>{courseSelected.courseName}</p>
                          </DataOption>
                        </Col>
                        <Col>
                          <DataOption>
                            <h2>Instituição:</h2>
                            <p>{courseSelected.collegeName}</p>
                          </DataOption>
                        </Col>
                        <Col></Col>
                      </Row>

                      <Row>
                        <Col>
                          <DataOption>
                            <h2>Turno:</h2>
                            <p>{courseSelected.courseTimeType}</p>
                          </DataOption>
                        </Col>
                        <Col>
                          <DataOption>
                            <h2>Grau:</h2>
                            <p>{courseSelected.courseDegree}</p>
                          </DataOption>
                        </Col>
                        <Col>
                          <DataOption>
                            <h2>Local:</h2>
                            <p>{courseSelected.campusCity}</p>
                            <span>{courseSelected.apiData1campusAddress}</span>
                          </DataOption>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <DataOption>
                            <h2>Código do curso:</h2>
                            <p>{courseSelected.courseTimeCode}</p>
                          </DataOption>
                        </Col>
                        <Col>
                          <DataOption>
                            <h2>Modalidade:</h2>
                            <p>Ação afirmativa</p>
                          </DataOption>
                        </Col>
                        <Col>
                          <DataOption>
                            <h2>Cidade/UF:</h2>
                            <p>
                              {courseSelected.campusCity}/{' '}
                              {courseSelected.campusState}
                            </p>
                            <span>{courseSelected.apiData1campusAddress}</span>
                          </DataOption>
                        </Col>
                      </Row>

                      {programName === 'PROUNI' && (
                        <Row>
                          <Col>
                            <DataOption>
                              <h2>Tipo de Bolsa</h2>
                              <p>
                                {courseSelected.courseTimeScholarshipType}{' '}
                                {courseSelected.courseTimeScholarshipType ===
                                'Parcial'
                                  ? '(50%)'
                                  : '(100%)'}
                              </p>
                            </DataOption>
                          </Col>
                          <Col>
                            <DataOption>
                              <h2>Valor da mensalidade</h2>
                              <p>
                                {formatToBRL(courseSelected.courseTimeTuition)}
                              </p>
                            </DataOption>
                          </Col>
                          <Col>
                            <DataOption>
                              <h2>Valor da Mensalidade Prouni</h2>
                              <p>
                                {formatToBRL(
                                  courseSelected.courseTimeTuition -
                                    courseSelected.courseTimeScholarshipValue
                                )}
                              </p>
                            </DataOption>
                          </Col>
                          {/* <DataOption>
                  <h2>Matriculado no curso ou em outro de área afim?</h2>
                </DataOption></Col> */}
                        </Row>
                      )}

                      <OptionDataContainer>
                        <DataOption>
                          <h2>Site:</h2>
                          <a href="#" target="_blank">
                            www.fsmg.edu.br
                          </a>
                        </DataOption>
                      </OptionDataContainer>

                      <OptionDataContainer>
                        <TextWithline className="w-100">
                          <h3>Documentações necessárias para a matrícula</h3>
                          <hr className="w-100" />
                        </TextWithline>
                      </OptionDataContainer>

                      <OptionDataContainer>
                        {/* <ResumeOptionCourse> */}
                        {courseSelected.registrationDocuments}
                        {/* </ResumeOptionCourse> */}
                      </OptionDataContainer>

                      <CandidateRanking rating={courseSelected.rating} />
                    </CardOptionCourse>
                  </DivSpaceContainer>
                  {programName !== 'PROUNI' && (
                    <DivSpaceContainer className="pagebreak">
                      <Data>
                        <h3>
                          Composição de nota do ENEM edição {userAverage.year}
                        </h3>
                      </Data>
                      <FamilyGroup>
                        <Table className="familyTable">
                          <thead>
                            <tr>
                              <th>Matéria</th>
                              <th>Nota Mínima</th>
                              <th>Peso</th>
                              <th>Sua Nota(A)</th>
                              <th>Sua Nota com peso(B)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {courseSelected.averageDetail.averageSubjectDTO.map(
                              subject => (
                                <tr>
                                  <td>{subject.subject}</td>
                                  <td>{formatNote(subject.minimumGrade, 2)}</td>
                                  <td>{formatNote(subject.weight, 2)}</td>
                                  <td>{formatNote(subject.grade)}</td>
                                  <td>{formatNote(subject.weightGrade, 2)}</td>
                                </tr>
                              )
                            )}

                            <tr>
                              <td>
                                Nota do estudante (B/A)={' '}
                                {formatNote(
                                  courseSelected.averageDetail.grade,
                                  2
                                )}
                              </td>
                              <td></td>
                              <td></td>
                              <td>
                                TOTAL{' '}
                                {console.log('course selected', courseSelected)}
                                {formatNote(
                                  courseSelected.averageDetail.sumGrade,
                                  2
                                )}{' '}
                                (A)
                              </td>

                              <td>
                                TOTAL{' '}
                                {formatNote(
                                  courseSelected.averageDetail.sumWeightGrade,
                                  2
                                )}{' '}
                                (B)
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </FamilyGroup>
                    </DivSpaceContainer>
                  )}
                </>
              ))}
            </DivSpaceContainer>

            {programName === 'PROUNI' ? (
              <DivSpaceContainer className="pagebreak">
                <Data>
                  <h3>
                    Composição de nota do ENEM edição{' '}
                    {userAverage && userAverage.year}
                  </h3>
                </Data>

                <FamilyGroup>
                  <Table responsive="md" className="familyTable text-left">
                    <thead>
                      <tr>
                        <th>Matéria</th>
                        <th>Nota</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Redação</td>
                        <td>
                          {new Intl.NumberFormat('pt-br', {}).format(
                            userAverage.notaRedacao
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Linguagens, Códigos e suas tecnologias</td>
                        <td>{formatNote(userAverage.notaLinguagens)}</td>
                      </tr>
                      <tr>
                        <td>Matemática e suas Tecnologias</td>
                        <td>{formatNote(userAverage.notaMatematica)}</td>
                      </tr>
                      <tr>
                        <td>Ciências da Natureza e suas Tecnologias</td>
                        <td>{formatNote(userAverage.notaCienciasNatureza)}</td>
                      </tr>
                      <tr>
                        <td>Ciências Humanas e suas Tecnologias</td>
                        <td>{formatNote(userAverage.notaHumanas)}</td>
                      </tr>

                      <tr>
                        <td>Média</td>
                        <td>
                          TOTAL{' '}
                          {formatNote(
                            (userAverage.notaRedacao +
                              userAverage.notaLinguagens +
                              userAverage.notaMatematica +
                              userAverage.notaCienciasNatureza +
                              userAverage.notaHumanas) /
                              5
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </FamilyGroup>
              </DivSpaceContainer>
            ) : null}

            <DivSpaceContainer className="pagebreak">
              <Data>
                <h3>Declarações</h3>
              </Data>

              <ContainerDeclarations>
                {coursesSelected.map(({ courseDeclaration }, index) => (
                  <p>
                    Declaração {index + 1} :{' '}
                    {courseDeclaration
                      ? courseDeclaration
                      : 'Declaro ter lido a documentação necessário para comprovação dos dados cadastrados'}
                    .
                  </p>
                ))}
                {/* <p>
                  ....
                </p> */}

                <Header>
                  <DivImage>
                    <TextsGov>
                      <p>
                        Inscrição efetuada em:&nbsp;
                        {`${new Date(createdAt).toLocaleDateString(
                          'pt-br'
                        )} - ${new Date(createdAt).toLocaleTimeString(
                          'pt-br'
                        )}`}
                      </p>
                      <span>{hash}</span>
                    </TextsGov>
                  </DivImage>

                  <QRCODEDIV>
                    <div>
                      <QRCode
                        value={`${process.env.REACT_APP_FRONTEND_URL}/comprovante/${hash}`}
                        size={80}
                      />
                    </div>
                  </QRCODEDIV>
                </Header>

                <p>Acesso único v. 0.872.1</p>
                <p>
                  Todos os horários referem-se ao horário oficial de Brasília.
                </p>
                <p> © 2022 {programName}. Todos os direitos reservados.</p>
              </ContainerDeclarations>

              <PrintText>
                <p>
                  Data da impressão:{' '}
                  {`${new Date().toLocaleDateString(
                    'pt-br'
                  )} - ${new Date().toLocaleTimeString('pt-br')}`}
                </p>
              </PrintText>
            </DivSpaceContainer>
          </ContainerComprovante>
        </PageStyle>
      ) : null}
    </>
  )
})
