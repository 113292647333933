export const editRelativeToApi = user => {
  const newCurrency = user.individualIncomeEdit.split('.').join('')
  const newCurrencyFinal = newCurrency.split(',').join('.')
  return {
    birthdate: user.birthdateEdit,
    cpf: user.cpfEdit,
    degreeKinship: user.degreeKinshipEdit,
    individualIncome: newCurrencyFinal,
    name: user.nameEdit,
    phoneNumber: user.phoneNumberEdit,
  }
}
