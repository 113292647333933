/* eslint-disable react/prop-types */
import * as Yup from 'yup'

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'

import { isFunction } from 'lodash'

import { yupResolver } from '@hookform/resolvers/yup'

import FormLoader from './fragments/FormLoader'
import { CustomForm } from './style'

const formSchemaDefault = Yup.object().shape({})

const Form = ({
  schema = formSchemaDefault,
  onSubmit,
  style,
  maxWidth,
  children,
  formMethods: formMethodsProp,
  defaultValues = {},
}) => {
  const active = useRef(false)
  const formRef = useRef(null)
  const [isLoading, seIsLoading] = useState(false)

  useEffect(() => {
    active.current = true

    return () => {
      active.current = false
    }
  })

  const wrappedSchema = yupResolver(schema)
  const defaultFormMethods = useForm({
    defaultValues,
    resolver: wrappedSchema,
    validationContext: {
      abortEarly: false,
    },
  })

  const formMethods = formMethodsProp || defaultFormMethods
  const { handleSubmit, reset } = formMethods

  const onSubmitHandler = useCallback(
    async data => {
      seIsLoading(true)

      try {
        await onSubmit(data, reset)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }

      if (active?.current) seIsLoading(false)
    },
    [onSubmit, reset]
  )

  return (
    <>
      {isLoading ? <FormLoader /> : null}

      <FormProvider {...formMethods}>
        <CustomForm
          ref={formRef}
          style={style}
          onSubmit={handleSubmit(onSubmitHandler)}
          maxWidth={maxWidth}
          noValidate
          autoComplete="off"
        >
          {isFunction(children) ? children(formMethods) : children}
        </CustomForm>
      </FormProvider>
    </>
  )
}

export default Form
