import { FONT_FAMILY } from 'constants/fonts'
import styled from 'styled-components'
export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 100px;
`

export const Data = styled.div`
  border-bottom: 1px solid #cccccc;
  margin: 2.4375rem 0;
  height: 5rem;
  h3 {
    font-size: 1.5rem;
  }
  display: flex;
  align-items: center;
`

export const ContainerBgColor = styled.div`
  background: #fafafa; ;
`

export const Button = styled.button`
  z-index: 99;
  outline: none;
  border: none;
  &.next {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 600;
    font-size: 16.8px;
    line-height: 23px;
    color: #ffffff;

    padding: 8px 24px;

    background: #1351b4;
    border-radius: 20px;

    max-width: 100%;
    width: 127px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all ease 0.2s;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    position: absolute;
    bottom: -60px;
    right: 15px;
  }

  &.prev {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 600;
    font-size: 16.8px;
    line-height: 23px;

    color: #1351b4;
    background: none;

    border: 1px solid #1351b4;
    border-radius: 20px;
    padding: 8px 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all ease 0.2s;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    position: absolute;
    bottom: -60px;
    left: 15px;
  }
`
