import { useContext, useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import InputMask from 'react-input-mask'

import { FaUpload } from 'react-icons/fa'
import {
  Button,
  DivSection,
  Input,
  RadioButton,
  RadioButtonContainer,
  SelectInputGroup,
  SendFile,
  Wrapper,
} from './styles'

import { MessageBox } from '../MessageBox'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { TextInputRequired } from '../TextInputRequired'
import { awsTokenManager } from 'config/auth'
import axios from 'axios'
import { LoadingFixedScreen } from '../styles'
import { schoolToApi } from '../adapters/schoolToApi'
import FormContext3 from '../formContext3'
import { ErrorSpan } from '../StepOne/styles'

export function StepThree() {
  const [files, setFiles] = useState([])

  const getFiles = async () => {
    const token = await awsTokenManager.getToken()
    const config = {
      headers: { Authorization: `${token}` },
    }

    const getUser = await axios.get(
      `${process.env.REACT_APP_API_URL}/users-education?name=${cpf}`,
      config
    )

    if (getUser.data.scholarshipFiles) {
      setFiles([...getUser.data.scholarshipFiles])
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    setFormContent3(false)
    getFiles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function handleAddFile(e) {
    const f = e.target.files

    // console.log(f)

    for (let count = 0; count < f.length; count++) {
      // console.log(f[count])
      await getBase64(f[count], f[count].name)
    }
  }
  const getBase64StringFromDataURL = dataURL =>
    dataURL.replace('data:', '').replace(/^.+,/, '')

  function getBase64(file, fileName = 'none') {
    return new Promise((resolve, reject) => {
      console.log('chegouuuuu', fileName)
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(
          setFiles([
            ...files,
            {
              content: getBase64StringFromDataURL(reader.result),
              fileLink: '',
              fileName: fileName,
            },
          ])
        )
      }
    })
  }

  const [schoolCountry, setSchoolCountry] = useState('Brasil')
  const [schoolType, setSchoolType] = useState('Selecione aqui')
  const [courseType, setCourseType] = useState('Selecione o curso')
  const [schoolGrade, setSchoolGrade] = useState('Selecione o grau')
  const [schoolName, setSchoolName] = useState('Selecione a instituição')

  function handleSetCountry(countryName) {
    setSchoolCountry(countryName)
  }
  function handleSetSchoolType(schoolName) {
    set_school_type_error(false)
    setSchoolType(schoolName)
  }
  function handleSetCourse(courseName) {
    set_course_choose_error(false)
    setCourseType(courseName)
  }
  function handleSetGrade(gradeName) {
    set_school_grade_error(false)
    setSchoolGrade(gradeName)
  }
  function handleSetInstitution(institutionName) {
    set_institution_error(false)
    setSchoolName(institutionName)
  }

  const [loaded, setLoaded] = useState(false)
  const [bolsa, setBolsa] = useState(false)
  const [conclusionYear, setConclusionYear] = useState('')
  const [brazilianSchoolFlag, setBrazilianSchoolFlag] = useState(true)
  const [professorFlag, setProfessorFlag] = useState(false)
  const [academicFlag, setAcademicFlag] = useState(false)
  const [superiorFlag, setSuperiorFlag] = useState(false)
  const [publicFlag, setPublicFlag] = useState(false)
  const [showErrorYear, setShowErrorYear] = useState(false)
  const [school_type_error, set_school_type_error] = useState(false)
  const [course_choose_error, set_course_choose_error] = useState(false)
  const [school_grade_error, set_school_grade_error] = useState(false)
  const [institution_error, set_institution_error] = useState(false)
  const [school_country_error, set_school_country_error] = useState(false)
  const [year_required_error, set_year_required_error] = useState(false)

  const [cpf, setCpf] = useState('')
  const [firstPost, setFirstPost] = useState(false)

  const onInputChange = async event => {
    const inputName = event.target.name
    const inputValue = event.target.value

    if (inputName === 'year') {
      set_year_required_error(false)
      if (parseInt(inputValue) <= 2022) {
        setShowErrorYear(false)
        setConclusionYear(inputValue)
        if (parseInt(inputValue) >= 1900) {
          setShowErrorYear(false)
          setConclusionYear(inputValue)
        } else {
          setShowErrorYear(true)
        }
      } else {
        setShowErrorYear(true)
      }
    }
  }

  useEffect(() => {
    const getUserMe = async () => {
      try {
        const token = await awsTokenManager.getToken()
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        }
        let data = await axios
          .get(`${process.env.REACT_APP_API_URL}/users/me`, config)
          .then(({ data }) => data)

        setCpf(data.cpf)

        try {
          const config = {
            headers: { Authorization: `${token}` },
          }

          let data = await axios
            .get(
              `${process.env.REACT_APP_API_URL}/users-education?name=${cpf}`,
              config
            )
            .then(({ data }) => data)

          if (data.length !== 0) {
            setConclusionYear(data.conclusionYear)
            setBrazilianSchoolFlag(data.brazilianSchoolFlag)
            if (data.brazilianSchoolFlag) {
              setSchoolCountry('Brasil')
            } else {
              setSchoolCountry(data.schoolCountry)
            }
            setSchoolType(data.schoolType)
            setProfessorFlag(data.professorFlag)
            setAcademicFlag(data.academicFlag)
            setSuperiorFlag(data.superiorFlag)
            setPublicFlag(data.publicFlag)
            setCourseType(data.courseType)
            setSchoolName(data.schoolName)
            setSchoolGrade(data.schoolGrade)
          } else {
            setFirstPost(true)
          }
          //console.log("dados step 3: ",data);
          setLoaded(true)
        } catch (err) {
          console.log(err)
        }
      } catch (err) {
        console.log(err)
      }
    }

    getUserMe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf])

  const handleForm3 = async data => {
    data.preventDefault()
    setLoaded(false)
    if (firstPost === true) {
      try {
        const userMapped = schoolToApi({
          conclusionYear,
          brazilianSchoolFlag,
          schoolCountry,
          schoolType,
          professorFlag,
          academicFlag,
          superiorFlag,
          publicFlag,
          courseType,
          schoolName,
          schoolGrade,
        })

        // userMapped.scholarshipFiles = files

        console.log('-------------->', userMapped)
        const token = await awsTokenManager.getToken()
        const config = {
          headers: { Authorization: `${token}` },
        }

        await axios.post(
          `${process.env.REACT_APP_API_URL}/users-education?name=${cpf}`,
          { ...userMapped, scholarshipFiles: files },
          config
        )
        setFormContent3(true)
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        const userMapped = schoolToApi({
          conclusionYear,
          brazilianSchoolFlag,
          schoolCountry,
          schoolType,
          professorFlag,
          academicFlag,
          superiorFlag,
          publicFlag,
          courseType,
          schoolName,
          schoolGrade,
        })

        const token = await awsTokenManager.getToken()
        const config = {
          headers: { Authorization: `${token}` },
        }
        await axios.put(
          `${process.env.REACT_APP_API_URL}/users-education?name=${cpf}`,
          { ...userMapped, scholarshipFiles: files },
          config
        )
        setFormContent3(true)
      } catch (err) {
        console.log(err)
      }
    }
  }

  useEffect(() => {
    if (schoolType === 'Privada com bolsa integral') {
      setBolsa(true)
    } else {
      setBolsa(false)
    }
  }, [schoolType])

  // eslint-disable-next-line no-unused-vars
  const { formContent3, setFormContent3 } = useContext(FormContext3)

  // const [showButton, setShowButton] = useState(false)

  // useEffect(() => {
  //   if (conclusionYear.trim().length !== 0) {
  //     setShowButton(true)
  //   } else {
  //     setShowButton(false)
  //   }
  // }, [conclusionYear])

  function handleValidationFocus() {
    // if (type === 'inputType') {
    //   document.getElementById(e).scrollIntoView({ block: 'center' });
    //   console.log('entrou no primeiro if');
    // } else if (type === 'dropdownType') {
    //   document.getElementById(e).scrollIntoView({ block: 'center' });
    //   console.log('entrou no segundo if ixo');
    // }
    document.getElementById('formtoscroll').scrollIntoView()
  }

  return (
    <>
      {loaded ? null : (
        <>
          <LoadingFixedScreen data-test="LoadingFixedScreen">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </LoadingFixedScreen>{' '}
        </>
      )}
      <Wrapper data-test="confirmation-data-steps-Wrapper">
        <MessageBox
          main_title="Confirme seus dados escolares"
          description={
            <>
              Agora vamos confirmar alguns dados relacionados aos seus dados
              escolares
            </>
          }
        />

        <form id="form3" onSubmit={handleForm3}>
          <DivSection
            className="row"
            data-test="confirmation-data-steps-DivSection"
          >
            <div className="col-md-6 " id="formtoscroll">
              <div className="row">
                <div className="col-md-6">
                  <TextInputRequired
                    title="Ano de conclusão do ensino médio"
                    errorMessage="Ano de conclusão deve ser menor que o ano do Enem."
                    showErrorMessage={false}
                  />

                  {loaded ? (
                    <>
                      <InputMask
                        data-test="confirmation-data-steps-input"
                        type="text"
                        placeholder="2022"
                        name="year"
                        defaultValue={conclusionYear}
                        mask="9999"
                        required
                        onChange={onInputChange}
                        onInvalid={() => {
                          handleValidationFocus()
                          set_year_required_error(true)
                        }}
                      />
                      {showErrorYear && (
                        <>
                          <ErrorSpan>
                            Ano de conclusão deve ser menor que o ano do Enem.
                          </ErrorSpan>
                        </>
                      )}
                      {year_required_error && (
                        <>
                          <ErrorSpan>Campo Obrigatório</ErrorSpan>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <InputMask
                        data-test="confirmation-data-steps-input"
                        type="text"
                        placeholder="2008"
                        name="year"
                        defaultValue={conclusionYear}
                        required
                        onChange={onInputChange}
                      />
                    </>
                  )}
                  <p className="infoText">Concluiu o ensino médio no Brasil?</p>
                  <RadioButtonContainer
                    className="col-md-12"
                    data-test="confirmation-data-steps-RadioButtonContainer"
                  >
                    {loaded ? (
                      <>
                        <RadioButton data-test="confirmation-data-steps-RadioButton">
                          <input
                            type="radio"
                            name="radio1"
                            id="radio1"
                            onChange={() => setBrazilianSchoolFlag(false)}
                            defaultChecked={!brazilianSchoolFlag}
                          />
                          <label htmlFor="radio1">Não</label>
                        </RadioButton>

                        <RadioButton data-test="confirmation-data-steps-RadioButton">
                          <input
                            type="radio"
                            name="radio1"
                            id="radio2"
                            onChange={() => {
                              setBrazilianSchoolFlag(true)
                              setSchoolCountry('Brasil')
                            }}
                            defaultChecked={brazilianSchoolFlag}
                          />
                          <label htmlFor="radio2">Sim</label>
                        </RadioButton>
                      </>
                    ) : null}
                  </RadioButtonContainer>
                </div>
              </div>
              {brazilianSchoolFlag ? null : (
                <>
                  <div className="col-md-12 noPadding">
                    <p className="selectText">
                      Em qual país você concluiu o ensino médio?
                    </p>
                    <SelectInputGroup data-test="confirmation-data-steps-SelectInputGroup">
                      {schoolCountry === 'Selecione o país' ? (
                        <>
                          <input
                            type="text"
                            className="hiddenInput"
                            name="hiddenInput"
                            required
                            onInvalid={() => {
                              set_school_country_error(true)
                              handleValidationFocus()
                            }}
                          />
                        </>
                      ) : null}

                      <DropdownButton
                        className="dropPersonalized"
                        title={schoolCountry}
                        data-test="confirmation-data-steps-DropdownButton"
                      >
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Afeganistão')
                          }}
                        >
                          Afeganistão
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('África do Sul')
                          }}
                        >
                          África do Sul
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Albânia')
                          }}
                        >
                          Albânia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Alemanha')
                          }}
                        >
                          Alemanha
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Andorra')
                          }}
                        >
                          Andorra
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Angola')
                          }}
                        >
                          Angola
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Anguilla')
                          }}
                        >
                          Anguilla
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Antilhas Holandesas')
                          }}
                        >
                          Antilhas Holandesas
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Antárctida')
                          }}
                        >
                          Antárctida
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Antígua e Barbuda')
                          }}
                        >
                          Antígua e Barbuda
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Argentina')
                          }}
                        >
                          Argentina
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Argélia')
                          }}
                        >
                          Argélia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Armênia')
                          }}
                        >
                          Armênia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Aruba')
                          }}
                        >
                          Aruba
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Austrália')
                          }}
                        >
                          Austrália
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Áustria')
                          }}
                        >
                          Áustria
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Azerbaijão')
                          }}
                        >
                          Azerbaijão
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Bahamas')
                          }}
                        >
                          Bahamas
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Bahrein')
                          }}
                        >
                          Bahrein
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Bangladesh')
                          }}
                        >
                          Bangladesh
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Barbados')
                          }}
                        >
                          Barbados
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Belize')
                          }}
                        >
                          Belize
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Benim')
                          }}
                        >
                          Benim
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Bielorrússia')
                          }}
                        >
                          Bielorrússia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Bolívia')
                          }}
                        >
                          Bolívia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Botswana')
                          }}
                        >
                          Botswana
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Brasil')
                          }}
                        >
                          Brasil
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Brunei')
                          }}
                        >
                          Brunei
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Bulgária')
                          }}
                        >
                          Bulgária
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Burkina Faso')
                          }}
                        >
                          Burkina Faso
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Burundi')
                          }}
                        >
                          Burundi
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Butão')
                          }}
                        >
                          Butão
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Bósnia e Herzegovina')
                          }}
                        >
                          Bósnia e Herzegovina
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Cabo Verde')
                          }}
                        >
                          Cabo Verde
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Camarões')
                          }}
                        >
                          Camarões
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Camboja')
                          }}
                        >
                          Camboja
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Canadá')
                          }}
                        >
                          Canadá
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Catar')
                          }}
                        >
                          Catar
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Chade')
                          }}
                        >
                          Chade
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Colômbia')
                          }}
                        >
                          Colômbia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Comores')
                          }}
                        >
                          Comores
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Coreia do Norte')
                          }}
                        >
                          Coreia do Norte
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Coreia do Sul')
                          }}
                        >
                          Coreia do Sul
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Costa do Marfim')
                          }}
                        >
                          Costa do Marfim
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Costa Rica')
                          }}
                        >
                          Costa Rica
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Croácia')
                          }}
                        >
                          Croácia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Cuba')
                          }}
                        >
                          Cuba
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Djibouti')
                          }}
                        >
                          Djibouti
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Dominica')
                          }}
                        >
                          Dominica
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Egito')
                          }}
                        >
                          Egito
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Emirados Árabes Unidos')
                          }}
                        >
                          Emirados Árabes Unidos
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Equador')
                          }}
                        >
                          Equador
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Eritreia')
                          }}
                        >
                          Eritreia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Escócia')
                          }}
                        >
                          Escócia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Eslováquia')
                          }}
                        >
                          Eslováquia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Eslovênia')
                          }}
                        >
                          Eslovênia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Espanha')
                          }}
                        >
                          Espanha
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Estados Federados da Micronésia')
                          }}
                        >
                          Estados Federados da Micronésia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Estados Unidos')
                          }}
                        >
                          Estados Unidos
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Estônia')
                          }}
                        >
                          Estônia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Etiópia')
                          }}
                        >
                          Etiópia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Fiji')
                          }}
                        >
                          Fiji
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Finlândia')
                          }}
                        >
                          Finlândia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('França')
                          }}
                        >
                          França
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Gabão')
                          }}
                        >
                          Gabão
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Gibraltar')
                          }}
                        >
                          Gibraltar
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Granada')
                          }}
                        >
                          Granada
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Gronelândia')
                          }}
                        >
                          Gronelândia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Grécia')
                          }}
                        >
                          Grécia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Guadalupe')
                          }}
                        >
                          Guadalupe
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Guam')
                          }}
                        >
                          Guam
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Guernesei')
                          }}
                        >
                          Guernesei
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Guiana')
                          }}
                        >
                          Guiana
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Guiana Francesa')
                          }}
                        >
                          Guiana Francesa
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Guiné')
                          }}
                        >
                          Guiné
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Guiné-Bissau')
                          }}
                        >
                          Guiné-Bissau
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Gâmbia')
                          }}
                        >
                          Gâmbia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Haiti')
                          }}
                        >
                          Haiti
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Hong Kong')
                          }}
                        >
                          Hong Kong
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Hungria')
                          }}
                        >
                          Hungria
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Ilha Bouvet')
                          }}
                        >
                          Ilha Bouvet
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Ilha de Man')
                          }}
                        >
                          Ilha de Man
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Ilha do Natal')
                          }}
                        >
                          Ilha do Natal
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Ilha Heard e Ilhas McDonald')
                          }}
                        >
                          Ilha Heard e Ilhas McDonald
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Ilha Norfolk')
                          }}
                        >
                          Ilha Norfolk
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Ilhas Cayman')
                          }}
                        >
                          Ilhas Cayman
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Ilhas Cocos (Keeling)')
                          }}
                        >
                          Ilhas Cocos (Keeling)
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Ilhas Cook')
                          }}
                        >
                          Ilhas Cook
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Ilhas Feroé')
                          }}
                        >
                          Ilhas Feroé
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry(
                              'Ilhas Geórgia do Sul e Sandwich do Sul'
                            )
                          }}
                        >
                          Ilhas Geórgia do Sul e Sandwich do Sul
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Ilhas Malvinas')
                          }}
                        >
                          Ilhas Malvinas
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Ilhas Marshall')
                          }}
                        >
                          Ilhas Marshall
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry(
                              'Ilhas Menores Distantes dos Estados Unidos'
                            )
                          }}
                        >
                          Ilhas Menores Distantes dos Estados Unidos
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Ilhas Salomão')
                          }}
                        >
                          Ilhas Salomão
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Ilhas Virgens Americanas')
                          }}
                        >
                          Ilhas Virgens Americanas
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Ilhas Virgens Britânicas')
                          }}
                        >
                          Ilhas Virgens Britânicas
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Ilhas Åland')
                          }}
                        >
                          Ilhas Åland
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Indonésia')
                          }}
                        >
                          Indonésia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Inglaterra')
                          }}
                        >
                          Inglaterra
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Índia')
                          }}
                        >
                          Índia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Irlanda do Norte')
                          }}
                        >
                          Irlanda do Norte
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Irlanda')
                          }}
                        >
                          Irlanda
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Irã')
                          }}
                        >
                          Irã
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Israel')
                          }}
                        >
                          Israel
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Itália')
                          }}
                        >
                          Itália
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Iêmen')
                          }}
                        >
                          Iêmen
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Japão')
                          }}
                        >
                          Japão
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Jordânia')
                          }}
                        >
                          Jordânia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Kiribati')
                          }}
                        >
                          Kiribati
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Kuwait')
                          }}
                        >
                          Kuwait
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Laos')
                          }}
                        >
                          Laos
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Letônia')
                          }}
                        >
                          Letônia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Libéria')
                          }}
                        >
                          Libéria
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Liechtenstein')
                          }}
                        >
                          Liechtenstein
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Lituânia')
                          }}
                        >
                          Lituânia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Luxemburgo')
                          }}
                        >
                          Luxemburgo
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Líbano')
                          }}
                        >
                          Líbano
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Líbia')
                          }}
                        >
                          Líbia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Madagáscar')
                          }}
                        >
                          Madagáscar
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Malawi')
                          }}
                        >
                          Malawi
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Maldivas')
                          }}
                        >
                          Maldivas
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Mali')
                          }}
                        >
                          Mali
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Marianas Setentrionais')
                          }}
                        >
                          Marianas Setentrionais
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Marrocos')
                          }}
                        >
                          Marrocos
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Martinica')
                          }}
                        >
                          Martinica
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Mauritânia')
                          }}
                        >
                          Mauritânia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Maurícia')
                          }}
                        >
                          Maurícia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Mayotte')
                          }}
                        >
                          Mayotte
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Moldávia')
                          }}
                        >
                          Moldávia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Mongólia')
                          }}
                        >
                          Mongólia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Montenegro')
                          }}
                        >
                          Montenegro
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Montserrat')
                          }}
                        >
                          Montserrat
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Moçambique')
                          }}
                        >
                          Moçambique
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Myanmar')
                          }}
                        >
                          Myanmar
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('México')
                          }}
                        >
                          México
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Mônaco')
                          }}
                        >
                          Mônaco
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Namíbia')
                          }}
                        >
                          Namíbia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Nauru')
                          }}
                        >
                          Nauru
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Nigéria')
                          }}
                        >
                          Nigéria
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Niue')
                          }}
                        >
                          Niue
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Nova Caledônia')
                          }}
                        >
                          Nova Caledônia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Nova Zelândia')
                          }}
                        >
                          Nova Zelândia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Níger')
                          }}
                        >
                          Níger
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Panamá')
                          }}
                        >
                          Panamá
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Papua-Nova Guiné')
                          }}
                        >
                          Papua-Nova Guiné
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Paquistão')
                          }}
                        >
                          Paquistão
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Paraguai')
                          }}
                        >
                          Paraguai
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('País de Gales')
                          }}
                        >
                          País de Gales
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Países Baixos')
                          }}
                        >
                          Países Baixos
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Peru')
                          }}
                        >
                          Peru
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Polinésia Francesa')
                          }}
                        >
                          Polinésia Francesa
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Polônia')
                          }}
                        >
                          Polônia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Porto Rico')
                          }}
                        >
                          Porto Rico
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Portugal')
                          }}
                        >
                          Portugal
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Quirguistão')
                          }}
                        >
                          Quirguistão
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Quênia')
                          }}
                        >
                          Quênia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Reino Unido')
                          }}
                        >
                          Reino Unido
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('República Centro-Africana')
                          }}
                        >
                          República Centro-Africana
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('República Checa')
                          }}
                        >
                          República Checa
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('República Democrática do Congo')
                          }}
                        >
                          República Democrática do Congo
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('República do Congo')
                          }}
                        >
                          República do Congo
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('República Dominicana')
                          }}
                        >
                          República Dominicana
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Reunião')
                          }}
                        >
                          Reunião
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Romênia')
                          }}
                        >
                          Romênia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Ruanda')
                          }}
                        >
                          Ruanda
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Rússia')
                          }}
                        >
                          Rússia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Saara Ocidental')
                          }}
                        >
                          Saara Ocidental
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Saint Martin')
                          }}
                        >
                          Saint Martin
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Saint-Barthélemy')
                          }}
                        >
                          Saint-Barthélemy
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Saint-Pierre e Miquelon')
                          }}
                        >
                          Saint-Pierre e Miquelon
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Samoa Americana')
                          }}
                        >
                          Samoa Americana
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Samoa')
                          }}
                        >
                          Samoa
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Santa Lúcia')
                          }}
                        >
                          Santa Lúcia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Senegal')
                          }}
                        >
                          Senegal
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Serra Leoa')
                          }}
                        >
                          Serra Leoa
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Seychelles')
                          }}
                        >
                          Seychelles
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Singapura')
                          }}
                        >
                          Singapura
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Somália')
                          }}
                        >
                          Somália
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Sri Lanka')
                          }}
                        >
                          Sri Lanka
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Suazilândia')
                          }}
                        >
                          Suazilândia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Sudão')
                          }}
                        >
                          Sudão
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Suécia')
                          }}
                        >
                          Suécia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Suíça')
                          }}
                        >
                          Suíça
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('São Cristóvão e Nevis')
                          }}
                        >
                          São Cristóvão e Nevis
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('São Marino')
                          }}
                        >
                          São Marino
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('São Tomé e Príncipe')
                          }}
                        >
                          São Tomé e Príncipe
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('São Vicente e Granadinas')
                          }}
                        >
                          São Vicente e Granadinas
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Sérvia')
                          }}
                        >
                          Sérvia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Síria')
                          }}
                        >
                          Síria
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Tailândia')
                          }}
                        >
                          Tailândia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Taiwan')
                          }}
                        >
                          Taiwan
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Tanzânia')
                          }}
                        >
                          Tanzânia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry(
                              'Terras Austrais e Antárticas Francesas'
                            )
                          }}
                        >
                          Terras Austrais e Antárticas Francesas
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry(
                              'Território Britânico do Oceano Índico'
                            )
                          }}
                        >
                          Território Britânico do Oceano Índico
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Timor-Leste')
                          }}
                        >
                          Timor-Leste
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Togo')
                          }}
                        >
                          Togo
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Trinidad e Tobago')
                          }}
                        >
                          Trinidad e Tobago
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Tunísia')
                          }}
                        >
                          Tunísia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Turcas e Caicos')
                          }}
                        >
                          Turcas e Caicos
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Turquemenistão')
                          }}
                        >
                          Turquemenistão
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Turquia')
                          }}
                        >
                          Turquia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Tuvalu')
                          }}
                        >
                          Tuvalu
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Ucrânia')
                          }}
                        >
                          Ucrânia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Uganda')
                          }}
                        >
                          Uganda
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Uruguai')
                          }}
                        >
                          Uruguai
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Uzbequistão')
                          }}
                        >
                          Uzbequistão
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Vanuatu')
                          }}
                        >
                          Vanuatu
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Vaticano')
                          }}
                        >
                          Vaticano
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Venezuela')
                          }}
                        >
                          Venezuela
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Vietname')
                          }}
                        >
                          Vietname
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Wallis e Futuna')
                          }}
                        >
                          Wallis e Futuna
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Zimbabwe')
                          }}
                        >
                          Zimbabwe
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            handleSetCountry('Zâmbia')
                          }}
                        >
                          Zâmbia
                        </Dropdown.Item>
                      </DropdownButton>

                      {school_country_error && (
                        <>
                          <ErrorSpan data-test="confirmation-data-steps-span">
                            Campo obrigatório
                          </ErrorSpan>
                        </>
                      )}
                    </SelectInputGroup>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-12 noPadding">
              <div className="row ">
                <div className="col-md-6">
                  <p className="selectText">
                    Qual tipo de escola o candidato cursou o ensino médio?{' '}
                  </p>
                  <SelectInputGroup
                    className="paddingBottom"
                    data-test="confirmation-data-steps-SelectInputGroup"
                  >
                    {schoolType === 'Selecione aqui' ? (
                      <>
                        <input
                          type="text"
                          className="hiddenInput"
                          name="hiddenInput"
                          required
                          onInvalid={() => {
                            set_school_type_error(true)
                            handleValidationFocus()
                          }}
                        />
                      </>
                    ) : null}
                    <DropdownButton
                      data-test="confirmation-data-steps-DropdownButton"
                      className="dropPersonalized"
                      title={schoolType}
                    >
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetSchoolType('Integral privada')
                        }}
                      >
                        Integral privada
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetSchoolType('Integral pública')
                        }}
                      >
                        Integral pública
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetSchoolType('Parcial (pública/privada)')
                        }}
                      >
                        Parcial (pública/privada)
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetSchoolType('Privada com bolsa integral')
                        }}
                      >
                        Privada com bolsa integral
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetSchoolType('Privada com bolsa parcial')
                        }}
                      >
                        Privada com bolsa parcial
                      </Dropdown.Item>
                    </DropdownButton>
                    {school_type_error && (
                      <>
                        <ErrorSpan data-test="confirmation-data-steps-span">
                          Campo obrigatório
                        </ErrorSpan>
                      </>
                    )}
                  </SelectInputGroup>
                </div>
                {bolsa ? (
                  <>
                    <div className="col-md-3">
                      <SendFile
                        className="sendFile"
                        data-test="confirmation-data-steps-SendFile"
                      >
                        <p className="selectText">Comprovante de bolsa</p>
                        <label htmlFor="sendFile">
                          <FaUpload />
                          {files && files.length > 0
                            ? `Arquivos Enviados: ${files.length}`
                            : ' Selecione o(s) arquivos(s)'}
                        </label>
                        <Input
                          type="file"
                          name="sendFile"
                          id="sendFile"
                          multiple={true}
                          onChange={handleAddFile}
                        />
                      </SendFile>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </DivSection>
          <DivSection
            className="row"
            data-test="confirmation-data-steps-DivSection"
          >
            <div className="col-md-6">
              <p className="infoText">
                O candidato é professor da rede pública de ensino, no efetivo
                exercício do magistério da educação básica, integrando o quadro
                de pessoal permanente?
              </p>
              <RadioButtonContainer
                className="col-md-12"
                data-test="confirmation-data-steps-RadioButtonContainer"
              >
                {loaded ? (
                  <>
                    <RadioButton data-test="confirmation-data-steps-RadioButton">
                      <input
                        type="radio"
                        name="radio2"
                        id="radio3"
                        onChange={() => setProfessorFlag(false)}
                        defaultChecked={!professorFlag}
                      />
                      <label htmlFor="radio1">Não</label>
                    </RadioButton>

                    <RadioButton data-test="confirmation-data-steps-RadioButton">
                      <input
                        type="radio"
                        name="radio2"
                        id="radio4"
                        onChange={() => setProfessorFlag(true)}
                        defaultChecked={professorFlag}
                      />
                      <label htmlFor="radio2">Sim</label>
                    </RadioButton>
                  </>
                ) : null}
              </RadioButtonContainer>
            </div>
            <div className="col-md-6">
              <p className="infoText">
                O candidato possui vínculo acadêmico (matrícula ativa ou
                trancada) com instituição de ensino superior pública e gratuita?{' '}
              </p>
              <RadioButtonContainer
                className="col-md-12 paddingBottom"
                data-test="confirmation-data-steps-RadioButtonContainer"
              >
                {loaded ? (
                  <>
                    <RadioButton data-test="confirmation-data-steps-RadioButton">
                      <input
                        type="radio"
                        name="radio3"
                        id="radio5"
                        onChange={() => setAcademicFlag(false)}
                        defaultChecked={!academicFlag}
                      />
                      <label htmlFor="radio1">Não</label>
                    </RadioButton>

                    <RadioButton data-test="confirmation-data-steps-RadioButton">
                      <input
                        type="radio"
                        name="radio3"
                        id="radio6"
                        onChange={() => setAcademicFlag(true)}
                        defaultChecked={academicFlag}
                      />
                      <label htmlFor="radio2">Sim</label>
                    </RadioButton>
                  </>
                ) : null}
              </RadioButtonContainer>
            </div>
          </DivSection>
          <DivSection
            className="row"
            data-test="confirmation-data-steps-DivSection"
          >
            <div className="col-md-6">
              <p className="infoText">Concluiu o ensino superior?</p>
              <RadioButtonContainer
                data-test="confirmation-data-steps-RadioButtonContainer"
                className="col-md-12 "
              >
                {loaded ? (
                  <>
                    <RadioButton data-test="confirmation-data-steps-RadioButton">
                      <input
                        type="radio"
                        name="radio4"
                        id="radio7"
                        onChange={() => setSuperiorFlag(false)}
                        defaultChecked={!superiorFlag}
                      />
                      <label htmlFor="radio1">Não</label>
                    </RadioButton>

                    <RadioButton data-test="confirmation-data-steps-RadioButton">
                      <input
                        type="radio"
                        name="radio4"
                        id="radio8"
                        onChange={() => setSuperiorFlag(true)}
                        defaultChecked={superiorFlag}
                      />
                      <label htmlFor="radio2">Sim</label>
                    </RadioButton>
                  </>
                ) : null}
              </RadioButtonContainer>
            </div>
            <div className="col-md-6">
              <p className="infoText">Cursou em instituição pública?</p>
              <RadioButtonContainer
                data-test="confirmation-data-steps-RadioButtonContainer"
                className="col-md-12"
              >
                {loaded ? (
                  <>
                    <RadioButton data-test="confirmation-data-steps-RadioButton">
                      <input
                        type="radio"
                        name="radio5"
                        id="radio9"
                        onChange={() => setPublicFlag(false)}
                        defaultChecked={!publicFlag}
                      />
                      <label htmlFor="radio1">Não</label>
                    </RadioButton>

                    <RadioButton data-test="confirmation-data-steps-RadioButton">
                      <input
                        type="radio"
                        name="radio5"
                        id="radio10"
                        onChange={() => setPublicFlag(true)}
                        defaultChecked={publicFlag}
                      />
                      <label htmlFor="radio2">Sim</label>
                    </RadioButton>
                  </>
                ) : null}
              </RadioButtonContainer>
            </div>
            {superiorFlag ? (
              <>
                <div className="col-md-6">
                  <p className="selectText">Qual o curso?</p>
                  <SelectInputGroup data-test="confirmation-data-steps-SelectInputGroup">
                    {courseType === 'Selecione o curso' ? (
                      <>
                        <input
                          type="text"
                          className="hiddenInput"
                          name="hiddenInput"
                          required
                          onInvalid={() => {
                            set_course_choose_error(true)
                            handleValidationFocus()
                          }}
                        />
                      </>
                    ) : null}
                    <DropdownButton
                      data-test="confirmation-data-steps-DropdownButton"
                      className="dropPersonalized"
                      title={courseType}
                    >
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetCourse('Curso 1')
                        }}
                      >
                        Curso 1
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetCourse('Curso 2')
                        }}
                      >
                        Curso 2
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetCourse('Curso 3')
                        }}
                      >
                        Curso 3
                      </Dropdown.Item>
                    </DropdownButton>
                    {course_choose_error && (
                      <>
                        <ErrorSpan data-test="confirmation-data-steps-span">
                          Campo obrigatório
                        </ErrorSpan>
                      </>
                    )}
                  </SelectInputGroup>
                </div>
                <div className="col-md-6">
                  <p className="selectText">Qual grau?</p>
                  <SelectInputGroup data-test="confirmation-data-steps-SelectInputGroup">
                    {schoolGrade === 'Selecione o grau' ? (
                      <>
                        <input
                          type="text"
                          className="hiddenInput"
                          name="hiddenInput"
                          required
                          onInvalid={() => {
                            set_school_grade_error(true)
                            handleValidationFocus()
                          }}
                        />
                      </>
                    ) : null}
                    <DropdownButton
                      data-test="confirmation-data-steps-DropdownButton"
                      className="dropPersonalized"
                      title={schoolGrade}
                    >
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetGrade('Bacharelado')
                        }}
                      >
                        Bacharelado
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetGrade('Licenciatura')
                        }}
                      >
                        Licenciatura
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetGrade('ABI (Área Básica e Ingresso)')
                        }}
                      >
                        ABI (Área Básica e Ingresso)
                      </Dropdown.Item>

                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetGrade('Tecnólogo')
                        }}
                      >
                        Tecnólogo
                      </Dropdown.Item>
                    </DropdownButton>

                    {school_grade_error && (
                      <>
                        <ErrorSpan data-test="confirmation-data-steps-span">
                          Campo obrigatório
                        </ErrorSpan>
                      </>
                    )}
                  </SelectInputGroup>
                </div>
              </>
            ) : null}
            {publicFlag ? (
              <>
                <div className="col-md-6">
                  <p className="selectText">Nome da Instituição</p>
                  <SelectInputGroup
                    data-test="confirmation-data-steps-SelectInputGroup"
                    className="paddingBottom"
                  >
                    {schoolName === 'Selecione a instituição' ? (
                      <>
                        <input
                          type="text"
                          className="hiddenInput"
                          name="hiddenInput"
                          required
                          onInvalid={() => {
                            set_institution_error(true)
                            handleValidationFocus()
                          }}
                        />
                      </>
                    ) : null}
                    <DropdownButton
                      data-test="confirmation-data-steps-DropdownButton"
                      className="dropPersonalized"
                      title={schoolName}
                    >
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetInstitution('Instituição 1')
                        }}
                      >
                        Instituição 1
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetInstitution('Instituição 2')
                        }}
                      >
                        Instituição 2
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetInstitution('Instituição 3')
                        }}
                      >
                        Instituição 3
                      </Dropdown.Item>
                    </DropdownButton>
                    {institution_error && (
                      <>
                        <ErrorSpan data-test="confirmation-data-steps-span">
                          Campo obrigatório
                        </ErrorSpan>
                      </>
                    )}
                  </SelectInputGroup>
                </div>
              </>
            ) : null}
          </DivSection>

          <Button
            data-test="confirmation-data-steps-ButtonNext"
            className="next"
            type="submit"
            form="form3"
            defaultValue="Submit"
          >
            Confirmar
          </Button>
        </form>
      </Wrapper>
    </>
  )
}
