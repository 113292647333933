import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { PUBLIC_ROUTES } from './routes'

const PublicRoutes = () => (
  <Switch>
    {PUBLIC_ROUTES.map(route => (
      <Route key={route.path} {...route} />
    ))}
  </Switch>
)

export default PublicRoutes
