import styled from 'styled-components'
export const TitleSearch = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  margin-top: 5rem;
  font-weight: 500;
`

export const Check = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* input {
    width: 100%;
    height: 100%;
  } */
`

export const ContainerFilterBy = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 1.5rem;
`

export const Checks = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
export const InputGroupDiv = styled.div`
  position: relative;

  p {
    margin: 0 0 4px 0;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: var(--colorBlack);
  }

  label {
    font-size: 1.125rem;
    color: #5c5c5c;
    font-weight: 400;
  }
  input {
    height: 51px;
    width: 100%;
    padding-left: 12px;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: var(--colorBlack);

    &::placeholder {
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: var(--colorBlack);

      opacity: 0.5;
    }

    /* &:not(:placeholder-shown) {
      background: #f3f3f3;
      border: 1px solid #cccccc;
      border-radius: 4px;
    } */
  }
`

export const DivSpace = styled.div`
  background: #fafafa;
  height: 100%;

  /* padding-bottom: 2rem; */

  .input-range__label-container {
    display: none !important;
  }

  .input-range__slider {
    background-color: #fff !important;
    border: 2px solid ${props => props.theme.bgColors.primaryDefault};
  }

  .input-range__track--active {
    background-color: ${props => props.theme.bgColors.primaryDefault};
  }
`

export const SearchButton = styled.div`
  margin-top: 2rem;

  button {
    min-width: 140px;
    background: ${props => props.theme.bgColors.primaryDefault};
    color: ${props => props.theme.bgColors.colorSecondary01};
    border-radius: 20px;
    border: 0;
    padding: 0.5rem 1.5rem;
    font-weight: 600;
    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const Options = styled.ul`
  z-index: 9999999999999999999;
  position: absolute;
  bottom: -11rem;
  background: ${props => props.theme.bgColors.colorSecondary01};
  left: 0;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 1rem;
  height: 9.375rem;
  overflow-y: scroll;

  li {
    cursor: pointer;
    width: 100%;
  }
`

export const TitleFilter = styled.p`
  font-size: 1.25rem;
  margin-top: 1rem;
  font-weight: 500;
  padding: 0;
`

export const SubTitle = styled.p`
  font-size: 1rem;
  margin: 0;
  padding: 0;
`

export const ContainerCheck = styled.div`
  margin-top: 1rem;
`

export const ClearFilter = styled.div`
  margin-top: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  p {
    font-weight: 600;
    color: ${props => props.theme.bgColors.primaryDefault};
    margin-top: 2rem;

    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
`

export const ResultsBox = styled.div`
  background: #f3f3f3;
  height: 105px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 1.5rem;
    padding: 0;
    margin: 0;
    color: #5c5c5c;
  }
`

export const TitleCourse = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: 400;
`

export const NotesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const DivRow = styled.div`
  width: 1140px;
  max-width: 100%;
  padding: 0;
  margin-right: 15px;
  margin-left: 15px;

  position: relative;

  @media (max-width: 900px) {
    padding: 15px;
  }
  @media (max-width: 575px) {
    padding: 0;
    margin: 0;
  }
`
