import React, { useEffect, useState } from 'react'
import { useLoading } from '../../hooks/useLoading'

import wrapper from 'components/Wrapper'

import { SISU_FOLLOW_REGISTRATION_ROUTES } from './constants/routes'
import BreadcrumbWrapper from 'components/Breadcrumb'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import homeSvg from './img/homeIcon.svg'
import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

import {
  ContainerStep,
  SubscribeInformation,
  ButtonsStepContainer,
  ConfirmedCourse,
  HashContainer,
  LeftButtons,
  LastCoursesContainer,
} from './style'
import { Container, Row, Col } from 'react-bootstrap'
import { CardConfirmCourse } from './CardConfirmCourse'
import { HeaderSisu } from './HeaderSisu'
import { apiWithToken } from 'services/apiWithToken'
import { LastCoursesCard } from './LastCoursesCard'

const SisuFollowRegistration = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [redirect, setRedirect] = useState(false)
  const [editionId, setEditionId] = useState(0)
  const [stage, setStage] = useState(null)
  const [hash, setHash] = useState('')

  const [rankType, setRankType] = useState('')
  const [options, setOptions] = useState([])
  const [updatedRankDate, setUpdatedRankDate] = useState()
  const [futureRankDate, setFutureRankDate] = useState()
  const [lastCourses, setLastCourses] = useState([])
  const [optionActived, setOptionActived] = useState({
    number: null,
    isClassified: null,
    isInWaitingList: null,
  })

  const [isOpenPeriodSubscribe, setIsOpenPeriodSubscribe] = useState(false)

  const { loading } = useLoading()

  const reload = () => {
    loadRating()
  }

  const loadRating = () => {
    if (!editionId) {
      setHash('')
      setRankType('')
      setOptions([])
      return
    }

    loading.turnOn()
    const getUserRegistration = async () => {
      try {
        const { data } = await apiWithToken.get(
          `/user-registration/edicao/${editionId}/user/rating`
        )

        for (let option of data.options) {
          option.opcao = option.opcao.replace('option', '')
        }

        for (let option of data.options) {
          if (option.isClassified) {
            setOptionActived({
              number: option.opcao,
              isClassified: option.isClassified,
              isInWaitingList: option.isInWaitingList,
            })
            break
          }
        }

        setHash(data.hash)
        setRankType(data.rankType)
        setOptions(data.options)
        setUpdatedRankDate(data.updatedRankDate)
        setFutureRankDate(data.futureRankDate)
      } catch (err) {
        console.error(err)
      } finally {
        loading.turnOff()
      }
    }

    getUserRegistration()
  }

  useEffect(() => {
    const getSchedule = async () => {
      try {
        const { data } = await apiWithToken(
          `/schedule/program?programName=Sisu`
        )

        setEditionId(data.id)
        setIsOpenPeriodSubscribe(data.editions[0].activeSchedule.active)
        setStage(data.editions[0].activeSchedule.stage)
      } catch (err) {
        console.error(err)
      }
    }

    getSchedule()
  }, [])

  useEffect(loadRating, [editionId])

  useEffect(() => {
    if (redirect) {
      window.location.href = '/candidate-page'
    }
  }, [redirect])

  const handleCancelSubscription = async () => {
    loading.turnOn()

    try {
      // const dataOption = options.map(option => {
      //   return option
      //   //  apiWithToken.delete(`/user-registration/${cpf}?courseCode=${option}&program=sisu`)
      // })

      await apiWithToken.delete(`/user-registration/registration/${hash}`)
      // await apiWithToken.delete(
      //   `/user-registration/${cpf}?courseCode=${courseTwo.courseTimeCode}&program=sisu`,
      //   {
      //     params: {
      //       editionId: editionId,
      //     },
      //   }
      // )

      setRedirect(true)
    } catch (err) {
      console.log(err)
    } finally {
      loading.turnOff()
    }
  }

  const [userAverage, setUserAverage] = useState({})

  useEffect(() => {
    async function LoadUserAverage() {
      try {
        let data = await apiWithToken.get(`/users/average`)

        setUserAverage(data.data)
      } catch (err) {
        console.log(err)
      }
    }

    LoadUserAverage()
  }, [])

  useEffect(() => {
    if (editionId) {
      loading.turnOn()
      const getUserLastRank = async () => {
        try {
          const { data } = await apiWithToken.get(
            `/user-registration/rank/past`,
            {
              params: {
                editionId: editionId,
              },
            }
          )
          setLastCourses(data)
          console.log(data)
        } catch (err) {
          console.log(err)
        } finally {
          loading.turnOff()
        }
      }

      getUserLastRank()
    }
  }, [editionId])

  let showNoMoreInThisCouses = () => {
    if (options.length !== lastCourses.length && lastCourses.length) {
      return true
    }

    for (let i = 0; i < options.length; i++) {
      if (options[i].courseTimeId !== lastCourses[i].courseTimeId) {
        return true
      }
    }

    return false
  }

  let formattedDateTime = theDate => {
    if (!theDate) {
      return undefined
    }

    return format(new Date(theDate), "dd/MM/yyyy 'às' HH:mm", {
      locale: ptBR,
    })
  }

  return (
    <>
      <ContainerStep>
        <BreadcrumbWrapper>
          <Link to="/initial-page">
            <img src={homeSvg} alt="" />
          </Link>
          <Typography>Acompanhar inscrição no SISU</Typography>
        </BreadcrumbWrapper>

        <Container>
          <HeaderSisu
            subType="Acompanhar inscrição no SISU"
            daysLeft="1 Dia e 23 horas e 12 minutos"
            note={userAverage.averageScore || ''}
            year={userAverage.year || ''}
          />
        </Container>

        <Container>
          <SubscribeInformation>
            <h1>
              {stage === 'Inscrições Encerradas'
                ? 'Inscrições Encerradas'
                : 'Inscrição Recebida'}
            </h1>
            <p>
              Fique atento aos nosso comunicados para saber o resultado do
              processo de inscrição.
            </p>

            <p className="rankingText">
              O ranking de classificação parcial será publicado dia 23/07/2022
              às 23:59:00
            </p>

            <ButtonsStepContainer>
              <LeftButtons>
                <button onClick={handleCancelSubscription}>
                  Cancelar Inscrição
                </button>
                {isOpenPeriodSubscribe && (
                  <Link to="/change-sisu" className="editSub">
                    Alterar Inscrição
                  </Link>
                )}
              </LeftButtons>

              {hash && (
                <Link
                  to={`/comprovante/${hash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Imprimir comprovante
                </Link>
              )}
            </ButtonsStepContainer>
          </SubscribeInformation>

          <ConfirmedCourse>
            <h2>Cursos Inscritos</h2>

            <Row>
              {options.map(option => (
                <Col md={6}>
                  <CardConfirmCourse
                    courseOptionId={option.courseOptionId}
                    courseData={true}
                    proUni={true}
                    position={option.posicao}
                    minimumGrade={option.notaMinina}
                    totalVacancies={option.numeroVagas}
                    totalSubscribers={option.totalIncricoes}
                    courseOption={option.opcao}
                    courseName={option.curso.nome}
                    courseType={option.curso.grau}
                    courseTime={option.curso.periodo}
                    courseLocation={option.curso.campus}
                    collegeName={option.curso.faculdade}
                    campusNeighborhood={option.curso.cidadeCampus}
                    courseState={option.curso.estadoCampus}
                    modalityType={option.modalidade}
                    isClassified={option.isClassified}
                    isInWaitingList={option.isInWaitingList}
                    waitingListEnterDate={option.waitingListEnterDate}
                    waitingListPosition={option.waitingListPosition}
                    waitingListTotalCandidates={
                      option.waitingListTotalCandidates
                    }
                    rankType={rankType}
                    optionActived={optionActived}
                    callToReload={reload}
                    stage={stage}
                  />
                </Col>
              ))}
            </Row>
          </ConfirmedCourse>
        </Container>
        {lastCourses.length > 0 && (
          <Container>
            <ConfirmedCourse>
              <h2>Opções de cursos anteriores</h2>
            </ConfirmedCourse>
            <LastCoursesContainer>
              <Row>
                {lastCourses.map(course => (
                  <Col md={6}>
                    <LastCoursesCard lastCoursesInformations={course} />
                  </Col>
                ))}
              </Row>
              {showNoMoreInThisCouses() && (
                <p>*Você não está mais concorrendo a estas ofertas de vaga</p>
              )}
            </LastCoursesContainer>
          </Container>
        )}

        <Container>
          <HashContainer>
            {futureRankDate && (
              <h3>
                Próxima atualização do ranking:{' '}
                {formattedDateTime(futureRankDate)}
              </h3>
            )}
            <Link to="candidate-page">Ir para o painel do candidato</Link>
            <p>{hash || ''}</p>
            {updatedRankDate && (
              <p>Última atualização: {formattedDateTime(updatedRankDate)}</p>
            )}
          </HashContainer>
        </Container>
      </ContainerStep>
    </>
  )
}

export default wrapper(SisuFollowRegistration, {
  route: { path: SISU_FOLLOW_REGISTRATION_ROUTES.SISU_FOLLOW_REGISTRATION },
})
