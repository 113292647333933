import styled from 'styled-components'
export const InformationContent = styled.div`
  margin-top: 1rem;
  h3 {
    font-weight: 600;
    color: #585858;
    font-weight: 600;
    font-size: 0.75rem;
  }

  p {
    margin-top: 0.5rem;
    color: ${props => props.theme.bgColors.colorSecondary09};
    font-weight: 400;
    font-size: 0.875rem;
  }

  &.noMarginText {
    p,
    h3 {
      margin: 0;
      padding: 0;
    }
  }
`
