/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import wrapper from 'components/Wrapper'

import { CHANGE_PROUNI_ROUTES } from './constants/routes'

import { Wrapper, LoadingFixedScreen, TextDiv, ButtonsDiv } from './styles'
import { Col, Container, Row } from 'react-bootstrap'
import { HeaderSisu } from 'components/SisuSubscribeForm/HeaderSisu'
import BreadcrumbWrapper from 'components/Breadcrumb'
import { Link } from 'react-router-dom'

import homeSvg from '../../assets/img/breadcrumb/homeIcon.svg'
import { Typography } from '@material-ui/core'
import { MessageBox } from 'components/DataConfirmSteps/MessageBox'
import { awsTokenManager } from 'config/auth'
import axios from 'axios'
import { CardConfirmCourse } from 'components/ProuniSubscribeForm/CardConfirmCourse'
import { apiWithToken } from 'services/apiWithToken'

const ChangeProuni = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [loaded, setLoaded] = useState(false)

  const [cpf, setCpf] = useState('')
  const [apiData1, setApiData1] = useState([])
  const [apiData2, setApiData2] = useState([])

  useEffect(() => {
    const getUserMe = async () => {
      try {
        const token = await awsTokenManager.getToken()
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        }
        let data = await axios
          .get(`${process.env.REACT_APP_API_URL}/users/me`, config)
          .then(({ data }) => data)

        setCpf(data.cpf)

        try {
          const config = {
            headers: { Authorization: `${token}` },
          }

          let data = await axios
            .get(
              `${process.env.REACT_APP_API_URL}/user-registration/${cpf}`,
              config
            )
            .then(({ data }) => data)

          if (data.length !== 0) {
            // eslint-disable-next-line array-callback-return
            data.map(item => {
              MapProgram(item)
            })
            async function MapProgram(item) {
              if (item.program === 'prouni') {
                item.courses.map(item => {
                  MapCoursesOptions(item)
                })
                async function MapCoursesOptions(item) {
                  const courseMap = item.option
                  console.log(courseMap)
                  const courseCode = item.courseName
                  if (courseMap === 'option3') {
                    try {
                      const config = {
                        headers: { Authorization: `${token}` },
                      }

                      let data = await axios
                        .get(`${process.env.REACT_APP_API_URL}/course`, config)
                        .then(({ data }) => data)

                      async function mapCourse1(item) {
                        const courseMap = item.courseTimeCode
                        if (courseMap === courseCode) {
                          setApiData1(item)
                        }
                      }
                      data.map(item => {
                        mapCourse1(item)
                      })
                      setLoaded(true)
                    } catch (err) {
                      console.log(err)
                    }
                  } else {
                    try {
                      const config = {
                        headers: { Authorization: `${token}` },
                      }

                      let data = await axios
                        .get(`${process.env.REACT_APP_API_URL}/course`, config)
                        .then(({ data }) => data)

                      async function mapCourse1(item) {
                        const courseMap = item.courseTimeCode
                        if (courseMap === courseCode) {
                          setApiData2(item)
                        }
                      }
                      data.map(item => {
                        mapCourse1(item)
                      })
                      setLoaded(true)
                    } catch (err) {
                      console.log(err)
                    }
                  }
                }
              } else {
                setLoaded(true)
              }
            }
          } else {
            setLoaded(true)
          }
        } catch (err) {
          console.log(err)
        }
      } catch (err) {
        console.log(err)
      }
    }

    getUserMe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf])

  const [userAverage, setUserAverage] = useState({})

  useEffect(() => {
    async function LoadUserAverage() {
      try {
        let data = await apiWithToken.get(
          `${process.env.REACT_APP_API_URL}/users/average`
        )

        setUserAverage(data.data)
      } catch (err) {
        console.log(err)
      }
    }

    LoadUserAverage()
  }, [])

  return (
    <>
      {loaded ? null : (
        <>
          <LoadingFixedScreen data-test="LoadingFixedScreen">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </LoadingFixedScreen>
        </>
      )}
      <BreadcrumbWrapper>
        <Link to="/initial-page">
          <img src={homeSvg} alt="Home" />
        </Link>
        <Typography>PROUNI</Typography>
        <Typography>Alterar inscrição do PROUNI</Typography>
      </BreadcrumbWrapper>
      <HeaderSisu
        subType="PROUNI"
        daysLeft="1 dia e 23 horas"
        note={userAverage.averageScore || ''}
        year={userAverage.year || ''}
      />
      <Wrapper>
        <Container>
          <MessageBox
            data-test="confirmation-data-steps-MessageBox"
            main_title="Faça a alteração na opção de curso que deseja alterar"
            description="Você pode cancelar ou alterar o curso selecionado em cada opção
            de curso."
          />
        </Container>
        <Container>
          <TextDiv>
            <h1>Cursos selecionados</h1>
          </TextDiv>
          <Row>
            <Col md={6}>
              <CardConfirmCourse
                program="prouni"
                editPage
                isEdit
                hideDocument
                option={1}
                courseCode={apiData1.courseTimeCode}
                courseCodeChange={apiData2.courseTimeCode}
                courseName={apiData1.courseName}
                modality={apiData1.courseDegree}
                courseTime={apiData1.courseTimeType}
                university={apiData1.campusName}
                city={apiData1.campusNeighborhood}
                uf={apiData1.campusState}
              />
            </Col>
            <Col md={6}>
              <CardConfirmCourse
                program="prouni"
                editPage
                isEdit
                hideDocument
                option={2}
                courseCode={apiData2.courseTimeCode}
                courseCodeChange={apiData1.courseTimeCode}
                courseName={apiData2.courseName}
                modality={apiData2.courseDegree}
                courseTime={apiData2.courseTimeType}
                university={apiData2.campusName}
                city={apiData2.campusNeighborhood}
                uf={apiData2.campusState}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ButtonsDiv>
                <Link to="/candidate-page" className="prev">
                  Voltar
                </Link>
                <Link to="/confirmation" className="middle">
                  Alterar meus Dados
                </Link>
                <Link to="/candidate-page" className="next">
                  Concluir
                </Link>
              </ButtonsDiv>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </>
  )
}

export default wrapper(ChangeProuni, {
  route: { path: CHANGE_PROUNI_ROUTES.CHANGE_PROUNI },
})
