import styled from 'styled-components'

export const ContainerStep = styled.div`
  h1 {
    font-size: 1.5rem;
    font-weight: bold;
  }
`

export const SubscribeInformation = styled.div`
  padding: 1.875rem;
  background: #f2f2f2;
`
export const ButtonsStepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    &:hover {
      filter: brightness(0.8);
    }
    transition: filter 0.2s;
    padding: 8px 24px;
    border-radius: 20px;
    border: 1px solid ${props => props.theme.bgColors.primaryDefault};
    background: ${props => props.theme.bgColors.primaryDefault};
    font-weight: bold;
    color: ${props => props.theme.bgColors.colorSecondary01};
  }

  a {
    &:hover {
      filter: brightness(0.8);
    }
    transition: filter 0.2s;
    padding: 8px 24px;
    border-radius: 20px;
    border: 1px solid ${props => props.theme.bgColors.primaryDefault};
    background: ${props => props.theme.bgColors.primaryDefault};
    font-weight: bold;
    color: ${props => props.theme.bgColors.colorSecondary01};
  }
`
export const LeftButtons = styled.div`
  display: flex;
  gap: 1rem;

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background: transparent;
    border-radius: 42px;
    border: 0;
    color: ${props => props.theme.bgColors.primaryDefault};

    &.editSub {
      border: 1px solid ${props => props.theme.bgColors.primaryDefault};
    }
  }
`

export const ConfirmedCourse = styled.div`
  h2 {
    margin: 2rem 0;
    font-size: 1.5rem;
  }
`

export const HashContainer = styled.div`
  &:hover {
    filter: brightness(0.8);
  }

  transition: filter 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3.875rem;

  h3 {
    font-size: 0.875rem;
  }

  p {
    font-size: 0.75rem;
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 16px 15px;
    gap: 4px;
    font-weight: bold;
    color: #1351b4;
    background-color: #fff;
    font-weight: 1.25rem;
    width: 356.04px;
    height: 48px;

    border: 1px solid #1351b4;
    border-radius: 42px;
  }
`
