import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { Router, Redirect, Route, Switch } from 'react-router-dom'

import PropTypes from 'prop-types'

import history from 'config/history/history'
import { mapRoutesPath } from 'helpers/mapRoutes'
import AuthProvider from 'modules/Auth/fragments/AuthProvider'
import NotificationList from 'modules/Notification/fragments/NotificationList'
import { ROLES } from 'modules/User/constants/roles'

import PrivateRoutes from './fragments/PrivateRoutes'
import PublicRoutes from './fragments/PublicRoutes'
import { PUBLIC_ROUTES } from './fragments/PublicRoutes/routes'
import { adminRouteProvider, defaultRouteProvider } from './routes'
import { AppContainer } from './style'
import { authService } from 'services/providers/auth'
// import { useUser } from 'hooks/useUser';

const publicRoutes = mapRoutesPath(PUBLIC_ROUTES)

const App = ({ role, isAuthenticated, meFailed, isWaitingGovBrLogout }) => {
  // const { cpf } = useUser();

  let routeProvider = {
    routes: [],
    links: [],
  }

  switch (role) {
    case ROLES.ROLE_ADMIN:
      routeProvider = adminRouteProvider
      break
    default:
      routeProvider = defaultRouteProvider
      break
  }

  const renderRedirect = useCallback(() => {
    const meFailedFlag = authService.getMeFailedFlag()
    const isWaitingGovBrFlag = authService.getIsWaitingGovBrFlag()
    if (
      (meFailedFlag || meFailed) &&
      (isWaitingGovBrFlag || isWaitingGovBrLogout)
    ) {
      return <Redirect to={PUBLIC_ROUTES[3].path} />
    } else if (meFailedFlag) {
      return <Redirect to={PUBLIC_ROUTES[1].path} />
    }

    if (!isAuthenticated || routeProvider.routes.length === 0) {
      return <Redirect to={PUBLIC_ROUTES[0].path} />
    }
    return <Redirect to={routeProvider.routes[0].path} />
  }, [isAuthenticated, routeProvider.routes, meFailed, isWaitingGovBrLogout])

  return (
    <AuthProvider>
      <AppContainer>
        <NotificationList />
        <Router history={history}>
          <Switch>
            {isAuthenticated ? (
              <Route
                path={mapRoutesPath(routeProvider.routes)}
                component={() => <PrivateRoutes {...routeProvider} />}
              />
            ) : null}
            <Route
              path={publicRoutes}
              component={() => <PublicRoutes {...routeProvider} />}
            />
            {renderRedirect()}
          </Switch>
        </Router>
      </AppContainer>
    </AuthProvider>
  )
}

App.defaultProps = {
  role: undefined,
  isAuthenticated: false,
}

App.propTypes = {
  role: PropTypes.string,
  isAuthenticated: PropTypes.bool,
}

const mapState = state => ({
  role: state.auth.role,
  isAuthenticated: state.auth.isAuthenticated,
  meFailed: state.auth.meFailed,
  isWaitingGovBrLogout: state.auth.isWaitingGovBrLogout,
})

export default connect(mapState)(App)
