/* eslint-disable no-debugger */
/* eslint-disable react/prop-types */

import React from 'react'

import { Container, RowPosition, UpArrow, DownArrow } from './style'

const CellOrder = ({ listLength, row, index, onChangePosition }) => {
  const disabledUp = index === 0
  const disabledDown = index + 1 === listLength

  return (
    <Container>
      <UpArrow
        $hidden={disabledUp}
        onClick={() =>
          onChangePosition({ row, fromIndex: index, toIndex: index - 1 })
        }
      />
      <RowPosition>{index + 1}</RowPosition>
      <DownArrow
        $hidden={disabledDown}
        onClick={() =>
          onChangePosition({ row, fromIndex: index, toIndex: index + 1 })
        }
      />
    </Container>
  )
}

export default CellOrder
