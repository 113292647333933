import React from 'react'
import { Container, DivImg, DivText } from './styles'

import avatarIconImg from './img/avatar_icon.png'

export function MessageBox(props) {
  return (
    <Container
      data-test="confirmation-data-Container"
      className={props.className}
    >
      <div className="row" data-test="confirmation-data-row">
        <DivImg
          className="col-sm-2"
          data-test="confirmation-data-avatar-DivImg"
        >
          <img
            data-test="confirmation-data-avatar"
            src={avatarIconImg}
            alt=""
          />
        </DivImg>
        <DivText className="col-sm-10" data-test="confirmation-data-DivText">
          <div>
            <svg
              data-test="confirmation-data-seta"
              width="35"
              height="36"
              viewBox="0 0 35 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.82842 20.5416C0.266326 18.9795 0.26633 16.4469 1.82843 14.8848L16.7132 -1.43458e-05L34.4264 17.7132L16.7132 35.4264L1.82842 20.5416Z"
                fill="#E2EAF5"
              />
            </svg>

            <h2 data-test="confirmation-data-main-title">{props.main_title}</h2>
            <p data-test="confirmation-data-description">{props.description}</p>
          </div>
        </DivText>
      </div>
    </Container>
  )
}
