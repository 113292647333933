import {
  CardConfirmCourseContainer,
  CardConfirmHeader,
  CardCourseName,
  CardCourseLocation,
  CardCourseModality,
  CardConfirmFooter,
  CourseTime,
  CourseLocation,
} from './style'
import clockImage from './img/clockImage.svg'
import locationImage from './img/locationCourse.svg'
import React, { useEffect, useState } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { awsTokenManager } from 'config/auth'
import axios from 'axios'
import { LoadingFixedScreen } from '../style'
import { Redirect } from 'react-router-dom'

export function CardConfirmCourse(props) {
  const [loaded, setLoaded] = useState(true)

  const [redirect, setRedirect] = useState(false)
  const [backEdit, setBackEdit] = useState(false)

  useEffect(() => {
    if (redirect) {
      window.location.reload(true)
    }
  }, [redirect])

  const handleDeleteCourse = async () => {
    setLoaded(false)
    try {
      const token = await awsTokenManager.getToken()
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      }
      let data = await axios
        .get(`${process.env.REACT_APP_API_URL}/users/me`, config)
        .then(({ data }) => data)

      const newCpf = data.cpf

      try {
        const config = {
          headers: { Authorization: `${token}` },
        }

        await axios.delete(
          `${process.env.REACT_APP_API_URL}/user-registration/${newCpf}?courseCode=${props.courseCode}&program=prouni`,
          config
        )
        setLoaded(true)
        if (props.editPage === true) {
          setBackEdit(true)
        } else {
          setRedirect(true)
        }
      } catch (err) {
        console.log(err)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleChangeCourses = async () => {
    setLoaded(false)
    try {
      const token = await awsTokenManager.getToken()
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      }
      let data = await axios
        .get(`${process.env.REACT_APP_API_URL}/users/me`, config)
        .then(({ data }) => data)

      const newCpf = data.cpf

      try {
        const config = {
          headers: { Authorization: `${token}` },
        }

        await axios.delete(
          `${process.env.REACT_APP_API_URL}/user-registration/${newCpf}/?courseCode=${props.courseCode}&program=prouni`,
          config
        )
        try {
          if (typeof props.courseCodeChange !== 'undefined') {
            const config = {
              headers: { Authorization: `${token}` },
            }

            await axios.delete(
              `${process.env.REACT_APP_API_URL}/user-registration/${newCpf}/?courseCode=${props.courseCodeChange}&program=prouni`,
              config
            )
          }

          try {
            const courseMapped = {
              courseCode: props.courseCode,
              option: props.option === 1 ? 'option4' : 'option3',
              program: 'prouni',
              modality: 'Ampla Concorrencia',
            }
            await axios.post(
              `${process.env.REACT_APP_API_URL}/user-registration/${newCpf}`,
              courseMapped,
              config
            )
            if (typeof props.courseCodeChange === 'undefined') {
              setLoaded(true)
              setRedirect(true)
            } else {
              try {
                const courseMapped = {
                  courseCode: props.courseCodeChange,
                  option: props.option === 1 ? 'option3' : 'option4',
                  program: 'prouni',
                  modality: 'Ampla Concorrencia',
                }
                await axios.post(
                  `${process.env.REACT_APP_API_URL}/user-registration/${newCpf}`,
                  courseMapped,
                  config
                )
                setLoaded(true)
                setRedirect(true)
              } catch (err) {
                console.log(err)
              }
            }
          } catch (err) {
            console.log(err)
          }
        } catch (err) {
          console.log(err)
        }
      } catch (err) {
        console.log(err)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      {backEdit && (
        <>
          <Redirect to="/prouni" />
        </>
      )}
      {loaded ? null : (
        <>
          <LoadingFixedScreen data-test="LoadingFixedScreen">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </LoadingFixedScreen>
        </>
      )}

      <CardConfirmCourseContainer>
        <CardConfirmHeader>
          <h1>{props.option}ª OPÇÃO DE CURSO</h1>

          {props.isEdit && (
            <>
              <OverlayTrigger
                trigger="click"
                key="bottom"
                placement="bottom"
                overlay={
                  <Popover id={`popover-positioned-bottom`}>
                    <Popover.Body>
                      <p
                        onClick={() => {
                          handleChangeCourses()
                        }}
                      >
                        Trocar para {props.option === 1 ? '2' : '1'}ª opção
                      </p>
                      <p onClick={handleDeleteCourse}>Cancelar Curso</p>
                      <p onClick={handleDeleteCourse}>Alterar Curso</p>
                    </Popover.Body>
                  </Popover>
                }
              >
                <span>
                  <svg
                    width="6"
                    height="16"
                    viewBox="0 0 6 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 5.75C2.375 5.75 1.84375 5.96875 1.40625 6.40625C0.96875 6.84375 0.75 7.375 0.75 8C0.75 8.625 0.96875 9.15625 1.40625 9.59375C1.84375 10.0312 2.375 10.25 3 10.25C3.625 10.25 4.15625 10.0312 4.59375 9.59375C5.03125 9.15625 5.25 8.625 5.25 8C5.25 7.375 5.03125 6.84375 4.59375 6.40625C4.15625 5.96875 3.625 5.75 3 5.75ZM0.75 2.5C0.75 1.875 0.96875 1.34375 1.40625 0.90625C1.84375 0.46875 2.375 0.25 3 0.25C3.625 0.25 4.15625 0.46875 4.59375 0.90625C5.03125 1.34375 5.25 1.875 5.25 2.5C5.25 3.125 5.03125 3.65625 4.59375 4.09375C4.15625 4.53125 3.625 4.75 3 4.75C2.375 4.75 1.84375 4.53125 1.40625 4.09375C0.96875 3.65625 0.75 3.125 0.75 2.5ZM0.75 13.5C0.75 12.875 0.96875 12.3438 1.40625 11.9062C1.84375 11.4688 2.375 11.25 3 11.25C3.625 11.25 4.15625 11.4688 4.59375 11.9062C5.03125 12.3438 5.25 12.875 5.25 13.5C5.25 14.125 5.03125 14.6562 4.59375 15.0938C4.15625 15.5312 3.625 15.75 3 15.75C2.375 15.75 1.84375 15.5312 1.40625 15.0938C0.96875 14.6562 0.75 14.125 0.75 13.5Z"
                      fill="#1351B4"
                    />
                  </svg>
                </span>
              </OverlayTrigger>
            </>
          )}
        </CardConfirmHeader>

        <CardCourseName>
          <h2>{props.courseName}</h2>
          <p>{props.modality}</p>
          <CourseTime>
            <img src={clockImage} alt="" />
            <p>{props.courseTime}</p>
          </CourseTime>
        </CardCourseName>

        <CardCourseLocation>
          <h2>{props.university}</h2>
          <CourseLocation>
            <img src={locationImage} alt="" />
            <p>
              {props.city} - {props.uf}
            </p>
          </CourseLocation>
        </CardCourseLocation>

        <CardCourseModality>
          <h2>Modalidade de concorrência</h2>
          <h3>
            Renda familiar bruta per capita igual ou inferior a 1,5 salário
            mínimo
          </h3>
        </CardCourseModality>

        {!props.hideDocument && (
          <>
            <CardConfirmFooter>
              <button>Ver documentação Necessária</button>
            </CardConfirmFooter>
          </>
        )}
      </CardConfirmCourseContainer>
    </>
  )
}
