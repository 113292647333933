import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  padding: 3px;
  height: 32px;
  position: absolute;
  top: 0;
  right: 1;
  z-index: 4;

  ${props => css`
    color: ${props.theme.palette.grey[200]};
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
  `}
`
