import React, { useEffect, useState } from 'react'
import { Container, DivImg, DivText } from './styles'

import avatarIconImg from './img/avatar_icon.png'

const amountCourses = {
  NONE: 'none',
  ONE: 'one',
  TWO: 'two',
}

export const GuidanceInCourseSelection = ({
  isFirstOptionSelected = false,
  isSecondOptionSelected = false,
}) => {
  const [amountCoursesSelected, setAmountCoursesSelected] = useState(
    amountCourses.NONE
  )

  useEffect(() => {
    if (!isFirstOptionSelected && !isSecondOptionSelected) {
      setAmountCoursesSelected(amountCourses.NONE)
    }

    if (isFirstOptionSelected && !isSecondOptionSelected) {
      setAmountCoursesSelected(amountCourses.ONE)
    }

    if (isFirstOptionSelected && isSecondOptionSelected) {
      setAmountCoursesSelected(amountCourses.TWO)
    }
  }, [isFirstOptionSelected, isSecondOptionSelected])

  return (
    <Container className="my-5">
      <div className="row">
        <DivImg className="col-sm-2">
          <img src={avatarIconImg} alt="" />
        </DivImg>
        <DivText className="col-sm-10">
          <div>
            <svg
              width="35"
              height="36"
              viewBox="0 0 35 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.82842 20.5416C0.266326 18.9795 0.26633 16.4469 1.82843 14.8848L16.7132 -1.43458e-05L34.4264 17.7132L16.7132 35.4264L1.82842 20.5416Z"
                fill="#E2EAF5"
              />
            </svg>
            {amountCoursesSelected === amountCourses.NONE && (
              <>
                <h2>Agora faça a seleção dos cursos</h2>
                <p>
                  Certo, agora você tem que selecionar 2 cursos de sua
                  preferência para concorrer às vagas. Clique na caixa de
                  seleção da sua 1ª opção de curso e realize a busca e seleção.”
                </p>
              </>
            )}
            {amountCoursesSelected === amountCourses.ONE && (
              <>
                <h2>Agora faça a seleção do curso de 2ª opção</h2>
                <p>
                  Certo, agora você tem que selecionar o curso de 2ª opção de
                  sua preferência para concorrer às bolsas. Clique na caixa de
                  seleção da sua 2ª opção de curso e realize a busca e seleção
                </p>
              </>
            )}
            {amountCoursesSelected === amountCourses.TWO && (
              <>
                <h2>Agora revise e confirme a seleção dos cursos</h2>
                <p>
                  Certo, agora você tem que revisar os cursos selecionados e
                  continuar para enviar os dados complementares
                </p>
              </>
            )}
          </div>
        </DivText>
      </div>
    </Container>
  )
}
