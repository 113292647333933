import { Link } from 'react-router-dom'

import styled, { css } from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  overflow: hidden;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 330px;
  height: 600px;
  z-index: 2;

  ${props => css`
    color: ${props.theme.palette.grey[400]};
  `}
`

export const Title = styled.h4`
  color: ${props => props.theme.palette.grey[400]};
`

export const LinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const CustomLink = styled(Link)`
  color: ${props => props.theme.palette.text.primary};
  text-decoration: none;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  & button {
    color: ${props => props.theme.palette.grey[400]} !important;
    width: 100%;
  }
`

export const BackgroundContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.2;

  & img {
    height: 100%;
  }
`

export const LoadingFixedScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999999999999999;
  height: 100vh;
  width: 100vw;
  background: #00000030;

  display: flex;
  align-items: center;
  justify-content: center;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
