import styled from 'styled-components'

export const Container = styled.div`
  background: #f3f1f1;
  padding: 1.5rem;
`

export const CourseDiv = styled.div`
  h1 {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
  }

  p {
    font-style: italic;
    margin: 0.5rem 0;
  }

  button {
    border: 1px solid #1351b4;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 42px;
    padding: 0.375rem 1rem 0.4375rem;
    color: ${props => props.theme.bgColors.primaryDefault};
    font-weight: bold;
    background: #fcfcfc;
    margin-top: 1.1875rem;
    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
    min-width: 19rem;
  }
`
export const ClockDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  p {
    font-size: 0.75rem;
  }
`

export const AboutCollege = styled.div`
  h1 {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
  }
`
export const LocationDiv = styled.div`
  display: flex;
  margin-top: 1rem;
  gap: 0.5rem;
  img {
    align-self: flex-start;
  }

  p {
    color: #000000;
  }
`
