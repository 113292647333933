/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { useExpanded, useTable, usePagination, useRowSelect } from 'react-table'

import update from 'immutability-helper'
import { compact } from 'lodash'
import styled from 'styled-components'

import Row from './Row'
import {
  TableContainer,
  Thead,
  TableBody,
  Pagination,
  BlockLeftPagination,
  BlockRightPagination,
} from './styles'

export * from './ToggleRow'

const Styles = styled.div`
  padding: 0;

  table {
    border-spacing: 0;
    border: none;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: none;
      border-right: none;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

const defaultGetRowId = row => row.uuid || row.id || row.name

const Table = ({
  getRowId: getRowIdProp,
  cols,
  data,
  fetchData,
  draggable,
  pageCount,
  renderRowSubComponent,
  hasRowSelect,
  handleChangePositionOfItem,
  dontShowPagination,
  setSelectedRows,
  onChangePosition,
}) => {
  const prevFetchDataRef = useRef(fetchData)
  const [records, setRecords] = useState([])
  const [pageSize, setPageSize] = useState(
    dontShowPagination ? data.length : 10
  )
  const [pageIndex, setPageIndex] = useState(0)

  const getRowId = getRowIdProp || defaultGetRowId

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    noDataMessage = 'Nenhum registro encontrado.',
    hasSubRow = false,
    selectedFlatRows,
  } = useTable(
    {
      data: records,
      columns: cols,
      getRowId,
      autoResetExpanded: false,
      expandSubRows: false,
      initialState: { pageIndex: 0 },
    },
    useExpanded,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns =>
        compact([
          hasRowSelect
            ? {
                id: 'selection',
                Header: ({ getToggleAllPageRowsSelectedProps }) => (
                  <div>
                    <IndeterminateCheckbox
                      {...getToggleAllPageRowsSelectedProps()}
                    />
                  </div>
                ),
                Cell: ({ row }) => (
                  <div>
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                    />
                  </div>
                ),
              }
            : null,
          ...columns,
        ])
      )
    }
  )

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex]
    if (handleChangePositionOfItem)
      handleChangePositionOfItem(dragIndex, hoverIndex, dragRecord)

    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    )
  }

  useEffect(() => {
    if (setSelectedRows) setSelectedRows(selectedFlatRows)
  }, [selectedFlatRows, setSelectedRows])

  useEffect(() => {
    if (fetchData) {
      if (prevFetchDataRef.current !== fetchData) {
        prevFetchDataRef.current = fetchData
        if (pageIndex !== 0) {
          setPageIndex(0)
          return
        }
      }

      fetchData({ pageIndex, pageSize })
    }
  }, [fetchData, pageIndex, pageSize])

  useEffect(() => {
    setRecords(data)
  }, [data])

  const renderTableItens = () =>
    rows.length ? (
      rows.map(
        (row, index) =>
          prepareRow(row) ||
          (row.depth === 1 ? null : (
            <Row
              listLength={rows.length}
              key={row.id}
              draggable={draggable}
              index={index}
              row={row}
              onChangePosition={onChangePosition}
              moveRow={moveRow}
              visibleColumns={visibleColumns}
              renderRowSubComponent={renderRowSubComponent}
              {...row.getRowProps()}
            />
          ))
      )
    ) : (
      <tr>
        <td colSpan={visibleColumns.length + (draggable ? 1 : 0)}>
          {noDataMessage}
        </td>
      </tr>
    )

  const nextPage = () => {
    setPageIndex(pageIndex + 1)
  }

  const previousPage = () => {
    setPageIndex(pageIndex - 1)
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <TableContainer {...getTableProps()}>
        <Thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {draggable ? <th style={{ width: '20px' }}> </th> : null}
              {onChangePosition ? <th style={{ width: '40px' }}> </th> : null}
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps({ style: column.style || {} })}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </Thead>
        <TableBody {...getTableBodyProps()}>{renderTableItens()}</TableBody>
      </TableContainer>
      {!hasSubRow && !dontShowPagination && !draggable && rows.length ? (
        <Pagination>
          <BlockLeftPagination>
            <span>Exibir</span>
            <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
                setPageIndex(0)
              }}
            >
              {[10, 20, 30, 40, 50].map(pSize => (
                <option key={pSize} value={pSize}>
                  {pSize}
                </option>
              ))}
            </select>
          </BlockLeftPagination>
          <BlockRightPagination>
            <span>
              <strong>
                {pageIndex + 1} de {pageCount}
              </strong>{' '}
              Páginas
            </span>
            <button
              type="button"
              onClick={() => previousPage()}
              disabled={pageIndex === 0}
            >
              {'<'}
            </button>{' '}
            <button
              type="button"
              onClick={() => nextPage()}
              disabled={pageIndex === pageCount - 1}
            >
              {'>'}
            </button>{' '}
          </BlockRightPagination>
        </Pagination>
      ) : null}
    </DndProvider>
  )
}

const StyledTable = ({
  getRowId,
  columns,
  data,
  fetchData,
  draggable,
  pageCount,
  renderRowSubComponent,
  hasRowSelect,
  handleChangePositionOfItem,
  onChangePosition,
  dontShowPagination,
  setSelectedRows,
}) => (
  <Styles>
    <Table
      {...{
        getRowId,
        draggable,
        cols: columns,
        data,
        onChangePosition,
        fetchData,
        pageCount,
        renderRowSubComponent,
        hasRowSelect,
        handleChangePositionOfItem,
        dontShowPagination,
        setSelectedRows,
      }}
    />
  </Styles>
)

export default StyledTable
