import { useLoading } from 'hooks/useLoading'
import React from 'react'

import { Container } from './styles'

export const Loading: React.FC = () => {
  const { isLoading } = useLoading()

  return (
    isLoading && (
      <Container>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Container>
    )
  )
}
