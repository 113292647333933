import styled from 'styled-components'

import { FONT_FAMILY } from 'constants/fonts'

export const LineTop = styled.div`
  height: 1px;
  background: #cccccc;
  width: 100%;
`

export const Container = styled.div`
  margin-top: 22px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 575px) {
    margin-top: 15px;
  }
`

export const DivRow = styled.div`
  width: 1140px;
  max-width: 100%;
  padding: 0;
  margin-right: 15px;
  margin-left: 15px;

  position: relative;

  @media (max-width: 900px) {
    padding: 15px;
  }
  @media (max-width: 575px) {
    padding: 0;
    margin: 0;
  }
`

export const TextH1 = styled.h1`
  font-family: ${FONT_FAMILY};
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;
  display: flex;
  align-items: center;
  color: var(--loading-page-text);
  margin: 0;
`

export const StepsDiv = styled.div`
  margin: 0 -30px;
  overflow-x: hidden;

  @media (max-width: 1099px) {
    margin: 0 -20px;
  }

  @media (max-width: 767px) {
    margin: 0 -10px;
  }

  @media (max-width: 575px) {
    margin: 0;
  }

  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;

  @media (max-width: 767px) {
    padding: 0;
  }
`

export const StepsBox = styled.div`
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; */

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-template-rows: none;
  min-height: 164px;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-top: 100px;
  width: 100%;

  @media (max-width: 767px) {
    padding: 20px 0;
    min-height: 0;
    p {
      margin: 0;
    }
  }

  width: 100%;
  max-width: 100%;

  span {
    @media (max-width: 767px) {
      opacity: 0;
    }
  }

  h3 {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 500;
    font-size: 24.19px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: var(--colorBlueConfirmation);

    position: relative;

    width: 40px;
    height: 40px;
    background-color: var(--white);
    border: 1px solid var(--colorBlueConfirmation);
    border-radius: 50%;

    transition: all 0.2s ease;

    &:hover {
      color: var(--white);
      background-color: var(--colorBlueConfirmation);
      cursor: pointer;
    }
  }

  p:first-child {
    &::after {
      display: none;
    }
  }
`

export const StepItem = styled.p`
  font-family: ${FONT_FAMILY};
  font-style: normal;
  font-weight: 500;
  font-size: 16.8px;
  line-height: 18px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--colorBlack);

  text-align: center;

  padding: 0 20%;

  position: relative;
  &:hover {
    cursor: pointer;
    &::before {
      background-color: var(--colorHoverBlue);
    }
  }

  img {
    position: absolute;
    top: -63px;
    left: 50%;
    z-index: 99;

    @media (max-width: 767px) {
      top: -10px;
    }
  }

  &::before {
    background-color: var(--background-light);
    border: 2px solid var(--interactive);
    border-radius: 50%;
    box-sizing: border-box;
    color: var(--interactive);
    content: '${props => props.stepNumber}';
    font-size: var(--font-size-scale-up-02, 20.16px);
    font-weight: var(--font-weight-semi-bold, 600);
    width: 40px;
    height: 40px;
    left: 50%;
    line-height: 29px;
    margin-top: -55px;

    @media (max-width: 767px) {
      margin-top: 0;
    }
    position: absolute;
    top: 0;
    transform: translateX(-50%);

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 3;
  }

  &::after {
    background-color: black !important;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    top: -35px;
    @media (max-width: 767px) {
      top: 18px;
    }
    transition-delay: 0s;
    transition-duration: 0.15s;
    transition-property: all;
    transition-timing-function: linear;
    z-index: 1;

    left: calc(-50% + 20px);
    width: calc(100% - 40px);
  }

  &.active {
    font-weight: 600;

    &::before {
      color: var(--white);
      background-color: var(--colorBlueDarkConfirmation);
      border: 2px solid var(--colorBlueDarkConfirmation);

      transition: all 0.2s ease;
    }
  }
`

export const Button = styled.button`
  z-index: 99;
  outline: none;
  border: none;
  &.next {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 600;
    font-size: 16.8px;
    line-height: 23px;
    color: #ffffff;

    padding: 8px 24px;

    background: #1351b4;
    border-radius: 20px;

    max-width: 100%;
    width: 127px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all ease 0.2s;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    position: absolute;
    bottom: 30px;
    right: 15px;
  }

  &.prev {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 600;
    font-size: 16.8px;
    line-height: 23px;

    color: #1351b4;
    background: none;

    border: 1px solid #1351b4;
    border-radius: 20px;
    padding: 8px 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all ease 0.2s;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    position: absolute;
    bottom: 35px;
    left: 15px;
  }
`

export const SaveTime = styled.p`
  padding: 0;
  margin: 0;
`

export const SaveTimeDiv = styled.div`
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 100%;

  position: absolute;
  bottom: 30px;

  @media (max-width: 991px) {
    bottom: -20px;
    justify-content: flex-start;
    padding-left: 15px;
  }

  p {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #333333;

    margin: 0;
    padding: 0;

    img {
      margin-right: 8px;
    }
  }
`

export const LoadingFixedScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999999999999999;
  height: 100vh;
  width: 100vw;
  background: #00000030;

  display: flex;
  align-items: center;
  justify-content: center;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
