import { createSlice } from '@reduxjs/toolkit'

import { ROLES } from 'modules/User/constants/roles'

export const AUTH_REDUCER = 'auth'

const hasRole = (user, role) => user.role === role

const auth = createSlice({
  name: AUTH_REDUCER,
  initialState: {
    isAuthenticated: false,
    user: undefined,
    challenge: undefined,
    session: undefined,
    securityQuestionsAnswered: undefined,
    dataProcessingAgreement: undefined,
  },
  reducers: {
    signIn: (state, { payload }) => {
      state.user = payload.user
      state.session = payload.session
      state.isAuthenticated = true
      state.securityQuestionsAnswered = payload.user.securityQuestionsAnswered
      state.dataProcessingAgreement = true // payload.user.dataProcessingAgreement;

      if (hasRole(payload.user, ROLES.ROLE_ADMIN)) {
        state.role = ROLES.ROLE_ADMIN
      }
    },
    signOut: state => {
      state.user = undefined
      state.session = undefined
      state.role = undefined
      state.securityQuestionsAnswered = undefined
      state.dataProcessingAgreement = undefined
      state.isAuthenticated = false
    },
    challengeUser: (state, { payload }) => {
      state.challenge = payload
    },
    me: (state, { payload }) => {
      state.user = payload
      state.securityQuestionsAnswered = payload.securityQuestionsAnswered
      state.dataProcessingAgreement = true // payload.dataProcessingAgreement;
    },
    meFailed: (state, { payload }) => {
      state.meFailed = payload
    },
    isWaitingGovBrLogout: (state, { payload }) => {
      state.isWaitingGovBrLogout = payload
    },
  },
})

export const authActions = auth.actions

export default auth.reducer
