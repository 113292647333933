import { LocalStorage } from 'config/localStorage/localStorage'
import { AwsTokenManager } from 'config/auth/awsTokenManager'
import { TokenManager } from 'config/auth/tokenManager'

const localStorage = new LocalStorage()

const tokenManager = new TokenManager({
  storage: localStorage,
  storageKey: 'ngStorage-jwt',
})

const awsTokenManager = new AwsTokenManager({
  storage: localStorage,
  storageKey: 'ngStorage-jwt',
})

export { tokenManager, awsTokenManager }
