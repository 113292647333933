import axios from 'axios'
import FormContext2 from 'components/DataConfirmSteps/formContext2'
import { awsTokenManager } from 'config/auth'
import React, { useContext, useEffect, useState } from 'react'
import { Table, Container } from 'react-bootstrap'
import { LoadingFixedScreen } from '../style'

import { FamilyGroup, DivSection, Wrapper, Button, MainText } from './style'

export function StepTwoSubscribeForm() {
  useEffect(() => {
    window.scrollTo(0, 0)
    setFormContent2(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [cpfUser, setCpfUser] = useState('')
  const [loaded, setLoaded] = useState(false)

  const { setFormContent2 } = useContext(FormContext2)

  const [relatives, setRelatives] = useState([])

  const getUserMe = async () => {
    try {
      const token = await awsTokenManager.getToken()
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      }
      let data = await axios
        .get(`${process.env.REACT_APP_API_URL}/users/me`, config)
        .then(({ data }) => data)

      setCpfUser(data.cpf)

      try {
        const config = {
          headers: { Authorization: `${token}` },
        }

        let data = await axios
          .get(
            `${process.env.REACT_APP_API_URL}/user-relative/relatives/${cpfUser}`,
            config
          )
          .then(({ data }) => data)

        if (data.length !== 0) {
          setRelativesNumber(data.length)
          function mapBirthdate(item) {
            const dateMap = item.birthdate.replace(/[^\d]/g, '')
            const mapSplit = dateMap.split('')
            const dateYear =
              mapSplit[0] + mapSplit[1] + mapSplit[2] + mapSplit[3]
            const dateMonth = mapSplit[4] + mapSplit[5]
            const dateDay = mapSplit[6] + mapSplit[7]
            const newBirthDate = dateDay + '/' + dateMonth + '/' + dateYear

            return {
              birthdate: newBirthDate,
              cpf: item.cpf,
              degreeKinship: item.degreeKinship,
              individualIncome: item.individualIncome,
              name: item.name,
              phoneNumber: item.phoneNumber,
            }
          }

          const mappedData = data.map(mapBirthdate)

          setRelatives(mappedData)
        }
        setLoaded(true)
      } catch (err) {
        console.log(err)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getUserMe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpfUser])
  const summary = relatives.reduce(
    (acc, transaction) => {
      acc.total += parseFloat(transaction.individualIncome)
      return acc
    },
    {
      total: 0,
    }
  )

  const [perCapita, setPerCapita] = useState(0)
  const [relativesNumber, setRelativesNumber] = useState(0)

  useEffect(() => {
    const perCapitaDecimal = summary.total / relativesNumber
    setPerCapita(perCapitaDecimal.toFixed(2))
  }, [perCapita, relativesNumber, summary])

  return (
    <>
      {loaded ? null : (
        <>
          <LoadingFixedScreen data-test="LoadingFixedScreen">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </LoadingFixedScreen>
        </>
      )}
      <Wrapper>
        <DivSection data-test="confirmation-data-steps-DivSection">
          <Container>
            <MainText>Renda mensal familiar</MainText>
          </Container>
        </DivSection>
        <DivSection data-test="confirmation-data-steps-DivSection">
          <FamilyGroup data-test="confirmation-data-steps-FamilyGroup">
            <Container data-test="confirmation-data-steps-Container">
              <h2>Membros do Grupo Familiar</h2>

              <Table
                responsive="md"
                className="familyTable"
                data-test="confirmation-data-steps-Table"
              >
                <thead>
                  <tr>
                    <th className="alignLeft">CPF</th>
                    <th className="alignLeft">Nome Completo</th>
                    <th>Data de Nascimento</th>
                    <th>Grau de Parentesco</th>
                    <th>Telefone</th>
                    <th>Renda individual (R$)</th>
                  </tr>
                </thead>
                <tbody>
                  {relatives.map(item => (
                    <tr key={item.id}>
                      <td className="alignLeft">{item.cpf}</td>
                      <td className="alignLeft">{item.name}</td>
                      <td>{item.birthdate}</td>
                      <td>{item.degreeKinship}</td>
                      <td>{item.phoneNumber}</td>
                      <td>
                        {new Intl.NumberFormat('pt-br', {
                          currency: 'BRL',
                          style: 'currency',
                        }).format(`${item.individualIncome}`)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Container>
            <Container data-test="confirmation-data-steps-Container">
              <p>
                Renda familiar mensal Brutal (R$)
                <strong> {summary.total.toFixed(2)}</strong>
              </p>
              <p>
                Renda mensal bruta per capita (R$)
                <strong> {perCapita}</strong>
              </p>
            </Container>
          </FamilyGroup>
        </DivSection>
        <Button
          data-test="confirmation-data-steps-ButtonNext"
          className="next"
          onClick={() => {
            setFormContent2(true)
          }}
        >
          Confirmar
        </Button>
      </Wrapper>
    </>
  )
}
