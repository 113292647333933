import Login from 'modules/Auth/submodules/Login'
import MFASetup from 'modules/Auth/submodules/MFASetup'
import MFAToken from 'modules/Auth/submodules/MFAToken'
import ChangePassword from 'modules/User/submodules/ChangePassword'
import DataProcessingAgreement from 'modules/User/submodules/DataProcessingAgreement'
import RequestPasswordReset from 'modules/User/submodules/RequestPasswordReset'
import ResetPassword from 'modules/User/submodules/ResetPassword'
import SelectSecurityQuestions from 'modules/User/submodules/SelectSecurityQuestions'
import Logout from '../../../Auth/submodules/Logout'
import Token from 'modules/Auth/submodules/Token'
import NoAccess from 'modules/App/submodules/NoAccess'

export const PUBLIC_ROUTES = [
  Login,
  NoAccess,
  Token,
  Logout,
  MFASetup,
  MFAToken,
  RequestPasswordReset,
  ResetPassword,
  ChangePassword,
  SelectSecurityQuestions,
  DataProcessingAgreement,
]
