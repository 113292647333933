import styled, { css } from 'styled-components'

export const Container = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  padding-bottom: 2rem;
  min-height: 37rem; 

  > header {
    border-radius: 4px 4px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(
      180deg,
      #dde4fc 0%,
      rgba(221, 228, 252, 0) 100%
    );

    > img {
      margin: 2.5rem 0;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `}

  @media screen and (max-width: 992px) {
    margin-top: 2rem;
    min-height: 0;
  }
`

export const CardContent = styled.div`
  padding: 1rem 2.5rem;

  @media (max-width: 1199px) {
    padding: 1rem;
  }

  strong {
    font-size: 1.25rem;
    font-weight: 700;
  }
  p {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    font-weight: 400;
    max-height: 3rem;
    margin-bottom: 2rem;

    @media (max-width: 991px) {
      max-height: initial;
    }
  }
`

export const SubscribeArea = styled.div``

export const DateCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0;

  > div svg {
    color: ${props => props.theme.textColors.primaryPastel03};
    font-size: 2.5rem;
  }

  .active svg {
    color: ${props => props.theme.bgColors.primaryDefault} !important;
  }

  > div {
    > h3 {
      margin: 0;
      padding: 0;

      font-weight: 400;
      font-size: 14px;
      line-height: 16px;

      color: ${props => props.theme.textColors.primaryPastel03};

      &.subscribeActive {
        color: ${props => props.theme.bgColors.primaryDefault};
        font-size: 1rem;
        font-weight: bold;
      }
    }

    > p {
      margin: 0.3rem 0;
      padding: 0;
      color: ${props => props.theme.textColors.colorTextGrey03};
      font-weight: 600;

      &.active {
        color: ${props => props.theme.bgColors.colorSecondary08};
      }
    }
  }
`

export const FooterCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .linkItem {
    text-decoration: underline;
    font-weight: 500;
  }

  a {
    text-decoration: underline;
    color: ${props => props.theme.bgColors.primaryDefault};

    :hover {
      background: transparent;
    }
  }
  button,
  a {
    &[disabled] {
      opacity: 1 !important;
    }
    margin: 0.5rem 0;

    transition: all ease 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
    &.readMore {
      width: 100%;
      padding: 0.4rem 1.5rem;
      border: 1px solid ${props => props.theme.bgColors.primaryDefault};
      background: ${props => props.theme.bgColors.colorSecondary01};
      border-radius: 200px;
      color: ${props => props.theme.bgColors.primaryDefault};
      font-weight: 600;
    }

    &.isSubscribed {
      width: 100%;
      padding: 0.4rem 1.5rem;
      border: 1px solid ${props => props.theme.bgColors.primaryDefault};
      background: none;
      border-radius: 200px;
      color: #1351b4;
      font-weight: 600;
      text-align: center;
    }

    &.subscribeButton {
      background: ${props => props.theme.bgColors.primaryDefault};
      border-radius: 20px;
      color: ${props => props.theme.bgColors.colorSecondary01};
      padding: 0.5rem 1.5rem;
      border: 0;
      width: 100%;

      font-weight: 600;
    }

    &.cantSubscribe {
      background: ${props => props.theme.textColors.primaryPastel03};
    }

    &.whyCantSubscribe {
      background: #ffda00;
      border-radius: 4px;
      width: 100%;
      margin-top: -0.2rem;
      border: 0;
      text-decoration: underline;
      font-weight: 700;
      color: ${props => props.theme.bgColors.primaryDefault};
    }
  }
`
