/* eslint-disable no-console */
import React from 'react'
import { useForm, useWatch } from 'react-hook-form'

import PropTypes from 'prop-types'

import {
  Divider,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))
function InputSearch({ placeholder, onSearch }) {
  const classes = useStyles()
  const formMethods = useForm({ defaultValues: { searchTerm: '' } })
  const { control, register, handleSubmit, reset } = formMethods

  const onSubmit = formData => {
    onSearch(formData.searchTerm)
  }

  const searchTerm = useWatch({ control, name: 'searchTerm' })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={classes.root} elevation={0}>
        <InputBase
          name="searchTerm"
          placeholder={placeholder}
          className={classes.input}
          inputRef={register}
        />
        {searchTerm ? (
          <IconButton
            size="small"
            color="primary"
            className={classes.iconButton}
            onClick={() => {
              reset()
              onSearch('')
            }}
          >
            <ClearIcon />
          </IconButton>
        ) : null}
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton
          type="submit"
          size="small"
          color="primary"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    </form>
  )
}

InputSearch.defaultProps = {
  placeholder: '',
}

InputSearch.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
}

export default InputSearch
