import React, { useContext, useEffect, useState } from 'react'
import { MessageBox } from '../MessageBox'
import InputMask from 'react-input-mask'

import {
  Button,
  DivReceita,
  DivSection,
  ErrorMessageText,
  ErrorSpan,
  RadioButton,
  RadioButtonContainer,
  SelectInputGroup,
  Wrapper,
} from './styles'

import infoSvg from './img/info.svg'
import {
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { awsTokenManager } from 'config/auth'
import axios from 'axios'
import { TextInputRequired } from '../TextInputRequired'
import FormContext1 from '../formContext1'
import { userToApi } from '../adapters/userToApi'
import { LoadingFixedScreen } from '../styles'
import { isValidCPF } from 'utils/numberValidation'

export function StepOne() {
  useEffect(() => {
    window.scrollTo(0, 0)
    setFormContent1(false)
  }, [])

  function handleSetDocumentId(documentIdName) {
    set_IdDoc_Error_Flag(false)
    setIdentificationDocument(documentIdName)
  }
  function handleSetUfName(ufName) {
    setUfEmissao_Error_Flag(false)
    setIssuingState(ufName)
  }
  function handleSetCivilStatusName(civilStatusName) {
    setEstado_Error_Flag(false)
    setMaritalStatus(civilStatusName)
  }
  function handleSetRaceStatusName(raceStatusName) {

    setEtnia_Error_Flag(false)
    setEthnicity(raceStatusName)
  }
  function handleSetDefStatusName(defStatusName) {
    setDeficiencia_Error_Flag(false)
    setDisabilityType(defStatusName)
  }

  const renderTooltip1 = props => (
    <Tooltip
      id="tooltip1"
      data-test="confirmation-data-steps-Tooltip"
      {...props}
    >
      Caso identifique que algum dos dados acima está incorreto, clique no botão
      Alterar dados na Receita Federal, para solicitar a atualização do mesmo,
      se já o fez na Receita Federal. Se não realizou ainda a alteração acesse o
      site da Receita Federal e faça a atualização.
    </Tooltip>
  )
  const renderTooltip2 = props => (
    <Tooltip
      id="tooltip2"
      data-test="confirmation-data-steps-Tooltip"
      {...props}
    >
      O preenchimento do campo denominado raça, cor, etnia deve respeitar o
      critério de autodeclaração, em conformidade com a classificação utilizada
      pelo Instituto Brasileiro de Geografia e Estatística - IBGE.
    </Tooltip>
  )
  const renderTooltip3 = props => (
    <Tooltip
      id="tooltip3"
      data-test="confirmation-data-steps-Tooltip"
      {...props}
    >
      Considera-se pessoa com deficiência aquela que tem impedimento de longo
      prazo de natureza física, mental, intelectual ou sensorial, o qual, em
      interação com uma ou mais barreiras, pode obstruir sua participação plena
      e efetiva na sociedade em igualdade de condições com as demais pessoas.
    </Tooltip>
  )

  const [loaded, setLoaded] = useState(false)
  const [under18, setUnder18] = useState(false)

  const [fullName, setFullName] = useState('')
  const [cpf, setCpf] = useState('')
  const [useSocialName, setUseSocialName] = useState(false)
  const [socialName, setSocialName] = useState('')
  const [gender, setGender] = useState('')
  const [birthdate, setBirthdate] = useState('')
  const [newBirthdate, setNewBirthdate] = useState('')
  const [uf, setUf] = useState('')
  const [birthPlace, setBirthPlace] = useState('')
  const [motherName, setMotherName] = useState('')
  const [responsibleCpf, setResponsibleCpf] = useState('')
  const [responsibleName, setResponsibleName] = useState('')
  const [responsibleContact, setResponsibleContact] = useState('')
  const [brazilian, setBrazilian] = useState(true)
  const [identificationDocument, setIdentificationDocument] = useState(
    'Selecione o documento'
  )
  const [issuingState, setIssuingState] = useState('Selecione UF')
  const [maritalStatus, setMaritalStatus] = useState('Selecione o estado civil')
  const [ethnicity, setEthnicity] = useState('Selecione a etnia/cor')
  const [disabilityFlag, setDisabilityFlag] = useState(false)
  const [disabilityType, setDisabilityType] = useState(
    'Selecione a deficiência'
  )

  const [ufDoc, setUfDoc] = useState('')
  const [orgFlag, setOrgFlag] = useState(false)
  const [issuingAgency, setIssuingAgency] = useState('')
  const [documentNumberFlag, setDocumentNumberFlag] = useState(false)
  const [documentNumber, setDocumentNumber] = useState('')
  const [documentSerialNumberFlag, setDocumentSerialNumberFlag] =
    useState(false)
  const [serie, setSerie] = useState('')

  const [firstPost, setFirstPost] = useState(false)
  const [existingCpf, setExistingCpf] = useState(false)
  const [invalidCpf, setInvalidCpf] = useState(false)

  const onCpfNumberChange = async event => {
    set_CpfRes_Error_Flag(false)
    setInvalidCpf(false)
    setExistingCpf(false)
    const cpfNumber = event.target.value
    const cpfNumberCleared = cpfNumber.replace(/\D/g, '')
    if (cpfNumberCleared.length === 11) {
      setResponsibleCpf(cpfNumberCleared)

      try {
        const token = await awsTokenManager.getToken()
        const config = {
          headers: { Authorization: `${token}` },
        }
        if (isValidCPF(cpfNumberCleared)) {
          const data = await axios
            .get(
              `${process.env.REACT_APP_API_URL}/users/rfb/${cpfNumberCleared}`,
              config
            )
            .then(({ data }) => data)
          setBirthdate(data?.birthdate)
          setInvalidCpf(false)
        } else {
          setInvalidCpf(true)
        }
      } catch (error) {
        setInvalidCpf(true)
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (identificationDocument === 'CNH' || identificationDocument === 'RG') {
      setUfDoc(true)
      setOrgFlag(true)
      setIssuingAgency('')
      setDocumentNumber('')
      setDocumentNumberFlag(true)
      setDocumentSerialNumberFlag(false)
      setIssuingState('Selecione UF')
    } else if (identificationDocument === 'CTPS') {
      setUfDoc(false)
      setOrgFlag(false)
      setIssuingAgency('')
      setDocumentNumber('')
      setDocumentNumberFlag(true)
      setDocumentSerialNumberFlag(true)
      setSerie('')
      setIssuingState('Selecione UF')
    } else if (identificationDocument === 'Passaporte') {
      setUfDoc(false)
      setOrgFlag(false)
      setIssuingAgency('')
      setDocumentNumber('')
      setDocumentNumberFlag(true)
      setDocumentSerialNumberFlag(false)
    } else if (identificationDocument === 'RNE') {
      setUfDoc(false)
      setOrgFlag(false)
      setIssuingAgency('')
      setDocumentNumber('')
      setDocumentNumberFlag(true)
      setDocumentSerialNumberFlag(false)
    } else if (identificationDocument === 'IdentidadeMilitar') {
      setUfDoc(false)
      setOrgFlag(false)
      setIssuingAgency('')
      setDocumentNumber('')
      setDocumentNumberFlag(true)
      setDocumentSerialNumberFlag(false)
    }
  }, [identificationDocument])

  useEffect(() => {
    const getUserMe = async () => {
      try {
        const token = await awsTokenManager.getToken()
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        }
        let data = await axios
          .get(`${process.env.REACT_APP_API_URL}/users/me`, config)
          .then(({ data }) => data)

        setFullName(data?.name)
        setCpf(data?.cpf)

        try {
          const config = {
            headers: { Authorization: `${token}` },
          }

          let data = await axios
            .get(
              `${process.env.REACT_APP_API_URL}/user-information/${cpf}`,
              config
            )
            .then(({ data }) => data)

          if (data) {
            setUseSocialName(data?.useSocialName)
            setSocialName(data?.socialName)
            setGender(data?.gender)
            setResponsibleName(data?.responsibleName)
            setResponsibleCpf(data?.responsibleCpf)
            setResponsibleContact(data?.responsibleContact)
            setBrazilian(data?.brazilian)
            setIdentificationDocument(data?.identificationDocument)
            setMaritalStatus(data?.maritalStatus)
            setEthnicity(data?.ethnicity)
            setDisabilityFlag(data?.disabilityFlag)
            setDisabilityType(data?.disabilityType)
            setDocumentNumber(data?.documentNumber)
            setIssuingAgency(data?.issuingAgency)
            setIssuingState(data?.issuingState)
            setSerie(data?.serie)
          } else {
            setNewBirthdate('DD/MM/AAAA')
            setFirstPost(true)
          }

          setLoaded(true)
        } catch (err) {
          console.log(err)
        }
      } catch (err) {
        console.log(err)
      }
    }

    getUserMe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf])

  const onInputChange = async event => {
    const inputName = event.target.name
    const inputValue = event.target.value

    if (inputName === 'useSocialName') {
      setUseSocialName(inputValue)
    }
    if (inputName === 'socialName') {
      set_Social_Error_Flag(false)
      setSocialName(inputValue)
    }
    if (inputName === 'gender') {
      set_Gender_Error_Flag(false)
      setGender(inputValue)
    }
    if (inputName === 'birthdate') {
      const dateMap = inputValue.replace(/[^\d]/g, '')
      const mapSplit = dateMap.split('')
      const dateYear = mapSplit[4] + mapSplit[5] + mapSplit[6] + mapSplit[7]

      const dateMonth = mapSplit[2] + mapSplit[3]

      const dateDay = mapSplit[0] + mapSplit[1]

      const newBirthDate = dateYear + '-' + dateMonth + '-' + dateDay

      var today = new Date()
      var NewDateGMT = new Date(dateYear, dateMonth - 1, dateDay, 0, 1, 0)
      var age = today.getFullYear() - NewDateGMT.getFullYear()
      var m = today.getMonth() - NewDateGMT.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < NewDateGMT.getDate())) {
        age--
      }

      if (age < 18) {
        setUnder18(true)
      } else {
        setUnder18(false)
      }

      setBirthdate(newBirthDate)
    }
    if (inputName === 'uf') {
      set_Uf_Error_Flag(false)
      setUf(inputValue)
    }
    if (inputName === 'birthPlace') {
      set_Municipio_Error_Flag(false)
      setBirthPlace(inputValue)
    }
    if (inputName === 'motherName') {
      set_Mother_Error_Flag(false)
      setMotherName(inputValue)
    }
    if (inputName === 'responsibleCpf') {
      const cpfValue = inputValue
      const cpfCleared = cpfValue.replace(/\D/g, '')
      setResponsibleCpf(cpfCleared)
      if (!cpfValue.includes('_')) {
        try {
          const token = await awsTokenManager.getToken()
          const config = {
            headers: { Authorization: `Bearer ${token}` },
          }
          const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/users/rfb/${cpfCleared}`,
            config
          )

          setResponsibleName(data?.name)
        } catch (error) {
          console.log(error)
        }
      }
    }
    if (inputName === 'responsibleName') {
      set_NameRes_Error_Flag(false)
      setResponsibleName(inputValue)
    }
    if (inputName === 'responsibleContact') {
      set_TelRes_Error_Flag(false)
      const cellPhoneInputNumber = inputValue.replace(/[^0-9]+/g, '')
      setResponsibleContact(cellPhoneInputNumber)
    }
    if (inputName === 'org') {
      set_DocOrg_Error_Flag(false)
      setIssuingAgency(inputValue)
    }
    if (inputName === 'documentNumber') {
      set_DocNumber_Error_Flag(false)
      setDocumentNumber(inputValue)
    }
    if (inputName === 'documentSerialNumber') {
      set_DocSerie_Error_Flag(false)
      setSerie(inputValue)
    }
  }

  const formatChars = {
    A: '[A-Z, a-z]',
  }

  const handleForm1 = async data => {
    data?.preventDefault()
    setLoaded(false)
    if (firstPost === true) {
      try {
        const userMapped = userToApi({
          fullName,
          cpf,
          useSocialName,
          socialName,
          gender,
          birthdate,
          uf,
          birthPlace,
          motherName,
          responsibleCpf,
          responsibleName,
          responsibleContact,
          brazilian,
          identificationDocument,
          maritalStatus,
          ethnicity,
          disabilityFlag,
          disabilityType,
          documentNumber,
          issuingAgency,
          issuingState,
          serie,
        })

        const token = await awsTokenManager.getToken()
        const config = {
          headers: { Authorization: `${token}` },
        }
        await axios.post(
          `${process.env.REACT_APP_API_URL}/user-information/`,
          userMapped,
          config
        )
        setFormContent1(true)
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        const userMapped = userToApi({
          fullName,
          cpf,
          useSocialName,
          socialName,
          gender,
          birthdate,
          uf,
          birthPlace,
          motherName,
          responsibleCpf,
          responsibleName,
          responsibleContact,
          brazilian,
          identificationDocument,
          maritalStatus,
          ethnicity,
          disabilityFlag,
          disabilityType,
          documentNumber,
          issuingAgency,
          issuingState,
          serie,
        })

        const token = await awsTokenManager.getToken()
        const config = {
          headers: { Authorization: `${token}` },
        }
        await axios.put(
          `${process.env.REACT_APP_API_URL}/user-information/${cpf}`,
          userMapped,
          config
        )
        setFormContent1(true)
      } catch (err) {
        console.log(err)
      }
    }
  }

  const [cpfStatus, setCpfStatus] = useState('')

  useEffect(() => {
    const getCpfRFB = async () => {
      try {
        const token = await awsTokenManager.getToken()
        const config = {
          headers: { Authorization: `${token}` },
        }
        let data = await axios
          .get(`${process.env.REACT_APP_API_URL}/users/rfb/${cpf}`, config)
          .then(({ data }) => data)
        setCpfStatus(data?.cpfStatus)
        setMotherName(data?.motherName)
        setBirthdate(data?.birthdate)
        setUf(data?.uf)
        setBirthPlace(data?.city)
      } catch (err) {
        console.log(err)
      }
    }

    getCpfRFB()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf])

  useEffect(() => {
    const dateMap = birthdate.replace(/[^\d]/g, '')
    const mapSplit = dateMap.split('')
    const dateYear = mapSplit[0] + mapSplit[1] + mapSplit[2] + mapSplit[3]

    const dateMonth = mapSplit[4] + mapSplit[5]

    const dateDay = mapSplit[6] + mapSplit[7]

    const newBirthDate = dateDay + '/' + dateMonth + '/' + dateYear

    setNewBirthdate(newBirthDate)
  }, [birthdate])

  useEffect(() => {
    if (birthdate !== '') {
      const dateMap = birthdate.replace(/[^\d]/g, '')
      const mapSplit = dateMap.split('')

      const dateYear = mapSplit[0] + mapSplit[1] + mapSplit[2] + mapSplit[3]

      const dateMonth = mapSplit[4] + mapSplit[5]

      const dateDay = mapSplit[6] + mapSplit[7]

      const newBirthDate = dateDay + '/' + dateMonth + '/' + dateYear

      setNewBirthdate(newBirthDate)

      var today = new Date()
      var NewDateGMT = new Date(dateYear, dateMonth - 1, dateDay, 0, 1, 0)
      var age = today.getFullYear() - NewDateGMT.getFullYear()
      var m = today.getMonth() - NewDateGMT.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < NewDateGMT.getDate())) {
        age--
      }

      if (age < 18) {
        setUnder18(true)
      } else {
        setUnder18(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded])

  // eslint-disable-next-line no-unused-vars
  const { formContent1, setFormContent1 } = useContext(FormContext1)

  const [social_Error_Flag, set_Social_Error_Flag] = useState(false)
  const [gender_Error_Flag, set_Gender_Error_Flag] = useState(false)
  const [date_Error_Flag, set_Date_Error_Flag] = useState(false)
  const [uf_Error_Flag, set_Uf_Error_Flag] = useState(false)
  const [municipio_Error_Flag, set_Municipio_Error_Flag] = useState(false)
  const [mother_Error_Flag, set_Mother_Error_Flag] = useState(false)
  const [cpfRes_Error_Flag, set_CpfRes_Error_Flag] = useState(false)
  const [nameRes_Error_Flag, set_NameRes_Error_Flag] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [telRes_Error_Flag, set_TelRes_Error_Flag] = useState(false)
  const [docNumber_Error_Flag, set_DocNumber_Error_Flag] = useState(false)
  const [idDoc_Error_Flag, set_IdDoc_Error_Flag] = useState(false)
  const [ufEmissao_Error_Flag, setUfEmissao_Error_Flag] = useState(false)
  const [estado_Error_Flag, setEstado_Error_Flag] = useState(false)
  const [etnia_Error_Flag, setEtnia_Error_Flag] = useState(false)
  const [deficiencia_Error_Flag, setDeficiencia_Error_Flag] = useState(false)
  const [docSerie_Error_Flag, set_DocSerie_Error_Flag] = useState(false)
  const [docOrg_Error_Flag, set_DocOrg_Error_Flag] = useState(false)

  // const [showButton, setShowButton] = useState(true)

  // console.log(responsibleContact.length);
  // console.log(under18);

  useEffect(() => {
    if (
      socialName.trim().length !== 0 ||
      !useSocialName ||
      (socialName.trim().length > 0 && useSocialName) ||
      (fullName.trim().length !== 0 &&
        gender.trim().length !== 0 &&
        (responsibleCpf.trim().length !== 0 || !under18) &&
        responsibleContact.trim().length !== 0 &&
        responsibleContact.length === 11)
    ) {
      if (identificationDocument === 'CNH' || identificationDocument === 'RG') {
        if (
          (documentNumber.trim().length !== 0) &
          (issuingAgency.trim().length !== 0)
        ) {
          // setShowButton(true)
        } else {
          // setShowButton(false)
        }
      } else if (identificationDocument === 'CTPS') {
        if (
          (documentNumber.trim().length !== 0) &
          (serie.trim().length !== 0)
        ) {
          // setShowButton(true)
        } else {
          // setShowButton(false)
        }
      } else if (
        identificationDocument === 'Passaporte' ||
        identificationDocument === 'RNE' ||
        identificationDocument === 'IdentidadeMilitar'
      ) {
        if (documentNumber.trim().length !== 0) {
          // setShowButton(true)
        } else {
          // setShowButton(false)
        }
      }
    } else {
      // setShowButton(false)
    }
  }, [
    documentNumber,
    fullName,
    gender,
    identificationDocument,
    issuingAgency,
    responsibleContact,
    responsibleCpf,
    serie,
    socialName,
    under18,
    useSocialName,
  ])

  function handleValidationFocus(e, type) {
    // if (type === 'inputType') {
    //   document.getElementById(e).scrollIntoView({ block: 'center' });
    //   console.log('entrou no primeiro if');
    // } else if (type === 'dropdownType') {
    //   document.getElementById(e).scrollIntoView({ block: 'center' });
    //   console.log('entrou no segundo if ixo');
    // }
    document.getElementById('formtoscroll').scrollIntoView()
  }

  return (
    <>
      {loaded ? null : (
        <>
          <LoadingFixedScreen data-test="LoadingFixedScreen">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </LoadingFixedScreen>{' '}
        </>
      )}
      <Wrapper data-test="confirmation-data-steps-Wrapper">
        <form id="form1" onSubmit={handleForm1}>
          <MessageBox
            data-test="confirmation-data-steps-MessageBox"
            main_title="Agora vamos conferir alguns dados sobre você."
            description={
              <>
                O formulário abaixo é só para confirmarmos que temos todos os
                dados necessários sobre você, para sua inscrição nos programas.
                Você poderia conferir se os dados ainda estão corretos?
                <br />
                <br />
                Não se preocupe você poderá edita-los mais tarde se
                necessesário.
              </>
            }
          />

          <div
            className="row"
            data-test="confirmation-data-steps-row"
            id="formtoscroll"
          >
            <div className="col-lg-6 col-md-6 col-sm-12 col-sx-12 ">
              <TextInputRequired
                title="Nome Completo"
                errorMessage="Campo obrigatório"
                showErrorMessage={false}
              />
              <InputMask
                disabled
                data-test="confirmation-data-steps-input"
                type="text"
                placeholder="Fulano da Silva"
                name="fullName"
                defaultValue={fullName}
                required
                onChange={onInputChange}
              />

              {loaded ? (
                <>
                  <input
                    type="checkbox"
                    name="socialName"
                    id="socialName"
                    onChange={() => setUseSocialName(!useSocialName)}
                    defaultChecked={useSocialName}
                  />
                  <label htmlFor="socialName">Utilizar meu nome social</label>
                </>
              ) : null}
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 col-sx-12">
              <TextInputRequired
                title="CPF"
                errorMessage="Campo obrigatório"
                showErrorMessage={false}
              />

              {loaded ? (
                <>
                  <InputMask
                    disabled
                    data-test="confirmation-data-steps-input"
                    type="text"
                    placeholder="999.999.999-99"
                    name="cpf"
                    defaultValue={cpf}
                    mask="999.999.999-99"
                    required
                    onChange={onInputChange}
                  />
                  <ErrorMessageText>
                    {cpfStatus === 'enabled' ? (
                      'Seu CPF está ativo.'
                    ) : (
                      <>
                        <span>Seu CPF esta na situação INATIVA.</span>
                      </>
                    )}
                  </ErrorMessageText>
                </>
              ) : (
                <InputMask
                  data-test="confirmation-data-steps-input"
                  type="text"
                  placeholder="999.999.999-99"
                  name="cpf"
                  defaultValue={cpf}
                  required
                  onChange={onInputChange}
                />
              )}
            </div>
          </div>

          <div className="row">
            {useSocialName ? (
              <div className="col-lg-6 col-md-6 col-sm-12 col-sx-12 ">
                <TextInputRequired
                  title="Nome Social"
                  errorMessage="Campo obrigatório"
                  showErrorMessage={social_Error_Flag ? true : false}
                />
                <InputMask
                  id="idGoesHere1"
                  onInvalid={() => {
                    handleValidationFocus('idGoesHere1', 'inputType')
                    set_Social_Error_Flag(true)
                  }}
                  data-test="confirmation-data-steps-input"
                  type="text"
                  placeholder="Digite seu nome social..."
                  name="socialName"
                  defaultValue={socialName}
                  required
                  onChange={onInputChange}
                />
              </div>
            ) : null}

            <div className="col-md-3">
              <TextInputRequired
                title="Gênero"
                errorMessage="Campo obrigatório"
                showErrorMessage={gender_Error_Flag ? true : false}
              />
              <InputMask
                id="idGoesHere2"
                onInvalid={() => {
                  handleValidationFocus('idGoesHere2', 'inputType')
                  set_Gender_Error_Flag(true)
                }}
                data-test="confirmation-data-steps-input"
                type="text"
                placeholder="Insira seu gênero..."
                name="gender"
                defaultValue={gender}
                required
                onChange={onInputChange}
              />
            </div>
            <div className="col-md-3">
              <TextInputRequired
                title="Data de Nascimento"
                errorMessage="Campo obrigatório"
                showErrorMessage={date_Error_Flag ? true : false}
              />
              {newBirthdate !== '' ? (
                <>
                  <InputMask
                    disabled
                    id="idGoesHere3"
                    onInvalid={() => {
                      handleValidationFocus('idGoesHere3', 'inputType')
                      set_Date_Error_Flag(true)
                    }}
                    data-test="confirmation-data-steps-input"
                    type="text"
                    placeholder="DD/MM/AAAA"
                    name="birthdate"
                    value={newBirthdate}
                    required
                    onChange={onInputChange}
                  />
                </>
              ) : (
                <>
                  <InputMask
                    data-test="confirmation-data-steps-input"
                    type="text"
                    placeholder="DD/MM/AAAA"
                    name="birthdate"
                    defaultValue={newBirthdate}
                    required
                    onChange={onInputChange}
                  />
                </>
              )}
            </div>
          </div>

          <div className="col-md-6 col-sm-12 col-sx-12">
            <div className="row">
              <div className="col-md-4">
                <TextInputRequired
                  title="Natural do UF"
                  errorMessage="Campo obrigatório"
                  showErrorMessage={uf_Error_Flag ? true : false}
                />
                <InputMask
                  disabled
                  id="idGoesHere4"
                  onInvalid={() => {
                    handleValidationFocus('idGoesHere4', 'inputType')
                    set_Uf_Error_Flag(true)
                  }}
                  className="ufInput"
                  data-test="confirmation-data-steps-input"
                  type="text"
                  placeholder="AA"
                  name="uf"
                  formatChars={formatChars}
                  defaultValue={uf}
                  required
                  onChange={onInputChange}
                />
              </div>
              <div className="col-md-8">
                <TextInputRequired
                  title="Natural do Município"
                  errorMessage="Campo obrigatório"
                  showErrorMessage={municipio_Error_Flag ? true : false}
                />
                <InputMask
                  disabled
                  id="idGoesHere5"
                  onInvalid={() => {
                    handleValidationFocus('idGoesHere5', 'inputType')
                    set_Municipio_Error_Flag(true)
                  }}
                  data-test="confirmation-data-steps-input"
                  type="text"
                  placeholder="Porto Alegre"
                  name="birthPlace"
                  defaultValue={birthPlace}
                  required
                  onChange={onInputChange}
                />
              </div>
              <div className="col-md-12">
                <TextInputRequired
                  title="Nome da Mãe"
                  errorMessage="Campo obrigatório"
                  showErrorMessage={mother_Error_Flag ? true : false}
                />
                <InputMask
                  disabled
                  id="idGoesHere6"
                  onInvalid={() => {
                    handleValidationFocus('idGoesHere6', 'inputType')
                    set_Mother_Error_Flag(true)
                  }}
                  data-test="confirmation-data-steps-input"
                  type="text"
                  placeholder="Marceline da Silva"
                  name="motherName"
                  defaultValue={motherName}
                  required
                  onChange={onInputChange}
                />
              </div>
              <DivReceita
                className="col-md-12"
                data-test="confirmation-data-steps-DivReceita"
              >
                <a
                  className="secondaryButton"
                  href="https://servicos.receita.fazenda.gov.br/servicos/cpf/alterar/default.asp"
                  data-test="confirmation-data-steps-Link"
                >
                  Alterar dados na Receita Federal
                </a>
                <OverlayTrigger
                  data-test="confirmation-data-steps-OverlayTrigger"
                  placement="left"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip1}
                >
                  <img src={infoSvg} alt="Info" />
                </OverlayTrigger>
              </DivReceita>
            </div>
          </div>
          <DivSection
            className="row"
            data-test="confirmation-data-steps-DivSection"
          >
            {under18 ? (
              <>
                <div className="col-md-4">
                  <TextInputRequired
                    title="CPF do Responsável Legal"
                    errorMessage="Campo obrigatório"
                    showErrorMessage={cpfRes_Error_Flag ? true : false}
                  />
                  <InputMask
                    id="idGoesHere12"
                    onInvalid={() => {
                      handleValidationFocus('idGoesHere12', 'inputType')
                      set_CpfRes_Error_Flag(true)
                      setInvalidCpf(false)
                    }}
                    data-test="confirmation-data-steps-input"
                    type="text"
                    placeholder="999.999.999-99"
                    name="cpf"
                    defaultValue={responsibleCpf}
                    mask="999.999.999-99"
                    required
                    onChange={onCpfNumberChange}
                  />
                  {existingCpf && (
                    <>
                      <p>CPF já adicionado!</p>
                    </>
                  )}
                  {invalidCpf && (
                    <>
                      <p style={{ color: '#DA0000' }}>
                        Número de CPF inválido!
                      </p>
                    </>
                  )}
                </div>
                <div className="col-md-4">
                  <TextInputRequired
                    title="Nome do Responsável Legal"
                    errorMessage="Campo obrigatório"
                    showErrorMessage={nameRes_Error_Flag ? true : false}
                  />

                  <InputMask
                    // disabled
                    id="idGoesHere7"
                    onInvalid={() => {
                      handleValidationFocus('idGoesHere7', 'inputType')
                      set_NameRes_Error_Flag(true)
                    }}
                    data-test="confirmation-data-steps-input"
                    type="text"
                    placeholder="Digite o nome do Responsável Legal"
                    name="responsibleName"
                    value={responsibleName}
                    required
                    onChange={onInputChange}
                  />
                </div>
                <div className="col-md-4">
                  <TextInputRequired
                    title="Contato do Responsável Legal"
                    errorMessage={
                      responsibleContact.replace(/[^0-9]+/g, '').length < 10 &&
                      responsibleContact.replace(/[^0-9]+/g, '').length > 0
                        ? 'Foi informado um dado inválido, favor realize o ajuste para prosseguir.'
                        : 'Campo obrigatório'
                    }
                    showErrorMessage={
                      (responsibleContact.replace(/[^0-9]+/g, '').length < 10 &&
                        responsibleContact.replace(/[^0-9]+/g, '').length >
                          0) ||
                      telRes_Error_Flag
                        ? true
                        : false
                    }
                  />
                  <InputMask
                    id="idGoesHere8"
                    onInvalid={() => {
                      handleValidationFocus('idGoesHere8', 'inputType')
                      set_TelRes_Error_Flag(true)
                    }}
                    data-test="confirmation-data-steps-input"
                    type="text"
                    placeholder="99 99999-9999"
                    name="responsibleContact"
                    mask="(99) 99999-9999"
                    defaultValue={responsibleContact}
                    required
                    onChange={onInputChange}
                  />
                </div>
              </>
            ) : null}
          </DivSection>
          <DivSection
            className="row"
            data-test="confirmation-data-steps-DivSection"
          >
            <p className="infoText" data-test="confirmation-data-steps-p">
              É brasileiro nato ou naturalizado?
            </p>
            <RadioButtonContainer
              className="col-md-12"
              data-test="confirmation-data-steps-RadioButtonContainer"
            >
              {loaded ? (
                <>
                  <RadioButton data-test="confirmation-data-steps-RadioButton">
                    <input
                      type="radio"
                      name="radio1"
                      id="radio1"
                      onChange={() => setBrazilian(false)}
                      defaultChecked={!brazilian}
                    />
                    <label htmlFor="radio1">Não</label>
                  </RadioButton>

                  <RadioButton data-test="confirmation-data-steps-RadioButton">
                    <input
                      type="radio"
                      name="radio1"
                      id="radio2"
                      onChange={() => setBrazilian(true)}
                      defaultChecked={brazilian}
                    />
                    <label htmlFor="radio2">Sim</label>
                  </RadioButton>
                </>
              ) : null}
            </RadioButtonContainer>

            <div className="col-md-3">
              <p className="selectText">Documento de Identificação</p>

              <SelectInputGroup data-test="confirmation-data-steps-SelectInputGroup">
                {identificationDocument === 'Selecione o documento' ? (
                  <>
                    <input
                      id="documentSelect"
                      type="text"
                      className="hiddenInput"
                      name="hiddenInput"
                      required
                      onInvalid={() => {
                        set_IdDoc_Error_Flag(true)
                        handleValidationFocus('documentSelect', 'dropdownType')
                      }}
                    />
                  </>
                ) : null}
                <DropdownButton
                  className="dropPersonalized"
                  title={identificationDocument}
                  data-test="confirmation-data-steps-DropdownButton"
                >
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      setDocumentNumberFlag(false)
                      setOrgFlag(false)
                      handleSetDocumentId('CTPS')
                    }}
                  >
                    CTPS
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      setDocumentNumberFlag(false)
                      setOrgFlag(false)
                      handleSetDocumentId('RG')
                    }}
                  >
                    RG
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      setDocumentNumberFlag(false)
                      setOrgFlag(false)
                      handleSetDocumentId('CNH')
                    }}
                  >
                    CNH
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      setDocumentNumberFlag(false)
                      setOrgFlag(false)
                      handleSetDocumentId('Passaporte')
                    }}
                  >
                    Passaporte
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      setDocumentNumberFlag(false)
                      setOrgFlag(false)
                      handleSetDocumentId('RNE')
                    }}
                  >
                    RNE
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      setDocumentNumberFlag(false)
                      setOrgFlag(false)
                      handleSetDocumentId('IdentidadeMilitar')
                    }}
                  >
                    Identidade Militar
                  </Dropdown.Item>
                </DropdownButton>
              </SelectInputGroup>

              {idDoc_Error_Flag && (
                <>
                  <ErrorSpan data-test="confirmation-data-steps-span">
                    Campo obrigatório
                  </ErrorSpan>
                </>
              )}
            </div>
            {documentNumberFlag ? (
              <>
                <div className="col-md-3">
                  <TextInputRequired
                    title="Número do documento"
                    errorMessage="Campo obrigatório"
                    showErrorMessage={docNumber_Error_Flag ? true : false}
                  />
                  <InputMask
                    id="idGoesHere9"
                    onInvalid={() => {
                      // handleValidationFocus('idGoesHere9', 'inputType');
                      set_DocNumber_Error_Flag(true)
                    }}
                    data-test="confirmation-data-steps-input"
                    type="text"
                    placeholder="000000000"
                    name="documentNumber"
                    defaultValue={documentNumber}
                    required
                    onChange={onInputChange}
                  />
                </div>
              </>
            ) : null}

            {documentSerialNumberFlag ? (
              <>
                <div className="col-md-3">
                  <TextInputRequired
                    title="Série do documento"
                    errorMessage="Campo obrigatório"
                    showErrorMessage={docSerie_Error_Flag ? true : false}
                  />
                  <InputMask
                    id="idGoesHere10"
                    onInvalid={() => {
                      handleValidationFocus('idGoesHere10', 'inputType')
                      set_DocSerie_Error_Flag(true)
                    }}
                    data-test="confirmation-data-steps-input"
                    type="text"
                    placeholder="000000000"
                    name="documentSerialNumber"
                    defaultValue={serie}
                    required
                    onChange={onInputChange}
                  />
                </div>
              </>
            ) : null}

            {orgFlag ? (
              <>
                <div className="col-md-3">
                  <TextInputRequired
                    title="Orgão Emissor"
                    errorMessage="Campo obrigatório"
                    showErrorMessage={docOrg_Error_Flag ? true : false}
                  />
                  <InputMask
                    id="idGoesHere11"
                    onInvalid={() => {
                      handleValidationFocus('idGoesHere11', 'inputType')
                      set_DocOrg_Error_Flag(true)
                    }}
                    data-test="confirmation-data-steps-input"
                    type="text"
                    placeholder="Insira o órgão emissor..."
                    name="org"
                    defaultValue={issuingAgency}
                    required
                    onChange={onInputChange}
                  />
                </div>
              </>
            ) : null}
            {ufDoc ? (
              <>
                <div className="col-md-3">
                  <p className="selectText">UF de Emissão</p>
                  <SelectInputGroup data-test="confirmation-data-steps-SelectInputGroup">
                    {issuingState === 'Selecione UF' ? (
                      <>
                        <input
                          type="text"
                          id="ufState"
                          className="hiddenInput"
                          name="hiddenInput"
                          required
                          onInvalid={() => {
                            setUfEmissao_Error_Flag(true)
                            // focusWithErrorOcurred('ufState');
                          }}
                        />
                      </>
                    ) : null}
                    <DropdownButton
                      className="dropPersonalized"
                      title={issuingState}
                      data-test="confirmation-data-steps-DropdownButton"
                    >
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('AL')
                        }}
                      >
                        AL
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('AC')
                        }}
                      >
                        AC
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('AP')
                        }}
                      >
                        AP
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('AM')
                        }}
                      >
                        AM
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('BA')
                        }}
                      >
                        BA
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('CE')
                        }}
                      >
                        CE
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('DF')
                        }}
                      >
                        DF
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('ES')
                        }}
                      >
                        ES
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('GO')
                        }}
                      >
                        GO
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('MA')
                        }}
                      >
                        MA
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('MT')
                        }}
                      >
                        MT
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('MS')
                        }}
                      >
                        MS
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('MG')
                        }}
                      >
                        MG
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('PA')
                        }}
                      >
                        PA
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('PB')
                        }}
                      >
                        PB
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('PR')
                        }}
                      >
                        PR
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('PE')
                        }}
                      >
                        PE
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('PI')
                        }}
                      >
                        PI
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('RJ')
                        }}
                      >
                        RJ
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('RN')
                        }}
                      >
                        RN
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('RS')
                        }}
                      >
                        RS
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('RO')
                        }}
                      >
                        RO
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('RR')
                        }}
                      >
                        RR
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('SC')
                        }}
                      >
                        SC
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('SP')
                        }}
                      >
                        SP
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('SE')
                        }}
                      >
                        SE
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetUfName('TO')
                        }}
                      >
                        TO
                      </Dropdown.Item>
                    </DropdownButton>
                  </SelectInputGroup>
                  {ufEmissao_Error_Flag && (
                    <>
                      <ErrorSpan data-test="confirmation-data-steps-span">
                        Campo obrigatório
                      </ErrorSpan>
                    </>
                  )}
                </div>
              </>
            ) : null}
          </DivSection>
          <DivSection
            className="row"
            data-test="confirmation-data-steps-DivSection"
          >
            <div className="col-md-6 col-sm-12 col-sx-12">
              <p className="selectText">Estado Civil</p>
              <SelectInputGroup data-test="confirmation-data-steps-SelectInputGroup">
                {maritalStatus === 'Selecione o estado civil' ? (
                  <>
                    <input
                      type="text"
                      id="maritalStatus"
                      className="hiddenInput"
                      name="hiddenInput"
                      required
                      onInvalid={() => {
                        setEstado_Error_Flag(true)
                        // focusWithErrorOcurred('maritalStatus')
                      }}
                    />
                  </>
                ) : null}
                <DropdownButton
                  className="dropPersonalized"
                  title={maritalStatus}
                  data-test="confirmation-data-steps-DropdownButton"
                >
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      handleSetCivilStatusName('Solteiro(a)')
                    }}
                  >
                    Solteiro (a)
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      handleSetCivilStatusName('Casado(a)')
                    }}
                  >
                    Casado (a)
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      handleSetCivilStatusName('Viúvo(a)')
                    }}
                  >
                    Viúvo (a)
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      handleSetCivilStatusName('Separado(a)')
                    }}
                  >
                    Separado (a)
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      handleSetCivilStatusName('Divorciado(a)')
                    }}
                  >
                    Divorciado (a)
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      handleSetCivilStatusName('União Estável')
                    }}
                  >
                    União Estável
                  </Dropdown.Item>
                </DropdownButton>
              </SelectInputGroup>
              {estado_Error_Flag && (
                <>
                  <ErrorSpan data-test="confirmation-data-steps-span">
                    Campo obrigatório
                  </ErrorSpan>
                </>
              )}
            </div>
            <div className="col-md-6 col-sm-12 col-sx-12">
              <p className="selectText">
                Etnia/Cor
                <OverlayTrigger
                  data-test="confirmation-data-steps-OverlayTrigger"
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip2}
                >
                  <img src={infoSvg} alt="Info" />
                </OverlayTrigger>
              </p>
              <SelectInputGroup data-test="confirmation-data-steps-SelectInputGroup">
                {ethnicity === 'Selecione a etnia/cor' ? (
                  <>
                    <input
                      type="text"
                      className="hiddenInput"
                      name="hiddenInput"
                      id="ethnicity"
                      required
                      onInvalid={() => {
                        setEtnia_Error_Flag(true)
                        // focusWithErrorOcurred('ethnicity');
                      }}
                    />
                  </>
                ) : null}
                <DropdownButton
                  className="dropPersonalized"
                  title={ethnicity}
                  data-test="confirmation-data-steps-DropdownButton"
                >
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      handleSetRaceStatusName('Amarelo')
                    }}
                  >
                    Amarelo
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      handleSetRaceStatusName('Branco')
                    }}
                  >
                    Branco
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      handleSetRaceStatusName('Pardo')
                    }}
                  >
                    Pardo
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      handleSetRaceStatusName('Preto')
                    }}
                  >
                    Preto
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      handleSetRaceStatusName('Indígena')
                    }}
                  >
                    Indígena
                  </Dropdown.Item>
                </DropdownButton>
              </SelectInputGroup>
              {etnia_Error_Flag && (
                <>
                  <ErrorSpan data-test="confirmation-data-steps-span">
                    Campo obrigatório
                  </ErrorSpan>
                </>
              )}
            </div>
            <p className="infoText">
              O candidato é PCD (Pessoa com deficiência)?
              <OverlayTrigger
                data-test="confirmation-data-steps-OverlayTrigger"
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip3}
              >
                <img src={infoSvg} alt="Info" />
              </OverlayTrigger>
            </p>
            <RadioButtonContainer
              data-test="confirmation-data-steps-RadioButtonContainer"
              className="col-md-12"
            >
              {loaded ? (
                <>
                  <RadioButton data-test="confirmation-data-steps-RadioButton">
                    <input
                      type="radio"
                      name="radio2"
                      id="radio3"
                      onChange={() => setDisabilityFlag(false)}
                      defaultChecked={!disabilityFlag}
                    />
                    <label htmlFor="radio3">Não</label>
                  </RadioButton>

                  <RadioButton data-test="confirmation-data-steps-RadioButton">
                    <input
                      type="radio"
                      name="radio2"
                      id="radio4"
                      onChange={() => setDisabilityFlag(true)}
                      defaultChecked={disabilityFlag}
                    />
                    <label htmlFor="radio4">Sim</label>
                  </RadioButton>
                </>
              ) : null}
            </RadioButtonContainer>
            {disabilityFlag ? (
              <>
                <div className="col-md-6 col-sm-12 col-sx-12">
                  <p className="selectText">Se sim, selecionar a deficiência</p>
                  <SelectInputGroup data-test="confirmation-data-steps-SelectInputGroup">
                    {disabilityType === 'Selecione a deficiência' ? (
                      <>
                        <input
                          id="disabilityType"
                          type="text"
                          className="hiddenInput"
                          name="hiddenInput"
                          required
                          onInvalid={() => {
                            setDeficiencia_Error_Flag(true)
                            // focusWithErrorOcurred('disabilityType');
                          }}
                        />
                      </>
                    ) : null}
                    <DropdownButton
                      data-test="confirmation-data-steps-DropdownButton"
                      className="dropPersonalized"
                      title={disabilityType}
                    >
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetDefStatusName('Física')
                        }}
                      >
                        Física
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetDefStatusName('Auditiva')
                        }}
                      >
                        Auditiva
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetDefStatusName('Visual')
                        }}
                      >
                        Visual
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetDefStatusName('Mental')
                        }}
                      >
                        Mental
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleSetDefStatusName('Múltipla')
                        }}
                      >
                        Múltipla
                      </Dropdown.Item>
                    </DropdownButton>
                  </SelectInputGroup>
                  {deficiencia_Error_Flag && (
                    <>
                      <ErrorSpan data-test="confirmation-data-steps-span">
                        Campo obrigatório
                      </ErrorSpan>
                    </>
                  )}
                </div>
              </>
            ) : null}
          </DivSection>

          <Button
            data-test="confirmation-data-steps-ButtonNext"
            className="next"
            type="submit"
            form="form1"
            defaultValue="Submit"
          >
            Confirmar
          </Button>
        </form>
      </Wrapper>
    </>
  )
}
