import React, { useEffect, useState } from 'react'

import sisuLogo from 'assets/logo/sisu.svg'

import { FaCalendar } from 'react-icons/fa'

import {
  CardContent,
  Container,
  DateCard,
  FooterCard,
  SubscribeArea,
} from './styles'

import { Link } from 'react-router-dom'
import { apiWithToken } from 'services/apiWithToken'
import { useUser } from 'hooks/useUser'
import { scheduleFromApi } from './adapters/fromApi'
import { isSubscribedInSisuFromApi } from './adapters/isSubscribedInSisuFromApi'

export const SisuProgramCard = () => {
  const { cpf } = useUser()

  const [isSubscribedInSisu, setIsSubscribedInSisu] = useState(false)
  const [schedule, setSchedule] = useState({
    stage: '',
    start: '',
    end: '',
    year: '',
    active: false,
  })

  useEffect(() => {
    const loadSubscribes = async () => {
      const { data } = await apiWithToken.get(`/user-registration/${cpf}`)

      const isSubscribedInSisuMapped = isSubscribedInSisuFromApi(data)

      setIsSubscribedInSisu(isSubscribedInSisuMapped)
    }

    if (!!cpf) {
      loadSubscribes()
    }
  }, [cpf])

  useEffect(() => {
    const loadSchedule = async () => {
      try {
        const { data } = await apiWithToken.get('/schedule/program', {
          params: { programName: 'sisu' },
        })
        debugger;
        const scheduleMapped = scheduleFromApi(data)
        setSchedule(scheduleMapped)
      } catch (err) {
        console.error(err)
      }
    }

    loadSchedule()
  }, [])

  return (
    <Container>
      <header>
        <img src={sisuLogo} alt="" />
      </header>
      <CardContent>
        <strong>SISU</strong>
        <p>
          Inscreva-se e escolha até duas das opções de curso superior ofertadas
          por instituições públicas.
        </p>
        <SubscribeArea>
          <DateCard>
            <div className={schedule.active && 'active'}>
              <FaCalendar />
            </div>
            <div>
              <h3 className={schedule.active && 'subscribeActive'}>
                {schedule.stage}
              </h3>
              <p>
                {schedule.start} até {schedule.end} de {schedule.year}
              </p>
            </div>
          </DateCard>
        </SubscribeArea>
        <FooterCard>
          {!isSubscribedInSisu && schedule.active && (
            <Link
              to={{
                pathname: '/sisu',
                state: { cameFromSubscribeButton: true },
              }}
              className="w-100"
            >
              <button className="subscribeButton">Inscrever</button>
            </Link>
          )}
          {isSubscribedInSisu && (
            <Link to="/SisuFollowRegistration" className="isSubscribed">
              Acompanhar Inscrição
            </Link>
          )}
          <Link
            target="_blank"
            to="//acessounico.mec.gov.br/sisu/"
            rel="noopener noreferrer"
          >
            Saiba Mais Sobre o SISU
          </Link>
          <Link
            target="_blank"
            to="//acessounico.mec.gov.br/sisu/duvidas/"
            rel="noopener noreferrer"
          >
            Dúvidas Frequentes
          </Link>
        </FooterCard>
      </CardContent>
    </Container>
  )
}
