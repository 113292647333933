import styled from 'styled-components'

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.palette.grey[400]};
  padding: 10px;
`

export const NextArrow = styled.div`
  cursor: pointer;
`

export const PrevArrow = styled.div`
  cursor: pointer;
`
export const TextPagination = styled.p`
  padding: 0 10px;
  margin: 0;
`
