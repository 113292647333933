import React, { useEffect, useState } from 'react'

import wrapper from 'components/Wrapper'

import { CONFIRMATION_ROUTES } from './constants/routes'
import { DataConfirm } from 'components/DataConfirm'
import Context from 'components/DataConfirm/Context'
import { DataConfirmSteps } from 'components/DataConfirmSteps'
import BreadcrumbWrapper from 'components/Breadcrumb'
import homeSvg from '../../assets/img/breadcrumb/homeIcon.svg'
import { Link, Redirect } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { awsTokenManager } from 'config/auth'
import axios from 'axios'
import { LoadingFixedScreen } from './styles'

const Confirmation = props => {
  useEffect(() => {
    window.scrollTo(0, 0)

    try {
      setFromCandidatePage(props.location.state.step)
      setStep1(true)
      console.log('try')
      console.log(props.location.state.step, '**********************')
    } catch {
      setFromCandidatePage(false)
      console.log('catch')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [click, setClick] = useState(false)
  const [showSteps, setShowSteps] = useState(false)
  const [name, setName] = useState('')

  useEffect(() => {
    if (click) {
      setShowSteps(true)
      setStep1(true)
    }
  }, [click])

  const getUser = async () => {
    setLoaded(false)
    try {
      const token = await awsTokenManager.getToken()
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      }
      let data = await axios
        .get(`${process.env.REACT_APP_API_URL}/users/me`, config)
        .then(({ data }) => data)

      setName(data.name)
      setLoaded(true)
      setStep0(true)
    } catch (err) {
      console.log(err)
    }
  }

  const [cpf, setCpf] = useState('')
  const [step0, setStep0] = useState(false)
  const [step1, setStep1] = useState(false)
  const [step2, setStep2] = useState(false)
  const [step3, setStep3] = useState(false)
  // const [step4, setStep4] = useState(false);
  const [fromCandidatePage, setFromCandidatePage] = useState(false)
  const [redirect, setRedirect] = useState(false)

  // const [fromCandidatePage] = useState(props.location.state.step);

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(false)
    const getUserStep = async () => {
      try {
        const token = await awsTokenManager.getToken()
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        }
        let data = await axios
          .get(`${process.env.REACT_APP_API_URL}/users/me`, config)
          .then(({ data }) => data)

        setCpf(data.cpf)

        try {
          const config = {
            headers: { Authorization: `${token}` },
          }

          let data = await axios
            .get(
              `${process.env.REACT_APP_API_URL}/user-relative/relatives/${cpf}`,
              config
            )
            .then(({ data }) => data)
          if (data.length !== 0) {
            // setLoaded(true);
            // setStep4(true);
            // setShowSteps(true);
            setRedirect(true)
          } else {
            try {
              const config = {
                headers: { Authorization: `${token}` },
              }

              let data = await axios
                .get(
                  `${process.env.REACT_APP_API_URL}/users-education?name=${cpf}`,
                  config
                )
                .then(({ data }) => data)
              if (data.length !== 0) {
                setLoaded(true)
                setStep3(true)
                setShowSteps(true)
              } else {
                try {
                  const config = {
                    headers: { Authorization: `${token}` },
                  }

                  let data = await axios
                    .get(
                      `${process.env.REACT_APP_API_URL}/users-address?name=${cpf}`,
                      config
                    )
                    .then(({ data }) => data)
                  if (data.length !== 0) {
                    setLoaded(true)
                    setStep2(true)
                    setShowSteps(true)
                  } else {
                    try {
                      const config = {
                        headers: { Authorization: `${token}` },
                      }

                      let data = await axios
                        .get(
                          `${process.env.REACT_APP_API_URL}/user-information/${cpf}`,
                          config
                        )
                        .then(({ data }) => data)
                      if (data.length !== 0) {
                        setLoaded(true)
                        setStep1(true)
                        setShowSteps(true)
                      } else {
                        getUser()
                      }
                    } catch (err) {
                      console.log(err)
                    }
                  }
                } catch (err) {
                  console.log(err)
                }
              }
            } catch (err) {
              console.log(err)
            }
          }
        } catch (err) {
          console.log(err)
        }
      } catch (err) {
        console.log(err)
      }
    }

    if (fromCandidatePage) {
      setLoaded(true)
      setStep1(true)
      setShowSteps(true)
      console.log('entrou no from candidate')
    } else {
      getUserStep()
    }
  }, [cpf, fromCandidatePage])

  // const [fullName, setFullName] = useState('');
  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');

  // const mappedName = 'Victor Saisse'.split(' ');
  // console.log(mappedName);
  // setFullName('teste');

  // setFullName(mappedName);
  // if (fullName.length === 1) {
  //   setFirstName(fullName);
  //   setLastName('');
  // } else if (fullName.length > 1) {
  //   setFirstName(fullName[0]);
  //   setLastName(fullName[fullName.length - 1]);
  // } else {
  //   setFirstName('Usuário (a)');
  //   setLastName('');
  // }

  // console.log(firstName + ' ' + lastName);

  return (
    <>
      {loaded ? null : (
        <>
          <LoadingFixedScreen data-test="LoadingFixedScreen">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </LoadingFixedScreen>
        </>
      )}

      <BreadcrumbWrapper>
        <Link to="/initial-page">
          <img src={homeSvg} alt="" />
        </Link>
        <Typography>Confirmação de Dados</Typography>
      </BreadcrumbWrapper>
      {step0 ? (
        <>
          <Context.Provider
            value={{
              setClick,
            }}
          >
            {!showSteps ? (
              <DataConfirm
                main_title="Confirmação de dados"
                hello_name={<>Olá, {name}!</>}
                description={
                  <>
                    Nos
                    <span> próximos passos </span>
                    iremos confirmar alguns dados sobre você e também adiantar
                    algumas informações para facilitar sua inscrição nos
                    programas.
                    <br />
                    <br />
                    As informações que iremos pedir estão relacionadas a:
                  </>
                }
                description_list={
                  <>
                    <li>Dados sobre você </li>
                    <li>Dados de contato </li>
                    <li>Dados socioeconômicos </li>
                    <li>Dados escolares</li>
                  </>
                }
                button_text="Vamos lá!"
              />
            ) : null}
          </Context.Provider>
        </>
      ) : null}

      {showSteps || fromCandidatePage ? (
        <>
          <DataConfirmSteps
            step1={step1}
            step2={step2}
            step3={step3}
            // step4={step4}
          />
        </>
      ) : null}
      {redirect ? <Redirect push to="/candidate-page" /> : null}
    </>
  )
}

export default wrapper(Confirmation, {
  route: { path: CONFIRMATION_ROUTES.CONFIRMATION },
})
