import {
  CourseContainer,
  CourseHeader,
  CourseBody,
  CourseFooter,
  CourseLocation,
  CourseTime,
  CourseLastInformation,
  AboutCourse,
  TextsHeader,
} from './style'

import starImg from './img/star.svg'
import starFill from './img/starFill.svg'
import locationImage from './img/locationCourse.svg'
import clockImage from './img/clockImage.svg'
export function CardCourse({
  title,
  subTitle,
  college,
  location,
  state,
  timeType,
  time,
  vacancies,
  affirmativeAction,
  note,
}) {
  return (
    <CourseContainer>
      <CourseHeader>
        <TextsHeader>
          <h1>{title}</h1>
          <p>{subTitle}</p>
        </TextsHeader>
        <CourseTime>
          <img src={clockImage} alt="" />
          <p>{timeType}</p>
        </CourseTime>
        <p className="concept">
          Conceito
          <span>
            <img src={starFill} alt="star" />
            <img src={starFill} alt="star" />
            <img src={starFill} alt="star" />
            <img src={starFill} alt="star" />
            <img src={starImg} alt="star" />
          </span>
        </p>
      </CourseHeader>
      <CourseBody>
        <h2>{college}</h2>
        <CourseLocation>
          <img src={locationImage} alt="" />
          <p>
            {location}
            <br />({state})
          </p>
        </CourseLocation>

        <CourseLastInformation>
          <AboutCourse>
            <h3>Total de Vagas</h3>
            <span>{vacancies}</span>
          </AboutCourse>
          <AboutCourse>
            <h3>Acões Afirmativas?</h3>
            <span>{affirmativeAction}</span>
          </AboutCourse>
        </CourseLastInformation>
      </CourseBody>
      <CourseFooter>
        <h1>Nota de Corte</h1>
        <span>{note}</span>
      </CourseFooter>
    </CourseContainer>
  )
}
