export const schoolToApi = user => {
  return {
    conclusionYear: user.conclusionYear,
    brazilianSchoolFlag: user.brazilianSchoolFlag,
    schoolCountry: user.schoolCountry,
    schoolType: user.schoolType,
    professorFlag: user.professorFlag,
    academicFlag: user.academicFlag,
    superiorFlag: user.superiorFlag,
    publicFlag: user.publicFlag,
    courseType: user.courseType,
    schoolName: user.schoolName,
    schoolGrade: user.schoolGrade,
  }
}
