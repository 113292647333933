import styled from 'styled-components'

import { FONT_FAMILY } from 'constants/fonts'

export const Wrapper = styled.div`
  padding: 0;
  margin: 0;
  margin-top: 28px;

  position: relative;

  font-family: ${FONT_FAMILY};

  p {
    margin: 0 0 4px 0;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: var(--colorBlack);

    span {
      position: initial;
      font-size: 30px;
      margin-bottom: -5px;
      margin-left: 2px;
    }
  }

  input {
    height: 51px;
    width: 100%;

    padding-left: 12px;

    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: var(--colorBlack);

    &::placeholder {
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: var(--colorBlack);

      opacity: 0.5;
    }

    &:not(:placeholder-shown) {
      background: #f3f3f3;
      border: 1px solid #cccccc;
      border-radius: 4px;
    }
  }

  span {
    position: absolute;
    bottom: -20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #da0000;
  }
`

export const LoadingFixedScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999999999999999;
  height: 100vh;
  width: 100vw;
  background: #00000030;

  display: flex;
  align-items: center;
  justify-content: center;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
