import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'

import { ThemeProvider as SCThemeProvider } from 'styled-components'
import GlobalStyles from 'assets/styles/global'
import muiTheme from 'assets/styles/theme'

import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles'

import store from 'config/store/store'

import 'config/i18n'
import 'config/awsAmplify/amplify-config'
import 'config/yup'

import NotificationProvider from 'modules/Notification/context/NotificationProvider'
import App from 'modules/App'
import { LoadingProvider } from 'hooks/useLoading'
import { Loading } from 'components/_components/Loading'
import { UserProvider } from 'hooks/useUser'

// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <ReduxProvider store={store}>
        <MuiThemeProvider theme={muiTheme}>
          <SCThemeProvider theme={muiTheme}>
            <NotificationProvider>
              <LoadingProvider>
                <UserProvider>
                  <GlobalStyles />
                  <App />
                  <Loading />
                </UserProvider>
              </LoadingProvider>
            </NotificationProvider>
          </SCThemeProvider>
        </MuiThemeProvider>
      </ReduxProvider>
    </StylesProvider>
  </React.StrictMode>,
  // <React.StrictMode>
  //   <App></App>
  // </React.StrictMode>,
  document.getElementById('root')
)
