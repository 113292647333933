import React, {useMemo } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import infoImage from 'assets/img/info.svg'

import { Container } from './styles'
import { format } from 'date-fns'

export const ModalityCard = ({
  modality: {
    codeDescription,
    vacancies,
    totalCandidate,
    canSubscribe,
    minGrade,
    reason,
    ldescription,
    lastUpdate,
  },
  onGetModalitySelected,
  modalitySelected,
  bestScore,
}) => {
  const lastUpdateFormatted = useMemo(() => {
    return format(new Date(lastUpdate), "dd'/'MM'/'yyyy' às ' HH'h'mm")
  }, [lastUpdate])

  return (
    <Container
      canSubscribe={canSubscribe}
      selected={codeDescription === modalitySelected}
      onClick={
        canSubscribe ? () => onGetModalitySelected(codeDescription) : null
      }
    >
      <header>
        {canSubscribe ? (
          <input type="radio" checked={codeDescription === modalitySelected} />
        ) : (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={props => (
              <Tooltip id="tooltip1" {...props}>
                {reason}
              </Tooltip>
            )}
          >
            <img src={infoImage} alt="" />
          </OverlayTrigger>
        )}
        <p>{codeDescription}</p>
      </header>

      <div>
        <h1>{vacancies} Vagas</h1>
        <h2>{ldescription}</h2>
        <h3>Nota de corte: {minGrade || 'Indisponível'}</h3>
        <h4>Melhor nota válida do ENEM: {bestScore}</h4>
        <h5>{totalCandidate} candidatos inscritos</h5>
        <h6>Última atualização: {lastUpdateFormatted}</h6>
      </div>
    </Container>
  )
}
