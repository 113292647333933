export const relativesFromApi = data => {
  const relativesMapped = data.map(item => {
    const dateMap = item.birthdate.replace(/[^\d]/g, '')
    const mapSplit = dateMap.split('')
    const dateYear = mapSplit[0] + mapSplit[1] + mapSplit[2] + mapSplit[3]
    const dateMonth = mapSplit[4] + mapSplit[5]
    const dateDay = mapSplit[6] + mapSplit[7]
    const newBirthDate = dateDay + '/' + dateMonth + '/' + dateYear

    return {
      birthdate: newBirthDate,
      cpf: item.cpf,
      degreeKinship: item.degreeKinship,
      individualIncome: item.individualIncome,
      name: item.name,
      phoneNumber: item.phoneNumber,
    }
  })

  return relativesMapped
}
