import React from 'react'
import { Container } from 'react-bootstrap'

import { Breadcrumbs as MUIBreadcrumbs } from '@material-ui/core'
import { Wrapper } from './styles'

const BreadcrumbWrapper = ({ children }) => {
  return (
    <>
      <Wrapper>
        <Container className="containerWrapper">
          <div className="row">
            <MUIBreadcrumbs
              separator={[
                <svg
                  width="5"
                  height="9"
                  viewBox="0 0 5 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.75 4.89844C4.96094 4.6875 4.96094 4.33594 4.75 4.10156L1.5625 0.914062C1.32812 0.703125 0.976562 0.703125 0.765625 0.914062L0.226562 1.45312C0.015625 1.6875 0.015625 2.03906 0.226562 2.25L2.5 4.52344L0.226562 6.77344C0.015625 6.98438 0.015625 7.33594 0.226562 7.57031L0.765625 8.08594C0.976562 8.32031 1.32812 8.32031 1.5625 8.08594L4.75 4.89844Z"
                    fill="#CCCCCC"
                  />
                </svg>,
              ]}
              aria-label="breadcrumb"
            >
              {children}
            </MUIBreadcrumbs>
          </div>
        </Container>
      </Wrapper>
    </>
  )
}

export default BreadcrumbWrapper
