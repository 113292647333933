import React from 'react'
import { Wrapper } from './styles'

export function TextInputOptional(props) {
  return (
    <>
      <Wrapper data-test="confirmation-data-steps-Wrapper">
        <p data-test="confirmation-data-steps-p">{props.title}</p>
        {props.showErrorMessage ? (
          <span data-test="confirmation-data-steps-span">
            {props.errorMessage}
          </span>
        ) : null}
      </Wrapper>
    </>
  )
}
