import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin-top: 1.25rem;
  border-radius: 0px 0px 4px 4px;

  > header {
    border-radius: 4px 4px 0px 0px;

    background: #e3e3e3;
    display: flex;
    gap: 0.8rem;
    padding: 0.5625rem 1.5625rem;
    align-items: center;
    transition: all 0.2s;

    > input {
      transform: scale(1.5);
    }

    p {
      margin: 0;
      padding: 0;
      color: #8a8a8a;
      font-weight: 500;
    }
  }

  > div {
    border: 1px solid #e3e3e3;
    border-radius: 0px 0px 4px 4px;

    background: #f2f2f2;
    padding: 2.5rem;
    transition: all 0.2s;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      padding: 0;
    }

    h1 {
      font-weight: 500;
      font-size: 1.5rem;
      color: #8a8a8a;
    }

    h2 {
      font-weight: 500;
      font-size: 0.875rem;
      color: #a5a5a5;
    }

    h3 {
      font-weight: 500;
      font-size: 1.25rem;
      color: #8a8a8a;
      margin-top: 1.5rem;
    }

    h4 {
      font-weight: 400;
      font-size: 1rem;
      color: #8a8a8a;
    }

    h5 {
      font-weight: 300;
      font-size: 0.875rem;
      margin-top: 1rem;
      color: #8a8a8a;
    }

    h6 {
      font-weight: 400;

      margin-top: 0.5rem;
      color: #8a8a8a;
      font-size: 0.625rem;
    }
  }

  ${({ canSubscribe }) =>
    canSubscribe &&
    css`
      cursor: pointer;

      > header {
        background: #bbbbbb;

        > p {
          color: #000000;
        }
      }

      > div {
        border-color: #cccccc;

        > h1 {
          color: #000000;
        }

        > h2 {
          color: #555555;
        }

        > h3 {
          color: #000000;
        }

        > h4 {
          color: #000000;
        }

        > h5 {
          color: #000000;
        }

        > h6 {
          color: #000000;
        }
      }
    `}

  ${({ selected }) =>
    selected &&
    css`
      cursor: pointer;

      > header {
        background: #1351b4;
        border-bottom: 0;

        > p {
          color: ${({ theme }) => theme.bgColors.colorSecondary01};
        }
      }

      > div {
        background: #0071bc;
        border-color: #0071bc;

        > h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: ${props => props.theme.bgColors.colorSecondary01};
        }
      }
    `}
`
