export const userToApi = user => {
  return {
    fullName: user.fullName,
    cpf: user.cpf,
    useSocialName: user.useSocialName,
    socialName: user.socialName,
    gender: user.gender,
    birthdate: user.birthdate,
    uf: user.uf,
    birthPlace: user.birthPlace,
    motherName: user.motherName,
    responsibleCpf: user.responsibleCpf,
    responsibleName: user.responsibleName,
    responsibleContact: user.responsibleContact,
    brazilian: user.brazilian,
    identificationDocument: user.identificationDocument,
    maritalStatus: user.maritalStatus,
    ethnicity: user.ethnicity,
    disabilityFlag: user.disabilityFlag,
    disabilityType: user.disabilityType,
    documentNumber: user.documentNumber,
    issuingAgency: user.issuingAgency,
    issuingState: user.issuingState,
    serie: user.serie,
  }
}
