import {
  CardConfirmCourseContainer,
  CardConfirmHeader,
  CardCourseName,
  CardCourseLocation,
  CardCourseModality,
  CardConfirmFooter,
  CourseTime,
  CourseLocation,
} from './style'
import clockImage from './img/clockImage.svg'
import locationImage from './img/locationCourse.svg'
import React, { useEffect, useState } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { useHistory } from 'react-router-dom'
import { useLoading } from 'hooks/useLoading'
import { apiWithToken } from 'services/apiWithToken'
import { useUser } from 'hooks/useUser'

export function CardConfirmCourse({
  courseCode,
  program,
  option,
  editPage,
  selectCourse2,
  courseCodeChange,
  changeCourse,
  isEdit,
  courseName,
  modality,
  courseTime,
  university,
  city,
  uf,
  hideDocument,
  campusNeighborhood,
  campusState,
  courseState,
  courseLocation,
  modalityType,
  modalityTypeChange,
}) {
  const { loading } = useLoading()
  const history = useHistory()
  const { cpf } = useUser()

  const [sisuEdition, setSisuEdition] = useState(0)

  useEffect(() => {
    const loadSchedule = async () => {
      try {
        loading.turnOn()

        const { data } = await apiWithToken.get('/schedule/program', {
          params: { programName: 'sisu' },
        })

        setSisuEdition(data.editions[0].id)
      } catch (err) {
        console.error(err)
      } finally {
        loading.turnOff()
      }
    }

    loadSchedule()
  }, [])

  const onSwitchBetweenCourseOptions = async () => {
    loading.turnOn()

    try {
      try {
        await apiWithToken.delete(`/user-registration/${cpf}`, {
          params: {
            courseCode,
            editionId: sisuEdition,
          },
        })
      } catch (err) {
        console.log(err)
      }

      try {
        if (typeof courseCodeChange !== 'undefined') {
          await apiWithToken.delete(`/user-registration/${cpf}`, {
            params: {
              courseCode: courseCodeChange,
              editionId: sisuEdition,
            },
          })
        }

        try {
          const courseMapped = {
            courseCode: courseCode,
            option: option === 1 ? 'option2' : 'option1',
            edition: sisuEdition,
            modality: modalityType,
          }

          await apiWithToken.post(`/user-registration/${cpf}`, courseMapped)

          if (typeof courseCodeChange === 'undefined') {
            window.location.reload(true)
          } else {
            try {
              const courseMapped = {
                courseCode: courseCodeChange,
                option: option === 1 ? 'option1' : 'option2',
                edition: sisuEdition,
                modality: modalityTypeChange,
              }

              await apiWithToken.post(`/user-registration/${cpf}`, courseMapped)

              window.location.reload(true)
            } catch (err) {
              console.log(err)
            }
          }
        } catch (err) {
          console.log(err)
        }
      } catch (err) {
        console.log(err)
      }
    } catch (err) {
      console.log(err)
    }

    loading.turnOff()
  }

  const onCancelCourse = async () => {
    loading.turnOn()

    try {
      await apiWithToken.delete(`/user-registration/${cpf}`, {
        params: {
          courseCode,
          editionId: sisuEdition,
        },
      })

      if (option === '2') {
        if (editPage === true) {
          history.push('/sisu')
        } else {
          window.location.reload(true)
        }
      } else {
        if (selectCourse2 === true) {
          try {
            await apiWithToken.delete(`/user-registration/${cpf}`, {
              params: {
                courseCode: courseCodeChange,
                editionId: sisuEdition,
              },
            })

            const courseMapped = {
              courseCode: courseCodeChange,
              edition: sisuEdition,
              modality: modalityTypeChange,
              option: 'option1',
            }

            await apiWithToken.post(`/user-registration/${cpf}`, courseMapped)

            if (editPage === true) {
              history.push('/sisu')
            } else {
              window.location.reload(true)
            }
          } catch (err) {
            console.log(err)
          }
        } else {
          if (editPage === true) {
            history.push('/sisu')
          } else {
            window.location.reload(true)
          }
        }
      }
    } catch (err) {
      console.log(err)
    }

    loading.turnOff()
  }

  const onChangeCourse = async () => {
    try {
      loading.turnOn()

      await apiWithToken.delete(`/user-registration/${cpf}`, {
        params: {
          courseCode,
          editionId: sisuEdition,
        },
      })

      if (editPage) {
        history.push('/sisu')

        return
      }

      changeCourse()
    } catch (err) {
      console.log(err)
    } finally {
      loading.turnOff()
    }
  }

  return (
    <CardConfirmCourseContainer>
      <CardConfirmHeader>
        <h1>{option}ª OPÇÃO DE CURSO</h1>

        {isEdit && (
          <OverlayTrigger
            trigger="click"
            key="bottom"
            placement="bottom"
            overlay={
              <Popover id={`popover-positioned-bottom`}>
                <Popover.Body>
                  <p onClick={onSwitchBetweenCourseOptions}>
                    Trocar para {option === 1 ? '2' : '1'}ª opção
                  </p>
                  <p onClick={onCancelCourse}>Cancelar Curso</p>
                  <p onClick={onChangeCourse}>Alterar Curso</p>
                </Popover.Body>
              </Popover>
            }
          >
            <span>
              <svg
                width="6"
                height="16"
                viewBox="0 0 6 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 5.75C2.375 5.75 1.84375 5.96875 1.40625 6.40625C0.96875 6.84375 0.75 7.375 0.75 8C0.75 8.625 0.96875 9.15625 1.40625 9.59375C1.84375 10.0312 2.375 10.25 3 10.25C3.625 10.25 4.15625 10.0312 4.59375 9.59375C5.03125 9.15625 5.25 8.625 5.25 8C5.25 7.375 5.03125 6.84375 4.59375 6.40625C4.15625 5.96875 3.625 5.75 3 5.75ZM0.75 2.5C0.75 1.875 0.96875 1.34375 1.40625 0.90625C1.84375 0.46875 2.375 0.25 3 0.25C3.625 0.25 4.15625 0.46875 4.59375 0.90625C5.03125 1.34375 5.25 1.875 5.25 2.5C5.25 3.125 5.03125 3.65625 4.59375 4.09375C4.15625 4.53125 3.625 4.75 3 4.75C2.375 4.75 1.84375 4.53125 1.40625 4.09375C0.96875 3.65625 0.75 3.125 0.75 2.5ZM0.75 13.5C0.75 12.875 0.96875 12.3438 1.40625 11.9062C1.84375 11.4688 2.375 11.25 3 11.25C3.625 11.25 4.15625 11.4688 4.59375 11.9062C5.03125 12.3438 5.25 12.875 5.25 13.5C5.25 14.125 5.03125 14.6562 4.59375 15.0938C4.15625 15.5312 3.625 15.75 3 15.75C2.375 15.75 1.84375 15.5312 1.40625 15.0938C0.96875 14.6562 0.75 14.125 0.75 13.5Z"
                  fill="#1351B4"
                />
              </svg>
            </span>
          </OverlayTrigger>
        )}
      </CardConfirmHeader>

      <CardCourseName>
        <h2>{courseName}</h2>
        <p>{modality}</p>
        <CourseTime>
          <img src={clockImage} alt="" />
          <p>{courseTime}</p>
        </CourseTime>
      </CardCourseName>

      <CardCourseLocation>
        <h2>{university}</h2>
        <CourseLocation>
          <img src={locationImage} alt="" />
          <p>
            {courseLocation}
            <br />({campusNeighborhood}, {courseState})
          </p>
        </CourseLocation>
      </CardCourseLocation>

      <CardCourseModality>
        <h2>Modalidade de concorrência</h2>
        <h3>{modalityType}</h3>
      </CardCourseModality>
      {!hideDocument && (
        <CardConfirmFooter>
          <button>Ver documentação Necessária</button>
        </CardConfirmFooter>
      )}
    </CardConfirmCourseContainer>
  )
}
