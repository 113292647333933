import React, { useContext } from 'react'
import { BarLoader } from 'react-spinners'

import PropTypes from 'prop-types'
import { ThemeContext } from 'styled-components'

import { Container } from './style'

export const DefaultLoader = ({ color, minHeight }) => {
  const theme = useContext(ThemeContext)

  if (!color) color = theme.palette.primary.main

  return (
    <Container minHeight={minHeight}>
      <BarLoader width="30%" color={color} />
    </Container>
  )
}

DefaultLoader.defaultProps = {
  color: undefined,
  minHeight: 0,
}

DefaultLoader.propTypes = {
  color: PropTypes.string,
  minHeight: PropTypes.number,
}
