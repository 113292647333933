/* eslint-disable class-methods-use-this */
import { Auth, Hub } from 'aws-amplify'
import { LocalStorage } from 'config/localStorage/localStorage'

import { AUTH_API } from './api'
import jwt_decode from 'jwt-decode'

import * as qs from 'qs'

export default class AuthService {
  storage

  COGNITO_SINGIN_KEY_PREPPEND = 'CognitoIdentityServiceProvider'

  AMPLIFY_SIGNIN_KEY = 'amplify-signin-with-hostedUI'

  ME_FAILED_KEY = 'ME_FAILED'

  WAITING_GOV_BR_LOGOUT = 'WAITING_GOV_BR_LOGOUT'

  /**
   * @param {object} props
   * @param {import('config/http/axios').Axios} props.http
   */
  constructor({ http }) {
    this.http = http
    this.storage = new LocalStorage()
  }

  async signIn(username, password) {
    const user = await Auth.signIn(username, password)
    return user
  }

  async signOut() {
    sessionStorage.clear()
    await Auth.signOut()
  }

  async accessTokenLogin(jwt, token, username) {
    const idTokenDecode = jwt_decode(token.id_token)
    if (
      !this.storage.get(
        `${this.COGNITO_SINGIN_KEY_PREPPEND}.${jwt.client_id}.${username}.accessToken`
      )
    ) {
      this.storage.set(this.AMPLIFY_SIGNIN_KEY, false)
      this.storage.set(
        `${this.COGNITO_SINGIN_KEY_PREPPEND}.${jwt.client_id}.LastAuthUser`,
        username
      )
      this.storage.set(
        `${this.COGNITO_SINGIN_KEY_PREPPEND}.${jwt.client_id}.${username}.idToken`,
        token.id_token
      )
      this.storage.set(
        `${this.COGNITO_SINGIN_KEY_PREPPEND}.${jwt.client_id}.${username}.accessToken`,
        token.access_token
      )
      this.storage.set(
        `${this.COGNITO_SINGIN_KEY_PREPPEND}.${jwt.client_id}.${username}.refreshToken`,
        token.refresh_token
      )
      this.storage.set(
        `${this.COGNITO_SINGIN_KEY_PREPPEND}.${jwt.client_id}.${username}.clockDrift`,
        '0'
      )
      this.storage.set(
        `${this.COGNITO_SINGIN_KEY_PREPPEND}.${jwt.client_id}.${username}.userData`,
        {
          UserAttributes: [
            {
              Name: 'sub',
              Value: jwt.sub,
            },
            {
              Name: 'email_verified',
              Value: 'true',
            },
            {
              Name: 'email',
              Value: idTokenDecode.email,
            },
            {
              Name: 'provider',
              Value: idTokenDecode.identities[0].providerName,
            },
          ],
          Username: username,
        }
      )
    }

    const session = await this.restoreUserSession()

    return {
      user: {
        username,
      },
      session: session,
    }
  }

  async awsLogout() {
    try {
      await Auth.signOut({ global: true })
    } catch (error) {
      console.log('[awsLogout-ERROR]', error)
    }
  }

  async govBrSignOut() {
    this.getForceLogoutUrl()
  }

  clearAuthStorage() {
    const keys = Object.keys(this.storage.getStorage())
    for (let i = 0; i < keys.length; i++) {
      if (
        keys[i].includes(this.COGNITO_SINGIN_KEY_PREPPEND) ||
        keys[i].includes(this.AMPLIFY_SIGNIN_KEY)
      ) {
        this.storage.remove(keys[i])
      }
    }
  }

  async completeNewPassword({ challenge, password }) {
    await Auth.completeNewPassword(
      challenge,
      password,
      null,
      challenge.challengeParam.userAttributes
    )
  }

  async restoreUserSession() {
    try {
      const userSession = await Auth.currentAuthenticatedUser()
      const session = await userSession.getSignInUserSession()
      return session
    } catch (error) {
      console.log('[restoreUserSession-ERROR]', error)
      return null
    }
  }

  async validateRecaptcha(token) {
    const response = await this.http.post(AUTH_API.RECAPTCHA_VALIDATION, {
      gRecaptchaResponse: token,
      gRecaptchaVersion: 'v2',
    })
    return response
  }

  async transactionToken() {
    const response = await this.http.get(AUTH_API.TRANSACTION_TOKEN)
    return response
  }

  async getUserToken() {
    try {
      const currentSession = await Auth.currentSession()

      if (!currentSession.isValid()) return null

      return currentSession.getIdToken().getJwtToken()
    } catch (error) {
      return null
    }
  }

  registerListener(event, listener) {
    Hub.listen(event, listener)
  }

  getForceLogoutUrl() {
    const logoutUrl = `https://${process.env.REACT_APP_AUTH_URL}/logout?response_type=code&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID_NO_SECRET}&logout_uri=${process.env.REACT_APP_FRONTEND_URL}/logout&state=STATE&scope=openid+profile+aws.cognito.signin.user.admin`
    window.location.href = logoutUrl
  }

  setMeFailedFlag() {
    this.storage.set(this.ME_FAILED_KEY, true)
  }

  unsetMeFailedFlag() {
    this.storage.remove(this.ME_FAILED_KEY)
  }

  getMeFailedFlag() {
    return this.storage.get(this.ME_FAILED_KEY)
  }

  setIsWaitingGovBrAuthLogout() {
    this.storage.set(this.WAITING_GOV_BR_LOGOUT, true)
  }

  unsetIsWaitingGovBrAuthLogout() {
    this.storage.remove(this.WAITING_GOV_BR_LOGOUT)
  }

  getIsWaitingGovBrFlag() {
    return this.storage.get(this.WAITING_GOV_BR_LOGOUT)
  }

  async getFullToken(code) {
    const url = `https://${process.env.REACT_APP_AUTH_URL}/oauth2/token`
    const data = {
      code,
      grant_type: 'authorization_code',
      redirect_uri: `${process.env.REACT_APP_FRONTEND_URL}/token`,
      client_id: process.env.REACT_APP_COGNITO_CLIENT_ID_NO_SECRET,
    }
    const options = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }

    try {
      const response = await this.http.post(url, qs.stringify(data), options)
      return response
    } catch (e) {
      return null
    }
  }

  isAuthenticated() {
    return this.storage.get(
      `${this.COGNITO_SINGIN_KEY_PREPPEND}.${process.env.REACT_APP_COGNITO_CLIENT_ID_NO_SECRET}.LastAuthUser`
    )
  }
}
