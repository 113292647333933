/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
// import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom'

import history from 'config/history/history'

// import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper'

import { APP_ROUTES } from 'modules/App/constants/route'
import { AUTH_ROUTES } from 'modules/Auth/constants/routes'
import { authService, authUseCases } from 'services/providers/auth'
import { notificationUseCases } from 'modules/Notification/providers'

// import { TokenLoaderContainer, TokenWrapperContainer } from './style';
// import { Container } from '../../../App/submodules/NoAccess/style';
// import { PulseLoader } from 'react-spinners';
// import { ThemeContext } from 'styled-components';
// import { LogoContainer } from '../../../../components/PublicContainer/style';
// import { LogoRNP } from '../../../../components/Image';
import queryString from 'query-string'
import jwt_decode from 'jwt-decode'
import { INITIAL_PAGE_ROUTES } from 'modules/InitialPage/constants/routes'
import { USER_ROUTES } from '../../../User/constants/routes'
import { LoadingScreen } from 'components/LoadingScreen'

const Token = () => {
  // const theme = useContext(ThemeContext);
  // const translate = useLangContext();

  const login = async (jwtDecoded, token, username) => {
    try {
      const response = await authUseCases.accessTokenLogin(
        jwtDecoded,
        token,
        username
      )
      if (response.session) {
        return history.push(INITIAL_PAGE_ROUTES.INITIAL_PAGE)
      }
      return history.push(APP_ROUTES.NOT_ALLOWED)
    } catch (error) {
      notificationUseCases.error(error, { namespace: 'ERROR' })
      return history.push(APP_ROUTES.NOT_ALLOWED)
    }
  }

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search)
    const getFullToken = async () => {
      const token = await authService.getFullToken(queryParams.code)

      if (token) {
        const jwtDecoded = jwt_decode(token.access_token)
        const username = jwtDecoded.username.split('gov.br_')[1]
        await login(jwtDecoded, token, username)
      }
    }
    const init = () => {
      if (queryParams.code && !authUseCases.isAuthenticated()) {
        getFullToken()
      } else if (!authUseCases.isAuthenticated()) {
        return <Redirect to={USER_ROUTES.LOGIN} />
      }
    }
    setTimeout(() => init(), 2000)
  }, [])

  return (
    <>
      <LoadingScreen
        title="Aguarde por favor"
        description="Aguarde enquanto validamos o seu acesso..."
      />
    </>
    // <Container>
    //   <TokenWrapperContainer>
    //     <TokenLoaderContainer>
    //       <PulseLoader
    //         size="10px"
    //         margin="2px"
    //         color={theme.textColors.primaryLighten01}
    //       />
    //       <span>{translate('VALIDATION')}</span>
    //     </TokenLoaderContainer>

    //     <LogoContainer>
    //       <LogoRNP />
    //     </LogoContainer>
    //   </TokenWrapperContainer>
    // </Container>
  )
}

export default wrapper(Token, {
  namespace: 'TOKEN',
  route: {
    path: AUTH_ROUTES.TOKEN,
  },
})
