import { isAfter, isBefore, isWithinInterval, startOfDay } from 'date-fns'

const initialScheduleFromApi = {
  editions: [
    {
      activeSchedule: {
        id: '',
        stage: '',
        startDate: '',
        endDate: '',
        active: false,
      },
      editionYear: 0,
    },
  ],
}

const subscriptionStageFromApi = (start = '', end = '') => {
  const startDate = new Date(start)
  const endDate = new Date(end)

  const isSubscriptionDate = {
    before: isBefore(startOfDay(startDate), startOfDay(new Date())),
    between: isWithinInterval(startOfDay(new Date()), {
      start: startOfDay(startDate),
      end: startOfDay(endDate),
    }),
    after: isAfter(startOfDay(endDate), startOfDay(new Date())),
  }

  if (isSubscriptionDate.between) {
    return 'Inscrições Abertas'
  }

  if (isSubscriptionDate.after) {
    return 'Período de Inscrição'
  }

  if (isSubscriptionDate.before) {
    return 'Inscrições Encerradas'
  }

  return ''
}

const formatScheduleDate = date => {
  const dateFormatted = new Date(date).toLocaleDateString(
    { language: 'pt-br' },
    {
      day: '2-digit',
      month: '2-digit',
    }
  )

  return dateFormatted
}

export const scheduleFromApi = (data = initialScheduleFromApi) => {
  const { activeSchedule, editionYear } = data.editions[0]

  return {
    stage: subscriptionStageFromApi(
      activeSchedule.startDate,
      activeSchedule.endDate
    ),
    start: formatScheduleDate(activeSchedule.startDate),
    end: formatScheduleDate(activeSchedule.endDate),
    year: editionYear,
    active: activeSchedule.active,
  }
}
