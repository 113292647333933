import React, { useContext, useEffect } from 'react'
import { MessageBox } from '../MessageBox'
import InputMask from 'react-input-mask'
import CurrencyInput from 'react-currency-input'
import { format } from 'date-fns'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useState } from 'react'
import { FaTrashAlt, FaEdit } from 'react-icons/fa'
import {
  Table,
  Container,
  Col,
  Row,
  DropdownButton,
  Dropdown,
  Button,
  Modal,
} from 'react-bootstrap'

import {
  Display,
  TitleArea,
  FamilyGroup,
  ButtonSetData,
  Wrapper,
  DivSection,
  SelectInputGroup,
  ButtonNext,
} from './styles'
import { Divider } from '@material-ui/core'
import { TextInputRequired } from '../TextInputRequired'
import { relativeToApi } from '../adapters/relativeToApi'
import { awsTokenManager } from 'config/auth'
import axios from 'axios'
import FormContext4 from '../formContext4'
import { LoadingFixedScreen } from '../styles'
import { Redirect } from 'react-router-dom'
import { editRelativeToApi } from '../adapters/editRelativeToApi'
import { clearNonNumericCharacters } from 'utils/clearNonNumericCharacters'
import { isValidCPF } from 'utils/numberValidation'

export function StepFour() {
  useEffect(() => {
    window.scrollTo(0, 0)
    setFormContent4(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function scrollTop() {
    window.scrollTo(0, 500)
  }

  const [redirect, setRedirect] = useState(false)
  const [firstPost, setFirstPost] = useState(false)
  const [relativesNumber, setRelativesNumber] = useState(0)

  const [show, setShow] = useState(false)
  const [newMember, setNewMember] = useState(false)
  const [editMember, setEditMember] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [cpfUser, setCpfUser] = useState('')
  const [idToDelete, setIdToDelete] = useState('')
  const [cpfToDelete, setCpfToDelete] = useState('')

  const handleSetModalOpen = async ({ id, cpf }) => {
    setIdToDelete(id)
    setCpfToDelete(cpf)
    setShow(!show)
  }

  function handleCloseModal() {
    setShow(!show)
  }

  const [degreeKinshipEdit, setDegreeKinshipEdit] = useState(
    'Selecione o parentesco'
  )
  const [birthdateEdit, setBirthdateEdit] = useState('')
  const [birthdateEditShow, setBirthdateEditShow] = useState('')
  const [cpfEdit, setCpfEdit] = useState('')
  const [individualIncomeEdit, setIndividualIncomeEdit] = useState('')
  const [nameEdit, setNameEdit] = useState('')
  const [phoneNumberEdit, setPhoneNumberEdit] = useState('')

  const [degreeKinship, setDegreeKinship] = useState('Selecione o parentesco')
  const [birthdate, setBirthdate] = useState('')
  const [cpf, setCpf] = useState('')
  const [invalidCPF, setInvalidValidCPF] = useState(false)
  const [individualIncome, setIndividualIncome] = useState('')
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const [under18, setUnder18] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [userCandidate, setUserCandidate] = useState([])

  function handleSetCourse(degreeName) {
    setDegreeKinship(degreeName)
  }

  function handleSetCourseEdit(degreeNameEdit) {
    setDegreeKinshipEdit(degreeNameEdit)
  }

  const handleSetEdit = async props => {
    setLoaded(false)
    setTimeout(() => {
      setEditMember(false)
      setDegreeKinshipEdit(props.degreeKinship)
      setCpfEdit(props.cpf)
      const newCurrency = props.individualIncome.split('.').join(',')
      setIndividualIncomeEdit(newCurrency)
      setNameEdit(props.name)
      setPhoneNumberEdit(props.phoneNumber)

      setBirthdateEditShow(props.birthdate)
      const dateMap = props.birthdate.replace(/[^\d]/g, '')
      const mapSplit = dateMap.split('')
      const dateYear = mapSplit[4] + mapSplit[5] + mapSplit[6] + mapSplit[7]
      const dateMonth = mapSplit[2] + mapSplit[3]
      const dateDay = mapSplit[0] + mapSplit[1]
      const newBirthDate = dateYear + '-' + dateMonth + '-' + dateDay
      setBirthdateEdit(newBirthDate)

      setNewMember(false)
      setEditMember(true)
      setLoaded(true)
    }, 500)
  }

  const onInputChange = async event => {
    const inputName = event.target.name
    const inputValue = event.target.value

    if (inputName === 'birthdate' && inputValue.replace(/[^\d]/g, '').length ===8) {
      if (isValidDate(inputValue)) {
        const dateMap = inputValue.replace(/[^\d]/g, '')
        const mapSplit = dateMap.split('')

        const dateYear = mapSplit[4] + mapSplit[5] + mapSplit[6] + mapSplit[7]

        const dateMonth = mapSplit[2] + mapSplit[3]

        const dateDay = mapSplit[0] + mapSplit[1]

        const newBirthDate = dateYear + '-' + dateMonth + '-' + dateDay

        setBirthdate(newBirthDate)
        setValidDate(true)

        var today = new Date()
        var NewDateGMT = new Date(dateYear, dateMonth - 1, dateDay, 0, 1, 0)
        var age = today.getFullYear() - NewDateGMT.getFullYear()
        var m = today.getMonth() - NewDateGMT.getMonth()
        if (m < 0 || (m === 0 && today.getDate() < NewDateGMT.getDate())) {
          age--
        }

        if (age < 14) {
          setUnder18(true)
          setCpf('00000000000')
        } else {
          setUnder18(false)
        }
      } else {
        setValidDate(false)
      }
    }
    if (inputName === 'cpf') {
      setCpf(inputValue)
    }
    if (inputName === 'individualIncome') {
      setIndividualIncome(inputValue)
    }
    if (inputName === 'name') {
      setName(inputValue)
    }
    if (inputName === 'phoneNumber') {
      const cellPhoneInputNumber = inputValue.replace(/[^0-9]+/g, '')
      console.log(cellPhoneInputNumber)
      setPhoneNumber(inputValue)
    }
  }

  const [validDate, setValidDate] = useState(true)

  const onInputChangeEdit = async event => {
    const inputNameEdit = event.target.name
    const inputValueEdit = event.target.value

    if (inputNameEdit === 'birthdateEdit' && inputValueEdit.replace(/[^\d]/g, '').length ===8) {
      if (isValidDate(inputValueEdit)) {
        const dateMapEdit = inputValueEdit.replace(/[^\d]/g, '')
        const mapSplitEdit = dateMapEdit.split('')

        const dateYearEdit =
          mapSplitEdit[4] + mapSplitEdit[5] + mapSplitEdit[6] + mapSplitEdit[7]

        const dateMonthEdit = mapSplitEdit[2] + mapSplitEdit[3]

        const dateDayEdit = mapSplitEdit[0] + mapSplitEdit[1]

        const newBirthDateEdit =
          dateYearEdit + '-' + dateMonthEdit + '-' + dateDayEdit

        setValidDate(true)
        setBirthdateEdit(newBirthDateEdit)

        var today = new Date()
        var NewDateGMT = new Date(
          dateYearEdit,
          dateMonthEdit - 1,
          dateDayEdit,
          0,
          1,
          0
        )
        var age = today.getFullYear() - NewDateGMT.getFullYear()
        var m = today.getMonth() - NewDateGMT.getMonth()
        if (m < 0 || (m === 0 && today.getDate() < NewDateGMT.getDate())) {
          age--
        }

        if (age < 14) {
          setUnder18(true)
          setCpf('00000000000')
        } else {
          setUnder18(false)
        }
      } else {
        setValidDate(false)
      }
    }
    if (inputNameEdit === 'cpfEdit') {
      setCpfEdit(inputValueEdit)
    }
    if (inputNameEdit === 'individualIncomeEdit') {
      setIndividualIncomeEdit(inputValueEdit)
    }
    if (inputNameEdit === 'nameEdit') {
      setNameEdit(inputValueEdit)
    }
    if (inputNameEdit === 'phoneNumberEdit') {
      const cellPhoneInputNumber = inputValueEdit.replace(/[^0-9]+/g, '')
      setPhoneNumberEdit(cellPhoneInputNumber)
    }
  }

  const handleDeleteRelative = async ({ id: relativeId, cpf: relativeCpf }) => {
    setLoaded(false)
    deletarCpfDeletado(relativeCpf)
    try {
      const token = await awsTokenManager.getToken()
      const config = {
        headers: { Authorization: `${token}` },
      }
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/user-relative/${relativeId}`,
        config
      )
      handleCloseModal()
      getUserMe()
    } catch (err) {
      console.log(err)
    }
  }

  const handleForm4 = async data => {
    data.preventDefault()
    setLoaded(false)

    try {
      const userMapped = relativeToApi({
        birthdate,
        cpf,
        degreeKinship,
        individualIncome: individualIncome === '' ? '0' : individualIncome,
        name,
        phoneNumber,
      })
      if (userMapped[0]?.cpf && isValidCPF(userMapped[0].cpf)) {
        const token = await awsTokenManager.getToken()
        const config = {
          headers: { Authorization: `${token}` },
        }
        await axios.post(
          `${process.env.REACT_APP_API_URL}/user-relative/${cpfUser}`,
          userMapped,
          config
        )
        getUserMe()
      } else {
        setLoaded(true)
        setInvalidValidCPF(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleFormEdit = async data => {
    data.preventDefault()
    setLoaded(false)

    try {
      if (birthdateEdit.indexOf('/') !== -1) {
        const dateMapEdit = birthdateEdit.replace(/[^\d]/g, '')
        const mapSplitEdit = dateMapEdit.split('')

        const dateYearEdit =
          mapSplitEdit[4] + mapSplitEdit[5] + mapSplitEdit[6] + mapSplitEdit[7]

        const dateMonthEdit = mapSplitEdit[2] + mapSplitEdit[3]

        const dateDayEdit = mapSplitEdit[0] + mapSplitEdit[1]

        const newBirthDateEdit =
          dateYearEdit + '-' + dateMonthEdit + '-' + dateDayEdit

        setBirthdateEdit(newBirthDateEdit)
      }
      const userMapped = editRelativeToApi({
        birthdateEdit,
        cpfEdit,
        degreeKinshipEdit,
        individualIncomeEdit,
        nameEdit,
        phoneNumberEdit,
      })

      const token = await awsTokenManager.getToken()
      const config = {
        headers: { Authorization: `${token}` },
      }
      await axios.put(
        `${process.env.REACT_APP_API_URL}/user-relative/${cpfUser}`,
        userMapped,
        config
      )
      getUserMe()
    } catch (err) {
      console.log(err)
    }
  }

  const handleForm5 = async data => {
    setRedirect(true)
  }

  const [relatives, setRelatives] = useState([])
  const [allCpfs, setAllCpfs] = useState([])

  const getUserMe = async () => {
    try {
      const token = await awsTokenManager.getToken()
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      }

      let data = await axios
        .get(`${process.env.REACT_APP_API_URL}/users/me`, config)
        .then(({ data }) => data)

      setCpfUser(data.cpf)

      try {
        let data = await axios
          .get(
            `${process.env.REACT_APP_API_URL}/user-information/${cpfUser}`,
            config
          )
          .then(({ data }) => data)

        setUserCandidate(data)

        try {
          const config = {
            headers: { Authorization: `${token}` },
          }

          let data = await axios
            .get(
              `${process.env.REACT_APP_API_URL}/user-relative/relatives/${cpfUser}`,
              config
            )
            .then(({ data }) => data)

          if (data.length !== 0) {
            setRelativesNumber(data.length)
            function mapBirthdate(item) {
              const dateMap = item.birthdate.replace(/[^\d]/g, '')
              const mapSplit = dateMap.split('')
              const dateYear =
                mapSplit[0] + mapSplit[1] + mapSplit[2] + mapSplit[3]
              const dateMonth = mapSplit[4] + mapSplit[5]
              const dateDay = mapSplit[6] + mapSplit[7]
              const newBirthDate = dateDay + '/' + dateMonth + '/' + dateYear

              // setAllCpfs([...allCpfs, item.cpf]);
              setAllCpfs(allCpfs => [...allCpfs, item.cpf])

              return {
                id: item.id,
                birthdate: newBirthDate,
                cpf: item.cpf,
                degreeKinship: item.degreeKinship,
                individualIncome: item.individualIncome,
                name: item.name,
                phoneNumber: item.phoneNumber,
              }
            }

            const mappedData = data.map(mapBirthdate)

            setRelatives(mappedData)
            setFirstPost(false)
          } else {
            setRelativesNumber(0)
            setFirstPost(true)
          }

          setBirthdate('')
          setCpf('')
          setIndividualIncome('')
          setName('')
          setPhoneNumber('')
          setBirthdateEdit('')
          setCpfEdit('')
          setIndividualIncomeEdit('')
          setNameEdit('')
          setPhoneNumberEdit('')
          setNewMember(false)
          setEditMember(false)
          setLoaded(true)
        } catch (err) {
          console.log(err)
        }
      } catch (err) {
        console.log(err)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getUserMe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpfUser])

  // eslint-disable-next-line no-unused-vars
  const { formContent4, setFormContent4 } = useContext(FormContext4)

  const summary = relatives.reduce(
    (acc, transaction) => {
      acc.total += parseFloat(transaction.individualIncome)
      return acc
    },
    {
      total: 0,
    }
  )

  const [perCapita, setPerCapita] = useState(0)
  const [existingCpf, setExistingCpf] = useState(false)
  const [invalidCpf, setInvalidCpf] = useState(false)

  useEffect(() => {
    if (loaded) {
      const perCapitaDecimal = summary.total / relativesNumber
      setPerCapita(perCapitaDecimal.toFixed(2))
    }
  }, [loaded, perCapita, relativesNumber, summary])

  const [memberData, setMemberData] = useState([])
  const [cpfIsCompletes, setCpfIsCompletes] = useState()

  const splitedDate = memberData?.birthdate
    ? memberData?.birthdate.split('-')
    : null

  const onCpfNumberChange = async event => {
    setInvalidCpf(false)
    setExistingCpf(false)
    const cpfNumber = event.target.value
    const cpfNumberCleared = cpfNumber.replace(/\D/g, '')
    setCpfIsCompletes(`${event.target.value.indexOf('_')}`)

    if (cpfNumberCleared.length === 11) {
      if (allCpfs.includes(cpfNumberCleared)) {
        setExistingCpf(true)
      } else {
        setInvalidCpf(false)
      }

      setCpf(cpfNumberCleared)

      if (isValidCPF(cpfNumberCleared)) {
        try {
          const token = await awsTokenManager.getToken()
          const config = {
            headers: { Authorization: `${token}` },
          }
          const data = await axios
            .get(
              `${process.env.REACT_APP_API_URL}/users/rfb/${cpfNumberCleared}`,
              config
            )
            .then(({ data }) => data)

          setInvalidCpf(false)
          setMemberData(data)
          setName(data.name)
          setBirthdate(data.birthdate)
          setValidDate(true)
        } catch (error) {
          setExistingCpf(false)
          setInvalidCpf(true)
          console.log(error)
        }
      }
    } else {
      setExistingCpf(false)
    }
  }

  // const [newBirthdate, setNewBirthdate] = useState('DD/MM/AAAA');

  // useEffect(() => {
  //   const dateMap = birthdate.replace(/[^\d]/g, '');
  //   const mapSplit = dateMap.split('');
  //   const dateYear = mapSplit[0] + mapSplit[1] + mapSplit[2] + mapSplit[3];

  //   const dateMonth = mapSplit[4] + mapSplit[5];

  //   const dateDay = mapSplit[6] + mapSplit[7];

  //   const newBirthDate = dateDay + '/' + dateMonth + '/' + dateYear;
  //   setNewBirthdate(newBirthDate);
  // }, [birthdate]);

  function formataCPF(cpf) {
    cpf = cpf.replace(/[^\d]/g, '')
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }

  function handleCancelarClick() {
    setDegreeKinship('Selecione o parentesco')
    setMemberData('')
    setName('')
    setBirthdate('')
    setCpfIsCompletes('')
    setCpf('')
    setName('')
    setBirthdate('')
    setBirthdateEditShow('')
    setIndividualIncome('')
    setPhoneNumber('')
    setInvalidCpf(false)
    setValidDate(true)
  }

  function isValidDate(dateString) {
    // First check for the pattern
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false

    // Parse the date parts to integers
    var parts = dateString.split('/')
    var day = parseInt(parts[0], 10)
    var month = parseInt(parts[1], 10)
    var year = parseInt(parts[2], 10)

    // Check the ranges of month and year
    if (year < 1900 || year > 2022 || month === 0 || month > 12) return false

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
      monthLength[1] = 29

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1]
  }

  // useEffect(() => {

  // }, [allCpfs]);

  function deletarCpfDeletado(cpfTopDelete) {
    const newArr = allCpfs.filter(object => {
      return object !== cpfTopDelete
    })

    setAllCpfs(newArr)
    console.log(allCpfs)
  }

  console.log(allCpfs)

  return (
    <>
      {loaded ? null : (
        <>
          <LoadingFixedScreen data-test="LoadingFixedScreen">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </LoadingFixedScreen>
        </>
      )}
      <MessageBox
        main_title="Vamos calcular sua renda familiar"
        description={
          <>
            Para que possa ter acesso às melhores condições de bolsas e
            financiamentos, adicione abaixo cada membro do seu grupo familiar.
          </>
        }
      />

      <Wrapper data-test="confirmation-data-steps-Wrapper">
        <DivSection data-test="confirmation-data-steps-DivSection">
          <Display data-test="confirmation-data-steps-Display">
            <TitleArea data-test="confirmation-data-steps-TitleArea">
              <Container data-test="confirmation-data-steps-Container">
                <h2>Dados dos membros do grupo familiar</h2>
                <Divider />
                <p>
                  Para concorrer a uma vaga na modalidade FIES, a soma de todos
                  os salários da casa dividido pelos número de pessoas que moram
                  nela, tem que ser de até <strong>3 salários mínimos.</strong>
                </p>
              </Container>
            </TitleArea>

            <FamilyGroup data-test="confirmation-data-steps-FamilyGroup">
              <Container data-test="confirmation-data-steps-Container">
                <h2>Membro do Grupo Familiar</h2>
                {!firstPost ? (
                  <>
                    <Table
                      responsive="md"
                      className="familyTable"
                      data-test="confirmation-data-steps-Table"
                    >
                      <thead>
                        <tr>
                          <th>CPF</th>
                          <th>Nome Completo</th>
                          <th>Data de Nascimento</th>
                          <th>Grau de Parentesco</th>
                          <th>Telefone</th>
                          <th>Renda individual (R$)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {relatives.map(item => (
                          <tr key={item.id}>
                            <td>
                              {clearNonNumericCharacters(item.cpf) ===
                              '00000000000'
                                ? ''
                                : formataCPF(item.cpf)}
                            </td>
                            <td>{item.name}</td>
                            <td>{item.birthdate}</td>
                            <td>{item.degreeKinship}</td>
                            <td>{item.phoneNumber}</td>
                            <td>
                              {new Intl.NumberFormat('pt-br', {
                                currency: 'BRL',
                                style: 'currency',
                              }).format(`${item.individualIncome}`)}
                            </td>
                            <td className="tableEdit">
                              <FaEdit
                                className="hoverTrash"
                                onClick={() => {
                                  const props = {
                                    birthdate: item.birthdate,
                                    cpf: item.cpf,
                                    degreeKinship: item.degreeKinship,
                                    individualIncome: item.individualIncome,
                                    name: item.name,
                                    phoneNumber: item.phoneNumber,
                                  }
                                  handleSetEdit(props)
                                  // handleBirthdateEdit(item.birthdate);
                                  // handleGetBirthdate();
                                }}
                              />
                            </td>

                            <td>
                              <FaTrashAlt
                                className="hoverTrash"
                                onClick={() => {
                                  handleSetModalOpen({
                                    id: item.id,
                                    cpf: item.cpf,
                                  })
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </Container>

              {editMember ? (
                <>
                  <Container data-test="confirmation-data-steps-Container">
                    <h3>Editar Membro do Grupo Familiar</h3>
                    <Divider data-test="confirmation-data-steps-Divider" />
                    <form id="formEdit" onSubmit={handleFormEdit}>
                      <Row
                        className="mt-3"
                        data-test="confirmation-data-steps-Row"
                      >
                        <Col md={6}>
                          <>
                            <TextInputRequired
                              title="Data de Nascimento"
                              errorMessage="Campo obrigatório"
                              showErrorMessage={false}
                            />

                            <InputMask
                              data-test="confirmation-data-steps-input"
                              type="text"
                              placeholder="DD/MM/AAAA"
                              name="birthdateEdit"
                              defaultValue={birthdateEditShow}
                              mask="99/99/9999"
                              required
                              disabled
                              onChange={onInputChangeEdit}
                            />
                            {!validDate && (
                              <>
                                <p>Data inválida!</p>
                              </>
                            )}
                          </>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        {!under18 && (
                          <>
                            <Col md={6}>
                              <>
                                <TextInputRequired
                                  title="CPF"
                                  errorMessage="Campo obrigatório"
                                  showErrorMessage={false}
                                />
                                <InputMask
                                  disabled
                                  data-test="confirmation-data-steps-input"
                                  type="text"
                                  placeholder="999.999.999-99"
                                  name="cpfEdit"
                                  defaultValue={cpfEdit}
                                  mask="999.999.999-99"
                                  required
                                  onChange={onInputChangeEdit}
                                />
                              </>
                            </Col>
                          </>
                        )}

                        <Col md={6}>
                          <>
                            <TextInputRequired
                              title="Nome"
                              errorMessage="Campo obrigatório"
                              showErrorMessage={false}
                            />

                            <InputMask
                              disabled
                              data-test="confirmation-data-steps-input"
                              type="text"
                              placeholder="Nome do membro familiar"
                              name="nameEdit"
                              defaultValue={nameEdit}
                              required
                              onChange={onInputChangeEdit}
                            />
                          </>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col md={4}>
                          <>
                            <TextInputRequired
                              title="Telefone"
                              errorMessage="Dado Inválido"
                              showErrorMessage={
                                phoneNumberEdit.replace(/[^0-9]+/g, '').length <
                                  11 &&
                                phoneNumberEdit.replace(/[^0-9]+/g, '').length >
                                  0
                              }
                            />

                            <InputMask
                              data-test="confirmation-data-steps-input"
                              type="text"
                              placeholder="(00) 00000-0000"
                              name="phoneNumberEdit"
                              defaultValue={phoneNumberEdit}
                              mask="(99) 99999-9999"
                              required
                              onChange={onInputChangeEdit}
                            />
                          </>
                        </Col>

                        <Col md={4}>
                          <p className="selectText">Grau de parentesco?</p>
                          <SelectInputGroup data-test="confirmation-data-steps-SelectInputGroup">
                            <DropdownButton
                              data-test="confirmation-data-steps-DropdownButton"
                              className="dropPersonalized"
                              title={degreeKinshipEdit}
                            >
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourseEdit('Pai')
                                }}
                              >
                                Pai
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourseEdit('Mãe')
                                }}
                              >
                                Mãe
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourseEdit('Irmão(a)')
                                }}
                              >
                                Irmão(a)
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourseEdit('Avô')
                                }}
                              >
                                Avô
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourseEdit('Avó')
                                }}
                              >
                                Avó
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourseEdit('Bisavô')
                                }}
                              >
                                Bisavô
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourseEdit('Bisavó')
                                }}
                              >
                                Bisavó
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourseEdit('Conjugue')
                                }}
                              >
                                Conjugue
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourseEdit('Neto(a)')
                                }}
                              >
                                Neto(a)
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourseEdit('Filho(a)')
                                }}
                              >
                                Filho(a)
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourseEdit('Bisneto(a)')
                                }}
                              >
                                Bisneto(a)
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourseEdit('Tio(a)')
                                }}
                              >
                                Tio(a)
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourseEdit('Sobrinho(a)')
                                }}
                              >
                                Sobrinho(a)
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourseEdit('Outros')
                                }}
                              >
                                Outros
                              </Dropdown.Item>
                            </DropdownButton>
                          </SelectInputGroup>
                        </Col>

                        <Col md={4}>
                          <>
                            <TextInputRequired
                              title="Renda Individual mensal bruta (R$)"
                              errorMessage="Campo obrigatório"
                              showErrorMessage={false}
                            />
                            <CurrencyInput
                              data-test="confirmation-data-steps-input"
                              prefix=""
                              decimalSeparator=","
                              thousandSeparator="."
                              placeholder="R$ 0,00"
                              value={individualIncomeEdit}
                              required
                              name="individualIncomeEdit"
                              onChangeEvent={onInputChangeEdit}
                            />
                          </>
                        </Col>
                      </Row>

                      <Row className="my-5">
                        <ButtonSetData data-test="confirmation-data-steps-ButtonSetData">
                          <Button
                            className="buttonStyleGOV "
                            onClick={() => {
                              setEditMember(false)
                              scrollTop()
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button
                            data-test="confirmation-data-steps-ButtonNext"
                            className="next"
                            type="submit"
                            form="formEdit"
                            defaultValue="Submit"
                            disabled={
                              clearNonNumericCharacters(phoneNumberEdit)
                                .length < 11
                            }
                          >
                            Salvar Edição
                          </Button>
                        </ButtonSetData>
                      </Row>
                    </form>
                  </Container>
                </>
              ) : null}

              {newMember ? (
                <>
                  <Container data-test="confirmation-data-steps-Container">
                    <h3>Adicionar Membro do Grupo Familiar</h3>
                    <Divider data-test="confirmation-data-steps-Divider" />
                    <form id="form4" onSubmit={handleForm4}>
                      <Row
                        className="mt-3"
                        data-test="confirmation-data-steps-Row"
                      >
                        <Col md={6}>
                          <>
                            <TextInputRequired
                              title="Data de Nascimento"
                              errorMessage="Campo obrigatório"
                              showErrorMessage={false}
                            />

                            <InputMask
                              data-test="confirmation-data-steps-input"
                              type="text"
                              placeholder="DD/MM/AAAA"
                              name="birthdate"
                              value={
                                memberData?.birthdate && cpfIsCompletes === '-1'
                                  ? format(
                                      new Date(
                                        splitedDate[0],
                                        splitedDate[1] - 1,
                                        splitedDate[2]
                                      ),
                                      'MM/dd/yyyy'
                                    )
                                  : null
                              }
                              disabled={
                                memberData?.birthdate && cpfIsCompletes === '-1'
                                  ? true
                                  : false
                              }
                              mask="99/99/9999"
                              required
                              onChange={onInputChange}
                            />
                            {!validDate && (
                              <>
                                <p className="invalidItem">Data inválida!</p>
                              </>
                            )}
                          </>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        {!under18 && (
                          <>
                            <Col md={6}>
                              <>
                                <TextInputRequired
                                  title="CPF"
                                  errorMessage={
                                    'Foi informado um dado inválido'
                                  }
                                  showErrorMessage={invalidCPF}
                                />

                                <InputMask
                                  data-test="confirmation-data-steps-input"
                                  type="text"
                                  placeholder="999.999.999-99"
                                  name="cpf"
                                  defaultValue={cpf}
                                  mask="999.999.999-99"
                                  required
                                  onChange={onCpfNumberChange}
                                />
                                {existingCpf && (
                                  <>
                                    <p style={{ color: '#DA0000' }}>
                                      CPF já adicionado!
                                    </p>
                                  </>
                                )}
                                {invalidCpf && (
                                  <>
                                    <p style={{ color: '#DA0000' }}>
                                      Número de CPF inválido!
                                    </p>
                                  </>
                                )}
                              </>
                            </Col>
                          </>
                        )}

                        <Col md={6}>
                          <>
                            <TextInputRequired
                              title="Nome"
                              errorMessage="Campo obrigatório"
                              showErrorMessage={false}
                            />

                            <InputMask
                              disabled={
                                memberData?.name && cpfIsCompletes === '-1'
                                  ? true
                                  : false
                              }
                              data-test="confirmation-data-steps-input"
                              type="text"
                              placeholder="Nome do membro familiar"
                              name="name"
                              value={
                                cpfIsCompletes === '-1'
                                  ? memberData?.name
                                  : null
                              }
                              required
                              onChange={onInputChange}
                            />
                          </>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col md={4}>
                          <>
                            <TextInputRequired
                              title="Telefone"
                              errorMessage="Dado Inválido"
                              showErrorMessage={
                                phoneNumber.replace(/[^0-9]+/g, '').length <
                                  11 &&
                                phoneNumber.replace(/[^0-9]+/g, '').length > 0
                              }
                            />

                            <InputMask
                              data-test="confirmation-data-steps-input"
                              type="text"
                              placeholder="(00) 00000-0000"
                              name="phoneNumber"
                              defaultValue={phoneNumber}
                              mask="(99) 99999-9999"
                              required
                              onChange={onInputChange}
                            />
                          </>
                        </Col>

                        <Col md={4}>
                          <p className="selectText">Grau de parentesco?</p>
                          <SelectInputGroup data-test="confirmation-data-steps-SelectInputGroup">
                            <DropdownButton
                              data-test="confirmation-data-steps-DropdownButton"
                              className="dropPersonalized"
                              title={degreeKinship}
                            >
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourse('Canditado(a)')
                                }}
                              >
                                Canditado(a)
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourse('Pai')
                                }}
                              >
                                Pai
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourse('Mãe')
                                }}
                              >
                                Mãe
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourse('Irmão(a)')
                                }}
                              >
                                Irmão(a)
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourse('Avô')
                                }}
                              >
                                Avô
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourse('Avó')
                                }}
                              >
                                Avó
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourse('Bisavô')
                                }}
                              >
                                Bisavô
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourse('Bisavó')
                                }}
                              >
                                Bisavó
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourse('Conjugue')
                                }}
                              >
                                Conjugue
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourse('Neto(a)')
                                }}
                              >
                                Neto(a)
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourse('Filho(a)')
                                }}
                              >
                                Filho(a)
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourse('Bisneto(a)')
                                }}
                              >
                                Bisneto(a)
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourse('Tio(a)')
                                }}
                              >
                                Tio(a)
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourse('Sobrinho(a)')
                                }}
                              >
                                Sobrinho(a)
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleSetCourse('Outros')
                                }}
                              >
                                Outros
                              </Dropdown.Item>
                            </DropdownButton>
                          </SelectInputGroup>
                        </Col>

                        <Col md={4}>
                          <>
                            <TextInputRequired
                              title="Renda Individual mensal bruta (R$)"
                              errorMessage="Campo obrigatório"
                              showErrorMessage={false}
                            />

                            <CurrencyInput
                              data-test="confirmation-data-steps-input"
                              prefix=""
                              decimalSeparator=","
                              thousandSeparator="."
                              placeholder="R$ 0,00"
                              value={individualIncome}
                              required
                              name="individualIncome"
                              onChangeEvent={onInputChange}
                            />
                          </>
                        </Col>
                      </Row>

                      <Row className="my-5">
                        <ButtonSetData data-test="confirmation-data-steps-ButtonSetData">
                          <Button
                            className="buttonStyleGOV "
                            onClick={() => {
                              setNewMember(false)
                              scrollTop()
                              handleCancelarClick()
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button
                            data-test="confirmation-data-steps-ButtonNext"
                            className="next"
                            type="submit"
                            form="form4"
                            defaultValue="Submit"
                            disabled={
                              existingCpf ||
                              !validDate ||
                              invalidCpf ||
                              phoneNumber.replace(/[^0-9]+/g, '').length < 11 ||
                              degreeKinship === 'Selecione o parentesco'
                            }
                          >
                            + Adicionar
                          </Button>
                        </ButtonSetData>
                      </Row>
                    </form>
                  </Container>
                </>
              ) : (
                <>
                  <Container>
                    {firstPost ? (
                      <>
                        <p className="addNew">
                          Por favor adicione os membro do seu grupo familiar.
                        </p>
                      </>
                    ) : null}

                    <Row className="my-5">
                      <ButtonSetData data-test="confirmation-data-steps-ButtonSetData">
                        {!editMember && (
                          <Button
                            className="buttonStyleGOV"
                            onClick={() => {
                              setNewMember(true)
                              setEditMember(false)
                              handleCancelarClick()
                            }}
                          >
                            + Adicionar Membro
                          </Button>
                        )}
                      </ButtonSetData>
                    </Row>
                  </Container>
                </>
              )}

              <Container data-test="confirmation-data-steps-Container">
                <p>
                  Renda familiar mensal bruta (R$)
                  {firstPost ? (
                    <>
                      <strong>R$ 0.00</strong>
                    </>
                  ) : (
                    <>
                      <strong>
                        {new Intl.NumberFormat('pt-br', {
                          currency: 'BRL',
                          style: 'currency',
                        })
                          .format(`${summary.total}`)
                          .replace('R$', '')}
                      </strong>
                    </>
                  )}
                </p>
                <p>
                  Renda mensal bruta per capita (R$)
                  {firstPost ? (
                    <>
                      <strong>R$ 0.00</strong>
                    </>
                  ) : (
                    <>
                      <strong>
                        {new Intl.NumberFormat('pt-br', {
                          currency: 'BRL',
                          style: 'currency',
                        })
                          .format(`${perCapita}`)
                          .replace('R$', '')}
                      </strong>
                    </>
                  )}
                </p>
              </Container>
            </FamilyGroup>

            <Modal
              data-test="confirmation-data-steps-Modal"
              show={show}
              onHide={handleCloseModal}
              centered
              style={{ boxShadow: '0px 9px 6px rgba(51, 51, 51, 0.2);' }}
            >
              <Modal.Title
                data-test="confirmation-data-steps-ModalTitle"
                style={{
                  fontSize: '1.25rem',
                  fontWeight: 'bold',
                  padding: '1rem',
                }}
              >
                Excluir Membro Familiar
              </Modal.Title>
              <Modal.Body
                data-test="confirmation-data-steps-ModalBody"
                style={{ fontStyle: 'italic', color: '#000000' }}
              >
                <p
                  className="modalBody"
                  style={{
                    color: '#000000',
                    fontWeight: 600,
                    fontSize: '0.8rem',
                  }}
                >
                  Você realmente quer excluir este membro do grupo familiar?
                  Isso irá refletir no valor da renda mensal da família.
                </p>
              </Modal.Body>
              <Modal.Footer
                data-test="confirmation-data-steps-ModalFooter"
                style={{ borderBottom: '0', borderTop: '0' }}
              >
                <ButtonSetData>
                  <Button
                    className="buttonStyleGOV modalBProps cancel"
                    onClick={handleCloseModal}
                  >
                    Cancelar
                  </Button>
                  <Button
                    className="buttonStyleGOV primary modalBProps "
                    onClick={() => {
                      handleDeleteRelative({
                        id: idToDelete,
                        cpf: cpfToDelete,
                      })
                    }}
                  >
                    Excluir
                  </Button>
                </ButtonSetData>
              </Modal.Footer>
            </Modal>
          </Display>
        </DivSection>
        {firstPost ? null : (
          <>
            <form id="form5" onSubmit={handleForm5}>
              <ButtonNext
                data-test="confirmation-data-steps-ButtonNext"
                className="next"
                type="submit"
                form="form5"
                defaultValue="Submit"
              >
                Confirmar
              </ButtonNext>
            </form>
          </>
        )}
      </Wrapper>
      {redirect ? <Redirect push to="/candidate-page" /> : null}
    </>
  )
}
