import styled, { css } from 'styled-components'
import { Container as MuiContainer } from '@material-ui/core'

import { APP_MEASURES } from 'modules/App/constants/measures'

export const Container = styled.div`
  /* height: ${`${APP_MEASURES.HEADER_HEIGHT}px`};
  min-height: ${`${APP_MEASURES.HEADER_HEIGHT}px`};
  max-height: ${`${APP_MEASURES.HEADER_HEIGHT}px`}; */

  width: 100%;
  background-color: ${props => props.theme.bgColors.colorSecondary01};
  box-shadow: 2px 2px 8px ${props => props.theme.bgColors.colorSecondary04};

  p.govFed {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;

    color: #555555;
  }
`

export const HeaderContent = styled(MuiContainer)`
  display: flex;
  align-items: center;
  padding-right: 10px;

  .barsImg {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0 15px;
    height: 55px;

    @media (max-width: 575px) {
      height: auto;
    }

    img {
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  .TextDiv {
    h3 {
      font-style: normal;
      font-weight: 400;
      font-size: 24.19px;
      line-height: 33px;
      color: #333333;

      @media (max-width: 575px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #555555;

      @media (max-width: 575px) {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
`

export const MenuOptions = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  position: relative;

  img {
    padding: 0;
  }

  .searchImg {
    position: absolute;
    right: 45px;
    bottom: 15px;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
    @media (max-width: 991px) {
      display: none;
    }
  }

  input[type='text'] {
    background: #f8f8f8;
    border: none;

    margin-right: 30px;

    padding: 10px 24px;

    border-radius: 8px;

    width: 385px;

    &:focus {
      border: none;
      outline: none;
    }

    @media (max-width: 991px) {
      display: none;
    }
  }
`

export const HeaderBrand = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  /* width: 300px; */
  padding: 0px;
  /* padding-right: 20px; */
  cursor: pointer;

  .logoBtn {
    background-image: none !important;
  }

  & * {
    cursor: pointer;
  }

  ${props => css`
    transition: background-color ${props.theme.animationDuration} ease-in-out;

    /* &:hover,
    &:active,
    &:focus {
      background-color: ${props.theme.bgColors.colorSecondary03};
    } */
  `}
`

export const NavBrand = styled.img`
  cursor: pointer;
`

export const Title = styled.h5`
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;

  color: ${props => props.theme.palette.grey[400]};
`

export const MenuButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;

  ${props => css`
    transition: background-color ${props.theme.animationDuration} ease-in-out;

    &:hover,
    &:active,
    &:focus {
      background-color: ${props.theme.bgColors.colorSecondary03};
    }
  `}
`

export const HideMobile = styled.div`
  padding: 0;
  margin: 0;
  display: flex;

  @media (max-width: 991px) {
    display: none;
  }
`
