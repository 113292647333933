import { format } from 'date-fns'
import { useMemo } from 'react'
import { Container, ResultBox, TextWithline } from './styles'

const classificationTypes = {
  NONE: null,
  PARTIAL: 'PARCIAL',
  REGULAR: 'REGULAR',
}

export const CandidateRanking = ({
  rating: {
    classification,
    isClassified,
    courseOption,
    currentPosition,
    totalVacancies,
    modality,
    date,
    hasClassifiedInAtLeastOneCourse,
  },
}) => {
  const dateFormatted = useMemo(() => {
    return format(new Date(date), "dd'/'MM'/'yyyy' às 'HH'h")
  }, [date])

  const hasSelectedInOtherOption =
    hasClassifiedInAtLeastOneCourse && !isClassified

  return (
    classification !== classificationTypes.NONE && (
      <Container>
        <ResultBox>
          <TextWithline>
            <h3>
              {classification === classificationTypes.PARTIAL
                ? 'Classificação parcial'
                : 'Resusltado da chamada regular'}
            </h3>
            <hr className="w-100" />
          </TextWithline>

          <strong>
            {isClassified &&
              classification === classificationTypes.PARTIAL &&
              'Você está parcialmente classificado'}

            {isClassified &&
              classification === classificationTypes.REGULAR &&
              'Você foi selecionado na chamada regular'}

            {hasSelectedInOtherOption &&
              !isClassified &&
              classification === classificationTypes.PARTIAL &&
              `Você está parcialmente classificado na ${
                courseOption === '1' ? 2 : 1
              }ª opção de curso`}

            {hasSelectedInOtherOption &&
              !isClassified &&
              classification === classificationTypes.REGULAR &&
              `Você foi selecionado na chamada regular na ${
                courseOption === '1' ? 2 : 1
              }ª opção de curso`}

            {!isClassified &&
              !hasSelectedInOtherOption &&
              'Você não está classificado na opção de curso'}
          </strong>

          {!hasSelectedInOtherOption && (
            <p>
              {currentPosition}º de {totalVacancies} vagas reservadas para ação
              afirmativado tipo: {modality}
            </p>
          )}
          <p>
            A classificação{' '}
            {classification === classificationTypes.PARTIAL
              ? 'parcial'
              : 'final'}{' '}
            foi divulgada em {dateFormatted}.
          </p>
        </ResultBox>
      </Container>
    )
  )
}
