/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react'
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa'
import { Document, Page } from 'react-pdf'

import { AsyncLoad } from 'components/AsyncLoad'
import { useLangContext } from 'components/Globalization'

import { userService } from 'services/providers/user'

import { Pagination, NextArrow, PrevArrow, TextPagination } from './style'

const PdfViewer = props => {
  const translate = useLangContext()

  const [dataProcessing, setDataProcessing] = useState('')
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  const dataProcessingPromise = useCallback(async () => {
    const dataProcessingResponse = await userService.dataProcessing()

    setDataProcessing(dataProcessingResponse)
  }, [])

  function onDocumentLoadSuccess({ numPages: numberOfPages }) {
    props.isPdfLoaded(numberOfPages)
    setNumPages(numberOfPages)
  }

  const prevPage = () => {
    if (pageNumber === 1) return
    setPageNumber(pageNumber - 1)
  }
  const nextPage = () => {
    if (pageNumber === numPages) return
    setPageNumber(pageNumber + 1)
  }

  const loadError = () => {
    props.isPdfLoaded(false)
  }

  return (
    <AsyncLoad promiseFn={dataProcessingPromise}>
      <Document
        wrap={false}
        file={dataProcessing}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={loadError}
        options={{ workerSrc: 'pdf.worker.js' }}
        noData="PDF não carregou"
      >
        <Page pageNumber={pageNumber} />
        <Pagination>
          <PrevArrow
            onClick={prevPage}
            style={{ opacity: pageNumber === 1 ? '0.3' : '1' }}
          >
            <FaCaretLeft style={{ fontSize: '18px', lineHeight: '10px' }} />
          </PrevArrow>
          <TextPagination>
            {translate('PAGINATION', { pageNumber, numPages })}
          </TextPagination>
          <NextArrow
            onClick={nextPage}
            style={{
              opacity: pageNumber === numPages ? '0.3' : '1',
            }}
          >
            <FaCaretRight style={{ fontSize: '18px' }} />
          </NextArrow>
        </Pagination>
      </Document>
    </AsyncLoad>
  )
}

export default PdfViewer
