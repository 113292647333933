/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react'
import wrapper from 'components/Wrapper'
import { COMPROVANTECOMHASH_ROUTES } from './constants/routes'
import { useReactToPrint } from 'react-to-print'

import { Wrapper, PrintButtonContainer } from './styles'
import { Container } from 'react-bootstrap'
import { ComprovanteComHashComponent } from 'components/ComprovanteHashComponent'
import { LoadingFixedScreen } from 'modules/InitialPage/styles'

const Comprovante = () => {
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const [loaded, setLoaded] = useState(false)

  // const [hash, setHash] = useState('');

  // console.log(hash);
  return (
    <>
      {loaded ? null : (
        <>
          <LoadingFixedScreen data-test="LoadingFixedScreen">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </LoadingFixedScreen>
        </>
      )}
      <Wrapper>
        <Container>
          <PrintButtonContainer>
            <a className="printButton" href="#" onClick={handlePrint}>
              Imprimir comprovante
            </a>
          </PrintButtonContainer>

          <ComprovanteComHashComponent
            ref={componentRef}
            setLoaded={setLoaded}

            // hash={hash}
          />
        </Container>
      </Wrapper>
    </>
  )
}

export default wrapper(Comprovante, {
  route: { path: COMPROVANTECOMHASH_ROUTES.COMPROVANTECOMHASH },
})
