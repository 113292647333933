import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  max-width: 145px;

  svg:not(:first-child) {
    margin-left: 8px;
  }
`
