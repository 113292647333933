/* eslint-disable no-debugger */
/* eslint-disable react/prop-types */
import React from 'react'
import { useController } from 'react-hook-form'

import { get, isFunction, merge } from 'lodash'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { withStyles } from '@material-ui/core'
import MuiTextField from '@material-ui/core/TextField'

import { useLangContext } from 'components/Globalization'

const MuiTextFieldCustom = styled(MuiTextField)`
  ${props =>
    props.$fullHeight &&
    css`
      &.MuiFormControl-root,
      .MuiInputBase-multiline {
        height: 100% !important;
      }

      .MuiInputBase-inputMultiline {
        height: 100% !important;
        overflow-y: auto;
      }

      .MuiInputBase-multiline {
        padding: 10px 0;
      }
    `}
`

const styles = theme => ({
  inputRoot: {
    borderRadius: 3,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #CCCCCC',
    padding: 0,
    'label + &': {
      marginTop: theme.spacing(3),
    },

    '&.Mui-disabled': {
      '& .MuiInputAdornment-root': {
        color: theme.palette.grey[400],
      },
    },
  },
  input: {
    fontSize: '1em',
    padding: '8px 10px',
    '&:focus': {
      borderColor: '#80bdff',
    },
  },
  inputLabelRoot: {
    color: '#555555',
    // fontWeight: 'bold',
    '&$formLabelFocused': {
      color: '#555555',
    },
  },
  formLabelFocused: {},
})

export const UncontrolledTextField = withStyles(styles)(
  React.forwardRef(
    (
      {
        fullHeight,
        classes,
        InputProps = {},
        InputLabelProps = {},
        children,
        maxLength,
        ...inputProps
      },
      ref
    ) => {
      const ComputedInputProps = merge(InputProps, {
        disableUnderline: true,
        classes: {
          root: classes.inputRoot,
          input: classes.input,
        },
        inputProps: { maxLength },
      })

      const ComputedInputLabelProps = merge(InputLabelProps, {
        shrink: true,
        classes: {
          root: classes.inputLabelRoot,
          focused: classes.formLabelFocused,
        },
      })

      return (
        <MuiTextFieldCustom
          $fullHeight={fullHeight}
          inputRef={ref}
          InputProps={ComputedInputProps}
          InputLabelProps={ComputedInputLabelProps}
          {...inputProps}
        >
          {isFunction(children) ? children(inputProps.value) : children}
        </MuiTextFieldCustom>
      )
    }
  )
)

const TextField = ({
  name,
  formMethods,
  defaultValue,
  children,
  onChange: onChangeProp = () => {},
  ...rest
}) => {
  const translate = useLangContext()

  const { control, errors } = formMethods
  const {
    field: { ref, value, onChange, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
  })

  let errorProps = {}
  const error = get(errors, name)
  if (error) {
    errorProps = {
      error: true,
      helperText:
        typeof error.message === 'string'
          ? translate(error.message)
          : translate(error.message.key, error.message.params),
    }
  }

  return (
    <UncontrolledTextField
      ref={ref}
      value={value}
      onChange={e => {
        onChangeProp(e)
        onChange(e?.target?.value || e)
      }}
      {...inputProps}
      {...rest}
      {...errorProps}
    >
      {isFunction(children) ? children(value) : children}
    </UncontrolledTextField>
  )
}

TextField.defaultProps = {
  defaultValue: '',
  label: '',
  fullWidth: true,
  fullHeight: false,
  disabled: false,
  children: null,
}

TextField.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.func,
  ]),
  formMethods: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  fullHeight: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  disabled: PropTypes.bool,
}

export default TextField
