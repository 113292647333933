import axios from 'axios'
import { awsTokenManager } from 'config/auth'

export const apiWithToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const configDefaultHeaders = async () => {
  apiWithToken.defaults.headers.Authorization = await awsTokenManager.getToken()
}

configDefaultHeaders()
