import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { TitlePage, Modalities } from './styles'

import { Button, ContainerStepButton, DivRow } from '../../styles'

import { useLoading } from 'hooks/useLoading'
import { apiWithToken } from 'services/apiWithToken'
import { useUser } from 'hooks/useUser'
import { SelectedCourse } from './components/SelectedCourse'
import { ModalityCard } from './components/ModalityCard'

export function CourseDetail({
  timeCode,
  option,
  course,
  modality,
  courseTime,
  university,
  city,
  uf,
  comeBackFilterCourse,
  courseTimeId,
}) {
  const { loading } = useLoading()
  const { cpf } = useUser()

  const [modalities, setModalities] = useState([])

  const [modalitySelected, setModalitySelected] = useState('')

  const [bestScore, setBestScore] = useState(0)

  const [sisuEdition, setSisuEdition] = useState(0)

  useEffect(() => {
    const loadSchedule = async () => {
      try {
        loading.turnOn()

        const { data } = await apiWithToken.get('/schedule/program', {
          params: { programName: 'sisu' },
        })
        setSisuEdition(data.editions[0].id)
      } catch (err) {
        console.error(err)
      } finally {
        loading.turnOff()
      }
    }

    loadSchedule()
  }, [])

  useEffect(() => {
    async function loadUserAverage() {
      try {
        loading.turnOn()
        const { data } = await apiWithToken.get('/users/average')

        setBestScore(data.averageScore)
      } catch (err) {
        console.log(err)
      } finally {
        loading.turnOff()
      }
    }

    loadUserAverage()
  }, [])

  useEffect(() => {
    const loadModalities = async () => {
      try {
        loading.turnOn()

        const { data } = await apiWithToken.get(
          `/course/modalities/${courseTimeId}/allowed`
        )

        setModalities(data)
      } catch (err) {
        console.error(err)
      } finally {
        loading.turnOff()
      }
    }

    loadModalities()
  }, [])

  const onSelectModality = async () => {
    try {
      loading.turnOn()

      const courseMapped = {
        courseCode: timeCode,
        option: option,
        edition: sisuEdition,
        modality: modalitySelected,
      }

      await apiWithToken.post(`/user-registration/${cpf}`, courseMapped)

      window.location.reload(true)
    } catch (err) {
      console.log(err)
    } finally {
      loading.turnOff()
    }
  }

  return (
    <Container>
      <TitlePage>1ª opção de curso - Detalhe</TitlePage>

      <SelectedCourse
        name={course}
        degree={modality}
        shift={courseTime}
        university={university}
        city={city}
        uf={uf}
      />

      <Modalities>
        <h1>VAGAS</h1>
        <h2>Selecione o Tipo de Vaga</h2>

        <h3>Vagas reservadas - Lei nº 12.711/2012</h3>
        <h4>
          Renda familiar bruta per capita igual ou inferior a 1,5 salário mínimo
        </h4>

        <Row>
          {modalities?.incomePercapitaLess?.map(modality => (
            <Col md={6}>
              <ModalityCard
                key={modality.id}
                modality={modality}
                onGetModalitySelected={setModalitySelected}
                modalitySelected={modalitySelected}
                bestScore={bestScore}
              />
            </Col>
          ))}
        </Row>

        <h4>Independente da renda</h4>
        <Row>
          {modalities?.regardlessIncome?.map(modality => (
            <Col md={6}>
              <ModalityCard
                key={modality.id}
                modality={modality}
                onGetModalitySelected={setModalitySelected}
                modalitySelected={modalitySelected}
                bestScore={bestScore}
              />
            </Col>
          ))}
        </Row>

        <h3>Demais vagas</h3>

        <Row>
          {modalities?.otherVacancies?.map(modality => (
            <Col md={6}>
              <ModalityCard
                key={modality.id}
                modality={modality}
                onGetModalitySelected={setModalitySelected}
                modalitySelected={modalitySelected}
                bestScore={bestScore}
              />
            </Col>
          ))}
        </Row>
      </Modalities>

      <Container>
        <ContainerStepButton>
          .
          <button
            className="nextButton"
            disabled={!modalitySelected}
            onClick={onSelectModality}
          >
            Selecionar Modalidade
          </button>
        </ContainerStepButton>
      </Container>
      <DivRow style={{ marginBottom: '50px' }}>
        <Button className="prev" onClick={comeBackFilterCourse}>
          Voltar
        </Button>
      </DivRow>
    </Container>
  )
}
