export const formatCurrency = (
  value = 0,
  { notation } = { notation: true }
) => {
  const currencyFormated = new Intl.NumberFormat('pt-br', {
    currency: 'BRL',
    style: 'currency',
  }).format(value)

  if (!notation) {
    return currencyFormated.replace('R$', '').trim()
  }

  return currencyFormated
}
