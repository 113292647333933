import React, { useEffect } from 'react'
import { globalization, useLangContext } from 'components/Globalization'
import { router } from 'components/Router'

import { APP_ROUTES } from 'modules/App/constants/route'
import Logout from 'modules/Auth/fragments/Logout'

import { NotAllowedStyled } from './style'
import { authActions } from 'config/store/slices/auth.slice'
import store from 'config/store/store'
import { authService } from 'services/providers/auth'

const NoAccess = () => {
  const translate = useLangContext()
  const authState = store.getState().auth
  const { meFailed } = authState

  useEffect(() => {
    store.dispatch(authActions.meFailed(false))
    authService.unsetMeFailedFlag()
  }, [meFailed])

  return (
    <NotAllowedStyled>
      <div>
        <h1>{translate('ACCESS_NOT_ALLOWED')}</h1>
        <Logout label={translate('ACCESS_HOME_PAGE')} />
      </div>
    </NotAllowedStyled>
  )
}

const globalized = globalization(NoAccess, 'NO_ACCESS')

export default router(globalized, {
  path: APP_ROUTES.NOT_ALLOWED,
})
