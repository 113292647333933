/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'

import {
  DivImage,
  TextsGov,
  QRCODEDIV,
  ContainerComprovante,
  Header,
  Data,
  LineText,
  FamilyGroup,
  OptionCourseText,
  CardOptionCourse,
  DataOption,
  OptionDataContainer,
  TextWithline,
  ResumeOptionCourse,
  ResultBox,
  ContainerDeclarations,
  PrintText,
  DivSpaceContainer,
  PageStyle,
} from './styles'
import governoimg from './img/governoimg.png'
// import qrcode from './img/qrcode.svg';

import { Col, Row } from 'react-bootstrap'
import { UserData } from './UserData'
import { Table } from 'react-bootstrap'
import axios from 'axios'
import { awsTokenManager } from 'config/auth'
import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import QRCode from 'react-qr-code'

export const SisuComprovante = React.forwardRef((props, ref) => {
  const [cpf, setCpf] = useState('')
  const [userInformation, setUserInformation] = useState({})
  const [userEducation, setUserEducation] = useState({})
  const [userAddress, setUserAddress] = useState({})
  const [userAverage, setUserAverage] = useState({})
  const [userRelative, setUserRelative] = useState([])
  const [perCapita, setPerCapita] = useState(0)
  const [relativesNumber, setRelativesNumber] = useState(0)
  const [rank, setRank] = useState([])

  const summary = userRelative.reduce(
    (acc, transaction) => {
      acc.total += parseFloat(transaction.individualIncome)
      return acc
    },
    {
      total: 0,
    }
  )

  useEffect(() => {
    const perCapitaa = summary.total / relativesNumber
    setPerCapita(perCapitaa)
  }, [relativesNumber, summary])

  useEffect(() => {
    props.setLoaded(false)
    const getUserMe = async () => {
      try {
        const token = await awsTokenManager.getToken()
        const config = {
          headers: { Authorization: `${token}` },
        }

        let data = await axios
          .get(`${process.env.REACT_APP_API_URL}/users/me`, config)
          .then(({ data }) => data)
        setCpf(data.cpf)

        try {
          const config = {
            headers: { Authorization: `${token}` },
          }
          let data = await axios
            .get(
              `${process.env.REACT_APP_API_URL}/user-information/${cpf}`,
              config
            )
            .then(({ data }) => data)
          setUserInformation(data)
          try {
            const config = {
              headers: { Authorization: `${token}` },
            }
            let data = await axios
              .get(
                `${process.env.REACT_APP_API_URL}/user-relative/relatives/${cpf}`,
                config
              )
              .then(({ data }) => data)

            setUserRelative(data)

            setRelativesNumber(data.length)
            try {
              const config = {
                headers: { Authorization: `${token}` },
              }
              let data = await axios
                .get(
                  `${process.env.REACT_APP_API_URL}/users-education/`,
                  config
                )
                .then(({ data }) => data)

              setUserEducation(data)
              try {
                const config = {
                  headers: { Authorization: `${token}` },
                }
                let data = await axios
                  .get(
                    `${process.env.REACT_APP_API_URL}/users-address/`,
                    config
                  )
                  .then(({ data }) => data)

                setUserAddress(data)
              } catch (err) {
                console.log(err)
              }

              try {
                const config = {
                  headers: { Authorization: `${token}` },
                }
                let data = await axios.get(
                  `${process.env.REACT_APP_API_URL}/users/average`,
                  config
                )

                setUserAverage(data.data)
              } catch (err) {
                console.log(err)
              }

              try {
                const programName = props.title.toLowerCase()
                const config = {
                  params: {
                    program: programName,
                  },
                  headers: { Authorization: `${token}` },
                }
                let data = await axios.get(
                  `${process.env.REACT_APP_API_URL}/user-registration/rank`,
                  config
                )

                setRank(data.data)
              } catch (err) {
                console.log(err)
              } finally {
                props.setLoaded(true)
              }
            } catch (err) {
              console.log(err)
            }
          } catch (err) {
            console.log(err)
          }
        } catch (err) {
          console.log(err)
        }
      } catch (err) {
        console.log(err)
      }
    }

    getUserMe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf, props.title])

  // console.log(userInformation);

  let formattedDate = theDate => {
    return format(new Date(theDate), 'dd/MM/yyyy', {
      locale: ptBR,
    })
  }

  function formatToBRL(number) {
    return new Intl.NumberFormat('pt-br', {
      currency: 'BRL',
      style: 'currency',
    }).format(number)
  }

  function formatNote(note, numberDecimals = 1) {
    return new Intl.NumberFormat('pt-br').format(note) || ''
  }

  // let formattedDateAndHour = theDate => {
  //   return format(new Date(theDate), 'dd/MM/yyyy', {
  //     locale: ptBR,
  //   });
  // };
  // const myTryedDate = new Date(props.createdAt);
  // console.log(userInformation.birthdate);
  // const parsedDate = parseISO(props.createdAt)
  // console.log(rank);
  return (
    <PageStyle ref={ref}>
      <ContainerComprovante>
        <Header>
          <DivImage>
            <img src={governoimg} alt="Imagem do Governo" />
            <TextsGov>
              <h1>MINISTÉRIO DA EDUCAÇÃO - MEC</h1>
              <h2>Secretaria de Educação Superior - {props.title}</h2>
              <h3>{props.title} - Programa Universidade Para Todos </h3>
            </TextsGov>
          </DivImage>

          <QRCODEDIV>
            <div>
              <QRCode
                value={`${process.env.REACT_APP_FRONTEND_URL}/comprovante/${props.hash}`}
                size={80}
              />
            </div>
            <span>{props.hash}</span>
            <p>
              Inscrição efetuada em:{' '}
              {`${new Date(props.createdAt).toLocaleDateString(
                'pt-br'
              )} - ${new Date(props.createdAt).toLocaleTimeString('pt-br')}`}
            </p>
          </QRCODEDIV>
        </Header>

        <LineText>
          <p>Comprovante de Inscrição {props.title} 1º/2022</p>
        </LineText>
        <DivSpaceContainer>
          <Data>
            <h3>Dados do Estudante</h3>
          </Data>

          <Row>
            <Col xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
              <UserData
                label="Nome Social"
                dataLabel={userInformation.socialName}
              />
            </Col>
            <Col xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
              <UserData
                label="Nome de Registro"
                dataLabel={userInformation.fullName}
              />
            </Col>

            <Col xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
              <UserData label="CPF" dataLabel={userInformation.cpf} />
            </Col>

            <Col xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
              <UserData label="Gênero" dataLabel={userInformation.gender} />
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
              <UserData
                label="Data de Nascimento"
                dataLabel={
                  userInformation.birthdate
                    ? formattedDate(userInformation.birthdate)
                    : null
                }
              />
            </Col>

            <Col xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
              <UserData
                label="Etnia/Cor"
                dataLabel={userInformation.ethnicity}
              />
            </Col>
            <Col xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
              <UserData
                label="Estado Civil"
                dataLabel={userInformation.maritalStatus}
              />
            </Col>

            <Col xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
              <UserData
                label="Candidato PCD"
                dataLabel={userInformation.disabilityType}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
              <UserData
                label="Brasileiro nato ou naturalizado?"
                dataLabel={userInformation.brazilian ? 'Sim' : 'Não'}
              />
            </Col>

            <Col xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
              <UserData
                label="Natural do Munícípio"
                dataLabel={userInformation.birthPlace}
              />
            </Col>
            <Col xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
              <UserData label="Natural do UF" dataLabel={userInformation.uf} />
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
              <UserData
                label="Documento de Identificação"
                dataLabel={userInformation.identificationDocument}
              />
            </Col>
            <Col xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
              <UserData
                label="Número do documento"
                dataLabel={userInformation.documentNumber}
              />
            </Col>

            {userInformation.identificationDocument !== 'CTPS' &&
            userInformation.uf !== 'Selecione seu UF' ? (
              <Col xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
                <UserData
                  label="Orgão Emissor"
                  dataLabel={userInformation.issuingAgency}
                />
              </Col>
            ) : null}

            {userInformation.uf !== 'Selecione seu UF' &&
            userInformation.identificationDocument !== 'CTPS' ? (
              <Col xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
                <UserData
                  label="UF de Emissão"
                  dataLabel={userInformation.issuingState}
                />
              </Col>
            ) : null}
          </Row>

          <Row>
            {/* <Col>
              <UserData label="Número do ENEM" dataLabel="181231190231902" />
            </Col> */}
            <Col xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
              <UserData
                label="Edição do ENEM"
                dataLabel={userAverage && userAverage.year}
              />
            </Col>
          </Row>
        </DivSpaceContainer>

        <DivSpaceContainer>
          <Data>
            <h3>Dados de Contato</h3>
          </Data>

          <Row>
            <Col xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <UserData label="E-mail" dataLabel={userAddress.email} />
            </Col>
            <Col xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <UserData
                label="Telefone para contato 1"
                dataLabel={userAddress.cellPhone}
              />
            </Col>

            <Col xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <UserData
                label="Telefone para contato 2"
                dataLabel={userAddress.phoneNumber}
              />
            </Col>
          </Row>
        </DivSpaceContainer>
        <DivSpaceContainer className="schoolDiv pagebreak">
          <Data>
            <h3>Dados Escolares</h3>
          </Data>

          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <UserData
                label="Pais de conclusão do ensino médio"
                dataLabel={userEducation.schoolCountry}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <UserData
                label="Ano de conlusão do ensino médio"
                dataLabel="2012"
              />
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <UserData
                label="UF de conclusão do ensino médio"
                dataLabel="Rio grande do norte"
              />
            </Col>
            <Col>
              <UserData
                label="Município de conclusão do ensino médio"
                dataLabel="Natal"
              />
            </Col>
          </Row> */}

          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <UserData
                label="Tipo de escola o candidato cursou o ensino médio"
                dataLabel={userEducation.schoolType}
              />
            </Col>
            {/* <Col>
              <UserData
                label="Cursou o ensino médio integralmente na UF informada?"
                dataLabel="Integralmente na UF informada"
              />
            </Col> */}
          </Row>

          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <UserData
                label="Concluí o ensino superior no curso"
                dataLabel={userEducation.courseType}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <UserData
                label="Cursou o ensino superior em instituição pública?"
                dataLabel={userEducation.publicFlag ? 'Sim' : 'Não'}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <UserData
                label="Qual o grau?"
                dataLabel={userEducation.schoolGrade}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <UserData
                label="Nome da Instituição"
                dataLabel={userEducation.schoolName}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <UserData
                label="O candidato é professor da rede pública de ensino, no efetivo exercício do magistério da educação básica, 
              integrando o quadro de pessoal permanente?"
                dataLabel={userEducation.professorFlag ? 'Sim' : 'Não'}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <UserData
                label="O candidato possui vínculo acadêmico (matrícula ativa ou trancada) com instituição de ensino superior pública e gratuita?"
                dataLabel={userEducation.academicFlag ? 'Sim' : 'Não'}
              />
            </Col>
          </Row>
        </DivSpaceContainer>

        <DivSpaceContainer className="pagebreak">
          <Data>
            <h3>Grupo Familiar</h3>
          </Data>

          <FamilyGroup>
            <Table responsive="md" className="familyTable">
              <thead>
                <tr>
                  <th width="350">Nome</th>
                  <th>CPF</th>
                  <th>Grau de Parentesco</th>
                  <th>Data de Nascimento</th>
                  <th>Renda Mensal (R$)</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr> */}
                {userRelative &&
                  userRelative.map(
                    ({
                      birthdate,
                      cpf,
                      degreeKinship,
                      individualIncome,
                      name,
                    }) => (
                      <tr>
                        <td width="350">{name}</td>
                        <td>{cpf}</td>
                        <td>{degreeKinship}</td>
                        <td>{birthdate ? formattedDate(birthdate) : null}</td>
                        <td>
                          {new Intl.NumberFormat('pt-br', {
                            currency: 'BRL',
                            style: 'currency',
                          }).format(individualIncome)}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </Table>

            <p>
              Renda familiar mensal Brutal{' '}
              <strong> {formatToBRL(summary.total)}</strong>
            </p>
            <p>
              Renda mensal bruta per capita
              <strong> {formatToBRL(perCapita)}</strong>
            </p>
          </FamilyGroup>
        </DivSpaceContainer>

        <LineText>
          <p>Comprovante de Inscrição {props.title} 1º/2022</p>
        </LineText>

        <DivSpaceContainer className="pagebreak">
          <Data>
            <h3>Cursos Selecionados</h3>
          </Data>

          <OptionCourseText>1ª Opção</OptionCourseText>

          <CardOptionCourse>
            <Row>
              <Col>
                <DataOption>
                  <h2>Curso selecionado:</h2>
                  <p>{props.firstCourseOption?.courseName}</p>
                </DataOption>
              </Col>
              <Col>
                <DataOption>
                  <h2>Instituição:</h2>
                  <p>{props.firstCourseOption?.campusName}</p>
                </DataOption>
              </Col>
            </Row>

            <Row>
              <Col>
                <DataOption>
                  <h2>Turno:</h2>
                  <p>{props.firstCourseOption?.courseTimeType}</p>
                </DataOption>
              </Col>
              <Col>
                <DataOption>
                  <h2>Grau:</h2>
                  <p>{props.firstCourseOption?.courseDegree}</p>
                </DataOption>
              </Col>
              <Col>
                <DataOption>
                  <h2>Local:</h2>
                  <p>{props.firstCourseOption?.campusCity}</p>
                  <span>{props.firstCourseOption?.campusAddress}</span>
                </DataOption>
              </Col>
            </Row>

            <Row>
              <Col>
                <DataOption>
                  <h2>Código do curso:</h2>
                  <p>238817177</p>
                </DataOption>
              </Col>
              <Col>
                <DataOption>
                  <h2>Modalidade:</h2>
                  <p>Ação afirmativa</p>
                </DataOption>
              </Col>
              <Col>
                <DataOption>
                  <h2>Cidade/UF:</h2>
                  <p>
                    {props.firstCourseOption?.campusCity}/
                    {props.firstCourseOption?.campusState}
                  </p>
                  <span>{props.firstCourseOption?.campusAddress}</span>
                </DataOption>
              </Col>
            </Row>

            {props.title === 'PROUNI' && (
              <Row>
                <Col>
                  <DataOption>
                    <h2>Tipo de Bolsa</h2>
                    <p>
                      {props.firstCourseOption.courseTimeScholarshipType}{' '}
                      {props.firstCourseOption.courseTimeScholarshipType ===
                      'Parcial'
                        ? '(50%)'
                        : '(100%)'}
                    </p>
                  </DataOption>
                </Col>
                <Col>
                  <DataOption>
                    <h2>Valor da mensalidade</h2>
                    <p>
                      {formatToBRL(props.firstCourseOption.courseTimeTuition)}
                    </p>
                  </DataOption>
                </Col>
                <Col>
                  <DataOption>
                    <h2>Valor da Mensalidade Prouni</h2>
                    <p>
                      {formatToBRL(
                        props.firstCourseOption.courseTimeTuition -
                          props.firstCourseOption.courseTimeScholarshipValue
                      )}
                    </p>
                  </DataOption>
                </Col>
                {/* <DataOption>
                  <h2>Matriculado no curso ou em outro de área afim?</h2>
                </DataOption> */}
              </Row>
            )}

            <OptionDataContainer>
              <DataOption>
                <h2>Site:</h2>
                <a href="#" target="_blank">
                  www.fsmg.edu.br
                </a>
              </DataOption>
            </OptionDataContainer>

            <OptionDataContainer>
              <TextWithline className="w-100">
                <h3>Documentações necessárias para a matrícula</h3>
                <hr className="w-100" />
              </TextWithline>
            </OptionDataContainer>

            <OptionDataContainer>
              <ResumeOptionCourse>
                <p>{props.firstCourseOption?.registrationDocuments}</p>
              </ResumeOptionCourse>
            </OptionDataContainer>

            {rank[0]?.course === props.firstCourseOption?.courseTimeId ? (
              <OptionDataContainer>
                <ResultBox>
                  <TextWithline>
                    <h3>Resultado da chamada regular</h3>
                    <hr className="w-100" />
                  </TextWithline>

                  <strong>
                    Você foi selecionado na chamada regular. {rank[0]?.rank}º de{' '}
                    {rank[0]?.totalCandidate} vagas
                  </strong>
                  {/* {console.log(props.firstCourseOption?.courseTimeId)} */}
                  {/* {props.secondCourseOption.courseTimeId} */}
                  <p>
                    {' '}
                    reservadas para ação afirmativado tipo: Candidatos com renda
                    familiar bruta per capita igual ou inferior a 1,5 salário
                    mínimo que tenham cursado integralmente o ensino médio em
                    escolas públicas (Lei nº 12.711/2012).
                  </p>
                  <p>
                    A classificação final foi divulgada em 22/02/2022 às 23h.
                  </p>
                </ResultBox>
              </OptionDataContainer>
            ) : null}
          </CardOptionCourse>
        </DivSpaceContainer>

        {props.title !== 'PROUNI' ? (
          <DivSpaceContainer className="pagebreak">
            <Data>
              <h3>
                Composição de nota do ENEM edição{' '}
                {userAverage && userAverage.year}
              </h3>
            </Data>

            <FamilyGroup>
              <Table className="familyTable">
                <thead>
                  <tr>
                    <th>Matéria</th>
                    <th>Nota Mínima</th>
                    <th>Peso</th>
                    <th>Sua Nota(A)</th>
                    <th>Sua Nota com peso(B)</th>
                  </tr>
                  {/* {console.log(userAverage)} */}
                </thead>
                <tbody>
                  <tr>
                    <td>Redação</td>
                    <td>0,01</td>
                    <td>1,00</td>
                    <td>
                      {new Intl.NumberFormat('pt-br', {}).format(
                        userAverage.notaRedacao
                      )}
                      {/* {formatNote(userAverage.notaRedacao)} */}
                    </td>
                    <td>{formatNote(userAverage.notaRedacao * 1, 2)}</td>
                  </tr>
                  <tr>
                    <td>Linguagens, Códigos e suas tecnologias</td>
                    <td>0,01</td>
                    <td>1,00</td>
                    <td>{formatNote(userAverage.notaLinguagens)}</td>
                    <td>{formatNote(userAverage.notaLinguagens * 1, 2)}</td>
                  </tr>
                  <tr>
                    <td>Matemática e suas Tecnologias</td>
                    <td>0,01</td>
                    <td>1,00</td>
                    <td>{formatNote(userAverage.notaMatematica)}</td>
                    <td>{formatNote(userAverage.notaMatematica * 1, 2)}</td>
                  </tr>
                  <tr>
                    <td>Ciências da Natureza e suas Tecnologias</td>
                    <td>0,01</td>
                    <td>1,00</td>
                    <td>{formatNote(userAverage.notaCienciasNatureza)}</td>
                    <td>
                      {formatNote(userAverage.notaCienciasNatureza * 1, 2)}
                    </td>
                  </tr>
                  <tr>
                    <td>Ciências Humanas e suas Tecnologias</td>
                    <td>0,01</td>
                    <td>1,00</td>
                    <td>{formatNote(userAverage.notaHumanas)}</td>
                    <td>{formatNote(userAverage.notaHumanas * 1, 2)}</td>
                  </tr>

                  <tr>
                    <td>
                      Nota do estudante (B/A)={' '}
                      {formatNote(
                        (userAverage.notaRedacao +
                          userAverage.notaLinguagens +
                          userAverage.notaMatematica +
                          userAverage.notaCienciasNatureza +
                          userAverage.notaHumanas) /
                          5
                      )}
                    </td>
                    <td></td>
                    <td></td>
                    <td>TOTAL{' '}{formatNote(
                        (userAverage.notaRedacao +
                          userAverage.notaLinguagens +
                          userAverage.notaMatematica +
                          userAverage.notaCienciasNatureza +
                          userAverage.notaHumanas),2
                      )} {' '}(A) </td>

                    <td>
                      TOTAL{' '}
                      {formatNote(
                        userAverage.notaRedacao +
                          userAverage.notaLinguagens +
                          userAverage.notaMatematica +
                          userAverage.notaCienciasNatureza +
                          userAverage.notaHumanas,
                        2
                      )}{' '}
                      (B)
                    </td>
                  </tr>
                </tbody>
              </Table>
            </FamilyGroup>
          </DivSpaceContainer>
        ) : null}

        <DivSpaceContainer className="pagebreak">
          <OptionCourseText>2ª Opção</OptionCourseText>

          <CardOptionCourse>
            <Row>
              <Col>
                <DataOption>
                  <h2>Curso selecionado:</h2>
                  <p>{props.secondCourseOption.courseName}</p>
                </DataOption>
              </Col>
              <Col>
                <DataOption>
                  <h2>Instituição:</h2>
                  <p>{props.secondCourseOption.campusName}</p>
                </DataOption>
              </Col>
            </Row>

            <Row>
              <Col>
                <DataOption>
                  <h2>Turno:</h2>
                  <p>{props.secondCourseOption.courseTimeType}</p>
                </DataOption>{' '}
              </Col>
              <Col>
                <DataOption>
                  <h2>Grau:</h2>
                  <p>{props.secondCourseOption.courseDegree}</p>
                </DataOption>{' '}
              </Col>
              <Col>
                <DataOption>
                  <h2>Local:</h2>
                  <p>{props.secondCourseOption.campusCity}</p>
                  <span>{props.secondCourseOption.campusAddress}</span>
                </DataOption>
              </Col>
            </Row>

            <Row>
              <Col>
                <DataOption>
                  <h2>Código do curso:</h2>
                  <p>238817177</p>
                </DataOption>{' '}
              </Col>
              <Col>
                <DataOption>
                  <h2>Modalidade:</h2>
                  <p>Ação afirmativa</p>
                </DataOption>
              </Col>
              <Col>
                <DataOption>
                  <h2>Cidade/UF:</h2>
                  <p>
                    {props.secondCourseOption.campusCity}/{' '}
                    {props.secondCourseOption.campusState}
                  </p>
                  <span>{props.secondCourseOption.campusAddress}</span>
                </DataOption>
              </Col>
            </Row>

            {props.title === 'PROUNI' && (
              <Row>
                <Col>
                  <DataOption>
                    <h2>Tipo de Bolsa</h2>
                    <p>
                      {props.secondCourseOption.courseTimeScholarshipType}{' '}
                      {props.secondCourseOption.courseTimeScholarshipType ===
                      'Parcial'
                        ? '(50%)'
                        : '(100%)'}
                    </p>
                  </DataOption>{' '}
                </Col>
                <Col>
                  <DataOption>
                    <h2>Valor da mensalidade</h2>
                    <p>
                      {formatToBRL(props.secondCourseOption.courseTimeTuition)}
                    </p>
                  </DataOption>{' '}
                </Col>
                <Col>
                  <DataOption>
                    <h2>Valor da Mensalidade Prouni</h2>
                    <p>
                      {formatToBRL(
                        props.secondCourseOption.courseTimeTuition -
                          props.secondCourseOption.courseTimeScholarshipValue
                      )}
                    </p>
                  </DataOption>
                </Col>
                {/* <DataOption>
                  <h2>Matriculado no curso ou em outro de área afim?</h2>
                </DataOption> */}
              </Row>
            )}

            <OptionDataContainer>
              <DataOption>
                <h2>Site:</h2>
                <a href="#" target="_blank">
                  www.fsmg.edu.br
                </a>
              </DataOption>
            </OptionDataContainer>

            <OptionDataContainer>
              <TextWithline className="w-100">
                <h3>Documentações necessárias para a matrícula</h3>
                <hr className="w-100" />
              </TextWithline>
            </OptionDataContainer>

            <OptionDataContainer>
              <ResumeOptionCourse>
                <p>{props.secondCourseOption?.registrationDocuments}</p>
              </ResumeOptionCourse>
            </OptionDataContainer>

            {rank[1]?.course === props.secondCourseOption.courseTimeId ? (
              <OptionDataContainer>
                <ResultBox>
                  <TextWithline>
                    <h3>Resultado da chamada regular</h3>
                    <hr className="w-100" />
                  </TextWithline>

                  <strong>
                    Você foi selecionado na chamada regular. {rank[1]?.rank}º de{' '}
                    {rank[1]?.totalCandidate} vagas
                  </strong>
                  {/* 
                  {console.log(rank[1]?.course)}
                  {console.log(props.secondCourseOption.courseTimeId)} */}
                  <p>
                    {' '}
                    reservadas para ação afirmativado tipo: Candidatos com renda
                    familiar bruta per capita igual ou inferior a 1,5 salário
                    mínimo que tenham cursado integralmente o ensino médio em
                    escolas públicas (Lei nº 12.711/2012).
                  </p>
                  <p>
                    A classificação final foi divulgada em 22/02/2022 às 23h.
                  </p>
                </ResultBox>
              </OptionDataContainer>
            ) : null}
          </CardOptionCourse>
        </DivSpaceContainer>

        {props.title !== 'PROUNI' ? (
          <DivSpaceContainer className="pagebreak">
            <Data>
              <h3>
                Composição de nota do ENEM edição{' '}
                {userAverage && userAverage.year}
              </h3>
            </Data>

            <FamilyGroup>
              <Table className="familyTable">
                <thead>
                  <tr>
                    <th>Matéria</th>
                    <th>Nota Mínima</th>
                    <th>Peso</th>
                    <th>Sua Nota(A)</th>
                    <th>Sua Nota com peso(B)</th>
                  </tr>
                  {/* {console.log(userAverage)} */}
                </thead>
                <tbody>
                  <tr>
                    <td>Redação</td>
                    <td>0,01</td>
                    <td>1,00</td>
                    <td>
                      {new Intl.NumberFormat('pt-br', {}).format(
                        userAverage.notaRedacao
                      )}
                      {/* {formatNote(userAverage.notaRedacao)} */}
                    </td>
                    <td>{formatNote(userAverage.notaRedacao * 1, 2)}</td>
                  </tr>
                  <tr>
                    <td>Linguagens, Códigos e suas tecnologias</td>
                    <td>0,01</td>
                    <td>1,00</td>
                    <td>{formatNote(userAverage.notaLinguagens)}</td>
                    <td>{formatNote(userAverage.notaLinguagens * 1, 2)}</td>
                  </tr>
                  <tr>
                    <td>Matemática e suas Tecnologias</td>
                    <td>0,01</td>
                    <td>1,00</td>
                    <td>{formatNote(userAverage.notaMatematica)}</td>
                    <td>{formatNote(userAverage.notaMatematica * 1, 2)}</td>
                  </tr>
                  <tr>
                    <td>Ciências da Natureza e suas Tecnologias</td>
                    <td>0,01</td>
                    <td>1,00</td>
                    <td>{formatNote(userAverage.notaCienciasNatureza)}</td>
                    <td>
                      {formatNote(userAverage.notaCienciasNatureza * 1, 2)}
                    </td>
                  </tr>
                  <tr>
                    <td>Ciências Humanas e suas Tecnologias</td>
                    <td>0,01</td>
                    <td>1,00</td>
                    <td>{formatNote(userAverage.notaHumanas)}</td>
                    <td>{formatNote(userAverage.notaHumanas * 1, 2)}</td>
                  </tr>

                  <tr>
                    <td>
                      Nota do estudante (B/A)={' '}
                      {formatNote(
                        (userAverage.notaRedacao +
                          userAverage.notaLinguagens +
                          userAverage.notaMatematica +
                          userAverage.notaCienciasNatureza +
                          userAverage.notaHumanas) /
                          5
                      )}
                    </td>
                    <td></td>
                    <td></td>
                    <td>TOTAL{' '}{formatNote(
                        (userAverage.notaRedacao +
                          userAverage.notaLinguagens +
                          userAverage.notaMatematica +
                          userAverage.notaCienciasNatureza +
                          userAverage.notaHumanas),2
                      )}{' '}(A)</td>

                    <td>
                      TOTAL{' '}
                      {formatNote(
                        userAverage.notaRedacao +
                          userAverage.notaLinguagens +
                          userAverage.notaMatematica +
                          userAverage.notaCienciasNatureza +
                          userAverage.notaHumanas,
                        2
                      )}{' '}
                      (B)
                    </td>
                  </tr>
                </tbody>
              </Table>
            </FamilyGroup>
          </DivSpaceContainer>
        ) : null}

        {props.title === 'PROUNI' ? (
          <DivSpaceContainer className="pagebreak">
            <Data>
              <h3>
                Composição de nota do ENEM edição{' '}
                {userAverage && userAverage.year}
              </h3>
            </Data>

            <FamilyGroup>
              <Table responsive="md" className="familyTable text-left">
                <thead>
                  <tr>
                    <th>Matéria</th>
                    <th>Nota</th>
                  </tr>
                  {/* {console.log(userAverage)} */}
                </thead>
                <tbody>
                  <tr>
                    <td>Redação</td>
                    <td>
                      {new Intl.NumberFormat('pt-br', {}).format(
                        userAverage.notaRedacao
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Linguagens, Códigos e suas tecnologias</td>
                    <td>{formatNote(userAverage.notaLinguagens)}</td>
                  </tr>
                  <tr>
                    <td>Matemática e suas Tecnologias</td>
                    <td>{formatNote(userAverage.notaMatematica)}</td>
                  </tr>
                  <tr>
                    <td>Ciências da Natureza e suas Tecnologias</td>
                    <td>{formatNote(userAverage.notaCienciasNatureza)}</td>
                  </tr>
                  <tr>
                    <td>Ciências Humanas e suas Tecnologias</td>
                    <td>{formatNote(userAverage.notaHumanas)}</td>
                  </tr>

                  <tr>
                    <td>Média</td>
                    <td>
                      TOTAL{' '}
                      {formatNote(
                        (userAverage.notaRedacao +
                          userAverage.notaLinguagens +
                          userAverage.notaMatematica +
                          userAverage.notaCienciasNatureza +
                          userAverage.notaHumanas) /
                          5
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </FamilyGroup>
          </DivSpaceContainer>
        ) : null}

        <DivSpaceContainer className="pagebreak">
          <Data>
            <h3>Declarações</h3>
          </Data>

          <ContainerDeclarations>
            <p>Declaração 1 : {props.firstCourseOption.courseDeclaration}</p>
            <p>Declaração 2 : {props.secondCourseOption.courseDeclaration}</p>
            <p>
              Declaro ter lido a documentação necessário para comprovação dos
              dados cadastrados.....
            </p>

            <Header>
              <DivImage>
                <TextsGov>
                  <p>
                    Inscrição efetuada em:{' '}
                    {`${new Date(props.createdAt).toLocaleDateString(
                      'pt-br'
                    )} - ${new Date(props.createdAt).toLocaleTimeString(
                      'pt-br'
                    )}`}
                  </p>

                  <span>{props.hash}</span>
                </TextsGov>
              </DivImage>

              <QRCODEDIV>
                <div>
                  <QRCode
                    value={`${process.env.REACT_APP_FRONTEND_URL}/comprovante/${props.hash}`}
                    size={80}
                  />
                </div>
              </QRCODEDIV>
            </Header>

            <p>Acesso único v. 0.872.1</p>
            <p>Todos os horários referem-se ao horário oficial de Brasília.</p>
            <p> © 2022 {props.title}. Todos os direitos reservados.</p>
          </ContainerDeclarations>

          <PrintText>
            <p>
              Data da impressão:{' '}
              {`${new Date().toLocaleDateString(
                'pt-br'
              )} - ${new Date().toLocaleTimeString('pt-br')}`}
            </p>
          </PrintText>
        </DivSpaceContainer>
      </ContainerComprovante>
    </PageStyle>
  )
})
