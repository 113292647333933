import React, { createContext, useState } from 'react'

export const StepSubscribeContext = createContext({})

export function StepSubsCriberProvider({ children }) {
  const [step1, setStep1] = useState(true)
  const [step2, setStep2] = useState(false)
  const [step3, setStep3] = useState(false)
  const [step4, setStep4] = useState(false)
  const [step, setStep] = useState(1)
  const [detailCourse, setDetailCourse] = useState('')
  const [valorTeste, setValorTeste] = useState('testezinho')
  const [selectCourse, setSelectCourse] = useState(false)
  const [myCourse, setMyCourse] = useState(false)
  const [course, setCourse] = useState('')
  const [courseDetail, setCourseDetail] = useState(false)
  const [local, setLocal] = useState('')
  const [inputText, setInputText] = useState('')
  const [instituition, setInstitution] = useState('')
  const [optionCourse, setOptionsCourse] = useState(false)
  const [search, setSearch] = useState(false)
  const [dataCourse, setDataCourse] = useState('')
  const [notaCorte, setNotaCorte] = useState('')

  const [courseContext, setCourseContext] = useState('')
  const [modality, setModality] = useState('')
  const [courseTime, setCourseTime] = useState('')
  const [university, setUniversity] = useState('')
  const [city, setCity] = useState('')
  const [uf, setUf] = useState('')

  const [selectCourse1, setSelectCourse1] = useState(false)
  const [selectCourse2, setSelectCourse2] = useState(false)

  return (
    <StepSubscribeContext.Provider
      value={{
        selectCourse1,
        selectCourse2,
        setSelectCourse1,
        setSelectCourse2,
        step1,
        setStep1,
        step2,
        setStep2,
        step3,
        setStep3,
        step4,
        setStep4,
        dataCourse,
        setDataCourse,
        step,
        setStep,
        detailCourse,
        setDetailCourse,
        valorTeste,
        setValorTeste,
        selectCourse,
        setSelectCourse,
        myCourse,
        setMyCourse,
        course,
        setCourse,
        local,
        setLocal,
        inputText,
        setInputText,
        instituition,
        setInstitution,
        optionCourse,
        setOptionsCourse,
        search,
        courseDetail,
        setCourseDetail,
        setSearch,
        notaCorte,
        setNotaCorte,
        setCourseContext,
        setModality,
        setCourseTime,
        setUniversity,
        setCity,
        setUf,
        courseContext,
        modality,
        courseTime,
        university,
        city,
        uf,
      }}
    >
      {children}
    </StepSubscribeContext.Provider>
  )
}
