/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import {
  ContainerStep,
  SubscribeInformation,
  ButtonsStepContainer,
  ConfirmedCourse,
  HashContainer,
} from './style'
import { Link, useHistory } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { CardConfirmCourse } from '../CardConfirmCourse'

import { useEffect, useState } from 'react'
import { useLoading } from 'hooks/useLoading'
import { apiWithToken } from 'services/apiWithToken'
import { useUser } from 'hooks/useUser'

export function StepFourSubscribeForm() {
  const { loading } = useLoading()
  const history = useHistory()

  const [hash, setHash] = useState('')

  const [courseOne, setCourseOne] = useState({})
  const [courseTwo, setCourseTwo] = useState({})

  const [createdDate, setCreatedDate] = useState('')

  const { cpf } = useUser()

  useEffect(() => {
    const getUserMe = async () => {
      loading.turnOn()

      try {
        const { data } = await apiWithToken.get(`/user-registration/${cpf}`)
        setHash(data[0].hash)
        setCourseOne(data[0].courses[0])
        setCourseTwo(data[0].courses[1])
        setCreatedDate(data[0].createdDate)
      } catch (err) {
        console.error(err)
      } finally {
        loading.turnOff()
      }

      loading.turnOff()
    }

    getUserMe()
  }, [cpf])

  return (
    <ContainerStep>
      <Container>
        <h1>Processo Concluído</h1>
      </Container>

      <Container>
        <SubscribeInformation>
          <p>
            Sua inscrição foi recebida, fique atento aos nosso comunicados para
            saber o resultado
            <br /> do processo.
          </p>

          <ButtonsStepContainer>
            <Link
              to={`/comprovante/${hash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Imprimir comprovante
            </Link>
          </ButtonsStepContainer>
        </SubscribeInformation>

        <ConfirmedCourse>
          <h2>Cursos Confirmados</h2>

          <Row>
            <Col md={6}>
              <CardConfirmCourse
                isEdit={false}
                option="1"
                courseName={courseOne.courseName}
                modality={courseOne.courseDegree}
                courseTime={courseOne.courseTimeType}
                university={courseOne.collegeName}
                city={courseOne.campusNeighborhood}
                campusState={courseOne.campusState}
                campusNeighborhood={courseOne.campusNeighborhood}
                courseState={courseOne.campusState}
                courseLocation={courseOne.campusName}
                modalityType={courseOne.modality}
              />
            </Col>

            {courseTwo && (
              <Col md={6}>
                <CardConfirmCourse
                  isEdit={false}
                  option="2"
                  courseLocation={courseTwo.campusName}
                  courseName={courseTwo.courseName}
                  modality={courseTwo.courseDegree}
                  courseTime={courseTwo.courseTimeType}
                  university={courseTwo.collegeName}
                  city={courseTwo.campusNeighborhood}
                  campusState={courseTwo.campusState}
                  campusNeighborhood={courseTwo.campusNeighborhood}
                  courseState={courseTwo.campusState}
                  modalityType={courseTwo.modality}
                />
              </Col>
            )}
          </Row>
        </ConfirmedCourse>
      </Container>

      <Container>
        <HashContainer>
          <h3>{hash || ''}</h3>
          <p>
            Inscrição efetuada em:{' '}
            {`${new Date(createdDate).toLocaleDateString('pt-br')} - ${new Date(
              createdDate
            ).toLocaleTimeString('pt-br')}`}
          </p>
          <button
            onClick={() => {
              history.push('/candidate-page')
            }}
          >
            Ir para o painel do estudante
          </button>
        </HashContainer>
      </Container>
    </ContainerStep>
  )
}
