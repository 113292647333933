/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container, Row, Col } from 'react-bootstrap'
import {
  Wrapper,
  Links,
  IconContainer,
  EndFooter,
  WrapperLinks,
  ImageMinisteryContainer,
  IconsWrapper,
} from './styles'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa'
import ministeryImage from './img/ministeryImage.svg'

export function Footer() {
  return (
    <Wrapper>
      <Container className="overflow-hidden ">
        <WrapperLinks className="overflow-hidden ">
          <Row className="overflow-hidden ">
            <Col sm={12} md={12} lg={2} className="mr-5">
              <Links>
                <h2>PROGRAMAS</h2>
                <li>
                  <a href="#">Conheça o SISU</a>
                </li>
                <li>
                  <a href="#">Conheça o PROUNI</a>
                </li>
                <li>
                  <a href="#">Conheça o FIES</a>
                </li>
              </Links>
            </Col>

            <Col sm={12} md={12} lg={2} className="mr-5">
              <Links>
                <h2>LEGISLAÇÃO</h2>
                <li>
                  <a href="#">SISU</a>
                </li>
                <li>
                  <a href="#">PROUNI</a>
                </li>
                <li>
                  <a href="#">FIES</a>
                </li>
              </Links>
            </Col>

            <Col sm={12} md={12} lg={2} className="mr-5">
              <Links>
                <h2>DOCUMENTAÇÃO</h2>
                <li>
                  <a href="#">Documentação dos programas</a>
                </li>
              </Links>
            </Col>

            <Col sm={12} md={12} lg={2} className="mr-5">
              <Links>
                <h2>BUSCA AVANÇADA</h2>
                <li>
                  <a href="#">Oferta por curso</a>
                </li>
              </Links>
            </Col>

            <Col sm={12} md={12} lg={2}>
              <Links>
                <h2>CONTATO</h2>
                <li>
                  <a href="#">0800-616161</a>
                </li>
                <li>
                  <a href="#">Central de Atendimento</a>
                </li>
              </Links>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="d-flex " sm={12} md={12} lg={6}>
              <IconsWrapper className=" d-flex gap-3">
                <IconContainer>
                  <div>
                    <FaFacebookF />
                  </div>
                </IconContainer>
                <IconContainer>
                  <div>
                    <FaInstagram />
                  </div>
                </IconContainer>
                <IconContainer>
                  <div>
                    <FaTwitter />
                  </div>
                </IconContainer>
                <IconContainer>
                  <div>
                    <FaYoutube />
                  </div>
                </IconContainer>
              </IconsWrapper>
            </Col>
            <Col className="d-flex" sm={12} md={12} lg={6}>
              <ImageMinisteryContainer>
                <img src={ministeryImage} alt="" />
              </ImageMinisteryContainer>
            </Col>
          </Row>
        </WrapperLinks>
      </Container>

      <Row>
        <EndFooter>
          <p>
            Texto destinado a exibição das informações relacionadas à{' '}
            <strong>licença de uso</strong>
          </p>
        </EndFooter>
      </Row>
    </Wrapper>
  )
}
