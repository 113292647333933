import { FONT_FAMILY } from 'constants/fonts'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;

  .container {
    position: relative;
  }

  .declaro {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;

    input {
      transform: scale(1.2);
      margin-top: 3px;
      margin-right: 10px;
    }
  }
`
export const ChooseCourse = styled.div`
  min-height: 23.9375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #f3f3f3;
  flex-direction: column;
  gap: 1.2rem;
  height: 100%;
  transition: ease background 0.2s, color 0.2s;
  cursor: pointer;

  h1 {
    font-size: 1.5rem;
  }
  &:hover {
    background: #525252;
    color: #ffffff;

    h1 {
      color: #ffffff;
    }
  }
`

export const InputGroupDiv = styled.div``

export const ContainerStepButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4.875rem;
  button {
    padding: 0.6rem 1rem 0.6rem;
    border: 0;
    font-weight: 700;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
    &.prevButton {
      background: #fcfcfc;
      border: 1px solid ${props => props.theme.bgColors.primaryDefault};
      border-radius: 42px;
      color: ${props => props.theme.bgColors.primaryDefault};
      min-width: 10.5625rem;
    }

    &.nextButton {
      background: #fcfcfc;
      border-radius: 42px;
      color: ${props => props.theme.bgColors.colorSecondary01};
      min-width: 12.625rem;
      background: ${props => props.theme.bgColors.primaryDefault};
    }
    @media screen and (max-width: 768px) {
      min-width: initial !important;
    }
  }
`

export const ButtonStepContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  button {
    padding: 0.6rem 1rem 0.6rem;
    border: 0;
    font-weight: 700;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    background: #fcfcfc;
    border: 1px solid ${props => props.theme.bgColors.primaryDefault};
    border-radius: 42px;
    color: ${props => props.theme.bgColors.primaryDefault};
    min-width: 10.5625rem;
    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.8);
    }

    @media screen and (max-width: 768px) {
      min-width: initial !important;
    }
  }

  span {
    display: flex;
    gap: 1rem;
    align-items: center;

    div {
      background: #000000;
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      border-radius: 100%;
    }

    p {
      margin: 0;
      padding: 0;
      font-weight: bold;
    }
  }
`

export const DivRow = styled.div`
  width: 1140px;
  max-width: 100%;
  padding: 0;
  margin-right: 15px;
  margin-left: 15px;

  position: relative;

  @media (max-width: 900px) {
    padding: 15px;
  }
  @media (max-width: 575px) {
    padding: 0;
    margin: 0;
  }
`

export const Button = styled.button`
  z-index: 99;
  outline: none;
  border: none;
  &.next {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 600;
    font-size: 16.8px;
    line-height: 23px;
    color: #ffffff;

    padding: 8px 24px;

    background: #1351b4;
    border-radius: 20px;

    max-width: 100%;
    width: 127px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all ease 0.2s;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    position: absolute;
    bottom: -60px;
    right: 15px;
  }

  &.prev {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 600;
    font-size: 16.8px;
    line-height: 23px;

    color: #1351b4;
    background: none;

    border: 1px solid #1351b4;
    border-radius: 20px;
    padding: 8px 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all ease 0.2s;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    position: absolute;
    bottom: -35px;
    left: 15px;
  }
`

export const SaveTimeDiv = styled.div`
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 100%;

  position: absolute;
  bottom: -50px;

  p {
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #333333;

    margin: 0;
    padding: 0;

    img {
      margin-right: 8px;
    }
  }

  @media (max-width: 991px) {
    justify-content: flex-start;
    position: unset;

    > p {
      padding-left: 15px;
      margin: 16px;
    }
  }
`
