/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import wrapper from 'components/Wrapper'

import { AUTH_ROUTES } from 'modules/Auth/constants/routes'
import { USER_ROUTES } from 'modules/User/constants/routes'
import { userService } from 'services/providers/user'

import PrivateFooter from './fragments/PrivateFooter'
import PrivateHeader from './fragments/PrivateHeader'
import { ContentWrapper } from './styles'

const PrivateRoutes = ({
  routes,
  links,
  menus,
  securityQuestionsAnswered,
  isAuthenticated,
  dataProcessingAgreement,
  user,
}) => {
  const location = useLocation()

  useEffect(() => {
    /* eslint-disable no-unused-vars */
    async function checkFistAccess() {
      if (isAuthenticated && !user.firstAccess) {
        await userService.setFirstAccess()
      }
    }
    /* eslint-enable no-unused-vars */
    // TODO: Chamar o first accesss se tiver alguma implementação de primeiro acesso na aplicacao
    // checkFistAccess();
  }, [user, isAuthenticated])

  if (!isAuthenticated) {
    return <Redirect to={AUTH_ROUTES.LOGIN} />
  }

  if (!securityQuestionsAnswered) {
    return <Redirect to={USER_ROUTES.SELECT_SECURITY_QUESTIONS} />
  }

  if (!dataProcessingAgreement) {
    return <Redirect to={USER_ROUTES.DATA_PROCESSING_AGREEMENT} />
  }

  const firstRoute = routes[0].path

  return (
    <>
      <PrivateHeader links={links} menus={menus} location={location} />
      <ContentWrapper>
        <Switch>
          {routes.map(route => (
            <Route key={route.path} {...route} />
          ))}

          <Redirect to={firstRoute} />
        </Switch>
      </ContentWrapper>

      <PrivateFooter />
    </>
  )
}

export default React.memo(
  wrapper(PrivateRoutes, {
    mapState: state => ({
      securityQuestionsAnswered: state.auth.securityQuestionsAnswered,
      dataProcessingAgreement: state.auth.dataProcessingAgreement,
      isAuthenticated: state.auth.isAuthenticated,
      user: state.auth.user,
    }),
  })
)
