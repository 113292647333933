import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { APP_MEASURES } from 'modules/App/constants/measures'

export const Container = styled.div`
  position: relative;
  bottom: 0;
  /* height: ${`${APP_MEASURES.FOOTER_HEIGHT}px`};
  min-height: ${`${APP_MEASURES.FOOTER_HEIGHT}px`};
  max-height: ${`${APP_MEASURES.FOOTER_HEIGHT}px`}; */
  min-height: 455px;
  overflow: hidden;
  width: 100%;
`

export const CustomLink = styled(Link)`
  color: ${props => props.theme.textColors.primaryLighten01};
  text-decoration: none;
`

export const Content = styled.div`
  position: absolute;
  bottom: 0;
  background-color: ${props => props.theme.bgColors.primaryDarken02};
  box-shadow: -2px -2px -8px ${props => props.theme.bgColors.colorSecondary04};
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0px 20px;
  margin-top: 40px;

  a {
    font-size: 11.67px;
    line-height: 16px;
  }
`
