import React from 'react'
import { useForm } from 'react-hook-form'

import PropTypes from 'prop-types'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Grid } from '@material-ui/core'

import { MASKS } from 'constants/masks'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components/Dialog'
import Form from 'components/Form'
import MaskedTextField from 'components/MaskedTextField'
import SelectField from 'components/SelectField'
import TextField from 'components/TextField'

import { notificationUseCases } from 'modules/Notification/providers'
import { userService } from 'services/providers/user'

import { schema } from './schema'

const ModalUser = ({ open, handleModal, userData }) => {
  const formMethods = useForm({
    resolver: yupResolver(schema),
  })

  const STATUS = [
    {
      id: 'true',
      name: 'Ativo',
    },
    {
      id: 'false',
      name: 'Inativo',
    },
  ]

  const ROLES = [
    {
      id: 'ROLE_ADMIN',
      name: 'Admin',
    },
  ]

  const onSubmit = async FormData => {
    let response = null
    const { cpf, email, name, role, status } = FormData

    try {
      const payload = {
        active: status,
        cpf,
        email,
        id: userData?.id,
        name,
        role,
      }

      if (userData) {
        response = await userService.editUser(payload)
        notificationUseCases.success('UPDATED_SUCCESS')
      } else {
        delete payload.id
        response = await userService.createAccount(payload)
        notificationUseCases.success('CREATED_SUCCESS')
      }
      handleModal(response)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle onClose={handleModal}>
        {userData ? 'Alterar Usuário' : 'Cadastrar Usuário'}
      </DialogTitle>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MaskedTextField
                disabled={!!userData}
                name="cpf"
                label="CPF"
                formMethods={formMethods}
                mask={MASKS.CPF}
                defaultValue={userData?.cpf}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Nome do usuário"
                formMethods={formMethods}
                maxLength={255}
                defaultValue={userData?.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={!!userData}
                name="email"
                label="Email"
                formMethods={formMethods}
                maxLength={255}
                defaultValue={userData?.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectField
                name="role"
                label="Perfil"
                options={ROLES}
                formMethods={formMethods}
                defaultValue={userData?.role}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectField
                name="status"
                label="Status"
                options={STATUS}
                formMethods={formMethods}
                defaultValue={userData?.active?.toString()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button variant="outlined" onClick={handleModal}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit">
                {userData ? 'Alterar' : 'Cadastrar'}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

ModalUser.defaultProps = {
  userData: null,
}

ModalUser.propTypes = {
  open: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  userData: PropTypes.objectOf(PropTypes.any),
}

export default ModalUser
