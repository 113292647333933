/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useRef } from 'react'

// import { ContentContainer } from 'ui/styles';

import { Button, Grid, Typography } from '@material-ui/core'

import Form from 'components/Form'
import { useLangContext } from 'components/Globalization'
import ReCAPTCHAInput from 'components/ReCAPTCHAInput'
import TextField from 'components/TextField'
import wrapper from 'components/Wrapper'

// import ReadOnlyTermsOfUse from 'modules/TermsOfUse/fragments/ReadOnlyTermsOfUse'

import usePasswordValidation from '../PasswordValidation'
import PasswordValidationDisplay from '../PasswordValidationDisplay'
import { Container } from './style'

const CreatePasswordForm = ({
  children,
  cpf,
  onSubmit,
  customValidation = {},
}) => {
  const translate = useLangContext()
  const recaptchaRef = useRef()

  const { validation, onPasswordChangeHandler, CreatePasswordSchema } =
    usePasswordValidation(translate)

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h4" color="primary">
            {translate('NEW_PASSWORD')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            {translate('FORM_FILLING')}
          </Typography>
        </Grid>
      </Grid>

      <Form
        defaultValues={{ cpf }}
        onSubmit={onSubmit}
        schema={CreatePasswordSchema(customValidation)}
      >
        {formMethods => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="cpf"
                formMethods={formMethods}
                label={translate('DOCUMENT')}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="password"
                name="password"
                label={translate('PASSWORD')}
                placeholder={translate('PASSWORD_PLACEHOLDER')}
                onChange={onPasswordChangeHandler}
                formMethods={formMethods}
                maxLength={20}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="password"
                name="password_confirmation"
                label={translate('REPEAT_PASSWORD')}
                placeholder={translate('REPEAT_PASSWORD_PLACEHOLDER')}
                formMethods={formMethods}
                maxLength={20}
              />
            </Grid>

            <Grid item xs={12}>
              {children ? children(formMethods) : null}
            </Grid>

            <Grid item xs={12}>
              <ReCAPTCHAInput formMethods={formMethods} ref={recaptchaRef} />
            </Grid>

            <Grid item xs={12}>
              <PasswordValidationDisplay {...validation} />
            </Grid>

            {/* <ReadOnlyTermsOfUse /> */}

            <Grid
              container
              item
              xs={12}
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Grid item>
                <Button type="submit">{translate('SAVE')}</Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Form>
    </Container>
  )
}

export default wrapper(CreatePasswordForm, {
  namespace: 'CREATE_PASSWORD',
})
