export const USER_ROUTES = {
  LOGIN: '/login',
  CHANGE_PASSWORD: '/change-password',
  CREATE_ACCOUNT: '/create-account',
  REQUEST_PASSWORD_RESET: '/request-password-reset',
  RESET_PASSWORD: '/reset-password/:token',
  CREATE_PASSWORD: '/create-password/:token',
  SELECT_SECURITY_QUESTIONS: '/select-security-questions',
  DATA_PROCESSING_AGREEMENT: '/data-processing-agreement',
  PUBLISHER_REGISTRATION_REQUEST: '/publisher-registration-request',
}
