import React, { useEffect, useState } from 'react'
import { StepFour } from './StepFour'
import { StepOne } from './StepOne'
import { StepThree } from './StepThree'
import { StepTwo } from './StepTwo'
import {
  Button,
  Container,
  DivRow,
  LineTop,
  SaveTimeDiv,
  StepItem,
  StepsBox,
  StepsDiv,
  TextH1,
} from './styles'

import tagSvg from './img/tag.svg'
import tagOkSvg from './img/tagOk.svg'
import FormContext1 from './formContext1'
import FormContext2 from './formContext2'
import FormContext3 from './formContext3'

export function DataConfirmSteps(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [step1, setStep1] = useState(props.step1)
  const [step2, setStep2] = useState(props.step2)
  const [step3, setStep3] = useState(props.step3)
  const [step4, setStep4] = useState(props.step4)

  const [formContent1, setFormContent1] = useState(false)
  const [formContent2, setFormContent2] = useState(false)
  const [formContent3, setFormContent3] = useState(false)
  const value1 = { formContent1, setFormContent1 }
  const value2 = { formContent2, setFormContent2 }
  const value3 = { formContent3, setFormContent3 }

  useEffect(() => {
    if (formContent1) {
      setStep1(false)
      setStep2(true)
      setStep3(false)
      setStep4(false)
    }
    if (formContent2) {
      setStep1(false)
      setStep2(false)
      setStep3(true)
      setStep4(false)
    }
    if (formContent3) {
      setStep1(false)
      setStep2(false)
      setStep3(false)
      setStep4(true)
    }
  }, [formContent1, formContent2, formContent3])

  document.addEventListener(
    'invalid',
    (function () {
      return function (e) {
        e.preventDefault()
        // document.getElementById('name').focus();
      }
    })(),
    true
  )

  return (
    <>
      <Container data-test="confirmation-data-steps-Container">
        <DivRow className="row" data-test="confirmation-data-steps-DivRow">
          <div className="col-12">
            <TextH1 data-test="confirmation-data-steps-TextH1">
              Confirmação de dados
            </TextH1>
            <StepsDiv data-test="confirmation-data-steps-StepsDiv">
              <StepsBox data-test="confirmation-data-steps-StepsBox">
                <StepItem
                  data-test="confirmation-data-steps-StepItem"
                  stepNumber="1"
                  className={` ${step1 ? 'active' : null}`}
                >
                  {step2 || step3 || step4 ? (
                    <img src={tagOkSvg} alt="Concluído" />
                  ) : null}

                  <span>
                    Dados sobre <br></br> você
                  </span>
                </StepItem>
                <StepItem
                  data-test="confirmation-data-steps-StepItem"
                  stepNumber="2"
                  className={`${step2 ? 'active' : null}`}
                >
                  {step3 || step4 ? (
                    <img src={tagOkSvg} alt="Concluído" />
                  ) : null}
                  <span>
                    Dados de <br></br> contato
                  </span>
                </StepItem>
                <StepItem
                  data-test="confirmation-data-steps-StepItem"
                  stepNumber="3"
                  className={` ${step3 ? 'active' : null}`}
                >
                  {step4 ? <img src={tagOkSvg} alt="Concluído" /> : null}
                  <span>
                    Dados <br></br> escolares
                  </span>
                </StepItem>
                <StepItem
                  data-test="confirmation-data-steps-StepItem"
                  stepNumber="4"
                  className={` ${step4 ? 'active' : null}`}
                >
                  <span>Dados socioeconômicos</span>
                </StepItem>
              </StepsBox>
            </StepsDiv>
          </div>
        </DivRow>
      </Container>
      <LineTop data-test="confirmation-data-steps-LineTop" />
      <Container data-test="confirmation-data-steps-Container">
        <DivRow data-test="confirmation-data-steps-DivRow">
          <FormContext1.Provider value={value1}>
            {step1 && <StepOne />}
          </FormContext1.Provider>

          <FormContext2.Provider value={value2}>
            {step2 && (
              <>
                <StepTwo />
                <Button
                  data-test="confirmation-data-steps-ButtonPrev"
                  className="prev"
                  onClick={function (event) {
                    setStep1(true)
                    setStep2(false)
                    setStep3(false)
                    setStep4(false)
                  }}
                >
                  Voltar
                </Button>
              </>
            )}
          </FormContext2.Provider>

          <FormContext3.Provider value={value3}>
            {step3 && (
              <>
                <StepThree />
                <Button
                  data-test="confirmation-data-steps-ButtonPrev"
                  className="prev"
                  onClick={function (event) {
                    setStep1(false)
                    setStep2(true)
                    setStep3(false)
                    setStep4(false)
                  }}
                >
                  Voltar
                </Button>
              </>
            )}
          </FormContext3.Provider>

          {step4 && (
            <>
              <StepFour />
              <Button
                data-test="confirmation-data-steps-ButtonPrev"
                className="prev"
                onClick={function (event) {
                  setStep1(false)
                  setStep2(false)
                  setStep3(true)
                  setStep4(false)
                }}
              >
                Voltar
              </Button>
            </>
          )}

          {!step1 ? (
            <>
              <SaveTimeDiv data-test="confirmation-data-steps-SaveTimeDiv">
                <p data-test="confirmation-data-steps-p">
                  <img
                    src={tagSvg}
                    alt="Check"
                    data-test="confirmation-data-steps-img"
                  />
                  Dados salvos às {new Date().toLocaleTimeString('pt-br')} -{' '}
                  {new Date().toLocaleDateString('pt-br')}
                </p>
              </SaveTimeDiv>
            </>
          ) : null}
        </DivRow>
      </Container>
    </>
  )
}
