import styled from 'styled-components'

export const ContainerStep = styled.div``

export const SubscribeInformation = styled.div`
  padding: 1.875rem;
  background: ${props => props.theme.bgColors.primaryDarken03};

  h1 {
    font-weight: 700;
    font-size: 2rem;
    color: ${props => props.theme.bgColors.primaryDefault};
  }

  p {
    font-weight: 400;
    font-size: 1.25rem;
    &.rankingText {
      font-weight: 600;
      font-size: 1.25rem;
    }
  }
`
export const ButtonsStepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    &:hover {
      filter: brightness(0.8);
    }
    transition: filter 0.2s;
    padding: 8px 24px;
    border-radius: 20px;
    border: 1px solid ${props => props.theme.bgColors.primaryDefault};
    background: ${props => props.theme.bgColors.primaryDefault};
    font-weight: bold;
    color: ${props => props.theme.bgColors.colorSecondary01};

    @media screen and (max-width: 992px) {
      margin-top: 1rem;
    }
  }

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }

  a {
    &:hover {
      filter: brightness(0.8);
    }
    cursor: pointer;
    transition: filter 0.2s;
    padding: 8px 24px;
    border-radius: 20px;
    border: 1px solid ${props => props.theme.bgColors.primaryDefault};
    background: ${props => props.theme.bgColors.primaryDefault};
    font-weight: bold;
    color: white !important;
    color: ${props => props.theme.bgColors.colorSecondary01};

    @media screen and (max-width: 992px) {
      margin-top: 1.5rem;
      width: 100%;
      text-align: center;
    }
  }
`
export const LeftButtons = styled.div`
  display: flex;
  gap: 1rem;

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background: transparent;
    border-radius: 42px;
    border: 0;
    color: ${props => props.theme.bgColors.primaryDefault};

    &:hover {
      filter: brightness(0.8);
    }
    transition: filter 0.2s;
    &.editSub {
      border: 1px solid ${props => props.theme.bgColors.primaryDefault};
    }
  }

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
`

export const ConfirmedCourse = styled.div`
  h2 {
    margin: 2rem 0;
    font-size: 1.5rem;
  }
`

export const HashContainer = styled.div`
  transition: filter 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3.875rem;

  h3 {
    font-weight: 600;
    font-size: 1.25rem;
  }

  p {
    font-size: 0.75rem;

    margin-top: 1.5rem;
  }

  p + p {
    margin-top: 1rem;
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 16px 15px;
    gap: 4px;
    font-weight: bold;
    color: #1351b4;
    background-color: #fff;
    font-weight: 1.25rem;
    width: 356.04px !important;
    height: 48px;

    border: 1px solid #1351b4;
    border-radius: 42px;

    @media screen and (max-width: 992px) {
      width: 100% !important;
    }

    transition: all ease 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
  }

  transition: filter 0.2s;
`

export const TitlePage = styled.h2`
  font-weight: 700;
  font-size: 1.5rem;
`

export const LastCoursesContainer = styled.div`
  padding: 1rem;
  background: #f3f3f3;
  h2 {
    font-size: 1.25rem;

    color: #585858;
    font-weight: 600;
  }
`

export const StatusVacancies = styled.h3`
  margin-top: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: #585858;
`
