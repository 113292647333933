import styled from 'styled-components'

export const Wrapper = styled.section`
  padding: 52px 0;
  margin: 52px 0;

  background-color: #fafafa;
`

export const TextDiv = styled.div`
  margin: 56px 0 36px 0;
  padding: 0;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
  }
`

export const ButtonsDiv = styled.div`
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  a {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 42px;
    min-width: 179px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;

    @media (max-width: 768px) {
      margin-top: 25px;
    }

    &:hover {
      filter: brightness(0.8);
    }

    &.prev,
    &.middle {
      border: 1px solid #1351b4;
      color: #1351b4;
    }

    &.next {
      color: #ffffff;
      background-color: #1351b4;
    }
  }
`

export const LoadingFixedScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999999999999999;
  height: 100vh;
  width: 100vw;
  background: #00000030;

  display: flex;
  align-items: center;
  justify-content: center;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
