import styled from 'styled-components'

import { FONT_FAMILY } from 'constants/fonts'

import stepImgOn from './img/step_loading_on.svg'
import stepImgOff from './img/step_loading_off.svg'

export const Container = styled.div`
  margin-top: 32px;
  max-width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 576px) {
    margin-top: 15px;
  }

  .row {
    width: 1140px;
    max-width: 100%;
    background-color: var(--loading-page);
    border-radius: 8px;
    padding: 34px 60px 0 60px;
    margin-right: 15px;
    margin-left: 15px;

    @media (max-width: 900px) {
      padding: 15px 30px 15px 30px;
    }
  }
`

export const ImgContainer = styled.img`
  width: 100%;

  @media (max-width: 576px) {
    max-width: 70%;
  }
`

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 10px;

  h2 {
    font-family: ${FONT_FAMILY};
    color: var(--loading-page-text);
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    padding: 0;

    @media (max-width: 1100px) {
      margin: 10px 0;
    }
  }

  p {
    font-family: ${FONT_FAMILY};
    color: var(--loading-page-text);
    font-style: normal;
    margin-bottom: 40px;

    @media (max-width: 1100px) {
      margin-bottom: 20px;
    }
    @media (max-width: 800px) {
      font-size: 16px;
    }
  }
`

export const LoadingItem = styled.img`
  width: 281px;
  max-width: 100%;
  margin-bottom: 5px;
  transition: all 3s ease;

  @media (max-width: 800px) and (min-width: 576px) {
    max-width: 80%;
  }

  &.done {
    content: url(${stepImgOn});
  }

  &.processing {
    content: url(${stepImgOff});
  }
`

export const DivImg = styled.div`
  padding-right: 60px;

  @media (max-width: 800px) {
    padding-right: 0;
  }
`

export const Wrapper = styled.div`
  padding: 0;
  margin: 0;
  z-index: 999999999999999999999;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d4dce7;
`
