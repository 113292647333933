/* eslint-disable react/prop-types */
import React from 'react'

import { Button, Grid } from '@material-ui/core'

import { Dialog, DialogTitle, DialogContent, DialogActions } from '../Dialog'

export const ConfirmDialog = ({
  open,
  title,
  onConfirm,
  onCancel,
  children,
}) => (
  <Dialog open={open}>
    {title && <DialogTitle>{title}</DialogTitle>}
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          <Button variant="outlined" onClick={onCancel}>
            Não
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={onConfirm}>Sim</Button>
        </Grid>
      </Grid>
    </DialogActions>
  </Dialog>
)
